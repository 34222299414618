.notificationsPopup {
  position: absolute;
  top: calc(100% + 1rem);
  padding: 1em 0.5em;
  width: 20rem;
  border-radius: 0.7em;
  right: -2rem;
  background: var(--clr-light);
  box-shadow: 0 0 1rem #0002;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  visibility: hidden;
}

.notificationsPopup.show {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.notificationsPopup::after {
  content: "";
  position: absolute;
  top: -0.5rem;
  right: 2rem;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  /* border-top: 1rem solid red; */
  border-bottom: 1rem solid var(--clr-light);
}

.notificationsPopup li {
  /* width: fit-content; */
  align-items: flex-start;
}

.notificationsPopup li img {
  width: 100%;
  max-width: 2.5rem;
  border-radius: 50%;
  margin-right: 0.75rem;
}

/* .notificationsPopup__img {
  margin-right: 0.75rem;
} */

.notificationsPopup li {
  padding: 0.5em 0.75em;
  border-radius: 0.7em;
}

.notificationsPopup li:hover {
  background: var(--clr-light-gray);
}

.notificationsPopup li + li {
  margin-top: 0.25rem;
}

.notifications__messages h3 {
  font-size: 0.85rem;
}

.notifications__messages h3 span {
  font-weight: 400;
}

.notifications__messages svg {
  display: flex;
  margin-bottom: 0.1rem;
  margin-right: 0.5rem;
}

.notifications__time {
  margin-top: 0.2rem;
  color: var(--clr-gray500);
}

.notifications__time span {
  font-size: 0.75rem;
}

.notifications__messages {
  width: 100%;
}
