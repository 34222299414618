.quizTeacher {
  /* margin-top: -1rem; */
  flex-wrap: wrap;
  /* gap: 0.25rem; */
  align-items: flex-start;
}

.quizTeacher__btn {
  border-radius: 0.5em;
  background: transparent;
  padding: 0.75em 1em;
  margin-left: auto;
  border: 0.1rem solid var(--clr-gray300);
}
