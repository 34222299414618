.watchVideo {
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  /* padding: 1em; */
}

.watchVideo small {
  color: var(--clr-gray);
}

.watchVideo__playedVideo img {
  width: 100%;
  /* height: 100%; */
  /* max-height: 28rem; */
  aspect-ratio: 16 / 9;
  border-radius: 0.5em;
}

.watchVideo__playedVideo {
  width: 100%;
  position: relative;

  /* width: fit-content; */
}

.watchVideo__playedVideo iframe {
  width: 100%;
  border-radius: 0.5em;
  aspect-ratio: 16 / 9;

  /* height: 100%; */
}

/* .watchVideo__playedVideo::after {
  content: "";
  left: 0;
  border-radius: 0 0 0.5em 0.5em;
  position: absolute;
  width: 100%;
  height: 3.5rem;
  bottom: 0.4rem;
  background: linear-gradient(to bottom, #0000, #000b);
} */

.watchVideo__playedAction {
  position: absolute;
  gap: 1rem;
  width: calc(100% - 2rem);
  bottom: 1.5rem;
  z-index: 1;
  left: 1rem;
}

.watchVideo__playedAction svg {
  cursor: pointer;
}

.watchVideo__progressBar {
  position: relative;
  width: 100%;
  cursor: pointer;
  height: 0.3rem;
  border-radius: 2rem;
  background: var(--clr-dark-gray);
}

.watchVideo__progressBar::after {
  content: "";
  position: absolute;
  width: 60%;
  border-radius: inherit;
  height: 100%;
  background: var(--clr-primary);
}

.watchVideo__playedDetails {
  margin-top: 0.5rem;
}

.watchVideo__playedDetailsTitle {
  justify-content: space-between;
  /* align-items: flex-start; */
  flex-direction: column;
}

.watchVideo__playedDetailsAction {
  font-size: 0.8rem;
  border-radius: 3em;
  padding: 0.15em;
  margin-top: 1rem;
  background: var(--clr-modal-light);
  border: 0.1rem solid var(--clr-gray300);
}

body.dark-mode .watchVideo__playedDetailsAction {
  background: var(--clr-modal);
  border: 0.1rem solid var(--clr-secondary-dark);
}

.watchVideo__playedDetailsActionUnlike {
  padding: 0.25em;
}
.watchVideo__playedDetailsActionLike {
  padding: 0 0.5em;
  margin-right: 0.5em;
  cursor: pointer;
  font-weight: 500;
  color: var(--clr-gray400);
  /* background: red; */
  border-right: 0.1rem solid var(--clr-gray300);
}

body.dark-mode .watchVideo__playedDetailsActionLike {
  border-right: 0.1rem solid var(--clr-secondary-dark);
}
.watchVideo__playedDetailsAction svg {
  cursor: pointer;
}

.watchVideo .watchVideo__playedDetailsActionGift {
  color: var(--clr-primary);
  background: transparent;
}
/* body.dark-mode .watchVideo__playedDetailsActionGift {
  color: var(--clr-light);
} */

.watchVideo__playedDetailsActionUnlike,
.watchVideo__playedDetailsActionLike svg,
.watchVideo__playedDetailsActionGift svg {
  margin-right: 0.2rem;
}

.watchVideo__playedDetailsActionLike.liked {
  color: var(--clr-primary);
}
/* .watchVideo__playedDetailsActionUnlike.unliked svg path {
  fill: var(--clr-invalid);
}

.watchVideo__playedDetailsActionLike.liked svg path {
  fill: var(--clr-primary);
} */
.watchVideo__playedDetailsActionUnlike.unliked svg rect {
  fill: var(--clr-invalid);
}

.watchVideo__playedDetailsActionLike.liked svg rect {
  fill: var(--clr-primary);
}

.watchVideo__playedDetailsTitle h1 {
  font-size: 1.1rem;
}

.watchVideo__playedDetailsTitle small {
  font-size: 0.8rem;
}

.watchVideo__more {
  margin-top: 1rem;
  padding: 1rem 0;
  border-bottom: 0.1rem solid var(--clr-light-gray);
  border-top: 0.1rem solid var(--clr-light-gray);
}
body.dark-mode .watchVideo__more {
  border-bottom: 0.1rem solid var(--clr-secondary-dark);
  border-top: 0.1rem solid var(--clr-secondary-dark);
}

.watchVideo__channel {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  justify-content: space-between;
}

.watchVideo__channelInfo {
  cursor: pointer;
}

.watchVideo__channelInfo h4 {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: -0.3rem;
  margin-right: 0.3rem;
}

.watchVideo__channelInfo small {
  font-size: 0.75rem;
}

.watchVideo__channelImg img {
  width: 2.8rem;
  height: 2.8rem;
  object-fit: cover;
  margin-right: 0.5rem;
  border-radius: 50%;
}

.watchVideo__channelImg.svg img {
  background: var(--clr-light);
  padding: 0.3em;
}

.watchVideo__commentAction {
  width: 100%;
}

.watchVideo__commentAction button,
.watchVideo__channel button {
  border-radius: 0.7em;
  padding: 0.75em 1.5em;
}

.watchVideo__channelInfoBtn button:first-child.primary {
  color: var(--clr-light);
}
.watchVideo__channelInfoBtn button:first-child {
  color: var(--clr-gray400);
  font-weight: 600;
}

.watchVideo__channelInfoBtn button:first-child svg {
  margin-right: 0.5rem;
}

.watchVideo__channelInfoBtn button:last-child {
  background: transparent;
  /* color: var(--clr-light); */
  margin-left: 0.5rem;
  color: var(--clr-gray400);
  font-weight: 600;
  padding: 0.7em 1.75em;
  border: 0.1rem solid var(--clr-primary);
}

.watchVideo__channelInfoBtn button:last-child svg {
  margin-right: 0.5rem;
}

.watchVideo__desc {
  margin-top: 1rem;
  max-height: 6rem;
  overflow: hidden;
}

.watchVideo__desc.more {
  max-height: fit-content;
  overflow: initial;
}

.watchVideo__desc p {
  font-size: 0.8rem;
  line-height: 1.5;
}

.watchVideo__descSeeMore {
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-top: 0.5rem;
  letter-spacing: 0.05rem;
  cursor: pointer;
  color: var(--clr-gray);
}

.watchVideo__other {
  gap: 1rem;
  flex: 1;
}

.watchVideo__comment {
  padding-bottom: 5em;
}

.watchVideo__comment img {
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 0.5rem;
}

.watchVideo__commentInputContainer {
  position: initial;
  align-items: flex-start;
  /* top: 100%; */
  /* width: calc(100% - 34rem); */
  /* max-width: 50rem; */
  margin-top: 1rem;
  /* transform: translateY(-100%); */
  /* padding: 1em 1.8em; */
  /* background: var(--clr-dark); */
}

/* .watchVideo__commentInputContainer.fix {
  position: fixed;
  top: 100%;
  width: 100%;
  max-width: 50rem;
  margin-top: 0;
  margin-left: -1.8em;
  transform: translateY(-100%);
  padding: 1em 1.8em;
  background: var(--clr-dark);
} */

.watchVideo__commentInput {
  width: 100%;
  padding: 1em 1.25em;
  border-radius: 1em;
  resize: none;
  font-size: 0.8rem;
  height: 3rem;
  z-index: 1;
  scrollbar-width: none;
  color: var(--clr-darker);
  background: var(--clr-modal-light);
  border: 0.1rem solid var(--clr-gray300);
}

body.dark-mode .watchVideo__commentInput {
  border: 0.15rem solid var(--clr-hover-dark);
  background: var(--clr-modal);
  color: var(--clr-light);
}

.watchVideo__commentInput label {
  position: absolute;
  left: 1em;
  top: 50%;
  color: var(--clr-gray400);
  transform: translateY(-50%);
  pointer-events: none;
}

/* .watchVideo__commentInput:focus-within.watchVideo__commentInput::after {
  display: none;
} */

/* .watchVideo__commentInput::after {
  content: "Comment what you thoughts";
  position: absolute;
  z-index: -1;
  font-size: 0.8rem;
  pointer-events: none;
  color: var(--clr-gray400);
} */

.watchVideo__commentInput::-webkit-scrollbar {
  display: none;
}

.watchVideo__commentAction {
  width: 100%;
  align-items: flex-end;
  gap: 0.8rem;
}

.watchVideo__commentAction button:first-child {
  margin-right: 0.5rem;
  background: transparent;
  color: var(--clr-gray);
}
/* ad */

.watchVideo__autoPlay {
  width: 100%;
  /* cursor: pointer; */
  position: sticky;
  top: 0;
  z-index: 1;
  justify-content: space-between;
  padding: 0.75em 1.25em;
  border-radius: 0.7em;
  margin-bottom: 0.5rem;
  background: var(--clr-modal-light);
  border: 0.1rem solid var(--clr-gray300);
}

.watchVideo__autoPlay::before {
  content: "";
  position: absolute;
  width: calc(100% + 2rem);
  left: -1rem;
  z-index: -2;
  height: calc(100% + 3rem);
  background: var(--clr-light);
  top: -2rem;
}

.watchVideo__autoPlay::after {
  content: "";
  position: absolute;
  width: 100%;
  z-index: -2;
  height: 100%;
  background: inherit;
  top: 0;
  border-radius: inherit;
  border: inherit;
  left: 0;
}
body.dark-mode .watchVideo__autoPlay {
  background: var(--clr-modal);
  border: 0.1rem solid var(--clr-secondary-dark);
}

.watchVideo__autoPlay p {
  font-size: 0.9rem;
}

.watchVideo__toggle {
  cursor: pointer;
  position: relative;
  width: 3.5rem;
  height: 1.75rem;
  border-radius: 3em;
  background: rgb(235, 235, 235);

  padding: 0.25em;
  transition: background 0.2s ease-in-out;
}
body.dark-mode .watchVideo__toggle {
  background: var(--clr-hover-dark);
}

/* .watchVideo.white-mode .watchVideo__toggle.active,
.watchVideo__toggle.active {
  background: var(--clr-primary);
} */

.watchVideo__toggle input {
  visibility: hidden;
  pointer-events: none;
}

.watchVideo__toggleIcon {
  position: absolute;
  top: 50%;
  width: 1.25rem;
  height: calc(100% - 0.5em);
  transform: translateY(-50%);
  left: 0.25rem;
  cursor: pointer;
  background: var(--clr-gray);

  transition: left 0.3s ease-in;
}

body.dark-mode .watchVideo__toggleIcon {
  background: var(--clr-darker);
}

.watchVideo__toggle input:checked ~ .watchVideo__toggleIcon {
  left: calc(100% - 1.5rem);
  background: var(--clr-primary);
}

@media (min-width: 450px) {
  .watchVideo__channel {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 900px) {
  .watchVideo {
    flex-direction: row;
    gap: 0;
    margin-top: -1rem;
    width: calc(100% + 4rem);
    margin-left: -2rem;
    margin-bottom: -5rem;
    height: calc(100vh - 4rem);
  }

  .watchVideo__played {
    padding: 2em 2em 1em 2em;
  }

  .watchVideo__other {
    padding: 2em 2em 1em 0;
  }

  .watchVideo__channelInfo {
    width: 100%;
  }

  .watchVideo__comment {
    width: inherit;
  }

  .watchVideo__commentInputContainer {
    align-items: flex-start;
    /* position: fixed; */
    /* top: 90%; */
    opacity: 1;
    margin-top: 0;
    margin-left: -1.8em;
    /* bottom: 50%; */
    /* transform: translateY(-90%); */
    bottom: 0rem;
    padding: 1em 1.8em;
    background: var(--clr-light);
  }

  .watchVideo__commentInputContainer.fix {
    position: fixed;
    /* top: 100%; */
    /* width: 100%; */
    /* max-width: 50rem; */
    margin-top: 0;
    opacity: 1;
    /* transform: translateY(-100%); */
    background: var(--clr-light);
  }

  body.dark-mode .watchVideo__commentInputContainer {
    background: var(--clr-dark-background);
  }

  .watchVideo__other,
  .watchVideo__played {
    overflow-y: scroll;
    /* height: 100vh; */
    height: calc(100vh - 4rem);

    scrollbar-width: none;
  }

  .watchVideo__played {
    flex: 2.5;
    /* max-width: 48rem; */
  }

  /* .watchVideo__playedVideo iframe {
    max-width: 48rem;
  } */

  .watchVideo__other::-webkit-scrollbar,
  .watchVideo__played::-webkit-scrollbar {
    display: none;
  }

  .watchVideo__playedDetailsTitle {
    flex-direction: row;
  }

  .watchVideo__playedDetailsAction {
    margin-top: 0;
  }
}
