.track {
  /* justify-content: center;
  height: calc(100vh - 8.5rem); */
  margin-top: 1rem;
}
.track ul {
  /* display: flex; */
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  gap: 1rem;
  margin-top: 2rem;
  /* flex-wrap: wrap; */
  z-index: 1;
}

.track ul li {
  /* flex: 1 1 25%; */
  display: flex;
  align-items: center;
  max-width: 15rem;
  flex-direction: column;
}

.track ul li.completed > div {
  background: var(--clr-primary);
}

.track ul li.completed > div svg {
  fill: var(--clr-light);
}

.track ul li > div {
  padding: 1.5em;
  border-radius: 50%;
  /* background: #c1deff; */
  background: var(--clr-primary-light);
}
.track ul li > div svg {
  display: flex;
  width: 1.8rem;
  fill: var(--clr-primary);
}
.track ul li:last-child > div svg {
  fill: none;
  stroke: var(--clr-primary);
}

.track ul li h3 {
  text-align: center;
  margin: 1rem 0 0.25rem 0;
}
.track ul li span {
  text-align: center;
  font-size: 0.9rem;
}
.track__desc {
  display: block;
  text-align: center;
  margin: 1rem 0;
  font-size: 0.9rem;
  color: var(--clr-text-dark);
  max-width: 45rem;
}

/* dropdown */

.track__dropdownSearch {
  width: 100%;
  max-width: 15rem;
  margin: 1.5rem 0;
}

.track__dropdownSearch .dropdown-container {
  font-size: 0.8rem;
  width: 100%;
  padding: 1em 1em;
  border: 0.1rem solid #0005;
  border-radius: .5em;
}
.track__dropdownSearch .dropdown-container.expand {
  border-radius: 1em;
  border: none;
  border: 0.1rem solid transparent;
}

.track__form.shop-page .track__dropdownSearch .dropdown-container {
  display: flex;
}

.track__dropdownSearch .dropdown-container p {
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 0.1rem;
  color: var(--clr-text-dark);
}

.track__dropdownSearchIcon {
  transform: rotate(0deg);
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
}
body.dark-mode .track__dropdownSearch .dropdown-container p {
  color: var(--clr-light);
}
.track__dropdownSearch .dropdown-item-container {
  top: calc(100% + 0.75rem);
  border-radius: 0.5em;
  z-index: 10;
  box-shadow: 0 0 0.5rem #0002;
}
.track__dropdownSearch .dropdown-item-container label {
  font-weight: 600;
  padding: 1em 1em;
  font-size: 0.8rem;
}

.track__dropdownSearch .dropdown-item-container label.menu-active {
  color: var(--clr-primary);
  position: relative;
}
.track__dropdownSearch .dropdown-item-container label.menu-active::after {
  content: "";
  position: absolute;
  height: 90%;
  left: 0;
  width: 0.15rem;
  background: var(--clr-primary);
}

.track__dropdownSearch .dropdown-item-container label span {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  border: 0.1rem solid var(--clr-gray500);
}
.track__dropdownSearch .dropdown-item-container label input:checked ~ span {
  border: 0.35rem solid var(--clr-primary);
}

.track__dropdownSearch .dropdown-item-container label.menu-active img {
  filter: invert(41%) sepia(53%) saturate(7452%) hue-rotate(204deg)
    brightness(105%) contrast(100%);
}

.track__dropdownSearch .dropdown-container .track__dropdownSearchArrow {
  width: 0.5rem;
  height: 0.5rem;
}

body.dark-mode .track__dropdownSearchIcon {
  filter: invert(1) sepia(100%) saturate(1%) hue-rotate(183deg) brightness(200%)
    contrast(101%);
}

body.dark-mode
  .track__dropdownSearch
  .dropdown-container
  .track__dropdownSearchArrow
  path,
.track__dropdownSearch .dropdown-container .track__dropdownSearchArrow path {
  stroke: var(--clr-primary);
}
.track__dropdownSearch {
  display: initial;
  justify-content: space-between;
  position: relative;
  font-size: 1rem;
}

@media (min-width: 600px) {
  .track ul::after {
    content: "";
    position: absolute;
    width: 80%;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 0.1rem;
    top: 2.5rem;
    z-index: -2;
    background: var(--clr-primary-light);
  }

  .track ul::before {
    content: "";
    width: 25%;
    position: absolute;
    height: 0.1rem;
    left: 5.5rem;
    z-index: -1;
    top: 2.5rem;
    background: var(--clr-primary);
  }
}
