.settingAccount {
  width: 100%;
}

.settingAccount__profileDescContainer {
  align-items: flex-start;
  /* flex-direction: column; */
  gap: 1rem;
}

.settingAccount__profileDescContainer img {
  /* width: 100%; */
  height: 5.5rem;
  /* max-width: 6rem; */
  border-radius: 0.5em;
}

.settingAccount__profileDesc {
  align-items: flex-start;
}

.settingAccount__profileDesc h2 {
  font-size: 1.1rem;
  font-weight: 500;
}

.settingAccount__profileDesc small {
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--clr-primary);
}

.settingAccount__profileDesc button {
  font-size: 0.8rem;
  padding: 0.5em 1em;
  margin-top: 0.7rem;
}

/* form  */

.settingAccount__formContainer {
  margin-top: -2rem;
}

.settingAccount__profileInputSections {
  justify-content: space-between;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1.75rem 0;
}

.settingAccount__profileInputContainer {
  width: 100%;
  align-items: flex-start;
  position: relative;
}

.settinAccount__changes {
  font-size: 0.7rem;
  margin-top: 0.2rem;
  margin-left: 0.5rem;
  position: absolute;
  bottom: -1rem;
  left: 0;
  color: var(--clr-success);
}

.settingAccount__profileInputContainer .dropdown-container {
  width: 100%;
  padding: 1em 1.25em;
  font-size: 0.85rem;
  /* background: var(--clr-modal-light); */
  border: 0.15rem solid var(--clr-gray300);
  /* box-shadow: 0rem 0rem 0.5rem #0002; */
  border-radius: 0.75em;
  font-weight: 500;
}

.settingAccount__profileInputContainer .dropdown-container.expand {
  box-shadow: none;
}

body.dark-mode
  .settingAccount__profileInputContainer
  .dropdown-container.expand {
  box-shadow: none;
}

.settingAccount__profileInputContainer .dropdown-item-container {
  width: calc(100% - 0.1rem);
  top: 3.5rem;
  font-weight: 500;
  left: 0.05rem;
  background: var(--clr-modal-light);
  border: 0.1rem solid var(--clr-gray300);
  border-radius: 0.75em;
}

body.dark-mode .settingAccount__profileInputContainer .dropdown-container,
body.dark-mode .settingAccount__profileInputContainer .dropdown-item-container {
  background: var(--clr-modal);
  color: var(--clr-gray);
  border: 0.1rem solid var(--clr-hover-dark);
}

body.dark-mode
  .settingAccount__profileInputContainer
  .dropdown-item-container
  label:first-child,
.settingAccount__profileInputContainer
  .dropdown-item-container
  label:first-child {
  background: none;
}

.settingAccount__profileInputContainer .dropdown-item-container.expand {
  max-height: 14rem;
}
.settingAccount__profileInputContainer .dropdown-item-container label {
  margin: 0;
  padding: 0.75em 1.5em;
}

body.dark-mode
  .settingAccount__profileInputContainer
  .dropdown-item-container
  input,
.settingAccount__profileInputContainer
  .settingAccount__profileInputSearch
  input {
  padding: 0.25em 0.5em;
  cursor: text;
  border: none;
}
.settingAccount__profileInputContainer
  .settingAccount__profileInputSearch
  input:focus-within {
  border: none;
}

.settingAccount__profileInputContainer input {
  width: 100%;
}

.settingAccount__profileInputContainer select {
  font-size: 0.85rem;
  padding: 0.75em 1.25em;
  width: 100%;
  border: 0.1rem solid var(--clr-gray);
  background: var(--clr-light-gray);
  border-radius: 1em;
  position: relative;
  color: var(--clr-darker);
  outline: none;
}

.settingAccount__dropdownIcon {
  position: absolute;
  right: 0;
  top: 55%;
  transform: rotate(-90deg) translateY(-55%);
  background: var(--clr-light-gray);
}
body.dark-mode .settingAccount__dropdownIcon {
  background: var(--clr-modal);
}

.settingAccount__dropdownIcon path {
  stroke: var(--clr-gray);
}
body.dark-mode .settingAccount__dropdownIcon path {
  stroke: var(--clr-light);
}

body.dark-mode .settingAccount__profileInputContainer select {
  color: #fff;
  background: var(--clr-modal);
  border: 0.15rem solid var(--clr-hover-dark);
  box-shadow: none;
}

.settingAccount__btnUpdate {
  width: 100%;
  border-radius: 1em;
  margin-top: 1.5rem;
}

.settingAccount__bio p {
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 0.5rem;
}

.settingAccount__bio > div:first-child svg {
  display: flex;
  width: 1rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.settingAccount__bioEdit {
  border: 0.15rem solid var(--clr-dark-background);
  padding: 1em;
  border-radius: 0.25em;
}

.settingAccount__btnBioContainer {
  margin-top: 1rem;
  gap: 1rem;
  justify-self: flex-end;
}

.settingAccount__btnBioContainer button {
  border-radius: 0.5em;
  padding: 0.75em 1em;
}

/* language */

.settingAccount__dropdown {
  min-height: 3.5rem;
  cursor: pointer;
}

.settingAccount__selectedLanguages {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  flex-wrap: wrap;
}

.settingAccount__selectedLanguage {
  padding: 0.25em 0.5em;
  border-radius: 3em;
  background: var(--clr-gray300);
}
.settingAccount__dropdownLists {
  position: absolute;
  background: var(--clr-light);
  width: 100%;
  max-height: 10rem;
  pointer-events: none;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: scaleY(0);
  visibility: hidden;
  border: 0.15rem solid var(--clr-primary);
  border-top: none;
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 0 0 1em 1em;
  z-index: 10;
  transform-origin: top;
  scrollbar-track-color: transparent;
  scrollbar-width: thin;
}

.settingAccount__identity .settingAccount__dropdownLists {
  top: -5.75rem;
  transform-origin: bottom;
}

.settingAccount__dropdownLists.show {
  pointer-events: all;
  transform: scaleY(1);
  visibility: visible;
}

.settingAccount__countryLists::-webkit-scrollbar,
.settingAccount__dropdownLists::-webkit-scrollbar {
  width: 0.5rem;
  cursor: pointer;
}

.settingAccount__countryLists::-webkit-scrollbar-track,
.settingAccount__dropdownLists::-webkit-scrollbar-track {
  background-color: transparent;
}

.settingAccount__countryLists::-webkit-scrollbar-thumb,
.settingAccount__dropdownLists::-webkit-scrollbar-thumb {
  background: var(--clr-primary);
  border-radius: 3rem;
}

.settingAccount__dropdownLists li {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1em;
  cursor: pointer;
  position: relative;
  z-index: -1;
}
.settingAccount__dropdownLists label {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
}
.settingAccount__dropdownLists li input[type="checkbox"] {
  display: none;
  cursor: pointer;
}
.settingAccount__dropdownLists li input[type="checkbox"]:checked ~ label span {
  border: 0.1rem solid var(--clr-primary);
  background: var(--clr-primary);
}
.settingAccount__dropdownLists li input[type="checkbox"]:checked ~ label {
  color: var(--clr-primary);
  font-weight: 700;
}

.settingAccount__dropdownLists span {
  display: block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  border-radius: 0.25em;
  position: relative;
  border: 0.1rem solid var(--clr-gray);
}

.settingAccount__dropdownLists
  li
  input[type="checkbox"]:checked
  ~ label
  span::after {
  content: url("data:image/svg+xml,%3Csvg width='11' height='10' viewBox='0 0 33 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 12L12 21.5L30.5 3' stroke='white' stroke-width='7' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  position: absolute;
  top: -0.45rem;
  left: 50%;
  transform: translateX(-50%);
}

/* country */
.settingAccount__countryLists {
  max-height: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  border-radius: 1em;
  top: 3.5rem;
  pointer-events: none;
  background: var(--clr-light);
  box-shadow: 0 0.5rem 1.25rem #0002;
  width: 100%;
  transition: height 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* transition: max-height 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  z-index: 5;
  scrollbar-width: none;
}

.settingAccount__countryLists.show {
  max-height: calc(100% + 8rem);
  pointer-events: all;
}

.settingAccount__countryLists li {
  padding: 1em;
  cursor: pointer;
}

.settingAccount__languages li:hover,
.settingAccount__countryLists li:hover {
  background: #0001;
}

/* identity & gender*/
.settingAccount__gender input[type="radio"] {
  display: none;
}

.settingAccount__gender .input__label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.settingAccount__gender .input__label img {
  margin-right: 0.5rem;
  width: 0.9rem;
  filter: invert(83%) sepia(17%) saturate(221%) hue-rotate(169deg)
    brightness(89%) contrast(91%);
}

.settingAccount__gender.open-dropdown .input__label img {
  filter: invert(42%) sepia(98%) saturate(4685%) hue-rotate(200deg)
    brightness(101%) contrast(102%);
}

.settingAccount__gender .settingAccount__dropdown {
  text-transform: capitalize;
}

.settingAccount__gender.settingAccount__dropdown.error ~ .input__label img {
  filter: invert(22%) sepia(39%) saturate(6019%) hue-rotate(344deg)
    brightness(122%) contrast(84%);
}

@media (min-width: 900px) {
  .settingAccount__profileInputSections {
    flex-direction: row;
  }
}
