.profileContent {
  width: 100%;
  margin-top: -1rem;
  align-items: flex-start;
}
/* 
.profileContent.missions {
  margin-top: 4rem;
} */

.profileContent__about {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 1.5em;
}

.profileContent__profile ul li > svg {
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
}

.profileContent__profile ul li.message-tab-profile > svg {
  stroke: var(--clr-dark);
  fill: transparent;
  width: 1.3rem;
  height: 1.3rem;
}

.profileContent__img {
  position: relative;
  width: 100%;
  border-radius: 1em;
  overflow: hidden;
}

.profileContent__img:hover .profileContent__imgOverlay {
  visibility: visible;
  pointer-events: all;
  cursor: pointer;
  opacity: 1;
}

.profileContent__img:hover .profileContent__imgOverlay.hidden {
  visibility: hidden;
  pointer-events: none;
  cursor: default;
}

.profileContent__img img {
  display: block;
  width: 100%;
  max-height: 20rem;
  border-radius: 1em;
  object-fit: cover;
}

.profileContent__profile {
  width: 95%;
  margin: 0 auto;
  padding: 1.15em;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 1rem;
  margin-top: -3rem;
  position: relative;
  z-index: 5;
  border-radius: 1em;
  background: var(--clr-light);
  box-shadow: 0 0.5rem 1.25rem #0002;
  justify-content: space-between;
}

.profileContent__profileMainImg {
  display: flex;
  width: 100%;
  max-width: 5rem;
  height: 5rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 1em;
}

.profileContent__profileImg {
  width: 100%;
  object-fit: cover;
}

.profileContent__profileMainImg:hover .profileContent__imgOverlay {
  visibility: visible;
  pointer-events: all;
  cursor: pointer;
  opacity: 1;
}
.profileContent__profileMainImg:hover .profileContent__imgOverlay.hidden {
  visibility: hidden;
  pointer-events: none;
  cursor: default;
}

.profileContent__imgOverlay {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  background: #000a;
  height: 100%;
  transition: 0.25s;
}

.profileContent__imgOverlayImgInput {
  width: 100%;
  height: 20rem;
  top: 0;
  left: 0;
  position: absolute;
  cursor: pointer;
  z-index: 100;
  color: transparent;
  background: transparent;
}

.profileContent__imgOverlay svg {
  fill: var(--clr-primary);
}

.profileContent__imgOverlayImgInput {
  visibility: hidden;
}

.profileContent__imgOverlayImgInput::-webkit-file-upload-button {
  visibility: hidden;
}

.profileContent__imgOverlayImgInput::-moz-file-upload-button {
  visibility: hidden;
}

.profileContent__profileDetails {
  /* margin-left: 1rem; */
  margin: 1rem 0;
}

.profileContent__profileDetails h3 {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: -0.2rem;
  text-align: center;
}

.profileContent__profile > div:first-of-type {
  flex-direction: column;
}

.profileContent__profileDetails h3 svg {
  width: 1.1rem;
  margin-bottom: -0.1rem;
  margin-left: 0.2rem;
}

.profileContent__profileDetails span {
  font-size: 0.8rem;
  font-weight: 400;
  cursor: pointer;
  text-align: center;
  margin-top: 0.3rem;

  /* color: var(--clr-primary); */
}

.profileContent__profileDetails span:hover svg {
  display: flex;
}

.profileContent__profileDetails span svg {
  display: none;
  width: 1.2rem;
  margin-right: 0.2rem;
}

.profileContent__profile ul {
  font-size: 0.9rem;
}

.profileContent__profile ul li {
  padding: 0.5em 1em;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.3s ease-out;
}
.profileContent__profile ul li p {
  display: none;
  margin-left: 0.3rem;
  font-size: 0.8rem;
}
.profileContent__profile ul a {
  color: #000;
}

.profileContent__profile ul li svg {
  fill: var(--clr-dark);
}
.profileContent__profile ul li.active {
  border-radius: 0.5em;
  background: var(--clr-primary);
  color: var(--clr-light);
  box-shadow: 0 0.1rem 0.4rem #077bff71;
}
.profileContent__profile ul li.followed {
  border-radius: 0.5em;
  background: var(--clr-gray300);
  color: var(--clr-gray400);
  box-shadow: 0 0.1rem 0.4rem #0003;
}

.profileContent__profile ul li.active svg {
  fill: var(--clr-light);
}

.profileContent__profile ul li.followed svg {
  fill: var(--clr-gray400);
}

.profileContent__profile ul li.active.message-tab-profile svg {
  fill: transparent;
  stroke: var(--clr-light);
}
.profileContent__profile ul li.active.message-tab-profile svg path {
  stroke: var(--clr-light);
}

.profileContent__badges {
  position: absolute;
  gap: 0.5rem;
  right: 1rem;
  top: 1rem;
}

.profileContent__badges img {
  max-width: 2rem;
}

.profileContent__teacherHashtag {
  position: absolute;
  color: var(--clr-light);
  font-weight: 800;
  font-size: 1rem;
  /* font-size: 2rem; */
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
}

@media (min-width: 475px) {
  .profileContent__profile ul li p {
    display: inline;
    margin-left: 0.3rem;
  }

  .profileContent__teacherHashtag {
    font-size: 2rem;
  }
}

@media (min-width: 600px) {
  .profileContent__profile {
    margin-top: -5rem;
  }
}

@media (min-width: 900px) {
  .profileContent {
    margin-top: 1rem;
    /* height: calc(100vh - 8.5rem); */
    /* overflow: hidden; */
    /* margin-bottom: -3rem; */
  }
}

@media (min-width: 1200px) {
  .profileContent__profile {
    flex-direction: row;
  }

  .profileContent__profile > div:first-of-type {
    width: 100%;
    flex-direction: row;
  }

  .profileContent__profileDetails {
    margin: 0;
    margin-left: 1rem;
    align-items: flex-start;
  }
}
