.productDetail {
  height: 100%;
  margin-top: -1rem;
  margin-left: -2rem;
  /* background: red; */
  width: calc(100% + 3rem);
  align-items: flex-start;
  flex-direction: column;
}

.productDetail__imgContainer {
  height: 100%;
  width: 100%;
  /* max-width: 50rem; */
}

.productDetail__imgContainer img {
  /* width: 120rem; */
  width: 100%;
  object-fit: cover;
  max-width: inherit;
  height: 100%;
  max-height: 20rem;
}

.productDetail__details {
  width: 100%;
  /* height: 100%; */
  padding: 3em 4em;
  align-items: flex-start;
  scrollbar-width: none;
  height: 100%;

  /* background: red; */
  /* justify-content: space-between; */
}

.productDetail__details::-webkit-scrollbar {
  display: none;
}

.productDetail__kind {
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  background: var(--clr-primary);
  padding: 0.25em 0.75em;
  border-radius: 0.5em;
  color: var(--clr-light);
}

.productDetail__tabs {
  width: 100%;
  justify-content: space-between;
  padding-bottom: 0.75em;
  margin: 2rem 0 1rem 0;
  border-bottom: 0.2rem solid var(--clr-gray300);
}

.productDetail__tabs li {
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  padding-inline: 0.2em;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.productDetail__tabs li.active::after {
  content: "";
  position: absolute;
  height: 0.15rem;
  width: 100%;
  bottom: -1.15em;
  left: 0;
  background-color: var(--clr-primary);
}

.productDetail__detailsTop {
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}

.productDetail__price {
  flex: 1 1 10%;
  align-items: flex-start;
  margin-left: auto;
  font-size: 1rem;
  margin-top: 0.5rem;
  font-weight: 800;
}

.productDetail__detailsTop h1 {
  flex: 1 1 90%;
  margin-right: 1rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.productDetail__detailsRating {
  margin: 1rem 0 1.5rem 0;
}

.productDetail__detailsDesc {
  font-size: 0.9rem;
  color: var(--clr-gray500);
  margin-bottom: 1rem;
}
.productDetail__SizesContainer p,
.productDetail__ColorsContainer p,
.productDetail__detailsQtyTitle {
  letter-spacing: 2px;
}

.productDetail__Sizes + .productDetail__Sizes {
  cursor: pointer;
}

.productDetail__attribute {
  gap: 2rem;
}

.productDetail__detailsQtyContainer {
  margin-top: 0.5rem;
  gap: 1rem;
}

.productDetail__ColorsContainer ul,
.productDetail__SizesContainer ul {
  gap: 0.5rem;
}
.productDetail__SizesContainer,
.productDetail__ColorsContainer,
.productDetail__detailsQty {
  font-size: 0.7rem;
  width: 100%;
  max-width: 15rem;
  margin: 1rem 0;
  align-items: flex-start;
  /* background: red; */
  justify-content: space-between;
  color: var(--clr-gray500);
  text-transform: uppercase;
  font-weight: 600;
}

.productDetail__detailsQtyCountContainer {
  padding: 0.25em 0.75em;
  justify-content: space-between;
  width: 5rem;
  border-radius: 2em;
  border: 0.1rem solid var(--clr-gray500);
}

.productDetail__detailsQtyCount {
  color: var(--clr-darker);
  font-weight: 700;
}

body.dark-mode .productDetail__detailsQtyCount {
  color: var(--clr-light);
}

.productDetail__detailsQtyMinus,
.productDetail__detailsQtyPlus {
  display: flex;
  cursor: pointer;
  padding: 0.2em;
}

.productDetail__Sizes,
.productDetail__ColorsColor {
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0rem;
  margin-top: 0.5rem;
  position: relative;
  cursor: pointer;
  border: 0.1rem solid var(--clr-light);
}

.productDetail__ColorsColor svg {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 0.05rem;
  transform: translate(-50%, -50%);
}

.productDetail__ColorsColor.selected svg {
  display: flex;
}

.productDetail__Sizes.selected,
body.dark-mode .productDetail__ColorsColor {
  border: 0.3rem solid var(--clr-dark);
}

.productDetail__ColorsColor.selected {
  border: 0.1rem solid var(--clr-light);
  /* box-shadow: 0 0 0.2rem var(--clr-gray500); */
}

.productDetail__Sizes.selected {
  cursor: pointer;
  color: var(--clr-darker);
  font-weight: 700;
  border: 0.18rem solid var(--clr-gray300);
}

.productDetail__price span {
  font-size: 1rem;
  margin-top: 0.1rem;
  color: var(--clr-gray500);
}

.productDetail__bottom {
  margin-top: 1rem;
  width: 100%;
  padding-top: 1.5rem;
  border-top: 0.15rem solid var(--clr-gray300);
  justify-self: flex-end;
  justify-content: space-between;
}

body.dark-mode .productDetail__bottom {
  border-top: 0.1rem solid var(--clr-secondary-dark);
}

.productDetail__bottom {
  color: var(--clr-gray500);
  font-size: 0.8rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
}

.productDetail__bottom svg {
  margin-right: 0.5rem;
}

.productDetail__bottom button {
  width: 100%;
  max-width: 13rem;
  font-size: 0.7rem;
  font-weight: normal;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1.25em 1.25em;
}

.productDetail__bottom button:last-child {
  color: var(--clr-gray400);
  background: transparent;
}
.productDetail__bottom button:last-child svg {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

@media (min-width: 900px) {
  .productDetail {
    margin-top: -1.5rem;
    margin-bottom: -5rem;
    height: calc(100vh - 4rem);
    overflow: hidden;
    /* height: 90.5vh; */
    flex-direction: row;
    width: calc(100% + 4rem);
  }

  .productDetail__details {
    overflow-y: scroll;
  }

  .productDetail__imgContainer img {
    max-height: initial;
  }
}
@media (min-width: 1200px) {
  .productDetail {
    margin-top: -1rem;
  }
}
