.settingPassword {
  margin-top: -0.75em;
}

.settingPassword__profileInputSections {
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
}

.settingPassword__profileInputContainer {
  width: 100%;
  align-items: flex-start;
  position: relative;
}

.settingPassword__profileInputContainer label {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0.5rem 0;
}

.settingPassword__profileInputContainer input {
  width: 100%;
}

.settingPassword__requirements li {
  color: var(--clr-text-gray);
  font-size: 0.7rem;
}

.settingPassword__btnUpdate {
  width: 100%;
  border-radius: 1em;
  margin-top: 1.5rem;
  color: var(--clr-gray400);
  font-weight: 600;
  cursor: default;
}
.settingPassword__btnUpdate.primary {
  cursor: pointer;
}

.settingPassword__twoStep {
  padding-top: 2em;
  margin: 2rem 0;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-top: 0.1rem solid var(--clr-gray300);
}

.settingPassword__twoStepHeader {
  margin-bottom: 0.3rem;
}

.settingPassword__twoStepHeader h3 {
  color: var(--clr-text-dark);
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: -0.5rem;
}

.settingPassword__twoStepHeader span {
  font-size: 0.6rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: var(--clr-success);
}

.settingPassword__twoStepDesc {
  flex: 1;
  margin-right: 4rem;
}

.settingPassword__twoStepDesc p {
  color: var(--clr-text-gray);
  font-size: 0.8rem;
  line-height: 1.5;
  font-weight: 400;
}

.settingPassword__dropdownToggle {
  position: relative;
  width: 3.5rem;
  height: 1.75rem;
  background: var(--clr-light-gray);
  border-radius: 3em;
  cursor: pointer;
  padding: 0.25em;
  transition: background 0.2s ease-in-out;
}
body.dark-mode .settingPassword__dropdownToggle {
  background: var(--clr-dark-gray);
}

body.dark-mode .settingPassword__dropdownToggle.active,
.settingPassword__dropdownToggle.active {
  background: var(--clr-primary);
}

body.dark-mode .settingPassword__dropdownToggle {
  background: var(--clr-hover-dark);
}

.settingPassword__dropdownToggle input {
  visibility: hidden;
  pointer-events: none;
}

.settingPassword__dropdownToggleIcon {
  position: absolute;
  top: 50%;
  width: 1.25rem;
  height: calc(100% - 0.5em);
  background: var(--clr-gray);

  transform: translateY(-50%);
  left: 0.25rem;
  cursor: pointer;
  transition: left 0.3s ease-in;
}

body.dark-mode .settingPassword__dropdownToggleIcon {
  background: var(--clr-darker);
}

.settingPassword__dropdownToggle
  input:checked
  ~ .settingPassword__dropdownToggleIcon {
  left: calc(100% - 1.7em);
  background: var(--clr-light);
}

@media (min-width: 900px) {
  .settingPassword__profileInputSections {
    flex-direction: row;
  }
}
