.imagesPage {
  width: calc(100% + 4rem);
  height: calc(100vh - 4rem);
  overflow: hidden;
  margin: -1rem 0 -5rem -2rem;
}

/* https://api.unsplash.com/search/photos?query=office&client_id=Fwa70W-29DEtqss22FEqDEVVJUxsVdFvWMlVFTYtTic */

.imagesPage__hero {
  width: 100%;
  height: 100%;
  /* max-height: 30rem; */
  position: relative;
  z-index: 1;
}

.imagesPage__hero img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  /* filter: blur(.2rem); */
  max-height: inherit;
}

.imagesPage__main,
.imagesPage__hero::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.imagesPage__hero::after {
  content: "";
  background: #0007;
}

.imagesPage__main {
  width: 100%;
  max-width: 50rem;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-start;
  color: var(--clr-light);
  z-index: 1;
}

.imagesPage__main h1 {
  font-size: 2.5rem;
}

.imagesPage__main p {
  margin: 0.5rem 0 0.5rem 0;
}

.imagesPage__main form {
  width: 100%;
  background: var(--clr-light);
  padding: 0.75em 0.5em;
  margin-top: 0.5rem;
  border-radius: 0.5em;
}

.imagesPage__main form input {
  width: 100%;
}

.imagesPage__attribute {
  width: 100%;
  position: absolute;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--clr-gray500);
  padding: 0.5em 1em;
  font-size: 0.75rem;
  z-index: 1;
}

.imagesPage__attribute a {
  color: var(--clr-gray400);
}
