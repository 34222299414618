/* .videosTeacher {
  justify-content: center;
  height: calc(100vh - 4rem);
  overflow: hidden;
  text-align: center;
  width: 80%;
  margin: 0 auto;
} */

.videosTeacher img {
  width: 100%;
  max-width: 18rem;
}

.videosTeacher h1 {
  margin: 2rem 0;
  font-size: 1.8rem;
  line-height: 1.3;
}

.videosTeacher p {
  margin-bottom: 2rem;
  max-width: 37rem;
}
.videosTeacher form {
  height: 3.5rem;
  width: 100%;
  max-width: 28rem;
}

.videosTeacher form button {
  border-radius: 0.25em;
  height: 100%;
  text-transform: uppercase;
  margin-left: 0.5rem;
  padding: 0;
  flex: 1 1 30%;
}

.videosTeacher input {
  width: 100%;
  height: 100%;
  flex: 1 1 70%;
  border-radius: 0.25em;
  padding: 0.25em 1em;
  border: 0.1rem solid var(--clr-gray400);
}

/*  */

.videosTeacher__image img {
  display: flex;
  width: 100%;
  max-width: fit-content;
  height: 8rem;
  margin: 0 auto;
  object-fit: contain;
}

.videosTeacher__card span {
  font-size: 0.8rem;
}

.videosTeacher__card {
  background: var(--clr-light);
  /* background: var(--clr-gray300); */
  /* box-shadow: 0 5rem 3rem -4rem #077aff20; */
  /* box-shadow: 0 2.5rem 3rem -1rem #077aff30; */
  justify-content: center;
  transform: scale(1);
  cursor: pointer;
  padding: 2em;
  border: 0.1rem solid var(--clr-gray300);
  min-height: 25rem;
  text-align: center;
  border-radius: 1em;
  transition: 0.5s;
}

.videosTeacher__card:hover {
  transform: scale(1.02);
  /* border: 0.1rem solid var(--clr-gray300); */
  box-shadow: 0 3.5rem 4rem -2rem #0003;
  /* box-shadow: 0 3.5rem 4rem -2rem #077aff40; */
}

.videosTeacher a {
  text-align: center;
  color: var(--clr-text-dark);
  margin-top: 0.5rem;
  line-height: 1.8;
  font-size: 0.9rem;
}

.videosTeacher__card h4 {
  text-align: center;
  color: var(--clr-text-dark-header);
}

.videosTeacher__ .videosTeacher__card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.videosTeacher__container {
  place-items: center;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  gap: 2rem;
}

.videosTeacher__image svg {
  display: flex;
  width: 4rem;
  height: 4rem;
}
.videosTeacher__header {
  max-width: 30rem;
}

.videosTeacher__container span {
  font-size: 0.9rem;
  color: var(--clr-text-dark);
  text-align: center;
  line-height: 2;
}

.videosTeacher__container h4 {
  font-size: 1rem;
  margin: 1.25rem;
}

.videosTeacher__videContainer {
  display: grid;
  place-items: center;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 1rem;
}
.videosTeacher__videContainer.less-content {
  grid-template-columns: repeat(auto-fit, minmax(15rem, 15rem));
}

@media (min-width: 900px) {
  .videosTeacher {
    justify-content: center;
    margin-bottom: -5rem;
    height: calc(100vh - 5rem);
    /* overflow: hidden; */
  }
  .videosTeacher.list-video {
    justify-content: flex-start;
  }
}
