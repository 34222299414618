.topupGiftPopup {
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000b;
  z-index: 200;
}

.topupGiftPopup__container {
  /* width: 80%; */
  width: 100%;
  height: 100%;
  /* max-width: 60rem; */
  background: var(--clr-light);
  box-shadow: 0 0px 27px 0 rgb(0 0 0 / 50%);
  padding: 1em;
  overflow-y: scroll;
  scrollbar-width: none;
  margin: auto;
}

.topupGiftPopup__container::-webkit-scrollbar {
  display: none;
}

.topupGiftPopup__container h1 {
  text-align: center;
  font-size: 1.5rem;
}

/* radio btn */

.topupGiftPopup__radio {
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 2rem 0;
  font-size: 0.8rem;
  cursor: pointer;
}

.topupGiftPopup__radioTitle {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.topupGiftPopup__radio label {
  flex: 1 1 50%;
  background: var(--clr-primary-light);
  width: 100%;
  padding: 2.5em 2em;
  border-radius: 0.75em;
  align-items: flex-start;
  border: 0.15rem solid transparent;
}

.topupGiftPopup__radio label > div {
  width: 100%;
}

.topupGiftPopup__radio label.checked {
  border: 0.15rem solid var(--clr-primary);
}

.topupGiftPopup__radioText {
  width: 100%;
  cursor: pointer;
  /* justify-content: space-between; */
}

.topupGiftPopup__radioText span {
  margin: 0.5rem 0 0 0.4rem;
}

.topupGiftPopup__radioText {
  font-size: 0.9rem;
  color: var(--clr-gray400);
}
.topupGiftPopup__radioText strong {
  font-size: 2rem;
}

body.dark-mode .topupGiftPopup__radioText {
  color: var(--clr-gray500);
}

.topupGiftPopup__radioContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topupGiftPopup__radioInput {
  visibility: hidden;
  display: none;
}

.topupGiftPopup__radioInput:checked ~ .topupGiftPopup__radioControl {
  border: 0.5rem solid var(--clr-primary);
}
.topupGiftPopup__radioInput:checked ~ .topupGiftPopup__radioText {
  color: var(--clr-darker);
}
body.dark-mode
  .topupGiftPopup__radioInput:checked
  ~ .topupGiftPopup__radioText {
  color: var(--clr-light);
}

.topupGiftPopup__radioControl {
  width: 100%;
  max-width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  cursor: pointer;
  border: 0.1rem solid var(--clr-dark);
}

/* slider or range price */

/* price} */

.topupGiftPopup__sliderSection {
  width: 100%;
  max-width: 25rem;
  margin: 2rem auto;
}

.topupGiftPopup__sliderSection > div:last-child {
  width: 100%;
  margin-top: 1.25rem;
  font-weight: 600;
  justify-content: space-between;
}

.topupGiftPopup__sliderSection h3 {
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
}

.topupGiftPopup__sliderPrice {
  width: 100%;
  margin-bottom: -0.5rem;
}

.topupGiftPopup__track {
  position: relative;
  width: 100%;
  height: 0.3rem;
  border-radius: 3em;
  background: #077aff55;
}

.topupGiftPopup__range {
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 3em;
  background: var(--clr-primary);
  height: 100%;
  left: 0;
  right: 0;
}

.topupGiftPopup__thumb {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  background: var(--clr-light);
  box-shadow: 0 0 0.25rem #0007;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.topupGiftPopup__value {
  position: relative;
  display: grid;
  place-items: center;
  /* padding: 0.25em 0.75em; */
  padding: 0.5em;
  max-width: fit-content;
  font-weight: 600;
  top: -2.5rem;
  font-size: 0.65rem;
  color: var(--clr-dark);
  transition: opacity 0.5s;
  opacity: 0;
  border-radius: 0.5em;
  box-shadow: 0 0px 27px 0 rgb(0 0 0 / 20%);
  background: var(--clr-light);

  /* box-shadow: 0 0 0.25rem #0005; */
}

.topupGiftPopup__value.show {
  opacity: 1;
}

.topupGiftPopup__sliderPrice {
  position: relative;
  margin-top: 1rem;
}

.topupGiftPopup__slider {
  position: absolute;
  width: 100%;
  z-index: 30;
  height: 0.4rem;
  pointer-events: none;
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
}

.topupGiftPopup__slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  pointer-events: all;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: red;
  cursor: pointer;
}

.topupGiftPopup__sliderPrice {
  justify-content: space-between;
  font-size: 0.9rem;
}

.topupGiftPopup button {
  margin: 1rem 0;
  /* width: 100%; */
  font-weight: 600;
  letter-spacing: 0.05rem;
  font-size: 0.85rem;
  color: var(--clr-gray400);
  padding: 1.5em 1.75em;
  border-radius: 0.5em;
  cursor: default;
}

.topupGiftPopup button.primary {
  cursor: pointer;
}

.topupGiftPopup button svg {
  margin-left: 0.5rem;
}
.topupGiftPopup button svg path {
  stroke: var(--clr-gray400);
}
.topupGiftPopup button.primary svg path {
  stroke: var(--clr-light);
}

@media (min-width: 900px) {
  .topupGiftPopup__container {
    position: fixed;
    width: 80%;
    height: fit-content;
    max-width: 45rem;
    padding: 2em;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.75em;
    animation: fadein 0.7s;
  }

  .topupGiftPopup__container h1 {
    font-size: 1.8rem;
  }

  .topupGiftPopup__radio {
    flex-wrap: nowrap;
  }
}
