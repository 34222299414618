.categories {
  position: fixed;
  width: 100%;
  padding: 0 calc(1.5em + 1rem) 0.5em 1.5em;
  z-index: 5;
  color: var(--clr-darker);
  background: var(--clr-light);
  top: 5rem;
  left: 0;
}

.categories.relative {
  position: relative;
  top: 0;
  /* padding-inline: 0; */
}

body.dark-mode .categories {
  background: var(--clr-darker);
}

.categories__items {
  gap: 0.7rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.categories__items::-webkit-scrollbar {
  transform: translateX(-200px);
  display: none;
}

.categories__item {
  padding: 0.5em 1em;
  font-size: 0.8rem;
  border-radius: 3rem;
  /* border-radius: 0.75em; */
  white-space: nowrap;
  transform: translateX(0);
  color: #4d4f5c;
  position: relative;
  z-index: 1;
  cursor: pointer;
  border: 0.12rem solid var(--clr-gray450);
}

.categories__item::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  left: 0;
  z-index: -1;
  transform: scale(0);
  border-radius: inherit;
  border: 0.12rem solid var(--clr-primary);
  transition: 0.1s;
}

.categories__item:hover.categories__item::after {
  transform: scale(1);
}

.categories__item:hover {
  color: var(--clr-light);
}

body.dark-mode .categories__item {
  border: 0.12rem solid #e4e5e7;
  color: var(--clr-light);
}
/* .categories__item:hover {
  cursor: pointer;
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  border: 0.12rem solid var(--clr-primary);
  color: var(--clr-light);
} */

.categories__itemImg.primary {
  padding: 0.25em;
  border-radius: 50%;
  margin-right: 0.5rem;
  background: var(--clr-primary-light);
}

.categories__itemImg img {
  display: block;
  /* width: 100%; */
  width: 1.01rem;
  filter: invert(33%) sepia(76%) saturate(2951%) hue-rotate(201deg)
    brightness(103%) contrast(102%);
}

body.dark-mode .categories__item:hover {
  cursor: pointer;
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  color: var(--clr-light);
}

.categories__item.active {
  border: 0.12rem solid var(--clr-primary);
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  color: var(--clr-light);
}

body.dark-mode .categories__item.active {
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  color: var(--clr-light);
}

.categories__nav {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-65%);
  cursor: pointer;
}

.categories__nav.right {
  right: 1rem;
  z-index: 10;
}

.categories__nav.left {
  left: 1rem;
  right: initial;
  z-index: 10;
}

.categories__nav::after {
  content: "";
  position: absolute;
  /* height: calc(100% + 3rem); */
  z-index: -1;
  transform: translateY(-50%);
  background: var(--clr-light);
}

body.dark-mode .categories__nav::after {
  background: #0d0e11;
}

.categories__nav.right::after {
  right: 1rem;
  width: 0.5rem;
  box-shadow: -1.2rem 0px 0.2rem -0.4rem #f5f5f5;
}

body.dark-mode .categories__nav.right::after {
  box-shadow: -1.2rem 0px 0.1rem -0.4rem #0d0e11;
}

.categories__nav.left::after {
  left: 0rem;
  box-shadow: 1rem 0px 1rem -0.4rem #f5f5f5;
  width: 1.2rem;
}

body.dark-mode .categories__nav.left::after {
  box-shadow: 0.5rem 0px 0.1rem -0.45rem #0d0e11;
}

.categories__nav.right svg {
  transform: rotate(180deg);
}

.categories__nav svg path {
  stroke: var(--clr-darker);
  stroke-width: 3;
}
body.dark-mode .categories__nav svg path {
  stroke: var(--clr-light);
  stroke-width: 4;
}

@media (min-width: 900px) {
  .categories {
    position: fixed;
    width: calc(100% - 16.5rem);
    padding: 1em 2em 1em 2em;
    right: 0;
    overflow: hidden;
    margin-left: auto;
    top: 4rem;
    background: var(--clr-light);
  }

  .categories.relative {
    width: 100%;
  }

  body.dark-mode .categories {
    background: var(--clr-dark);
  }

  .categories__item {
    font-size: 0.85rem;
  }

  .categories__nav::after {
    background: var(--clr-light);
    height: calc(100% + 3rem);
  }

  body.dark-mode .categories__nav::after {
    background: var(--clr-dark);
    width: 2.5rem;
    box-shadow: -1.5rem 0px 0.5rem -0.3rem #191a1f;
  }

  .categories__nav {
    position: absolute;
    right: 2rem;
    top: 51%;
    z-index: 1;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .categories__nav.right::after {
    width: 2rem;
    right: 0rem;
    box-shadow: -1.2rem 0px 1rem -0.4rem #ffffff;
  }

  /* .categories.relative .categories__nav.right::after {
    right: -2rem;
  } */

  .categories__nav.left::after {
    left: -2rem;
    box-shadow: 1.2rem 0px 1rem -0.4rem #ffffff;
    width: 3rem;
  }
  body.dark-mode .categories__nav.right::after {
    box-shadow: -1.2rem 0px 1rem -0.45rem #191a1f;
  }
  body.white-mode .categories__nav.left::after {
    box-shadow: 1.2rem 0px 1rem -0.45rem #0d0e11;
  }

  .categories__nav.right {
    right: 2rem;
  }
  .categories__nav.left {
    left: 2rem;
  }
}

@media (min-width: 1326px) {
  .categories__items.center {
    justify-content: center;
  }
}
