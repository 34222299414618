.settingTopics {
  height: 100%;
}
.settingTopics__container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}

.settingTopics__container::-webkit-scrollbar {
  display: none;
}

.settingTopics__btnContainer {
  position: sticky;
  width: 100%;
  bottom: -2.15rem;
  left: 0;
  right: 0;
  padding: 1em 0;
  margin-left: auto;
  align-items: flex-start;
  background: var(--clr-light);
}

.settingTopics__btnContainer {
  justify-content: flex-end;
}

.settingTopics__btnContainer > div {
  width: 100%;
}
.settingTopics__btnContainer > div {
  gap: 0.5rem;
  max-width: 20rem;
}

.settingTopics__btnContainer button {
  height: fit-content;
  width: 100%;
  /* margin: 0 1rem 1rem auto; */
}
