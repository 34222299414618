.communities {
  padding-top: 2em;
}

.communities__container {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}

@media (min-width: 900px) {
  .communities__container {
    margin-top: 3rem;
  }
}
