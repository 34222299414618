/* .contactSupport {
  margin-top: 5rem;
} */

.contactSupport h1 {
  width: 100%;
  text-align: center;
  max-width: 35rem;
  margin-top: 1rem;
  color: var(--clr-text-dark-header);
}

.contactSupport__header h3 {
  font-size: 1.6rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
  color: var(--clr-text-dark);
}

.contactSupport__card span {
  font-size: 0.8rem;
}

.contactSupport__card {
  background: var(--clr-light);
  /* background: var(--clr-gray300); */
  /* box-shadow: 0 5rem 3rem -4rem #077aff20; */
  /* box-shadow: 0 2.5rem 3rem -1rem #077aff30; */
  justify-content: center;
  transform: scale(1);
  cursor: pointer;
  border: 0.1rem solid var(--clr-gray300);
  padding: 2em;
  min-height: 25rem;
  text-align: center;
  border-radius: 1em;
  transition: 0.5s;
}

.contactSupport__card:hover {
  transform: scale(1.02);
  /* box-shadow: 0 3.5rem 4rem -2rem #077aff40; */
  box-shadow: 0 3.5rem 4rem -2rem #0003;
}

.contactSupport a,
.contactSupport__header span {
  text-align: center;
  color: var(--clr-text-dark);
  margin-top: 0.5rem;
  line-height: 1.8;
  font-size: 0.9rem;
}

.contactSupport__card h4 {
  text-align: center;
  color: var(--clr-text-dark-header);
}

.contactSupport__ .contactSupport__card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactSupport__container {
  place-items: center;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  gap: 2rem;
}

.contactSupport__image img {
  display: flex;
  width: 100%;
  max-width: fit-content;
  height: 8rem;
  margin: 0 auto;
  object-fit: cover;
}
.contactSupport__header {
  max-width: 30rem;
}

.contactSupport__container span {
  font-size: 0.9rem;
  color: var(--clr-text-dark);
  text-align: center;
  line-height: 2;
}

.contactSupport__container h4 {
  font-size: 1rem;
  margin: 1.25rem;
}
/* 
  @media (min-width: 960px) {
    
      .contactSupport__card:last-child {
        grid-column: 2 / 2;
      }
    } */

@media (min-width: 768px) {
  .contactSupport__header h3 {
    font-size: 2rem;
  }
}

@media (min-width: 900px) {
  .contactSupport {
    justify-content: center;
    margin-bottom: -5rem;
    height: calc(100vh - 5rem);
    /* overflow: hidden; */
  }
}
/* 
  @media (min-width: 1280px) {
      .contactSupport__card:last-child {
        grid-column: initial;
      }
    }
    
    @media (min-width: 1290px) {
      .contactSupport__card:last-child {
        grid-column: initial;
      }
    } */
