.teacherCreateCard {
  flex-wrap: wrap;
  gap: 1rem;
}
.teacherCreateCard__create {
  flex: 1 1 50%;
  /* padding-right: 2em; */
  /* background-color: var(--clr-gray300); */
}

.teacherCreateCard__create > div:first-child p {
  font-size: 0.9rem;
  margin: 0.5rem 0 1rem 0;
}

.teacherCreateCard__create form {
  width: 100%;
  align-items: flex-start;
}

.teacherCreateCard__create button {
  width: 100%;
  border-radius: 0.5em;
  margin-top: 0.5rem;
  font-weight: 600;
  cursor: default;
  color: var(--clr-gray400);
}
.teacherCreateCard__create button.primary {
  cursor: pointer;
}

.teacherCreateCard__create form > label {
  width: 100%;
  margin-top: 0.75rem;
  font-size: 0.9rem;
  font-weight: 500;
}

/* .teacherCreateCard__create form > label select {
      width: 100%;
  } */

.teacherCreateCard__create form > label select,
.teacherCreateCard__create form > label textarea,
.teacherCreateCard__create form > label input {
  width: 100%;
  border-radius: 0.5em;
  font-size: 0.9rem;
  padding: 0.75em 1em;
  border: 0.1rem solid var(--clr-gray300);
  background: var(--clr-modal-light);
  align-items: flex-start;
}

.teacherCreateCard__create form > label textarea {
  resize: none;
  min-height: 10rem;
}

/* dropdown */

.teacherCreateCard__dropdownSearch {
  width: 100%;
  position: relative;
}

.teacherCreateCard__dropdownSearch .dropdown-container {
  font-size: 0.9rem;
  width: 100%;
  padding: 0.75em 1em;
  border: 0.1rem solid var(--clr-gray300);
  background: var(--clr-modal-light);
  border-radius: 0.5em;
}
.teacherCreateCard__dropdownSearch .dropdown-container.expand {
  border-radius: 0.5em;
  border: none;
  border: 0.1rem solid transparent;
}

.teacherCreateCard__form.shop-page
  .teacherCreateCard__dropdownSearch
  .dropdown-container {
  display: flex;
}

.teacherCreateCard__dropdownSearch .dropdown-container p {
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 0.1rem;
  color: var(--clr-gray500);
}
.teacherCreateCard__dropdownSearch .dropdown-container p.active {
  color: var(--clr-dark);
}

.teacherCreateCard__dropdownSearchIcon {
  transform: rotate(0deg);
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
}
body.dark-mode .teacherCreateCard__dropdownSearch .dropdown-container p {
  color: var(--clr-light);
}
.teacherCreateCard__dropdownSearch .dropdown-item-container {
  top: calc(100% + 0.75rem);
  border-radius: 0.5em;
  z-index: 10;
  top: calc(100% + 0.5rem);
  box-shadow: 0 0 0.5rem #0002;
}
.teacherCreateCard__dropdownSearch .dropdown-item-container label {
  font-weight: 600;
  padding: 1em 1em;
  font-size: 0.8rem;
}

.teacherCreateCard__dropdownSearch .dropdown-item-container label span {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  border: 0.1rem solid var(--clr-gray500);
}
.teacherCreateCard__dropdownSearch
  .dropdown-item-container
  label
  input:checked
  ~ span {
  border: 0.35rem solid var(--clr-primary);
}

.teacherCreateCard__dropdownSearch
  .dropdown-item-container
  label.menu-active
  img {
  filter: invert(41%) sepia(53%) saturate(7452%) hue-rotate(204deg)
    brightness(105%) contrast(100%);
}

.teacherCreateCard__dropdownSearch
  .dropdown-container
  .teacherCreateCard__dropdownSearchArrow {
  width: 0.5rem;
  height: 0.5rem;
}

body.dark-mode .teacherCreateCard__dropdownSearchIcon {
  filter: invert(1) sepia(100%) saturate(1%) hue-rotate(183deg) brightness(200%)
    contrast(101%);
}

body.dark-mode
  .teacherCreateCard__dropdownSearch
  .dropdown-container
  .teacherCreateCard__dropdownSearchArrow
  path,
.teacherCreateCard__dropdownSearch
  .dropdown-container
  .teacherCreateCard__dropdownSearchArrow
  path {
  stroke: var(--clr-primary);
}

/* preview */
.teacherCreateCard__preview {
  flex: 1 1 50%;
  width: fit-content;
  /* background: red; */
  height: 100%;
  /* padding: 2em; */
  /* background: #077aff10; */
}

.teacherCreateCard__preview h2 {
  font-weight: 600;
}
.teacherCreateCard__previewCard.community {
  max-width: initial;
  width: 20rem;
}
.teacherCreateCard__previewCard {
  max-width: 15rem;
  margin: 2rem auto;
  filter: drop-shadow(0 10px 20px rgb(0 0 0 / 10%));
}

.teacherCreateCard__wordLimit {
  font-size: 0.8rem;
  margin: 0.2rem 0 0.2rem auto;
}

.teacherCreateCard__twoInputs {
  gap: 1rem;
  margin-top: 0.5rem;
}

.teacherCreateCard__twoInputs > label {
  font-size: 0.9rem;
  font-weight: 500;
}
.teacherCreateCard__twoInputs > label:first-child {
  flex: 1 1 80%;
}

.teacherCreateCard__twoInputs input[type="file"] {
  color: transparent;
  position: relative;
  max-width: 10rem;
  padding: 0.75em 1em;
}
.teacherCreateCard__twoInputs input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}
.teacherCreateCard__twoInputs input[type="file"]::after {
  content: "Upload Icon";
  color: var(--clr-light);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
  cursor: pointer;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 100%;
  left: 0;
  background: var(--clr-primary);
}

.teacherCreateCard__twoInputs > label:last-child {
  flex: 1 1 20%;
}

@media (min-width: 900px) {
  .teacherCreateCard {
    gap: 0;
  }
}
