.upgradePremiumPopup {
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000b;
  z-index: 200;
}

.upgradePremiumPopup__container h1 {
  font-size: 1.3rem;
}

.upgradePremiumPopup__container {
  /* width: 80%; */
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: var(--clr-light);
  box-shadow: 0 0px 27px 0 rgb(0 0 0 / 50%);
  padding: 1.5em;
  margin: auto;
}

.upgradePremiumPopup__container img {
  width: 100%;
  max-width: 5rem;
  margin-bottom: 1rem;
}

.upgradePremiumPopup__container button {
  border-radius: 0.5em;
  margin-top: 1rem;
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  box-shadow: 0 1rem 0.5rem var(--clr-primary-light) / 50%;
}

.upgradePremiumPopup__container > div:last-child {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upgradePremiumPopup__container h3 {
  font-size: 1.3rem;
  text-align: center;
  color: var(--clr-text-dark-header);
}

.upgradePremiumPopup__container p {
  text-align: center;
  max-width: 28rem;
  line-height: 1.8;
  margin: 1rem 0;
  font-size: 0.9rem;
}

.upgradePremiumPopup__close {
  position: absolute;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  stroke: var(--clr-gray300);
  top: 1.5rem;
  right: 1.5rem;
}

@media (min-width: 900px) {
  .upgradePremiumPopup__container {
    position: fixed;
    width: 45%;
    height: 60%;
    overflow-y: scroll;
    max-width: 40rem;
    padding: 2em;
    left: 50%;
    top: 50%;
    scrollbar-width: none;
    transform: translate(-50%, -50%);
    border-radius: 0.75em;
    box-shadow: 0 10px 27px 0 rgb(0 0 0 / 50%);
    animation: fadein 0.7s;
  }

  .upgradePremiumPopup__container::-webkit-scrollbar {
    display: none;
  }

  /* .topupGiftPopup__radio {
      flex-wrap: nowrap;
    } */
}
