.commentCard {
  margin-top: 1rem;
  align-items: flex-start;
}

.commentCard img {
  width: 100%;
  margin-right: 0.5rem;
  max-width: 2.8rem;
  /* border-radius: 0.7em; */
}

.commentCard__header h3 {
  font-size: 0.9rem;
  font-weight: 500;
  margin-right: 1rem;
}

.commentCard__header small {
  font-size: 0.7rem;
}
.commentCard__reply {
  cursor: pointer;
}

.commentCard__content p {
  font-size: 0.8rem;
  line-height: 1.5;
  margin: 0.3rem 0;
}
.commentCard__action {
  font-size: 0.8rem;
  gap: 1rem;
}
.commentCard__action svg {
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
  margin-right: 0.2rem;
}

.commentCard__actionLike {
  cursor: pointer;
  font-weight: 500;
  color: var(--clr-gray400);
}

.commentCard__actionLike.liked {
  color: var(--clr-primary);
}
/* .commentCard__actionLike.liked svg path {
  fill: var(--clr-primary);
  stroke: var(--clr-primary);
} */
/* .commentCard__actionUnlike.unliked svg path {
  fill: var(--clr-invalid);
}

*/
.commentCard__actionUnlike.unliked svg rect {
  fill: var(--clr-invalid);
}

.commentCard__actionLike.liked svg rect {
  fill: var(--clr-primary);
}
