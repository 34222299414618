.popupUserInfo {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  background: #000b;
}

.popupUserInfo__container {
  position: absolute;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  overflow-y: scroll;
  align-items: flex-start;
  background: var(--clr-light);
  box-shadow: 0rem 0rem 1rem #0002;
  border-radius: 0;
}

/* popup user info content */

.popupUserInfo__leftSection {
  justify-content: center;
  text-align: center;
  padding: 2.5em;
  background: var(--clr-primary);
  color: var(--clr-light);
  flex: 1 1 40%;
  width: 100%;
  min-width: 18rem;
  height: 100%;
}

.popupUserInfo__leftSection img {
  width: 100%;
  max-width: 12rem;
}

.popupUserInfo__leftSectionText {
  margin: 3rem 0;
}

.popupUserInfo__leftSectionText span {
  display: block;
  max-width: 15rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  font-weight: 300;
}

.popupUserInfo__btnArrow {
  background: var(--clr-light);
  display: block;
  width: 3rem;
  display: grid;
  height: 3rem;
  place-items: center;
  cursor: pointer;
}

.popupUserInfo__btnArrow svg {
  width: 0.75rem;
  fill: var(--clr-primary);
  transform: rotate(90deg);
}

.popupUserInfo__rightSection {
  padding: 3em 1.5em;
  /* padding: 3em 3.5em; */
  height: fit-content;
  justify-content: flex-start;
  flex: 1 1 60%;
  grid-gap: 1rem;
  gap: 1rem;
  font-size: 0.9rem;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  align-items: flex-start;
}

.popupUserInfo__container.parental-consent .popupUserInfo__rightSection {
  padding: 3em 1.5em;
  /* padding: 3em 6em; */
}
.popupUserInfo__container.choose-topic .popupUserInfo__rightSection {
  padding: 2em;
  justify-content: flex-start;
}

.popupUserInfo__rightSection > div {
  flex-direction: column;
  /* flex-wrap: wrap; */
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
}
.popupUserInfo__rightSection > div > label {
  flex: 1 1 30%;
}
.popupUserInfo__rightSection > div > input {
  flex: 1 1 70%;
}

/* topics */

.popupUserInfo__chooseTopics {
  position: relative;
}

.popupUserInfo__chooseTopics .settingTopics__btnContainer {
  bottom: -1.75rem;
}

/* language */

.popupUserInfo__dropdown {
  min-height: 3.5rem;
  cursor: pointer;
}

.popupUserInfo__selectedLanguages {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  flex-wrap: wrap;
}

.popupUserInfo__selectedLanguage {
  padding: 0.25em 0.5em;
  border-radius: 3em;
  background: var(--clr-gray300);
}

.popupUserInfo__dropdownLists {
  position: absolute;
  background: var(--clr-light);
  width: 100%;
  max-height: 10rem;
  pointer-events: none;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: scaleY(0);
  visibility: hidden;
  border: 0.15rem solid var(--clr-primary);
  border-top: none;
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 0 0 1em 1em;
  z-index: 10;
  transform-origin: top;
  scrollbar-track-color: transparent;
  scrollbar-width: thin;
}
/* .popupUserInfo__identity .popupUserInfo__dropdownLists {
  top: -5.75rem;
  transform-origin: top;
} */

/* .popupUserInfo__identity .popupUserInfo__dropdownLists {
  top: -9.5rem;
  border-radius: 1em 1em 0 0;
  border: 0.15rem solid var(--clr-primary);
  border-bottom: none;
  transform-origin: bottom;
} */

.popupUserInfo__dropdownLists.show {
  pointer-events: all;
  transform: scaleY(1);
  visibility: visible;
}

.popupUserInfo__countryLists::-webkit-scrollbar,
.popupUserInfo__dropdownLists::-webkit-scrollbar {
  width: 0.5rem;
  cursor: pointer;
}

.popupUserInfo__countryLists::-webkit-scrollbar-track,
.popupUserInfo__dropdownLists::-webkit-scrollbar-track {
  background-color: transparent;
}

.popupUserInfo__countryLists::-webkit-scrollbar-thumb,
.popupUserInfo__dropdownLists::-webkit-scrollbar-thumb {
  background: var(--clr-primary);
  border-radius: 3rem;
}

.popupUserInfo__dropdownLists li {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1em;
  cursor: pointer;
  position: relative;
  z-index: -1;
}
.popupUserInfo__dropdownLists label {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
}
.popupUserInfo__dropdownLists li input[type="checkbox"] {
  display: none;
  cursor: pointer;
}
.popupUserInfo__dropdownLists li input[type="checkbox"]:checked ~ label span {
  border: 0.1rem solid var(--clr-primary);
  background: var(--clr-primary);
}
.popupUserInfo__dropdownLists li input[type="checkbox"]:checked ~ label {
  color: var(--clr-primary);
  font-weight: 700;
}

.popupUserInfo__dropdownLists span {
  display: block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  border-radius: 0.25em;
  position: relative;
  border: 0.1rem solid var(--clr-gray);
}

.popupUserInfo__dropdownLists
  li
  input[type="checkbox"]:checked
  ~ label
  span::after {
  content: url("data:image/svg+xml,%3Csvg width='11' height='10' viewBox='0 0 33 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 12L12 21.5L30.5 3' stroke='white' stroke-width='7' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  position: absolute;
  top: -0.25rem;
  left: 50%;
  transform: translateX(-50%);
}

/* country */
.popupUserInfo__countryLists {
  max-height: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  border-radius: 1em;
  top: 3.5rem;
  pointer-events: none;
  background: var(--clr-light);
  box-shadow: 0 0.5rem 1.25rem #0002;
  width: 100%;
  transition: height 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* transition: max-height 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  z-index: 5;
  scrollbar-width: none;
}

.popupUserInfo__countryLists.show {
  max-height: calc(100% + 2.5rem);
  pointer-events: all;
}

.popupUserInfo__countryLists li {
  padding: 1em;
  cursor: pointer;
}

.popupUserInfo__languages li:hover,
.popupUserInfo__countryLists li:hover {
  background: #0001;
}

/* .popupUserInfo__languages li:focus-within::after {
  content: "";
  width: 100%; 
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  background: var(--clr-focus-shadow);
} */

/* .popupUserInfo__rightSection input[type="text"] {
  width: 100%;
  padding: 1em 1.25em;
  border: 0.1rem solid var(--clr-gray300);
  border-radius: 0.5em;
  color: var(--clr-darker);
  background: var(--clr-modal-light);
} */

/* identity & gender*/
.popupUserInfo__gender .input__label,
.popupUserInfo__identity .input__label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.popupUserInfo__gender .input__label img,
.popupUserInfo__identity .input__label img {
  margin-right: 0.5rem;
  width: 0.9rem;
  filter: invert(83%) sepia(17%) saturate(221%) hue-rotate(169deg)
    brightness(89%) contrast(91%);
}

.popupUserInfo__gender.open-dropdown .input__label img,
.popupUserInfo__identity.open-dropdown .input__label img {
  filter: invert(42%) sepia(98%) saturate(4685%) hue-rotate(200deg)
    brightness(101%) contrast(102%);
}

.popupUserInfo__gender .popupUserInfo__dropdown,
.popupUserInfo__identity .popupUserInfo__dropdown {
  text-transform: capitalize;
}

.popupUserInfo__gender.popupUserInfo__dropdown.error ~ .input__label img,
.popupUserInfo__identity .popupUserInfo__dropdown.error ~ .input__label img {
  filter: invert(22%) sepia(39%) saturate(6019%) hue-rotate(344deg)
    brightness(122%) contrast(84%);
}

/* /////// */

.popupUserInfo__rightSection input[type="radio"] {
  display: none;
}
.popupUserInfo__rightSection
  input[type="radio"]:checked
  ~ .popupUserInfo__rightSectionIconContainer {
  background: var(--clr-primary);
}
.popupUserInfo__rightSection
  input[type="radio"]:checked
  ~ .popupUserInfo__rightSectionIconContainer
  img {
  filter: invert(1);
}

.popupUserInfo__rightSectionIdentity > div:first-of-type > div:first-of-type,
.popupUserInfo__rightSectionGender > div:first-of-type > div:first-of-type {
  gap: 1rem;
  flex: 1 1 70%;
}

.popupUserInfo__rightSectionIdentity.error > div:first-of-type > label,
.popupUserInfo__rightSectionGender.error > div:first-of-type > label {
  color: var(--clr-invalid);
  font-weight: 500;
}

.popupUserInfo__rightSectionIdentity > div:first-of-type > label,
.popupUserInfo__rightSectionGender > div:first-of-type > label {
  flex: 1 1 30%;
}

.popupUserInfo__rightSectionIdentity
  > div:first-of-type
  > div:first-of-type
  > label,
.popupUserInfo__rightSectionGender
  > div:first-of-type
  > div:first-of-type
  > label {
  cursor: pointer;
}

.popupUserInfo__rightSectionIconContainer {
  background: var(--clr-modal-light);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 0.5rem;
  width: 100%;
  aspect-ratio: 1 / 1;
  max-width: 2.5rem;
  height: 2.5rem;
}

.popupUserInfo__rightSectionIconContainer img {
  display: block;
  width: 1.25rem;
  filter: invert(83%) sepia(17%) saturate(221%) hue-rotate(169deg)
    brightness(89%) contrast(91%);
  aspect-ratio: 1 / 1;
  margin-right: 0;
}

/* .popupUserInfo__rightSectionIconContainer.gender-female img {
  margin-left: -.5rem;
} */

.popupUserInfo__rightSectionParentalConsent {
  font-size: 1.1rem;
}

.popupUserInfo__rightSectionIdentity > div {
  flex-wrap: wrap;
}

.popupUserInfo__rightSectionIdentity > div > label {
  flex: 1 1 33%;
}

.popupUserInfo__nextBtnMobile {
  width: 100%;
  font-size: 1rem;
  padding: 0.75em 1em;
  margin-top: 1.5rem;
  border-radius: 0.75em;
}

@media (min-width: 720px) {
  .popupUserInfo__btnArrow svg {
    transform: rotate(0);
  }

  .popupUserInfo__rightSectionIdentity > div {
    flex-wrap: nowrap;
  }

  .popupUserInfo__container,
  .popupUserInfo__rightSection {
    scrollbar-width: none;
  }
  .popupUserInfo__container::-webkit-scrollbar,
  .popupUserInfo__rightSection::-webkit-scrollbar {
    display: none;
  }

  .popupUserInfo__nextBtnMobile {
    display: none;
  }
}

@media (min-width: 768px) {
  .popupUserInfo__rightSection {
    padding: 3em 3.5em;
    height: 100%;
    justify-content: center;
  }
  .popupUserInfo__container.parental-consent .popupUserInfo__rightSection {
    padding: 3em 6em;
  }

  .popupUserInfo__rightSection > div {
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .popupUserInfo__container {
    position: absolute;
    width: 60rem;
    /* width: fit-content; */
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 90%;
    border-radius: 1em;
    animation: fadein 0.7s;
    /* overflow-y: hidden; */
  }

  .popupUserInfo__container.parental-consent {
    animation: fadeIn2 0.7s;
  }
}

@keyframes fadeIn2 {
  0% {
    left: 50%;
    top: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
