.credits {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.credits h1 {
  text-align: center;
}

.credits__description {
  max-width: 35rem;
  text-align: center;
  font-weight: 300;
  margin-top: 0.5rem;
}

.credits__toggle {
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 1.5rem;
  cursor: pointer;
  font-size: 0.85rem;
  width: 100%;
  border-radius: 3em;
  justify-content: space-around;
  padding: 0.75em 0;
  --max-width: 12rem;
  max-width: var(--max-width);
  font-weight: 600;
  border: 0.125rem solid var(--clr-primary);
}

.credits__toggle input[type="checkbox"]:checked ~ span:nth-of-type(2),
.credits__toggle span:first-of-type {
  color: var(--clr-primary);
}

.credits__toggle input[type="checkbox"]:checked ~ span:first-of-type {
  color: var(--clr-dark);
}

.credits__toggle input[type="checkbox"]:checked ~ .credits__box {
  left: 100%;
  transform: translateX(-100%);
}

.credits__box {
  content: "";
  background: var(--clr-primary-light);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* margin-left: auto; */
  height: 100%;
  border-radius: 3em;
  transition: 0.25s;
  z-index: -1;
  width: calc(var(--max-width) / 2);
}

.credits__toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.credits__options {
  width: 100%;
  display: grid;
  gap: 1rem;
  max-width: 48rem;
  margin-block: 3rem;
  /* margin-inline: auto; */
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}

.credits__option svg {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.5rem;
}

.credits__option {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5em;
  width: 100%;
  background: var(--clr-light);
  position: relative;
  z-index: 1;
  height: 14rem;
  border-radius: 1em;
  filter: drop-shadow(0 5px 27px rgb(0 0 0 / 7%));
}

.credits__option h4 {
  font-weight: 600;
}

.credits__option.best-price {
  color: var(--clr-light);
  position: relative;
  background: var(--clr-primary);
}

.credits__option.best-price::after {
  content: "Most Popular";
  background: var(--clr-light);
  padding: 0.5em 1em;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 0.8rem;
  border-radius: 3rem;
  text-align: center;
  width: fit-content;
  color: var(--clr-dark);
}

.credits__option.best-price button {
  color: var(--clr-light);
}
.credits__option.best-price button svg path {
  stroke: var(--clr-light);
}

.credits__optionPrice {
  font-size: 1.5rem;
}

/* .credits__optionPrice span {
  font-size: 1.5rem;
} */

.credits__option button {
  background: transparent;
  padding: 0;
  width: fit-content;
}

.credits__option button svg {
  width: 1rem;
  margin-left: 0.5rem;
}
.credits__option button svg path {
  stroke: var(--clr-dark);
}

.credits__optionPriceYear {
  font-size: 0.9rem;
  display: block;
  margin-top: -0.05rem;
}

.credits__btn {
  border-radius: 1em;
  background: transparent;
  color: var(--clr-dark);
  font-weight: 600;
  width: 100%;
  max-width: 9rem;
  border: 0.1rem solid var(--clr-dark);
}

@media (min-width: 768px) {
  .credits {
    height: calc(100vh - 10rem);
  }
}
