.connections__initial {
  margin-top: 2rem;
}

.connections__initial img {
  max-width: 20rem;
  margin-bottom: -4rem;
}

.connections__initial h1 {
  margin-top: 1.5rem;
  text-align: center;
  line-height: 1.5;
  color: var(--clr-text-dark-header);
}

.connections__initial h1 span {
  display: block;
  font-size: 2.25rem;
  margin-bottom: .5rem;
}

.connections__initial p {
  max-width: 30rem;
  text-align: center;
  margin-top: 1rem;
}

.connections__cardContainer {
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  display: grid;
  gap: 1rem;
}

.connections__table {
  margin-top: 1rem;
  width: 100%;
  background: var(--clr-light);
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
  /* box-shadow: 0 0 1rem #00000022; */
  padding-right: 1;
  border-radius: 1em;
}
.connections__tableContainer {
  overflow-x: scroll;
}

.connections__table h3 {
  padding: 1em;
  font-weight: 600;
  font-size: 1.05rem;
  color: var(--clr-text-dark-header);
}

.connections table {
  width: 100%;
  border-collapse: collapse;
}
.connections th {
  text-align: left;
  padding: 1em 1.5em;
  font-size: 0.7rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--clr-text-dark);
  border-bottom: 0.1rem solid var(--clr-gray450);
  /* background: var(--clr-light-gray); */
}

.connections tr:first-child th:last-child {
  border-radius: 0 0.5em 0 0;
}
.connections tr:first-child th:first-child {
  border-radius: 0.5em 0 0 0;
}
.connections tr:last-child td:last-child {
  border-radius: 0 0 0 0.5em;
}
.connections tr:last-child td:first-child {
  border-radius: 0 0 0.5em 0;
}

body.dark-mode .connections th {
  /* border-bottom: 0.1rem solid var(--clr-secondary-dark); */
  background: var(--clr-modal);
}

/* .connections tr:nth-of-type(2n + 1) {
  background: #077aff10;
}
body.dark-mode .connections tr:nth-of-type(2n + 1) {
  background: var(--clr-modal);
} */

.connections td {
  padding: 1em 1.5em;
  font-size: 0.8rem;
  font-weight: 500;
  border-bottom: 0.1rem solid var(--clr-gray450);
  color: var(--clr-text-dark);
}

.connections__connection {
  cursor: pointer;
}

.connections__connection img {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  margin-right: 1rem;
}

.connections__connection h4 {
  font-size: 0.9rem;
  font-weight: 700;
}

.connections__connection span {
  font-size: 0.7rem;
  font-weight: 500;
}

td.connections__identity {
  font-size: 0.7rem;
}

.connections__identity span {
  display: block;
  font-weight: 700;
}

.connections__status span {
  padding: 0.25em 0.75em;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 0.5em;
  color: var(--clr-light);
}

.connections__status.online span {
  background: var(--clr-success);
}
.connections__status.offline span {
  background: var(--clr-dark-gray);
}

td.connections__function {
  font-size: 0.7rem;
  /* padding: 0; */
  cursor: pointer;
  gap: 0.75rem;
}

.connections__function > div,
.connections__function a {
  position: relative;
  top: -0.5rem;
}

.connections__function a:hover::after {
  content: "Message";
}

.connections__function > div:nth-child(2):hover::after {
  content: "Gift";
}
.connections__function > div:last-child:hover::after {
  content: "Remove";
}
.connections__function > div::after,
.connections__function a::after {
  position: absolute;
  color: var(--clr-light);
  padding: 0.5em 0.75em;
  font-size: 0.75em;
  background-color: var(--clr-dark-background);
  box-shadow: 0 0 0.5rem #0002;
  top: -1.6rem;
  left: 50%;
  transform: translate(-50%);
  border-radius: 0.5em;
}

.connections__function > div:nth-child(2):hover::before,
.connections__function > div:last-child:hover::before,
.connections__function a:hover::before {
  content: "";
  position: absolute;
  border-left: 0.3rem solid transparent;
  left: 50%;
  top: -0.4rem;
  transform: translate(-50%);
  border-right: 0.3rem solid transparent;
  border-top: 0.35rem solid var(--clr-dark-background);
}

.connections__statusCircle {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.5rem;
  margin-bottom: 0.2rem;
}

.connections__statusCircle.Ongoing {
  background: var(--clr-yellow);
}
.connections__statusCircle.Completed {
  background: var(--clr-success);
}
.connections__statusCircle.On {
  background: var(--clr-primary);
}
.connections__statusCircle.Delayed {
  background: #fb6240;
}

.connections__completionProgress {
  position: relative;
  width: 9rem;
  margin-left: 0.5rem;
  height: 0.3rem;
  border-radius: 3em;
  background: #b9c1cc;
}
body.dark-mode .connections__completionProgress {
  background: #373737;
}

.connections__projects img {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.connections__completionProgress::after {
  content: "";
  position: absolute;
  height: 100%;
}

@media (min-width: 900px) {
  .connections__tableContainer {
    scrollbar-width: none;
  }
  .connections__tableContainer::-webkit-scrollbar {
    display: none;
  }
}
