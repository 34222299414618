@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --clr-primary: #077aff;
  --clr-primary-light: #f7f7ff;
  --clr-primary-dark: #045efb;
  --clr-secondary: #90a0b9;
  --clr-secondary-light: #e8f2ff;
  --clr-secondary-dark: #2c373b;
  --clr-text-dark-header: #2d2f39;
  --clr-text-dark: #4d4f5c;
  --clr-text-gray: #828282;
  --clr-light: #ffffff;
  --clr-dark: #161616;
  /* --clr-dark: #191a1f; */
  --clr-hover-dark: #242526;
  --clr-darker: #0d0e11;
  --clr-dark-background: #1b1b1b;
  --clr-light-gray: #dde1eb;
  --clr-gray: #90a0b9;
  --clr-dark-gray: #4d607e;
  --clr-gray500: #757679;
  --clr-gray400: #a4a4a6;
  --clr-gray450: #f4f4f4;
  /* --clr-gray200: #F5F5F5; */
  --clr-focus: #057afe;
  --clr-focus-shadow: #deebfe;
  --clr-invalid: #ea3943;
  --clr-invalid-shadow: #ffe5d3;
  --clr-invalid-shadow: #f8f2f6;
  --clr-dark400: #151a30;
  --clr-gray300: #e4e5e7;
  --clr-modal: #1e1e1e;
  --clr-modal-darker: #1c1c1c;
  --clr-modal-light: #fafbfc;
  --clr-hover-light: #d8d8d8;
  --clr-header-light: #f5f5f5;
  --clr-yellow: #ffb800;
  --clr-yellow200: #fff5cc;
  --clr-yellow-shadow: #ffb70040;
  --clr-dark-purple: #4339f2;
  --clr-dark-purple-shadow: #dad7fe;
  --clr-purple: #891be8;
  --clr-red200: #ffe5d3;
  --clr-green200: #e2fbd7;
  --clr-blue200: #dad7fe;
  --clr-light-blue200: #ccf8fe;
  /* --clr-purple: #8f75be; */
  --clr-success: #28c76f;
}

body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--clr-dark);
  background: var(--clr-light);
}

body.dark-mode {
  background: var(--clr-dark);
  color: var(--clr-light);
}
html {
  /* scrollbar-color: #44ee4e; */
  scrollbar-track-color: transparent;
  /* scrollbar-arrow-color: var(--clr-dark); */
  scrollbar-width: thin;
}

html::-webkit-scrollbar {
  width: 0.5rem;
}

html::-webkit-scrollbar-track {
  background-color: transparent;
}

html::-webkit-scrollbar-thumb {
  background: #4e4e4e;
  border-radius: 3rem;
}
a {
  text-decoration: none;
  color: var(--clr-primary);
}

ul {
  list-style: none;
}

textarea,
input {
  border: none;
  outline: none;
  background: transparent;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}

select {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}

/* p, span, h {
  cursor: default;
} */

.container {
  width: 100%;
  /* min-height: 90vh; */
  padding-bottom: calc(40px + 4rem);
}

.container-header {
  top: 0;
  position: fixed;
  width: 100%;
  background: #f5f5f5;
  z-index: 20;
}
.container-content {
  padding: 1em 1em;
  margin-top: 5rem;
}

.logo.logo-head {
  display: none;
}

.logo {
  margin-right: 1rem;
}

.logo-container svg path:first-child {
  fill: var(--clr-light);
}
.logo-container svg path + path {
  stroke: var(--clr-light);
}

.logo-container {
  width: fit-content;
  padding: 0.25em;
  margin: 0.5rem 0;
  background: var(--clr-primary);
}

.logo-container svg {
  width: 2rem;
  height: 2rem;
  display: flex;
}

.container-header.dark-mode {
  /* background: var(--clr-light); */
  background: var(--clr-darker);
  /* box-shadow: 0rem 0.2rem 0.2rem #0003; */
}

/* cross mark */
.cross-mark {
  display: flex;
  position: relative;
  width: 1.5rem;
  margin-left: 0.5rem;
  height: 1.5rem;
  z-index: 1001;
  cursor: pointer;
}

.cross-mark::after,
.cross-mark::before {
  content: "";
  position: absolute;
  width: 0.1rem;
  height: 1rem;
  border-radius: 3em;
  background: var(--clr-dark);
}
.cross-mark::after {
  transform: rotate(45deg);
}

.cross-mark::before {
  transform: rotate(-45deg);
}

@media (min-width: 900px) {
  .container {
    width: calc(100% - 16.5rem);
    margin-left: auto;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
  }

  .container-header {
    width: 100%;
    background: var(--clr-light);
    padding-bottom: 0;
  }
  .container-content {
    padding: 1em 2em;
    margin-top: 4rem;
  }

  .logo.logo-head {
    display: flex;
  }
}

/* input */

.input__container {
  position: relative;
  width: 100%;
}

.input__label {
  position: absolute;
  left: 1rem;
  top: 0.6rem;
  color: var(--clr-gray500);
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0.5rem 0;
  cursor: text;
}

.input__textError {
  display: flex;
  justify-self: flex-start;
  /* font-weight: 600; */
  font-size: 0.7rem;
  text-align: left;
  margin-top: 0.3rem;
  color: #ea3943;
}

/* .inputIcon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
} */

.input {
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 600;
  padding: 1em 1.25em;
  border-radius: 0.7em;
  border: 0.15rem solid var(--clr-gray400);
  font-size: 0.9rem;
  outline: none;
  background: #fff;
}

.input.error {
  border: 0.15rem solid var(--clr-invalid);
}

.input.error ~ .input__label {
  color: var(--clr-invalid);
}

.input__container.open-dropdown .input,
.input__container.open-language .input {
  border: 0.15rem solid var(--clr-primary);
  border-bottom: none;
  border-radius: 1em 1em 0 0;
}

.input:focus {
  border: 0.15rem solid var(--clr-primary);
}

/* .input__container.open-dropdown.popupUserInfo__identity .input {
  border-radius: 0 0 1em 1em;
  border: 0.15rem solid var(--clr-primary);
  border-top: none;
} */

.input::placeholder {
  color: transparent;
}

.input:focus::placeholder {
  color: var(--clr-gray400);
}

.input__container.open-dropdown .input__label,
.input__container.open-language .input__label,
.input:focus ~ .input__label {
  color: var(--clr-primary);
}

.input__container.selected-gender .input__label,
.input__container.selected-identity .input__label,
.input__container.selected-language .input__label,
.input__container.open-dropdown .input__label,
.input__container.open-language .input__label,
.input:focus ~ .input__label,
.input:valid ~ .input__label {
  top: -1.25rem;
  left: 0.75rem;
  font-size: 12px;
  background: #fff;
  padding: 0.25em 0.5em;
  text-align: center;
  transition: all 0.5s;
}

.input__container.readOnly .input:focus {
  border: 0.15rem solid var(--clr-gray400);
}
.input__container.readOnly .input:focus ~ .input__label {
  color: var(--clr-gray400);
}
.input__container.readOnly .input {
  cursor: not-allowed;
  caret-color: transparent;
}

.input.error:focus ~ .input__label {
  color: var(--clr-invalid);
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.hide-password-icon,
.show-password-icon {
  position: absolute;
  right: 1.25em;
  top: 50%;
  fill: var(--clr-gray400);
  cursor: pointer;
  transform: translateY(-50%);
}

.show-password-icon.hide,
.hide-password-icon.hide {
  display: none;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 1.5em;
  border: none;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  border-radius: 3rem;
  cursor: pointer;
}

.rounded {
  border-radius: 50%;
}

.img-channel {
  position: relative;
  display: flex;
  padding: 0.3rem;
}

.img-channel.story {
  border: 0.12rem solid var(--clr-ligth);

  box-shadow: 0px 0px 0px 0.1rem var(--clr-primary);
}

body.dark-mode .img-channel.story {
  border: 0.12rem solid var(--clr-dark);
}

.img-channel.notification::after {
  content: "";
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: inherit;
  top: -0.3rem;
  right: -0.3rem;
  background: var(--clr-invalid);
}

.img-channel.svg.selected::before,
.img-channel.selected::before {
  content: "";
  position: absolute;
  width: 0.7rem;
  height: 0.2rem;
  border-radius: 3rem;
  bottom: -0.65rem;
  left: 50%;
  transform: translateX(-50%);
  background: var(--clr-gray400);
}

.img-channel.selected::before {
  bottom: -0.5rem;
}

.img-channel.png img,
.img-channel.jpg img {
  width: 1.5rem;
  height: 1.5rem;
}

.img-channel.svg {
  background: var(--clr-light);
}

/* .img-channel.border {
  border: 0.12rem solid var(--clr-dark);
  box-shadow: 0px 0px 0px 0.1rem var(--clr-primary);
} */

.loading.overlay {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.25rem;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  gap: 0.7rem;
}

@media (min-width: 900px) {
  .loading.overlay {
    bottom: -1rem;
  }
}

.loading .ball {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  background: var(--clr-darker);

  transition: transform 0.3s infinite;
}

body.dark-mode .ball {
  background: var(--clr-light);
}

.loading .ball.one {
  animation: loadingBall 0.7s infinite alternate;
}
.loading .ball.two {
  animation: loadingBall 0.7s infinite 0.3s alternate;
}
.loading .ball.three {
  animation: loadingBall 0.7s infinite 0.5s alternate;
}

@keyframes loadingBall {
  0% {
    transform: translateY(0);
  }
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(5px);
  }
}

.item-center {
  display: grid;
  place-items: center;
}

.btn.primary {
  background: var(--clr-primary);
  color: var(--clr-light);
}
.btn.light {
  background: var(--clr-light);
}
.btn.secondary {
  background: var(--clr-light-gray);
  color: var(--clr-gray);
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-row {
  display: flex;
  align-items: center;
}

@keyframes fadein {
  0% {
    left: 50%;
    top: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

/* rating */
.rating svg {
  display: flex;
}

.rating span {
  font-weight: 600;
  margin-right: 0.1rem;
  margin-top: 0.1rem;
}

/* custom dropdwn */

.dropdown-container {
  justify-content: space-between;
  padding: 0.4em 1em;
  cursor: pointer;
}

.dropdown-container.expand {
  box-shadow: 0 0rem 0.5rem #0002;
  border-radius: 0.7em 0.7em 0 0;
  background: var(--clr-light);
}

body.dark-mode .dropdown-container.expand {
  background: var(--clr-dark);
  box-shadow: 0 0rem 0.5rem #0007;
}

.dropdown-container svg {
  width: 0.6rem;
  height: 0.6rem;
  margin-left: 0.3rem;
  transform: rotate(-90deg);
}
body.dark-mode .dropdown-container svg path {
  stroke: var(--clr-light);
}

.dropdown-item-container {
  background: var(--clr-light);
  position: absolute;
  align-items: flex-start;
  width: 100%;
  /* color: var(--clr-gray); */
  max-height: 0;
  opacity: 0;
  z-index: 10;
  pointer-events: none;
  overflow-x: hidden;
  scrollbar-width: none;
  border-radius: 0 0 0.7em 0.7em;
  box-shadow: 0 0.3rem 0.5rem #0002;
}

.dropdown-item-container::-webkit-scrollbar {
  display: none;
}

body.dark-mode .dropdown-item-container {
  background: var(--clr-dark);
  box-shadow: 0 0.3rem 0.5rem #0007;
}

.dropdown-item-container.expand {
  max-height: 18rem;
  transition: max-height 0.3s ease-out, opacity 0.3s 0.1s;
  opacity: 1;
  pointer-events: all;
}

.dropdown-item-container label {
  padding: 0.4em 1em;
  width: 100%;
}

.dropdown-item-container label:hover {
  background: var(--clr-header-light);
}
body.dark-mode .dropdown-item-container label:hover {
  background: var(--clr-hover-dark);
}

.dropdown-item-container input[type="radio"] {
  display: none;
}

.dropdown-item-container input,
.dropdown-item-container label {
  cursor: pointer;
}

.bg-black {
  background: #000;
}
.bg-white {
  background: #f3f3f3;
}
.bg-red {
  background: var(--clr-invalid);
}
.bg-yellow {
  background: var(--clr-yellow);
}
.bg-blue {
  background: var(--clr-primary);
}
.bg-green {
  background: var(--clr-success);
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

/* error */
.text-input-error {
  font-size: 0.8rem;
  color: var(--clr-invalid);
  margin-top: 0.2rem;
}
