.profileProjects {
  margin-top: 2rem;
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 10%);
  background: var(--clr-light);
  border-radius: 1em;
}

.profileProjects .categories {
  border-radius: 1em 1em 0 0;
}

.profileProjects__container {
  padding: 1.5em;
  width: 100%;
}
.profileProjects__container.genius-projects {
  padding: 0 1.5em 1.5em;
}

.profileProjects h3 {
  font-size: 1.05rem;
  font-weight: 600;
}

.profileProjects h4 {
  width: 100%;
  justify-content: space-between;
}

.profileProjects__items {
  width: 100%;
  display: grid;
  margin-top: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

.profileProjects__imgContainer.video-show {
  width: 100%;
  margin: 0 2rem;
}

.profileProjects__items iframe,
.profileProjects__imgThumbnail {
  display: block;
  width: 100%;
  height: 10rem;
  border-radius: 0.75em;
  object-fit: cover;
}

.profileProjects__items button {
  background: transparent;
  padding: 0.5em 1.25em;
  text-transform: uppercase;
  color: var(--clr-primary);
  font-weight: 600;
  border-radius: 0.75em;
  width: 100%;
  margin-top: 1rem;
  border: 0.1rem solid var(--clr-primary);
}

.profileProjects__category {
  display: block;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  color: var(--clr-gray500);
}

.profileProjects__items {
  display: grid;
  height: 100%;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}

.profileProjects__item {
  min-height: 20rem;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
}

.profileProjects__ranking {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 11;
}

.profileProjects__ranking img {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
}

.profileProjects__item h4 {
  font-size: 1.2rem;
  font-weight: 600;
}

.profileProjects__item p {
  font-size: 0.85rem;
  margin: 0.5rem 0;
}
.profileProjects__item span {
  display: block;
  color: var(--clr-gray500);
  font-size: 0.9rem;
  margin: 0.5rem 0 0.25rem 0;
}

.profileProjects__item ~ .profileProjects__create {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: var(--clr-gray500);
  border: 0.1rem solid #0002;
  border-radius: 1em;
  cursor: pointer;
  font-weight: 600;
}

.profileProjects__item ~ .profileProjects__create svg {
  margin-bottom: 0.5rem;
}

/* videos premium */
.profileProjects__imgContainer {
  position: relative;
  border-radius: 0.75em;
  overflow: hidden;
  margin-bottom: 0.5rem;
  z-index: 1;
}

.profileProjects__imgContainer.premium-video::after {
  content: url("https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/47002e261e3a4fc729b59877e495d7012c40e306/lock.svg");
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 1;
  border-radius: 50%;
  margin: auto;
  z-index: 11;
  transform: scale(1.1);
  background: var(--clr-primary);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.profileProjects__imgContainer.premium-video::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  border: 2px solid transparent;
  -webkit-font-smoothing: antialiased;
  background-clip: padding-box;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(1rem);
  left: 0;
  top: 0;
}

/* contributors */
.profileProjects__contributors {
  display: flex;
  position: absolute;
  bottom: 0rem;
  right: 0.5rem;
  gap: 0.25rem;
}

.profileProjects__contributors img {
  width: 100%;
  box-shadow: 0 0.5rem 1rem #0005;
  max-width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  z-index: 200;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.profileProjects__darkBg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0005;
  left: 0;
  top: -0.5rem;
}
