.quiz {
  display: grid;
  gap: 1rem;
  /* overflow: hidden; */
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}

.quiz__content::-webkit-scrollbar {
  display: none;
}

.quiz__invite {
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #000;
  z-index: 200;
  position: fixed;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.quiz__inviteContainer {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  inset: 0;
  height: 100%;
  justify-content: space-between;
  background: #f8f8f8;
  padding: 2em;
}

body.dark-mode .quiz__inviteContainer {
  background: var(--clr-dark);
  color: var(--clr-light);
}
/* body.dark-mode .quiz__inviteContainer form:focus-within {
  border: none;
} */

/* .quiz__container + .quiz__container {
  margin-left: 1rem;
} */

.quiz__toggle input[type="checkbox"] {
  display: none;
}

.quiz__toggle {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  position: relative;
  /* background-color: var(--clr-light); */
  background-color: var(--clr-focus-shadow);
  border: 0.2rem solid #077aff17;
  /* border: 0.2rem solid var(--clr-primary); */
  --height: 2.8rem;
  height: var(--height);
  width: fit-content;
  border-radius: calc(var(--height) / 2);
  margin-top: 0.5rem;
  cursor: pointer;
  /* margin: 0 auto; */
}

body.dark-mode .quiz__toggle {
  background: var(--clr-darker);
  border: 0.2rem solid var(--clr-secondary-dark);
}

.quiz__toggle :is(.quiz__toggleName, .quiz__toggleLink) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* width: 128px; */
  padding: 1em 1.25em;
  font-size: 0.7rem;
  font-weight: 600;
  /* background: red; */
  z-index: 2;
  -webkit-transition: color 400ms ease;
  transition: color 400ms ease;
}
.quiz__toggle .quiz__toggleName {
  color: var(--clr-primary);
  /* margin-left: -0.4rem; */
}
.quiz__toggle .quiz__toggleLink {
  padding-right: 1.5em;
  padding-left: 1em;
  color: var(--clr-text-gray);
}
.quiz__toggle input ~ div {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  height: 100%;
  width: 7.2rem;
  border-radius: 22px;
  padding: 1em;
  background-color: var(--clr-primary-light);
  z-index: 1;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}

body.dark-mode .quiz__toggle input ~ div {
  background: var(--clr-modal);
}
.quiz__toggle input:checked ~ div {
  left: 100%;
  -ms-transform: translate(-100%, -50%);
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}
/* .quiz__toggle input:checked ~ div {
  width: 7.2rem;
} */
.quiz__toggle input:checked ~ .quiz__toggleName {
  color: var(--clr-text-gray);
}
.quiz__toggle input:checked ~ .quiz__toggleLink {
  color: var(--clr-primary);
}

.quiz__inviteContainer h3 {
  font-size: 1.2rem;
}
.quiz__inviteContainerText {
  display: block;
  font-size: 0.8rem;
  line-height: 1.8;
  margin: 0.75rem 0 1.5rem 0;
}

.quiz__inviteContainerAction {
  margin-top: 1.5rem;
}

/* .quiz__inviteContainerAction form:focus-within {
  box-shadow: 0 0 0.5rem #077aff30;
  border: 0.1rem solid var(--clr-primary);
} */

.quiz__inviteContainerAction {
  font-size: 0.8rem;
}

.quiz__inviteContainerAction form {
  background: var(--clr-light);
  padding: 0.25em 0.75em;
  height: fit-content;
  margin-top: 0.75rem;
  box-shadow: 0 0 0.5rem #077aff30;
  border: 0.1rem solid var(--clr-primary);
  border-radius: 3em;
  /* border: 0.1rem solid var(--clr-light); */
  position: relative;
}

body.dark-mode .quiz__inviteContainerAction form {
  background: var(--clr-modal);
}

.quiz__inviteContainerAction button {
  flex: 0.5;
  font-weight: 600;
  font-size: 0.65rem;
  color: var(--clr-gray500);
}

.quiz__inviteContainerAction input {
  flex: 2;
  /* background: red; */
  /* height: 100%; */
  padding: 1.25em 1em;
}

body.dark-mode .quiz__inviteContainerAction input {
  color: var(--clr-light);
}

.quiz__inviteSuccess {
  position: absolute;
  padding: 0.75em 2em;
  width: fit-content;
  justify-content: center;
  background: var(--clr-light);
  font-size: 0.8rem;
  border-radius: 3em;
  font-weight: 700;
  left: 50%;
  bottom: -5rem;
  opacity: 0;
  transform: translateX(-50%);
}

body.dark-mode .quiz__inviteSuccess {
  background: var(--clr-modal);
}

.quiz__inviteSuccess.sent {
  bottom: 1rem;
  transition: bottom 0.3s, opacity 0.2s 0.2s;
  opacity: 1;
}

.quiz__inviteSuccessIcon {
  padding: 0.5em;
  width: fit-content;
  margin-left: 0.5rem;
  /* background: var(--clr-success); */
  background: #4bec00;
}

.quiz__inviteSuccessIcon svg {
  width: 0.9rem;
  display: flex;
  height: 0.9rem;
}

.quiz__inviteContainerPerson {
  position: absolute;
  top: calc(100% + 1rem);
  width: calc(100% - 11rem);
  left: 0;
  background: var(--clr-light);
  max-height: 12rem;
  overflow-y: scroll;
  border-radius: 1em;
  scrollbar-width: none;
}

body.dark-mode .quiz__inviteContainerPerson {
  background: var(--clr-modal);
}
.quiz__inviteContainerPerson::-webkit-scrollbar {
  display: none;
}

/* .quiz__inviteContainerPerson li:first-child {
  border-radius: 1em 1em 0 0;
}
.quiz__inviteContainerPerson li:last-child {
  border-radius: 0 0 1em 1em;
} */
.quiz__inviteContainerPerson li {
  color: var(--clr-text-dark);
  padding: 1em 1.5em;
}

body.dark-mode .quiz__inviteContainerPerson li {
  color: var(--clr-light);
}
body.dark-mode .quiz__inviteContainerPerson li:hover {
  background: var(--clr-modal-darker);
}
.quiz__inviteContainerPerson li:hover {
  background: var(--clr-light-gray);
  cursor: pointer;
}

.quiz__inviteContainerPerson li img {
  max-width: 2.5rem;
  margin-right: 1rem;
}
.quiz__inviteContainerPerson li p {
  font-size: 0.9rem;
  font-weight: 600;
}

.quiz__inviteContainerPerson li p span {
  color: var(--clr-gray500);
}

.quiz__invited {
  margin-top: 0.5rem;
  gap: 0.5rem;
}

/* .quiz__invited {
  display: grid;
  flex: 2;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  gap: 0.5rem;
}

.quiz__invited li:last-child {
  background: transparent;
  padding: 0;
} */

.quiz__invited li {
  background: #efefef;
  padding: 0.5rem 1em;
  width: fit-content;
  border-radius: 3em;
  font-weight: 700;
}

.quiz__invited li svg {
  margin-left: 1rem;
  cursor: pointer;
}
.quiz__invited li svg path {
  stroke: var(--clr-gray500);
}

@media (min-width: 425px) {
  .quiz__inviteContainerAction button {
    font-size: 0.8rem;
  }
  .quiz__toggle :is(.quiz__toggleName, .quiz__toggleLink) {
    font-size: 0.8rem;
  }

  .quiz__toggle input ~ div {
    width: 8.2rem;
  }

  /* .quiz__toggle input:checked ~ div {
    width: 8.2rem;
  } */
}

@media (min-width: 528px) {
  .quiz__content {
    height: calc(100vh - 6rem);
    /* height: 100%; */
    scrollbar-width: none;
    overflow-y: scroll;
  }
}

@media (min-width: 768px) {
  .quiz__inviteContainer {
    padding: 1.8em 2em;
  }
}

@media (min-width: 900px) {
  .quiz {
    padding: 1em;
    height: calc(100vh - 5.5rem);
    overflow: hidden;
    margin-bottom: -4rem;
  }

  .quiz__content::after {
    content: "asdasdasda";
    font-size: 3.5rem;
    visibility: hidden;
    pointer-events: none;
    /* height: 10rem; */
  }
}

@media (min-width: 1200px) {
  .quiz__invite {
    background: #000000b3;
  }

  body.dark-mode .quiz__inviteContainer {
    box-shadow: 0 0 1rem #0007;
  }

  .quiz__inviteContainer {
    position: relative;
    border-radius: 1em;
    max-width: 50rem;
    height: fit-content;
    left: 50%;
    top: 50%;
    padding: 5em 4em;
    animation: fadein 0.7s;
    transform: translate(-50%, -50%);
  }
}
