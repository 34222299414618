.settingPopup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  background: #000b;
}

.settingPopup__container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--clr-light);
  box-shadow: 0rem 0rem 1rem #0002;
  border-radius: 0;
}

body.dark-mode .settingPopup__container {
  background: var(--clr-dark);
  box-shadow: 0rem 0rem 1rem #0007;
}

.settingPopup__header {
  padding: 1em 1.5em;
  border-bottom: 0.05rem solid var(--clr-light-gray);
}

.settingPopup__header svg {
  margin-right: 1rem;
}

body.dark-mode .settingPopup__header {
  border-bottom: 0.05rem solid var(--clr-secondary-dark);
  padding: 1em 2em;
}

.settingPopup__header h1 {
  font-size: 1rem;
  font-weight: 500;
}

.settingPopup__body {
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
}

.settingPopup__bodyNav {
  width: 100%;
}

.settingPopup__bodyNavItems {
  align-items: flex-start;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  padding: 1em 1.5em;
  border-bottom: 0.05rem solid var(--clr-gray300);
}

body.dark-mode .settingPopup__bodyNavItems {
  border-bottom: 0.05rem solid var(--clr-secondary-dark);
}

.settingPopup__bodyNavItems::-webkit-scrollbar {
  display: none;
}

.settingPopup__bodyNavItem:hover,
body.dark-mode .settingPopup__bodyNavItem.active,
.settingPopup__bodyNavItem.active {
  color: var(--clr-primary);
}

.settingPopup__bodyNavItem {
  font-size: 0.85rem;
  width: 100%;
  margin-right: 1rem;
  font-weight: 400;
  cursor: pointer;
}

.settingPopup__mainContent {
  width: 100%;
  padding: 1.5em 1.5em calc(1.5em + 3.5em) 1.5em;
  /* height: 100%; */
  /* max-height: initial; */
  /* overflow-y: scroll; */
  scrollbar-width: none;
  /* border-left: 0.05rem solid var(--clr-gray); */
}

.settingPopup__mainContent::-webkit-scrollbar {
  display: none;
}
/* 
.settingPopup input {
  padding: 0.75em 1.25em;
  border-radius: 1em;
  color: var(--clr-darker);
  background: var(--clr-modal-light);
  border: 0.1rem solid var(--clr-gray300);
}

body.dark-mode .settingPopup input {
  color: var(--clr-light);
  background: var(--clr-modal);
  border: 0.1rem solid var(--clr-hover-dark);
}

.settingPopup input::placeholder {
  color: var(--clr-text-gray);
}

.settingPopup label {
  color: var(--clr-text-dark);
}

body.dark-mode .settingPopup label {
  color: var(--clr-light);
}

body.dark-mode .settingPopup input:focus-within,
.settingPopup input:focus-within {
  border: 0.1rem solid var(--clr-primary);
} */

@media (min-width: 550px) {
  .settingPopup__body {
    flex-direction: row;
  }

  .settingPopup__header {
    padding: 1em 2em;
  }

  .settingPopup__bodyNav {
    max-width: 11rem;
    height: 100%;
    border-right: 0.05rem solid var(--clr-gray300);
  }
  /* body.dark-mode .settingPopup__bodyNav {
    border-right: 0.05rem solid var(--clr-secondary-dark);
  } */

  .settingPopup__bodyNavItems {
    flex-direction: column;
    /* padding: 2.5em 2em; */
    border-bottom: none;
  }

  .settingPopup__bodyNavItem {
    padding: 0.5em 1em;
    margin-bottom: 0.5rem;
    color: var(--clr-text-dark);
    border-radius: 0.5em;
  }

  body.dark-mode .settingPopup__bodyNavItem {
    color: var(--clr-light);
  }

  .settingPopup__bodyNavItem:hover {
    /* background: var(--clr-modal-light); */
    background: var(--clr-light-gray);
    color: var(--clr-text-dark);
  }

  .settingPopup__bodyNavItem.active {
    position: relative;
  }
  body.dark-mode .settingPopup__bodyNavItem.active::after,
  .settingPopup__bodyNavItem.active::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: var(--clr-primary);
    height: 90%;
    width: 0.1rem;
  }

  body.dark-mode .settingPopup__bodyNavItem:hover {
    color: var(--clr-light);
    /* background: var(--clr-hover-dark); */
    background: var(--clr-modal-darker);

  }

  .settingPopup__mainContent {
    padding: 2em;
  }
}

@media (min-width: 1200px) {
  .settingPopup__container {
    position: absolute;
    width: 60rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 36rem;
    border-radius: 1em;
    animation: fadein 0.7s;
    /* overflow-y: hidden; */
  }

  .settingPopup__header svg {
    display: none;
  }

  .settingPopup__mainContent {
    border-left: 0.05rem solid var(--clr-gray300);
    overflow-y: scroll;
    height: 32.25rem;
  }

  body.dark-mode .settingPopup__mainContent {
    border-left: 0.05rem solid var(--clr-secondary-dark);
  }

  .settingPopup__bodyNav {
    border-right: none;
  }
}
