.cart table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 4rem;
}

.cart th {
  text-align: left;
  padding: 0.75em 1em;
  /* font-size: 0.65rem; */
  /* font-size: 0.9rem; */
  font-weight: 700;
  /* text-transform: uppercase; */
  /* color: var(--clr-gray500); */
  /* letter-spacing: 2px; */
  display: none;
}

body.dark-mode .cart th {
  border-bottom: 0.05rem solid var(--clr-gray300);
}

.cart td {
  padding: 2em 0.5em 2.75em 0.5em;
  font-size: 0.8rem;
  border-bottom: 0.05rem solid var(--clr-gray300);
}

body.dark-mode .cart td {
  border-bottom: 0.05rem solid var(--clr-secondary-dark);
}

/* .cart td:last-child {
  padding: 0;
} */

.cart__item img {
  width: 6rem;
  height: 6rem;
  border-radius: 0.3em;
  margin-right: 1rem;
  object-fit: cover;
}

.cart__itemTitle {
  font-size: 0.9rem;
}
.cart__promoCode,
.cart__price,
.cart__priceTotal strong {
  display: none;
}

.cart__priceTotal strong,
.cart__price strong {
  font-size: 0.9rem;
}

.cart__discountTotal p,
.cart__allPriceTotal p {
  /* font-size: 0.6rem; */
  /* text-transform: uppercase; */
  color: var(--clr-gray500);
  /* letter-spacing: 2px; */
  font-weight: 600;
}

.cart__ColorsContainer {
  font-size: 0.6rem;
  text-transform: uppercase;
  color: var(--clr-gray500);
  letter-spacing: 2px;
  font-weight: 600;
}

.cart tr {
  position: relative;
}

.cart__removeItem {
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  font-size: 0.85rem;
  color: var(--clr-invalid);
}

.cart__removeItem svg {
  margin-right: 0.3rem;
  width: 1rem;
  height: 1rem;
}
/* 
.cart__detailsQty {
  flex-direction: column;
} */

.cart__deleteIconItem {
  margin-top: 0.5rem;
}

.cart__detailsQtyCountContainer {
  padding: 0.25em 0.3em;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 2em;
  border: 0.1rem solid var(--clr-gray500);
}

.cart__detailsQtyCountContainer svg {
  margin: 0.2rem 0;
}

.cart__detailsQtyCount {
  color: var(--clr-darker);
  font-weight: 700;
}

body.dark-mode .cart__detailsQtyCount {
  color: var(--clr-light);
}

.cart__detailsQtyMinus,
.cart__detailsQtyPlus {
  display: flex;
  cursor: pointer;
  padding: 0.2em;
}

.cart__ColorsColor {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  position: relative;
  border: 0.1rem solid var(--clr-light);
}

/* body.dark-mode .cart__ColorsColor {
  border: 0.3rem solid var(--clr-dark);
} */

.cart__priceTotal .flex-row {
  justify-content: space-between;
}

.cart__priceTotal svg {
  cursor: pointer;
}
.cart__subtotal {
  width: 100%;
  /* width: 90%; */
  margin: 2rem auto 0 auto;
  justify-content: space-between;
}
.cart__promoCode {
  padding: 1.25em 2em;
  width: 100%;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 700;
  border: 0.1rem solid var(--clr-gray300);
  background: var(--clr-header-light);
  color: var(--clr-gray500);
  max-width: 10rem;
}

.cart__promoCode > div.flex-row {
  width: 100%;
  justify-content: space-between;
}

.cart__promoCode.promo-input {
  width: 100%;
  max-width: 20rem;
  padding: 0.75em;
}

.cart__promoCode button {
  padding: 0.75em !important;
  color: var(--clr-gray400);
  cursor: default;
  /* color: var(--clr-light); */
}
.cart__promoCode button.primary {
  cursor: pointer;
}

.cart__promoCode input {
  padding: 0 0.75em;
  max-width: 10rem;
}
.cart__promoCode input.promo-err {
  color: var(--clr-invalid);
  font-weight: 700;
}

body.dark-mode .cart__promoCode {
  background: var(--clr-modal);
}

.cart__promoCode svg {
  width: 0.5rem;
  display: flex;
}

.cart__promoCode svg path {
  stroke: var(--clr-primary);
}

.cart__discountTotal,
.cart__allPriceTotal {
  align-items: flex-start;
  margin: 0 1rem;
}

.cart__discountTotal {
  display: none;
}
.cart__discountTotal strong,
.cart__allPriceTotal strong {
  align-items: flex-start;
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 0rem;
}

.cart__discountTotal strong span,
.cart__allPriceTotal strong span {
  font-size: 1rem;
  margin-top: 0.1rem;
  color: var(--clr-gray500);
}

.cart__subtotal button {
  width: 100%;
  max-width: 8rem;
  font-size: 0.6rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1.25em;
}

@media (min-width: 580px) {
  .cart__subtotal button {
    max-width: 10rem;
    font-size: 0.7rem;
  }

  .cart__promoCode {
    display: flex;
  }
  .cart__price {
    display: table-cell;
  }
  .cart__priceTotal strong {
    display: flex;
  }

  .cart__removeItem {
    position: initial;
  }

  .cart__removeItem svg {
    margin-right: 0.5rem;
    width: initial;
    height: initial;
  }

  .cart__deleteIconItem {
    display: none;
  }

  .cart__detailsQtyCountContainer {
    padding: 0.25em 0.75em;
    flex-direction: row;
    width: 5rem;
  }

  .cart__removeItem small {
    display: none;
  }

  .cart td {
    padding: 2em 1em;
    font-size: 0.8rem;
    border-bottom: 0.05rem solid var(--clr-gray300);
  }

  .cart th {
    display: table-cell;
    border-bottom: 0.05rem solid var(--clr-gray400);
  }
}

@media (min-width: 900px) {
  .cart {
    padding: 0 2em;
  }

  .cart__itemTitle strong {
    display: none;
  }

  .cart__discountTotal {
    display: flex;
  }

  .cart__item img {
    margin-right: 2rem;
  }
}
