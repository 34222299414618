.overview {
  margin-top: 2rem;
  overflow-x: hidden;
}

.overview__hiring {
  padding: 2em;
  background: var(--clr-primary);
  border-radius: 0.75em;
  margin-top: 1rem;
  color: var(--clr-light);
  position: relative;
  overflow: hidden;
}

.overview__hiring > div {
  max-width: 35rem;
}

.overview__hiring img {
  position: absolute;
  width: 100%;
  max-width: 25rem;
  right: 0;
  top: 0;
}

.overview__hiring > div > span {
  display: inline-block;
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
  color: #fffa;
}

.overview__hiringBtnContainer {
  margin-top: 1.5rem;
  gap: 0.5rem;
}

.overview__hiringBtnContainer button {
  border-radius: 0.75em;
}

.overview__hiringBtnContainer button:first-child {
  background: var(--clr-light);
  font-weight: 600;
}

.overview__hiringBtnContainer button:last-child {
  background: #0002;
  color: var(--clr-light);
}
