.contentCard {
  width: 100%;
  height: 100%;
  position: relative;
}

.contentCard small {
  color: var(--clr-text-dark);
  cursor: pointer;
}
body.dark-mode .contentCard small {
  color: var(--clr-gray400);
}
.contentCard__img {
  width: 100%;
  cursor: pointer;
}

.contentCard__imgChannel:hover .contentCard__channelDetails {
  opacity: 1;
  pointer-events: all;
}

.contentCard__channelDetails {
  position: absolute;
  top: 2.5rem;
  padding: 1em;
  border-radius: 0.4em;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  z-index: 100;
  width: 19rem;
  pointer-events: none;
  min-height: 7rem;
  transition: 0.3s ease-in-out;
  /* background: var(--clr-dark400); */
  background: var(--clr-light);
  color: #000;

  box-shadow: 0rem 0rem 1rem #0002;
}

body.dark-mode .contentCard__channelDetails {
  color: var(--clr-ligth);
  background: var(--clr-modal);
  box-shadow: 0rem 0rem 0.5rem #0007;
}

.contentCard__channelDetails::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -1rem;
  border-right: 1rem solid transparent;
  border-left: 1rem solid transparent;
  /* border-bottom: 1.3rem solid var(--clr-dark400); */
  border-top: 0.5rem solid transparent;
  border-bottom: 1.3rem solid var(--clr-light);
}

body.dark-mode .contentCard__channelDetails:after {
  border-bottom: 1.3rem solid var(--clr-modal);
}

.contentCard__mainImg {
  position: relative;
  border-radius: 0.3rem;
  overflow: hidden;
  margin-bottom: 0.3rem;
}

.contentCard__mainImg.premium-video::after {
  content: url("https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/47002e261e3a4fc729b59877e495d7012c40e306/lock.svg");
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 1;
  border-radius: 50%;
  margin: auto;
  transform: scale(1.1);
  background: var(--clr-primary);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.contentCard__mainImg.premium-video::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  -webkit-font-smoothing: antialiased;
  background-clip: padding-box;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(1rem);
  left: 0;
  top: 0;
}

.contentCard__thumbnail {
  display: block;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}

.contentCard__about {
  justify-content: space-between;
}

.contentCard__about small {
  font-size: 0.7rem;
}

.contentCard__views small {
  margin-left: 0.2rem;
}

.contentCard__desc {
  margin-top: 0.4rem;
}

.contentCard__descHeader {
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
}

.contentCard__descChannel {
  margin-top: 0.3rem;
  cursor: pointer;
}

.contentCard__descChannel small {
  margin-right: 0.3rem;
  font-size: 0.75rem;
}

.contentCard__imgChannel {
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  bottom: 1rem;
  left: 0.5rem;
}

.contentCard__imgChannel.png .contentCard__imgChannelImg,
.contentCard__imgChannel.jpg .contentCard__imgChannelImg {
  position: absolute;
  width: 2rem !important;
  height: 2rem !important;
}
