.missionCard {
  position: relative;
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 10%);
  border-radius: 0.75em;
  justify-content: center;
  cursor: default;
  padding: 1.75em;
}

.missionCard.clicked {
  cursor: pointer;
}

.missionCard__emblem {
  width: 100%;
  max-width: 5.5rem;
}
.missionCard__emblem.main-emblem {
  max-width: 10rem;
}

.missionCard__miniEmblem {
  position: absolute;
  width: 100%;
  top: 1rem;
  max-width: 2rem;
  left: 1.5rem;
}

.missionCard__progress {
  width: 100%;
  position: relative;
  border-radius: 3rem;
  height: 0.25rem;
  margin: 1rem 0;
  background: var(--clr-gray);
}

.missionCard__exp {
  position: absolute;
  right: -1rem;
  top: 1rem;
  border-radius: 3em;
  background: var(--clr-light);
  padding: 0.5em 1em;
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 10%);
  font-size: 0.7em;
  font-weight: 700;
}

.missionCard__exp svg {
  margin: 0 0.2rem 0.1rem 0;
}

/* .missionCard__emblem {
  margin-bottom: 1rem;
} */

.missionCard__progress.unlocked::after {
  content: "";
  position: absolute;
  width: var(--mission-progress-persent);
  height: inherit;
  border-radius: inherit;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    to left,
    #86ddff,
    #57caff,
    #3b9aff,
    #288cfd,
    #077aff
  );
}

.missionCard__title {
  margin: 1rem 0;
}

.missionCard__desc {
  font-size: 0.8rem;
  max-width: 15rem;
  text-align: center;
}

.missionCard__lock {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
}

.missionCard__badges {
  margin-top: 1rem;
  display: flex;
  gap: 0.25rem;
}

.missionCard__badges img {
  width: 1.25rem;
}
