.earnMoney__offer {
  display: flex;
  padding: 1em;
  border-radius: 1.25em;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 3rem;
  box-shadow: 0 5px 27px 0 rgb(0 0 0 / 10%);
}

.earnMoney__offer img {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 0.75em;
  max-width: 5rem;
}

.earnMoney__offerText {
  max-width: 35rem;
  font-size: 0.8rem;
}

.earnMoney__offerText h3 {
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

.earnMoney__offer button {
  margin-left: auto;
  margin-right: 1rem;
  font-size: 0.8rem;
  padding: 0.75em 1em;
  border-radius: 0.5em;
}

.earnMoney__card {
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  flex: 1 1 40%;
  width: 100%;
  border-radius: 0.75em;
  padding: 1em;
  max-width: 22rem;
  height: 13rem;
  color: var(--clr-light);
  align-items: flex-start;
  justify-content: space-between;
}

.earnMoney__cardHeader {
  width: 100%;
  justify-content: space-between;
}

.earnMoney__cardHeader strong {
  font-size: 1.15rem;
}

.earnMoney__cardInput {
  width: 100%;
  align-items: flex-start;
}

.earnMoney__card label {
  font-size: 0.75rem;
  margin-bottom: 0.1rem;
}

.earnMoney input::placeholder {
  color: #fffa;
}

.earnMoney__cardInput input {
  color: var(--clr-light);
  font-size: 1.25rem;
  letter-spacing: 0.15rem;
  font-weight: 600;
  width: 100%;
}

.earnMoney__cardDetails {
  width: 100%;
  justify-content: space-between;
}

.earnMoney__cardDetails > div {
  align-items: flex-start;
  width: fit-content;
}

.earnMoney__cardDetails input {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--clr-light);
}

/* exp */
.earnMoney__cardDetails > div:last-of-type {
  max-width: 4rem;
}

.earnMoney__options {
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  /* justify-content: center; */
  gap: 1.5rem;
}

.earnMoney__cardPage {
  padding: 2em;
  width: 100%;
  max-width: 13rem;
  height: 13rem;
  flex: 1 1 20%;
  border: 0.1rem solid var(--clr-gray300);
  justify-content: center;
  border-radius: 0.75em;
  cursor: pointer;
  transition: 0.25s;
}

.earnMoney__cardPage:hover {
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
}

.earnMoney__cardPage img {
  width: 100%;
  max-width: 4rem;
}

.earnMoney__cardPage {
  text-align: center;
}

.earnMoney__tasksContainer {
  margin-top: 2rem;
}

.earnMoney__tasks {
  display: grid;
  margin-top: 1rem;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1rem;
}

.earnMoney__task {
  width: 100%;
  padding: 0.75em;
  gap: 0.5rem;
  border: 0.1rem solid var(--clr-gray300);
  border-radius: 0.75em;
}

.earnMoney__taskScore {
  width: 5rem;
  height: 5rem;
  border-radius: 0.5em;
  justify-content: center;
  line-height: 1.4;
}

.earnMoney__taskScore.A {
  color: var(--clr-primary);
  background: var(--clr-blue200);
}
.earnMoney__taskScore.B {
  color: var(--clr-success);
  background: var(--clr-green200);
}
.earnMoney__taskScore.C {
  color: var(--clr-yellow);
  background: var(--clr-yellow200);
}
.earnMoney__taskScore.D {
  color: var(--clr-invalid);
  background: var(--clr-red200);
}
.earnMoney__taskScore.E {
  color: var(--clr-dark);
  background: #0002;
}

.earnMoney__taskScore span:first-child {
  font-size: 1.5rem;
  font-weight: 800;
}
.earnMoney__taskScore span:last-child {
  font-size: 0.9rem;
  font-weight: 600;
}

.earnMoney__taskDesc {
  font-size: 0.9rem;
}

@media (min-width: 1200px) {
  .earnMoney__options {
    flex-wrap: nowrap;
  }
}
