.completedSteps {
  /* position: relative; */
  width: 80%;
  display: flex;
  justify-content: space-between;
  /* background: red; */
  max-width: 50rem;
  margin: 0 auto;
  margin-top: 1rem;
}

.completedSteps__content {
  display: flex;
  align-items: center;
  width: 100%;
}

.completedSteps__content:last-child {
  width: fit-content;
}

.completedSteps__rounded::after {
  content: "";
  position: absolute;
  bottom: -1.5em;
  font-size: 0.8rem;
  /* text-transform: uppercase; */
  /* letter-spacing: 2px; */
  font-weight: 500;
  color: var(--clr-gray500);
}

/* .completedSteps__rounded::after {
  content: "";
  position: absolute;
  bottom: -1.2rem; */
  /* left: 50%;
  transform: translateX(-50%); */
  /* text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--clr-gray500);
} */

.completedSteps__rounded.active,
.completedSteps__rounded {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  width: 1.5rem;
  height: 1.5rem;
  font-weight: 600;
  background: var(--clr-light-gray);
  margin-bottom: 5px;
  color: var(--clr-dark-gray);
  border-radius: 50%;
}

.completedSteps__rounded.active {
  color: var(--clr-light);
}

body.dark-mode .completedSteps__rounded {
  color: var(--clr-darker);
}
body.dark-mode .completedSteps__rounded.active {
  color: var(--clr-light);
}

.completedSteps__rounded.active::after {
  color: var(--clr-darker);
}
body.dark-mode .completedSteps__rounded.active::after {
  color: var(--clr-light);
}

.completedSteps__rounded.one::after {
  content: "Cart";
}
.completedSteps__rounded.two::after {
  content: "Delivery";
}
.completedSteps__rounded.three::after {
  content: "Payment";
}
.completedSteps__rounded.four::after {
  content: "Done";
}

.completedSteps__line {
  /* width: 100%; */
  max-width: 15rem;
  height: 0.18rem;
  margin-top: -5px;
  flex: 1;
  background: var(--clr-light-gray);
}

.completedSteps__rounded.active,
.completedSteps__line.active {
  background: var(--clr-primary);
}
