header {
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  padding: 0.5em 1.5em;
  min-height: 5rem;
  background: var(--clr-light);
}

body.dark-mode header {
  /* background: var(--clr-light); */
  background: var(--clr-dark-background);
  color: var(--clr-light);

  /* transition: background 0.4s; */
}

.header__dropdownSearch {
  display: none;
}

.header__form svg {
  width: 1.1rem;
  height: 1.1rem;
}
.header__balance {
  margin-right: 0.5rem;
}

.header__balance p {
  /* color: var(--clr-gray500); */
  margin-left: 0.3rem;
  font-size: 0.85rem;
  margin-top: 0.2rem;
}

.header__form input {
  display: none;
}

.header__rightMessage,
.header__rightNotification {
  display: none;
}

.header__dropbox select {
  font-size: 0.85rem;
  padding: 0.5em 0;
  margin-left: 0.5rem;
  border: none;
  background: var(--clr-ligth);
  color: var(--clr-darker);
  position: relative;
  outline: none;
  box-shadow: none;
}

body.dark-mode .header__dropbox select {
  color: #fff;
  background: var(--clr-darker);
}

.header__detail {
  display: none;
}

.header__right a {
  display: none;
}

.header__btnDonate,
.header__balanceUser {
  display: none;
}

/* .header__dropdownSearch { */
/* display: none; */
/* } */

@media (min-width: 900px) {
  header {
    padding: 0.25em 1.5em;
    min-height: initial;
    flex-direction: row;
    /* margin-top: 1rem; */
    background: var(--clr-light);
    border-bottom: 0.1rem solid var(--clr-gray300);
  }

  .header__right a {
    display: initial;
  }

  .header__right {
    gap: 1.5rem;
  }

  /* body.dark-mode header { */
  /* background: var(--clr-dark); */
  /* border-bottom: 0.1rem solid var(--clr-secondary-dark); */
  /* } */

  /* .header__form svg {
    width: 35px;
    height: 35px;
  }

  .header__balance svg {
    width: 32px;
    height: 32px;
  }

  .header__balance p {
    font-size: 0.9rem;
  } */

  .header__form {
    width: 18rem;
    padding: 0.45em 0.25em;
    /* padding: 0.25em 0.75em 0.25em 0.75em; */
    background: var(--clr-modal-light);
    border: 0.1rem solid var(--clr-gray300);
    border-radius: 0.7em;
    flex-direction: row-reverse;
  }

  .header__form.shop-page {
    flex-direction: row;
    padding: 0.1em 0.75em 0.25em 0;
  }

  .header__dropdownSearch {
    width: 100%;
    /* width: fit-content; */
    max-width: 8.1rem;

    /* margin-right: 0.5rem; */
  }

  .header__dropdownSearch .dropdown-container {
    font-size: 0.8rem;
    width: 100%;
    /* display: none; */
    padding: 1em 1em;
  }
  .header__dropdownSearch .dropdown-container.expand {
    background-color: transparent;
    box-shadow: none;
  }

  .header__form.shop-page .header__dropdownSearch .dropdown-container {
    display: flex;
  }

  .header__dropdownSearch .dropdown-container p {
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 0.1rem;
    color: var(--clr-text-dark);
  }

  .header__dropdownSearchIcon {
    transform: rotate(0deg);
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
  }
  body.dark-mode .header__dropdownSearch .dropdown-container p {
    color: var(--clr-light);
  }
  .header__dropdownSearch .dropdown-item-container {
    top: calc(100% + 0.5rem);
    border-radius: 0.5em;
    z-index: 10;
  }
  .header__dropdownSearch .dropdown-item-container label {
    font-weight: 600;
    padding: 1em 1em;
    width: 10rem;
    font-size: 0.8rem;
  }

  .header__dropdownSearch .dropdown-item-container label.menu-active {
    color: var(--clr-primary);
    position: relative;
  }
  .header__dropdownSearch .dropdown-item-container label.menu-active::after {
    content: "";
    position: absolute;
    height: 90%;
    left: 0;
    width: 0.15rem;
    background: var(--clr-primary);
  }

  .header__dropdownSearch .dropdown-item-container label span {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    border: 0.1rem solid var(--clr-gray500);
  }
  .header__dropdownSearch .dropdown-item-container label input:checked ~ span {
    border: 0.35rem solid var(--clr-primary);
  }

  .header__dropdownSearch .dropdown-item-container label.menu-active img {
    filter: invert(41%) sepia(53%) saturate(7452%) hue-rotate(204deg)
      brightness(105%) contrast(100%);
  }

  .header__dropdownSearch .dropdown-container .header__dropdownSearchArrow {
    width: 0.5rem;
    height: 0.5rem;
  }

  body.dark-mode .header__dropdownSearchIcon {
    filter: invert(1) sepia(100%) saturate(1%) hue-rotate(183deg)
      brightness(200%) contrast(101%);
  }

  body.dark-mode
    .header__dropdownSearch
    .dropdown-container
    .header__dropdownSearchArrow
    path,
  .header__dropdownSearch
    .dropdown-container
    .header__dropdownSearchArrow
    path {
    stroke: var(--clr-primary);
  }
  .header__dropdownSearch {
    display: initial;
    justify-content: space-between;
    position: relative;
    font-size: 1rem;
  }

  .header__searchIcon {
    width: 2.2rem !important;
    height: 1.8rem !important;
    /* width: 2.2rem !important;
    height: 2rem !important; */
    /* margin-top: 0.1rem; */
  }

  .header__form.shop-page input {
    border-left: 0.1rem solid var(--clr-gray300);
    padding-left: 1em;
  }

  body.dark-mode .header__form {
    /* background: var(--clr-modal); */
    background: transparent;
    border: 0.1rem solid transparent;
  }

  body.dark-mode .header__form:focus-within,
  .header__form:focus-within {
    box-shadow: 0 0 0.5rem #077aff30;
    border: 0.1rem solid var(--clr-primary);
  }
  body.dark-mode .header__form:focus-within input {
    border: none;
  }

  .header__form:focus-within svg rect {
    fill: var(--clr-primary);
  }

  .header__form:focus-within svg circle {
    stroke: var(--clr-primary);
  }

  .header__form input {
    display: inline;
    width: 100%;
    background: transparent;
    font-size: 0.8rem;
    margin-left: 0.5rem;
    color: var(--clr-darker);
  }
  body.dark-mode .header__form input {
    color: #a0a0a0;
  }

  .header__form input::placeholder {
    /* color: #818e94; */
    color: var(--clr-gray500);
  }

  .header__right {
    flex-direction: row-reverse;
  }

  .header__notification {
    display: flex;
    position: relative;
  }

  .header__notification.notification-circle::after {
    content: "";
    position: absolute;
    width: 0.8rem;
    height: 0.8rem;
    right: 0;
    border-radius: 50%;
    border: 0.2rem solid var(--clr-light);
    background: var(--clr-primary);
  }

  body.dark-mode .header__notification::after {
    border: 0.2rem solid var(--clr-dark);
  }

  .header__cart,
  .header__rightMessage,
  .header__rightNotification {
    display: flex;
    cursor: pointer;
  }

  .header__cart {
    display: flex;
    position: relative;
  }

  .header__cartItem {
    position: absolute;
    font-size: 0.5rem;
    width: 1.1rem;
    height: 1rem;
    right: -0.4rem;
    line-height: 0.9rem;
    top: -0.2rem;
    font-weight: 500;
    color: var(--clr-light);
    border-radius: 2em;
    border: 0.1rem solid var(--clr-light);
    background: var(--clr-primary);
  }

  body.dark-mode .header__rightMessage svg {
    fill: #818e94;
  }

  .header__balance {
    display: none;
  }

  .header__dropbox {
    display: none;
  }
  .header__detail {
    display: flex;
    /* margin-right: -1rem; */
  }
  .header__detail span {
    font-size: 0.7rem;
    color: var(--clr-gray);
  }
  .header__detail strong {
    font-size: 0.9rem;
  }

  .header__balanceUser {
    display: flex;
    margin-right: 0.5rem;
  }

  .header__balanceUser,
  .header__totalAssets {
    cursor: pointer;
  }

  .header__totalAssets strong {
    color: var(--clr-primary);
  }

  .header__balanceUser svg {
    display: flex;
    margin-right: 0.4rem;
  }

  .header__balanceUser strong {
    color: var(--clr-primary);
    font-size: 0.9rem;
    margin-bottom: -0.1rem;
  }

  .sidebar__logoName {
    color: var(--clr-primary);
    margin-left: 0.5rem;
    font-weight: 600;
  }

  body.dark-mode .sidebar__logoName {
    color: var(--clr-light);
  }
}

@media (min-width: 1200px) {
  header {
    justify-content: space-between;
  }

  .header__left {
    flex: 1.75;
  }

  .header__rightMessage,
  .header__rightNotification {
    display: flex;
  }

  .header__form {
    width: 100%;
    max-width: 25rem;
    margin-left: 1.5rem;
  }

  .header__form.no-dropdown {
    max-width: 28rem;
  }

  /* .header__dropdownSearch {
    max-width: 15rem;
  } */

  body.dark-mode .header__cartItem {
    border: 0.15rem solid var(--clr-dark);
  }
  .header__dropdownSearch {
    display: block;
  }

  .header__right {
    flex-direction: row;
  }

  .header__btnDonate.btn {
    display: flex;
    padding: 0.65em 1.25em;
    border-radius: 0.5em;
    margin-right: -1.25rem;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--clr-primary);
    background: transparent;
    border: 0.1rem solid var(--clr-primary);
    transition: 0.25s;
  }

  .header__btnDonate.btn:hover {
    background: var(--clr-primary);
    color: var(--clr-light);
  }

  .header__btnDonate.btn:hover svg {
    fill: var(--clr-gray450);
  }

  .header__btnDonate svg {
    width: 0.9rem;
    transition: 0.25s;
    margin-left: 0.5rem;
    fill: var(--clr-primary);
  }
}
