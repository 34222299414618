.search__card {
  padding: 1.5em;
  border-radius: 0.7em;
  align-items: flex-start;
  background: var(--clr-light);
  /* overflow-x: hidden; */
  cursor: pointer;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
  /* flex: 1 1 50%; */
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
}

.search__card h3 svg {
  display: flex;
  width: 1.2rem;
  margin-left: 0.5rem;
  margin-bottom: 0.2rem;
}

.search__card > div:first-child {
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.search__card + .search__card {
  margin-top: 2rem;
}

.search__cardImgProfile {
  margin-right: 1rem;
}

.search__cardImgProfile img {
  display: flex;
  width: 4.5rem;
  object-fit: cover;
  height: 4.5rem;
  border: 0.4rem solid var(--clr-light);
}

.search__cardDetail {
  width: 100%;
  /* align-items: flex-start; */
  align-items: stretch;
  justify-content: space-between;
}

.search__cardDetailProfile {
  align-items: flex-start;
  width: 100%;
  padding-top: 0.5em;
  /* width: 100%; */
  /* overflow-x: scroll; */
}

.search__cardDetailProfile span {
  display: block;
  margin-top: 0.2rem;
  font-size: 0.9rem;
}

.search__cardDetailProfile button svg {
  width: 1.2rem;
  height: 1.1rem;
  fill: var(--clr-light);
  margin-right: 0.5rem;
}

.search__cardDetailProfile button {
  display: flex;
  width: fit-content;
  font-weight: 500;
  padding: 0.75em 1.5em;
  border-radius: 0.5em;
  margin: 1rem 0;
}

.search__cardProfileProject img {
  width: 100%;
  border-radius: 0.5em;
}

.search__cardProfileProject {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  gap: 1rem;
}

.search__cardBtnFollowed {
  background: transparent;
  border: 0.1rem solid var(--clr-secondary-dark);
}

.search__cardDetail .search__cardBtnFollowed svg {
  fill: var(--clr-secondary-dark);
}
.search__cardUserBadges {
  width: 100%;
  max-width: 1.5rem;
  object-fit: contain;
  margin-left: 0.5rem;
}

@media (min-width: 475px) {
  .search__cardDetailProfile {
    flex-direction: row;
    justify-content: space-between;
  }

  .search__cardDetailProfile button {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .search__card {
    flex-wrap: nowrap;
  }

  .search__card > div:first-child {
    max-width: 20rem;
  }

  .search__cardDetailProfile {
    flex-direction: column;
  }

  .search__cardDetailProfile button {
    margin: 1rem 0;
  }
}
