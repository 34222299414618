.successfullOrder__container {
  width: 80%;
  margin: 5rem auto 0 auto;
}

.successfullOrder__container {
  text-align: center;
}

.successfullOrder__container h1 {
  font-size: 1.3rem;
  margin: 1.5rem 0;
}

.successfullOrder__container p {
  width: 100%;
  max-width: 35rem;
  font-size: 0.8rem;
  color: var(--clr-gray500);
}

.successfullOrder__container button {
  width: 100%;
  margin-top: 2rem;
  max-width: 10rem;
  font-size: 0.55rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1.5em 1em;
}

@media (min-width: 550px) {
  .successfullOrder__container button {
    font-size: 0.7rem;
    padding: 1.25em;
  }
}

@media (min-width: 900px) {
  .successfullOrder {
    padding: 0 2em;
    min-height: calc(100vh - 8.5rem);
    margin-bottom: -3rem;
    overflow: hidden
  }

  .successfullOrder__container h1 {
    font-size: 1.5rem;
    margin: 1.5rem 0;
  }

  .successfullOrder__container p {
    font-size: 0.9rem;
  }
}
