.quizCard {
  padding: 1em;
  background: var(--clr-gray450);
  border-radius: 1em;
}

.quizCard a {
  color: #000;
}
body.dark-mode .quizCard a {
  color: var(--clr-light);
}

body.dark-mode .quizCard {
  background: var(--clr-modal);
}

.quizCard + .quizCard {
  margin-top: 1rem;
}

.quizCard__mainImg {
  width: 100%;
  height: 8rem;
  object-fit: cover;
  border-radius: 0.7em;
}

.quizCard__title {
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
}

.quizCard__desc {
  font-size: 0.75rem;
  margin: 0.5rem 0;
  color: var(--clr-gray400);
}

.quizCard__bottom {
  margin-top: 1rem;
  justify-content: space-between;
}

.quizCard__usersImg {
  width: 1.5rem;
  object-fit: cover;
  height: 1.5rem;
}

.quizCard__action {
  font-size: 0.75rem;
  color: var(--clr-gray400);
  margin: 0 0.25rem;
  cursor: pointer;
}

.quizCard__action svg {
  display: flex;
}

.quizCard__badges {
  margin: 0.5rem 0;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.quizCard__badge {
  font-size: 0.7rem;
  padding: 0.25em 1em;
  border-radius: 3em;
  font-weight: 600;
}

.quizCard__usersImg + .quizCard__usersImg {
  margin-left: -0.5rem;
}

.quizCard__userMore,
.quizCard__userAdd {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin-left: 0.3rem;
  background: var(--clr-darker);
}

.quizCard__userMore {
  color: var(--clr-gray);
  margin-left: -0.5rem;
  font-weight: 700;
  background: var(--clr-light-gray);
  font-size: 0.6rem;
  cursor: default;
}

.quizCard__badge.yellow {
  background: var(--clr-yellow);
  color: var(--clr-light);
}
.quizCard__badge.red {
  background: var(--clr-invalid);
  color: var(--clr-light);
}
.quizCard__badge.blue {
  background: var(--clr-primary);
  color: var(--clr-light);
}
.quizCard__badge.dark-blue {
  background: var(--clr-dark-purple);
  color: var(--clr-light);
}
.quizCard__badge.green {
  background: var(--clr-success);
  color: var(--clr-light);
}
.quizCard__badge.purple {
  background: var(--clr-purple);
  color: var(--clr-light);
}
