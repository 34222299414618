.searchImages h1 {
  margin-bottom: 1rem;
  text-transform: capitalize;
}

.searchImages__container {
  display: flex;
  position: relative;
  z-index: 1;
}

.searchImages__container img {
  width: 100%;
  position: -1;
}

.searchImages__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  background: #000a;
  z-index: 10;
  cursor: pointer;
  transition: 0.25s;
}

.searchImages__container:hover .searchImages__overlay {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.searchImages__overlay a:first-child {
  background: var(--clr-light);
  width: 4rem;
  height: 4rem;
  opacity: 1;
  transition: 0.5s;
}
.searchImages__overlay a:first-child:hover {
  opacity: 0.75;
}

.searchImages__overlay a svg {
  width: 1.5rem;
}

.searchImages__overlay a:last-child {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.5em 1em;
}
.searchImages__overlay a:last-child img {
  width: 2.2rem;
  margin-right: 0.5rem;
}

.searchImages__overlay a:last-child {
  color: var(--clr-light);
}

.searchImages__overlay a:last-child > div {
  align-items: flex-start;
}

.searchImages__overlay a:last-child span {
  display: block;
  font-size: 0.8rem;
}
.searchImages__overlay a:last-child span:last-child {
  font-size: 0.65rem;
  font-weight: 400;
}
