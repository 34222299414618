.avatarIntialUser {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  max-width: 10rem;
  max-height: 10rem;
  font-size: 5rem;
  /* border-radius: 50%; */
  text-transform: uppercase;
  background: #001219;
}

.avatarIntialUser.rounded {
  border-radius: 50%;
}

.avatarIntialUser.rounded-3 {
  border-radius: 1em;
}
