.delivery__container {
  margin-top: 4rem;
  align-items: flex-start;
  flex-direction: column;
}

.delivery__containerInformation {
  width: 100%;
}

.delivery__containerInformation,
.delivery__shippingMethod {
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
  border-radius: 0.7em;
  padding: 1.25em 1.75em;
}
body.dark-mode .delivery__containerInformation,
body.dark-mode .delivery__shippingMethod {
  box-shadow: 0rem 0rem 0.5rem #0005;
  border-radius: 0.7em;
}

.delivery__container h3 {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 2rem;
}

body.dark-mode input:focus-within,
.delivery input:focus-within {
  border: 0.15rem solid var(--clr-primary);
}

.delivery__inputContainer {
  width: 100%;
  align-items: flex-start;
  position: relative;
}

.delivery__inputContainer label {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0.5rem 0;
}

.delivery__data form {
  margin-top: 1rem;
}

.delivery__profileInputSections {
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
}


.delivery__shippingMethod {
  width: 100%;
  /* flex: 1; */
  margin-top: 1rem;
}

.delivery__radio {
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  font-size: 0.8rem;
  cursor: pointer;
}

.delivery__radio label {
  width: 100%;
}

.delivery__radioText {
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
}

.delivery__radioText {
  color: var(--clr-gray400);
}
body.dark-mode .delivery__radioText {
  color: var(--clr-gray500);
}

.delivery__radioText strong {
  font-size: 0.9rem;
}

.delivery__radioContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delivery__radioInput {
  visibility: hidden;
  display: none;
}

.delivery__radioInput:checked ~ .delivery__radioControl {
  border: 0.35rem solid var(--clr-primary);
}
.delivery__radioInput:checked ~ .delivery__radioText {
  color: var(--clr-darker);
}
body.dark-mode .delivery__radioInput:checked ~ .delivery__radioText {
  color: var(--clr-light);
}

.delivery__radioControl {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  cursor: pointer;
  border: 0.1rem solid var(--clr-primary);
}

.delivery__btnContainer {
  width: 100%;
  justify-content: space-between;
}

.delivery__btnContainer button {
  width: 100%;
  margin-top: 2rem;
  max-width: 10rem;
  font-size: 0.55rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1.5em 1em;
}

.delivery__btnContainer button:first-child {
  margin-right: 0.5rem;
}
body.dark-mode .delivery__btnContainer button:first-child {
  background: var(--clr-modal);
  color: var(--clr-light);
}

.delivery__btnContainer svg {
  display: flex;
  margin-right: 1rem;
  margin-bottom: 0.1rem;
}

body.dark-mode .delivery__btnContainer path {
  stroke: var(--clr-light);
}

@media (min-width: 550px) {
  .delivery__btnContainer button {
    max-width: 15rem;
    font-size: 0.7rem;
    padding: 1.25em;
  }
}
@media (min-width: 550px) {
  .delivery {
    padding: 0 2em;
  }

  .delivery__inputContainer + .delivery__inputContainer {
    margin-left: 1rem;
  }

  .delivery__profileInputSections {
    flex-direction: row;
  }
  .delivery input {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 900px) {
  .delivery {
    padding: 0 2em;
  }

  .delivery__inputContainer + .delivery__inputContainer {
    margin-left: 1rem;
  }

  .delivery input {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 1100px) {
  .delivery__container {
    margin-top: 4rem;
    align-items: flex-start;
    flex-direction: row;
  }

  .delivery__shippingMethod {
    margin-top: 0;
    max-width: 20rem;
  }

  .delivery__inputContainer + .delivery__inputContainer {
    margin-left: 0;
  }

  .delivery__profileInputSections {
    flex-direction: column;
  }

  .delivery__containerInformation {
    margin-right: 2rem;
  }

  .delivery__radio {
    max-width: 25rem;
  }
}

@media (min-width: 1200px) {
  .delivery__radio {
    max-width: initial;
  }

  .delivery__inputContainer + .delivery__inputContainer {
    margin-left: 1rem;
  }

  .delivery__profileInputSections {
    flex-direction: row;
  }
}
