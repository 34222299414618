.communitiesCard {
  padding: 1.5em;
  width: 100%;
  background: var(--clr-light);
  align-items: flex-start;
  border-radius: 0.5em;
  position: relative;
  z-index: 1;
  box-shadow: 0 5px 27px 0 rgb(0 0 0 / 10%);
}

.communitiesCard::after,
.communitiesCard::before {
  position: absolute;
  left: 0;
  top: 0;
}

.communitiesCard.communities-lock::after {
  content: url("https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/47002e261e3a4fc729b59877e495d7012c40e306/lock.svg");
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 1;
  border-radius: 50%;
  margin: auto;
  transform: scale(1.1);
  background: var(--clr-primary);
  right: 0;
  bottom: 0;
}

.communitiesCard.communities-lock::before {
  content: "";
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  -webkit-font-smoothing: antialiased;
  background-clip: padding-box;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(1rem);
}

.communitiesCard h3 {
  color: var(--clr-text-dark-header);
}

.communitiesCard img {
  width: 100%;
  max-width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 1.5rem;
}

.communitiesCard span {
  display: block;
  font-size: 0.8rem;
  color: var(--clr-gray500);
}

.communitiesCard p {
  font-size: 0.9rem;
  margin-top: 0.75rem;
  color: var(--clr-text-dark);
}
