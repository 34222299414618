.overviewSlider {
  width: 95%;
  position: relative;
  max-width: 800px;
  margin: 4rem auto;
  background: var(--clr-light);
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 25px;
  border-radius: 25px;
  height: 400px;
  transition: all 0.3s;
}

.overviewSlider__code {
  color: var(--clr-text-dark) !important;
}

.swiper-container {
  overflow: unset !important;
}

.overviewSlider__item.swiper-slide-next {
  visibility: hidden;
}

@media screen and (max-width: 992px) {
  .overviewSlider {
    max-width: 680px;
    height: 400px;
  }
}

@media screen and (max-width: 768px) {
  .overviewSlider {
    min-height: 500px;
    height: auto;
    margin: 180px auto;
  }
}

@media screen and (max-height: 500px) and (min-width: 992px) {
  .overviewSlider {
    height: 350px;
  }
}
.overviewSlider__item {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .overviewSlider__item {
    flex-direction: column;
  }
}
.overviewSlider__item.swiper-slide-active .overviewSlider__img img {
  opacity: 1;
  transition-delay: 0.3s;
}
.overviewSlider__item.swiper-slide-active .overviewSlider__content > * {
  opacity: 1;
  transform: none;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(1) {
  transition-delay: 0.3s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(2) {
  transition-delay: 0.4s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(3) {
  transition-delay: 0.5s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(4) {
  transition-delay: 0.6s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(5) {
  transition-delay: 0.7s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(6) {
  transition-delay: 0.8s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(7) {
  transition-delay: 0.9s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(8) {
  transition-delay: 1s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(9) {
  transition-delay: 1.1s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(10) {
  transition-delay: 1.2s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(11) {
  transition-delay: 1.3s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(12) {
  transition-delay: 1.4s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(13) {
  transition-delay: 1.5s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(14) {
  transition-delay: 1.6s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(15) {
  transition-delay: 1.7s;
}
.overviewSlider__img {
  width: 300px;
  flex-shrink: 0;
  height: 300px;
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  box-shadow: 4px 13px 30px 1px #077aff30;
  border-radius: 20px;
  transform: translateX(-80px);
  overflow: hidden;
}
.overviewSlider__img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  border-radius: 20px;
  opacity: 0.8;
}
.overviewSlider__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  opacity: 0;
  border-radius: 20px;
  transition: all 0.3s;
}
@media screen and (max-width: 768px) {
  .overviewSlider__img {
    transform: translateY(-50%);
    width: 90%;
  }
}
@media screen and (max-width: 576px) {
  .overviewSlider__img {
    width: 95%;
  }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
  .overviewSlider__img {
    height: 270px;
  }
}
.overviewSlider__content {
  padding-right: 25px;
}
@media screen and (max-width: 768px) {
  .overviewSlider__content {
    margin-top: -80px;
    text-align: center;
    padding: 0 30px;
  }
}
@media screen and (max-width: 576px) {
  .overviewSlider__content {
    padding: 0;
  }
}
.overviewSlider__content > * {
  opacity: 0;
  transform: translateY(25px);
  transition: all 0.4s;
}
.overviewSlider__code {
  color: #7b7992;
  margin-bottom: 15px;
  display: block;
  font-weight: 500;
}
.overviewSlider__title {
  font-size: 24px;
  font-weight: 700;
  color: #0d0925;
  margin-bottom: 20px;
}
.overviewSlider__text {
  color: #4e4a67;
  margin-bottom: 30px;
  line-height: 1.5em;
}
.overviewSlider__button {
  display: inline-flex;
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  padding: 15px 35px;
  border-radius: 50px;
  color: #fff;
  box-shadow: 0px 14px 80px #077aff30;
  text-decoration: none;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
}
@media screen and (max-width: 576px) {
  .overviewSlider__button {
    width: 100%;
  }
}
.overviewSlider .swiper-container-horizontal > .swiper-pagination-bullets,
.overviewSlider .swiper-pagination-custom,
.overviewSlider .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.overviewSlider__pagination {
  position: absolute;
  z-index: 21;
  right: 20px;
  width: 11px !important;
  text-align: center;
  left: auto !important;
  top: 50%;
  bottom: auto !important;
  transform: translateY(-50%);
}
@media screen and (max-width: 768px) {
  .overviewSlider__pagination {
    transform: translateX(-50%);
    left: 50% !important;
    top: 205px;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.overviewSlider__pagination.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 8px 0;
}
@media screen and (max-width: 768px) {
  .overviewSlider__pagination.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 5px;
  }
}
.overviewSlider__pagination .swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  display: block;
  border-radius: 10px;
  background: #062744;
  opacity: 0.2;
  transition: all 0.3s;
}
.overviewSlider__pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--clr-primary);
  height: 30px;
  box-shadow: 0px 0px 20px #077aff30;
}
@media screen and (max-width: 768px) {
  .overviewSlider__pagination .swiper-pagination-bullet-active {
    height: 11px;
    width: 30px;
  }
}
