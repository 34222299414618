.mission__allCategory,
.mission__mainEmblem {
  display: grid;
  margin-top: 2rem;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

.mission__title {
  margin: 4rem 0 2rem 0;
  font-size: 1.5rem;
}

.mission__allCategory {
  margin-top: 1rem;
}
