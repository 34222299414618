.gift__card {
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  padding: 2.5em;
  justify-content: space-between;
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
  margin-bottom: 2rem;
  position: relative;
  border-radius: 0.5em;
}

.gift h1 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.gift__card > div:first-child p {
  font-size: 0.9rem;
  margin-top: 0.3rem;
}
.gift__card > div:first-child {
  max-width: 50%;
}

.gift__card:last-child::after {
  content: "Most Popular";
  position: absolute;
  color: var(--clr-light);
  top: 0;
  left: 0;
  width: 15rem;
  text-align: center;
  padding: 0.25em 1em;
  font-size: 0.8rem;
  border-radius: 0 0 0.5em 0;
  background: var(--clr-primary);
}

.gift__radio {
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  font-size: 0.8rem;
  margin-left: auto;
  cursor: pointer;
}

.gift__radio label {
  width: 100%;
}

.gift__radioText {
  width: 100%;
  cursor: pointer;
  /* justify-content: space-between; */
}

.gift__radioText {
  font-size: 0.9rem;
  color: var(--clr-gray400);
}
.gift__radioText strong {
  font-size: 1rem;
}

body.dark-mode .gift__radioText {
  color: var(--clr-gray500);
}

.gift__radioContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gift__radioInput {
  visibility: hidden;
  display: none;
}

.gift__radioInput:checked ~ .gift__radioControl {
  border: 0.35rem solid var(--clr-primary);
}
.gift__radioInput:checked ~ .gift__radioText {
  color: var(--clr-darker);
}
body.dark-mode .gift__radioInput:checked ~ .gift__radioText {
  color: var(--clr-light);
}

.gift__radioControl {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  cursor: pointer;
  border: 0.1rem solid var(--clr-primary);
}

.gift__term {
  font-size: 0.8rem;
  max-width: 35rem;
  text-align: center;
  margin-bottom: 1rem;
}

.gift button {
  width: 15rem;
  border-radius: 0.75em;
}
