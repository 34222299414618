.refer__cta {
  justify-content: center;
  position: relative;
  gap: 5rem;
  width: 100%;
  padding: 2em;
  min-height: 18rem;
  border-radius: 1em;
  z-index: 1;
  background: var(--clr-primary);
  overflow: hidden;
}

.refer__ctaBackgroundImg {
  width: 100%;
  right: -13rem;
  position: absolute;
  z-index: -1;
}

.refer__ctaImg {
  width: 100%;
  max-width: 11rem;
}

.refer h1 {
  font-size: 2.2rem;
  line-height: 1.3;
}

.refer__ctaAction {
  width: 100%;
  max-width: 22rem;
}

.refer__ctaAction h1 {
  color: var(--clr-light);
  margin-bottom: 1.5rem;
}

.refer__ctaAction form {
  background: var(--clr-light);
  height: 3rem;
  overflow: hidden;
  border-radius: 0.25em;
}

.refer__ctaAction form input {
  flex: 1 1 70%;
  padding: 0 0.75em;
}
.refer__ctaAction form button {
  flex: 1 1 30%;
  height: 100%;
  font-size: 0.8rem;
  padding: 0;
  background: var(--clr-dark-background);
  color: var(--clr-light);
  border-radius: 0;
  width: max-content;
}

/* how it works */
.refer__works {
  margin: 5rem 0 3rem 0;
}

.refer__works ul {
  /* display: flex; */
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  gap: 1rem;
  /* flex-wrap: wrap; */
  margin-top: 3.5rem;
  z-index: 1;
}

.refer__works ul li {
  /* flex: 1 1 25%; */
  display: flex;
  align-items: center;
  max-width: 15rem;
  flex-direction: column;
}

.refer__works ul li > div {
  padding: 1.5em;
  border-radius: 50%;
  /* background: #c1deff; */
  background: var(--clr-primary-light);
}
.refer__works ul li > div svg {
  display: flex;
  width: 1.8rem;
  fill: var(--clr-primary);
}

.refer__works ul li h3 {
  text-align: center;
  margin: 1rem 0 0.25rem 0;
}
.refer__works ul li span {
  text-align: center;
  font-size: 0.9rem;
  max-width: 14rem;
}

@media (min-width: 600px) {
  .refer__works ul::after {
    content: "";
    position: absolute;
    width: 80%;
    left: 0;
    right: 0;
    margin: 0 auto;
    /* height: 0.1rem; */
    height: 0.1rem;
    top: 2.5rem;
    z-index: -1;
    /* border: 0.2rem dotted #077aff40; */
    filter: invert(96%) sepia(31%) saturate(2187%) hue-rotate(174deg)
      brightness(99%) contrast(104%);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    /* background: #077aff40; */
  }
}
