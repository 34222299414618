.productCard {
  /* border: 0.1rem solid var(--clr-gray300); */
  /* border-radius: 0.8em; */
  cursor: pointer;
  width: 100%;
  height: 18rem;
  border-radius: 0.5em;
  overflow: hidden;
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
  /* background-color: red; */
}

body.dark-mode .productCard {
  border: 0.1rem solid var(--clr-secondary-dark);
}

.productCard.list {
  height: 100%;
  align-items: flex-start;
  /* overflow: hidden; */
}

.productCard__img {
  position: relative;
}

.productCard img {
  width: 100%;
  height: 11rem;
  /* border-radius: 0.6em; */
  /* max-height: 20rem; */
  object-fit: cover;
  /* aspect-ratio: 3 / 2; */
}

.productCard__discount {
  position: absolute;
  font-size: 0.75rem;
  color: var(--clr-primary);
  padding: 0.1em 0.5em;
  border-radius: 1em;
  background: var(--clr-primary-light);
  top: 0.5rem;
  left: 0.5rem;
}

.productCard__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* max-height: calc(100% - 22rem + 10.5rem); */
  max-height: calc(100% - 11.5rem);
  padding: 0 0.9em 0.9em;
}

.productCard__bodyTitle {
  font-size: 1rem;
}

.productCard__bodyDetails > div {
  margin-top: 0.3rem;
  justify-content: space-between;
}

.productCard__bodyDetails {
  font-size: 0.8rem;
}

.productCard__bodyDetails > span {
  display: block;
  font-weight: 500;
  font-size: 0.8rem;
  /* margin-top: -0.1rem; */

  color: var(--clr-gray400);
}

.productCard__bodyDesc {
  font-size: 0.8rem;
  margin: 0.3rem 0 0.7rem;
  color: var(--clr-dark-gray);
}
.productCard.list .productCard__bodyDesc {
  margin: 0.2rem 0;
}

.productCard__itemDetails {
  margin-top: 0.5rem;
}

.productCard__itemDetail {
  justify-content: space-between;
  font-size: 0.8rem;
  max-width: 10rem;
  margin-top: 0.3rem;

  color: var(--clr-gray400);
}

.productCard__bodyBot {
  justify-content: space-between;
  margin-top: 0.7rem;
}

.productCard__delivery {
  font-size: 0.75rem;
  margin: 0.4rem 0 0.2rem 0;
  color: var(--clr-gray400);
}

.productCard__delivery strong {
  font-weight: 500;
}

.productCard.list .productCard__bodyDetails {
  flex: 2;
  width: 100%;
  height: 100%;
}

.productCard.list .productCard__bodyBot {
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-left: 1rem;
  margin-top: 0;
  height: 100%;
}

.productCard__bodyBotPrice {
  justify-self: flex-end;
  align-items: center;
  margin-left: auto;
}

.productCard__bodyBotPrice strong {
  font-size: 0.9rem;
  margin-left: 0.5rem;
}
.productCard__bodyBotPrice small {
  text-decoration: line-through;
  color: var(--clr-gray400);
  font-weight: 600;
  margin-top: 0.05rem;
}

.productCard.list .productCard__btnContainer {
  height: 100%;
}

.productCard__btnContainer button {
  padding: 0.75em 1em;
  border-radius: 0.7em;
  font-size: 0.8rem;
}
.productCard.list .productCard__btnContainer button {
  width: 100%;
  padding: 0.75em;
  margin-top: 0.5rem;
}
.productCard.list .productCard__btnContainer button:last-child svg {
  width: 0.7rem;
  height: 0.7rem;
  margin-left: 0.5rem;
  transform: rotate(-180deg);
}

.productCard__bodyBot > div:first-child ul {
  gap: 0.25rem;
}

.productCard__ColorsColor {
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0rem;
  position: relative;
}
