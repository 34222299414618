.quizHeader {
  background: var(--clr-gray450);
  padding: 0.75em 1em;
  justify-content: space-between;
  border-radius: 0.75em;
  margin-bottom: 0.5rem;
}

body.dark-mode .quizHeader {
  background: var(--clr-modal);
}

.quizHeader h3 {
  font-size: 0.9rem;
  font-weight: 600;
}

.quizHeader {
  cursor: pointer;
}
