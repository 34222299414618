.contentSection {
  margin-bottom: 1.5rem;
  /* overflow-x: hidden; */
}

.contentSection__header {
  /* overflow-x: hidden; */
  display: none;
  justify-content: space-between;
  margin-bottom: 1rem;
  /* overflow-x: hidden; */
}

.contentSection__header h2 {
  font-size: 0.95rem;
  font-weight: 500;
  color: var(--clr-text-dark-header);
  cursor: pointer;
}
body.dark-mode .contentSection__header h2 {
  color: var(--clr-light);
}

.contentSection__header h2 svg {
  margin-right: 0.5rem;
}

.contentSection__headerChannelContainer {
  width: 11.5rem;
  padding: 0.5rem 0.2rem;
  overflow-x: hidden;
  padding: 0.5rem 15rem 15rem 10rem;
  margin: 0 -3.5rem -14.5rem -10rem;
  scrollbar-width: none;
  transition: width 1.2s ease-in-out;
}

.contentSection__headerChannelWrapper {
  margin-left: auto;
  gap: 0.8rem;
}

.contentSection__headerChannelContainer.show {
  width: 33rem;
  padding: 0.5rem 0.2rem;
  margin: initial;
  padding-bottom: 15rem;
  margin-bottom: -14.5rem;
}

.contentSection__headerChannelContainer::-webkit-scrollbar {
  display: none;
}

.contentSection__headerChannelNav {
  cursor: pointer;
  display: flex;
}

.contentSection__headerChannelNav svg {
  transform: rotate(180deg);
  margin-left: 0.85rem;
}

.contentSection__headerChannelImg {
  display: grid;
  place-items: center;
  position: relative;
  opacity: 1;
  padding: 0;
  border: 0.12rem solid transparent;
  transition: 1s;
  /* display: none; */
}

.contentSection__headerChannelImg.order5,
.contentSection__headerChannelImg.order6,
.contentSection__headerChannelImg.order7,
.contentSection__headerChannelImg.order8,
.contentSection__headerChannelImg.order9 {
  pointer-events: none;
  width: 0;
  visibility: hidden;
  opacity: 0;
}

.contentSection__headerChannelImg.svg {
  height: 1.5rem;
  width: 1.5rem;
}

.contentSection__headerChannelImg img {
  width: 0.75rem;
  height: 0.75rem;
}

/* .contentSection__headerChannelImg:hover.contentSection__headerChannelImg::before {
  content: "";
  position: absolute;
  width: 0.7rem;
  height: 0.2rem;
  border-radius: 3rem;
  bottom: -0.35rem;
  left: 50%;
  transform: translateX(-50%);
  background: var(--clr-gray400);
} */

.contentSection__headerChannelImg:hover.contentSection__headerChannelImg.svg::before {
  bottom: -0.65rem;
}

.contentSection__headerChannelImg {
  cursor: pointer;
}

.contentSection__content {
  display: grid;
  place-items: center;
  /* width: 100%; */
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 1rem;
}
.contentSection__content.less-content {
  grid-template-columns: repeat(auto-fit, minmax(15rem, 15rem));
}

.contentSection__expand {
  display: none;
}

@media (min-width: 900px) {
  .contentSection__header {
    margin-bottom: 0.5rem;
    display: flex;
  }

  .contentSection__header .contentSection__headerChannelNav svg path {
    stroke: var(--clr-darker);
    stroke-width: 3;
  }

  body.dark-mode .contentSection__headerChannelNav svg path {
    stroke: var(--clr-light);
    stroke-width: 4;
  }

  .contentSection__headerChannelContainer li {
    position: relative;
  }

  .contentSection__headerChannelImg:hover ~ .contectSection__channelDetails {
    opacity: 1;
    pointer-events: initial;
  }

  /* .contentSection__headerChannelImg::after {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    padding: 1.2em;
    top: 0;
    z-index: 40;
  } */

  .contectSection__channelDetails {
    position: absolute;
    top: 2.5rem;
    padding: 1em;
    border-radius: 0.4em;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    z-index: 30;
    width: 19rem;
    pointer-events: none;
    min-height: 7rem;
    transition: 0.3s ease-in-out;
    /* background: var(--clr-dark400); */
    background: var(--clr-light);
    color: #000;
    box-shadow: 0rem 0rem 1rem #0002;
  }

  body.dark-mode .contectSection__channelDetails {
    background: var(--clr-modal);
    color: var(--clr-ligth);
    box-shadow: 0rem 0rem 0.5rem #0007;
  }

  .contectSection__channelDetails.order6,
  .contectSection__channelDetails.order2 {
    left: calc(50% - 2rem);
  }

  .contectSection__channelDetails.order7,
  .contectSection__channelDetails.order3 {
    left: calc(50% - 5rem);
  }

  .contectSection__channelDetails.order4,
  .contectSection__channelDetails.order8 {
    left: calc(50% - 7rem);
  }

  .contectSection__channelDetails.order9 {
    left: calc(50% - 8.5rem);
  }

  .contectSection__channelDetails.expand.order4,
  .contectSection__channelDetails.expand.order3,
  .contectSection__channelDetails.expand.order3::after,
  .contectSection__channelDetails.expand.order4::after {
    left: 50%;
  }

  .contectSection__channelDetails:hover {
    opacity: 1;
    pointer-events: initial;
  }

  .contectSection__channelDetails::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -1rem;
    border-right: 1rem solid transparent;
    border-left: 1rem solid transparent;
    /* border-bottom: 1.3rem solid var(--clr-dark400); */
    border-top: 0.5rem solid transparent;
    border-bottom: 1.3rem solid var(--clr-light);
  }
  body.dark-mode .contectSection__channelDetails:after {
    border-bottom: 1.3rem solid var(--clr-modal);
  }

  .contectSection__channelDetails.order6::after,
  .contectSection__channelDetails.order2::after {
    left: calc(50% + 2rem);
  }

  .contectSection__channelDetails.order7::after,
  .contectSection__channelDetails.order3::after {
    left: calc(50% + 5rem);
  }

  .contectSection__channelDetails.order4::after,
  .contectSection__channelDetails.order8::after {
    left: calc(50% + 7rem);
  }

  .contectSection__channelDetails.order9::after {
    left: calc(50% + 8.5rem);
  }
}

@media (min-width: 900px) {
  .contentSection__content {
    /* height: initial; */
    /* height: 100%; */
    /* max-height: 0; */
    overflow: hidden;
    position: relative;
  }

  .contentSection__content::after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 100%;
    background: var(--clr-light);
    height: 100%;
    transition: 2s;
  }

  /* .contentSection__content.expand-video {
    animation: exapandVideo2 15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  } */

  /* .contentSection__content.expand-video::after { */
  /* bottom: -100%; */

  /* transition: max-height 10s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  /* } */

  .contentSection__expand {
    display: flex;
    cursor: pointer;
    width: 0.8rem;
    height: 0.8rem;
    margin-left: 0.5rem;
  }

  .contentSection__videoRowOne {
    max-height: 0;
    height: 100%;
    display: none;
    visibility: hidden;
    pointer-events: none;
    margin-bottom: -2rem;
    /* transition: max-height 5s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  }

  .contentSection__videoRowOne.expanded-video {
    display: flex;
    visibility: visible;
    pointer-events: all;
    margin-bottom: 0;
    max-height: 50rem;
    animation: exapandVideo2 5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    /* max-height: 50rem;
    display: flex;
    visibility: visible;
    pointer-events: all;
    margin-bottom: 0; */
  }

  /* .contentSection.video-expanded {
    position: fixed;
    width: calc(100% - 16.5rem);
    right: 0;
    height: calc(100vh - 7rem);
    top:7.8rem;
    padding: 1em 2em;
    z-index: 20;

    background-color: red;
  } */
}

@media (min-width: 1200px) {
  /* .contentSection__content.expand-video { */
  /* transition: max-height 10s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  /* } */
}

@keyframes exapandVideo2 {
  0% {
    max-height: 0rem;
  }
  100% {
    /* max-height: ; */

    max-height: 50rem;
  }
}
