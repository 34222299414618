@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --clr-primary: #077aff;
  --clr-primary-light: #f7f7ff;
  --clr-primary-dark: #045efb;
  --clr-secondary: #90a0b9;
  --clr-secondary-light: #e8f2ff;
  --clr-secondary-dark: #2c373b;
  --clr-text-dark-header: #2d2f39;
  --clr-text-dark: #4d4f5c;
  --clr-text-gray: #828282;
  --clr-light: #ffffff;
  --clr-dark: #161616;
  /* --clr-dark: #191a1f; */
  --clr-hover-dark: #242526;
  --clr-darker: #0d0e11;
  --clr-dark-background: #1b1b1b;
  --clr-light-gray: #dde1eb;
  --clr-gray: #90a0b9;
  --clr-dark-gray: #4d607e;
  --clr-gray500: #757679;
  --clr-gray400: #a4a4a6;
  --clr-gray450: #f4f4f4;
  /* --clr-gray200: #F5F5F5; */
  --clr-focus: #057afe;
  --clr-focus-shadow: #deebfe;
  --clr-invalid: #ea3943;
  --clr-invalid-shadow: #ffe5d3;
  --clr-invalid-shadow: #f8f2f6;
  --clr-dark400: #151a30;
  --clr-gray300: #e4e5e7;
  --clr-modal: #1e1e1e;
  --clr-modal-darker: #1c1c1c;
  --clr-modal-light: #fafbfc;
  --clr-hover-light: #d8d8d8;
  --clr-header-light: #f5f5f5;
  --clr-yellow: #ffb800;
  --clr-yellow200: #fff5cc;
  --clr-yellow-shadow: #ffb70040;
  --clr-dark-purple: #4339f2;
  --clr-dark-purple-shadow: #dad7fe;
  --clr-purple: #891be8;
  --clr-red200: #ffe5d3;
  --clr-green200: #e2fbd7;
  --clr-blue200: #dad7fe;
  --clr-light-blue200: #ccf8fe;
  /* --clr-purple: #8f75be; */
  --clr-success: #28c76f;
}

body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #161616;
  color: var(--clr-dark);
  background: #ffffff;
  background: var(--clr-light);
}

body.dark-mode {
  background: #161616;
  background: var(--clr-dark);
  color: #ffffff;
  color: var(--clr-light);
}
html {
  /* scrollbar-color: #44ee4e; */
  scrollbar-track-color: transparent;
  /* scrollbar-arrow-color: var(--clr-dark); */
  scrollbar-width: thin;
}

html::-webkit-scrollbar {
  width: 0.5rem;
}

html::-webkit-scrollbar-track {
  background-color: transparent;
}

html::-webkit-scrollbar-thumb {
  background: #4e4e4e;
  border-radius: 3rem;
}
a {
  text-decoration: none;
  color: #077aff;
  color: var(--clr-primary);
}

ul {
  list-style: none;
}

textarea,
input {
  border: none;
  outline: none;
  background: transparent;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}

select {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}

/* p, span, h {
  cursor: default;
} */

.container {
  width: 100%;
  /* min-height: 90vh; */
  padding-bottom: calc(40px + 4rem);
}

.container-header {
  top: 0;
  position: fixed;
  width: 100%;
  background: #f5f5f5;
  z-index: 20;
}
.container-content {
  padding: 1em 1em;
  margin-top: 5rem;
}

.logo.logo-head {
  display: none;
}

.logo {
  margin-right: 1rem;
}

.logo-container svg path:first-child {
  fill: #ffffff;
  fill: var(--clr-light);
}
.logo-container svg path + path {
  stroke: #ffffff;
  stroke: var(--clr-light);
}

.logo-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.25em;
  margin: 0.5rem 0;
  background: #077aff;
  background: var(--clr-primary);
}

.logo-container svg {
  width: 2rem;
  height: 2rem;
  display: -webkit-flex;
  display: flex;
}

.container-header.dark-mode {
  /* background: var(--clr-light); */
  background: #0d0e11;
  background: var(--clr-darker);
  /* box-shadow: 0rem 0.2rem 0.2rem #0003; */
}

/* cross mark */
.cross-mark {
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: 1.5rem;
  margin-left: 0.5rem;
  height: 1.5rem;
  z-index: 1001;
  cursor: pointer;
}

.cross-mark::after,
.cross-mark::before {
  content: "";
  position: absolute;
  width: 0.1rem;
  height: 1rem;
  border-radius: 3em;
  background: #161616;
  background: var(--clr-dark);
}
.cross-mark::after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.cross-mark::before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

@media (min-width: 900px) {
  .container {
    width: calc(100% - 16.5rem);
    margin-left: auto;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
  }

  .container-header {
    width: 100%;
    background: #ffffff;
    background: var(--clr-light);
    padding-bottom: 0;
  }
  .container-content {
    padding: 1em 2em;
    margin-top: 4rem;
  }

  .logo.logo-head {
    display: -webkit-flex;
    display: flex;
  }
}

/* input */

.input__container {
  position: relative;
  width: 100%;
}

.input__label {
  position: absolute;
  left: 1rem;
  top: 0.6rem;
  color: #757679;
  color: var(--clr-gray500);
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0.5rem 0;
  cursor: text;
}

.input__textError {
  display: -webkit-flex;
  display: flex;
  justify-self: flex-start;
  /* font-weight: 600; */
  font-size: 0.7rem;
  text-align: left;
  margin-top: 0.3rem;
  color: #ea3943;
}

/* .inputIcon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
} */

.input {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  font-weight: 600;
  padding: 1em 1.25em;
  border-radius: 0.7em;
  border: 0.15rem solid #a4a4a6;
  border: 0.15rem solid var(--clr-gray400);
  font-size: 0.9rem;
  outline: none;
  background: #fff;
}

.input.error {
  border: 0.15rem solid #ea3943;
  border: 0.15rem solid var(--clr-invalid);
}

.input.error ~ .input__label {
  color: #ea3943;
  color: var(--clr-invalid);
}

.input__container.open-dropdown .input,
.input__container.open-language .input {
  border: 0.15rem solid #077aff;
  border: 0.15rem solid var(--clr-primary);
  border-bottom: none;
  border-radius: 1em 1em 0 0;
}

.input:focus {
  border: 0.15rem solid #077aff;
  border: 0.15rem solid var(--clr-primary);
}

/* .input__container.open-dropdown.popupUserInfo__identity .input {
  border-radius: 0 0 1em 1em;
  border: 0.15rem solid var(--clr-primary);
  border-top: none;
} */

.input::-webkit-input-placeholder {
  color: transparent;
}

.input:-ms-input-placeholder {
  color: transparent;
}

.input::placeholder {
  color: transparent;
}

.input:focus::-webkit-input-placeholder {
  color: #a4a4a6;
  color: var(--clr-gray400);
}

.input:focus:-ms-input-placeholder {
  color: #a4a4a6;
  color: var(--clr-gray400);
}

.input:focus::placeholder {
  color: #a4a4a6;
  color: var(--clr-gray400);
}

.input__container.open-dropdown .input__label,
.input__container.open-language .input__label,
.input:focus ~ .input__label {
  color: #077aff;
  color: var(--clr-primary);
}

.input__container.selected-gender .input__label,
.input__container.selected-identity .input__label,
.input__container.selected-language .input__label,
.input__container.open-dropdown .input__label,
.input__container.open-language .input__label,
.input:focus ~ .input__label,
.input:valid ~ .input__label {
  top: -1.25rem;
  left: 0.75rem;
  font-size: 12px;
  background: #fff;
  padding: 0.25em 0.5em;
  text-align: center;
  transition: all 0.5s;
}

.input__container.readOnly .input:focus {
  border: 0.15rem solid #a4a4a6;
  border: 0.15rem solid var(--clr-gray400);
}
.input__container.readOnly .input:focus ~ .input__label {
  color: #a4a4a6;
  color: var(--clr-gray400);
}
.input__container.readOnly .input {
  cursor: not-allowed;
  caret-color: transparent;
}

.input.error:focus ~ .input__label {
  color: #ea3943;
  color: var(--clr-invalid);
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.hide-password-icon,
.show-password-icon {
  position: absolute;
  right: 1.25em;
  top: 50%;
  fill: #a4a4a6;
  fill: var(--clr-gray400);
  cursor: pointer;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.show-password-icon.hide,
.hide-password-icon.hide {
  display: none;
}

.btn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 1em 1.5em;
  border: none;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  border-radius: 3rem;
  cursor: pointer;
}

.rounded {
  border-radius: 50%;
}

.img-channel {
  position: relative;
  display: -webkit-flex;
  display: flex;
  padding: 0.3rem;
}

.img-channel.story {
  border: 0.12rem solid var(--clr-ligth);

  box-shadow: 0px 0px 0px 0.1rem #077aff;

  box-shadow: 0px 0px 0px 0.1rem var(--clr-primary);
}

body.dark-mode .img-channel.story {
  border: 0.12rem solid #161616;
  border: 0.12rem solid var(--clr-dark);
}

.img-channel.notification::after {
  content: "";
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: inherit;
  top: -0.3rem;
  right: -0.3rem;
  background: #ea3943;
  background: var(--clr-invalid);
}

.img-channel.svg.selected::before,
.img-channel.selected::before {
  content: "";
  position: absolute;
  width: 0.7rem;
  height: 0.2rem;
  border-radius: 3rem;
  bottom: -0.65rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #a4a4a6;
  background: var(--clr-gray400);
}

.img-channel.selected::before {
  bottom: -0.5rem;
}

.img-channel.png img,
.img-channel.jpg img {
  width: 1.5rem;
  height: 1.5rem;
}

.img-channel.svg {
  background: #ffffff;
  background: var(--clr-light);
}

/* .img-channel.border {
  border: 0.12rem solid var(--clr-dark);
  box-shadow: 0px 0px 0px 0.1rem var(--clr-primary);
} */

.loading.overlay {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0.25rem;
}

.loading {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto;
  grid-gap: 0.7rem;
  gap: 0.7rem;
}

@media (min-width: 900px) {
  .loading.overlay {
    bottom: -1rem;
  }
}

.loading .ball {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  background: #0d0e11;
  background: var(--clr-darker);

  transition: -webkit-transform 0.3s infinite;

  transition: transform 0.3s infinite;

  transition: transform 0.3s infinite, -webkit-transform 0.3s infinite;
}

body.dark-mode .ball {
  background: #ffffff;
  background: var(--clr-light);
}

.loading .ball.one {
  -webkit-animation: loadingBall 0.7s infinite alternate;
          animation: loadingBall 0.7s infinite alternate;
}
.loading .ball.two {
  -webkit-animation: loadingBall 0.7s infinite 0.3s alternate;
          animation: loadingBall 0.7s infinite 0.3s alternate;
}
.loading .ball.three {
  -webkit-animation: loadingBall 0.7s infinite 0.5s alternate;
          animation: loadingBall 0.7s infinite 0.5s alternate;
}

@-webkit-keyframes loadingBall {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}

@keyframes loadingBall {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}

.item-center {
  display: grid;
  place-items: center;
}

.btn.primary {
  background: #077aff;
  background: var(--clr-primary);
  color: #ffffff;
  color: var(--clr-light);
}
.btn.light {
  background: #ffffff;
  background: var(--clr-light);
}
.btn.secondary {
  background: #dde1eb;
  background: var(--clr-light-gray);
  color: #90a0b9;
  color: var(--clr-gray);
}

.flex-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.flex-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

@-webkit-keyframes fadein {
  0% {
    left: 50%;
    top: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
            transform: translate(-50%, -50%) scale(0.5);
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes fadein {
  0% {
    left: 50%;
    top: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
            transform: translate(-50%, -50%) scale(0.5);
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
}

/* rating */
.rating svg {
  display: -webkit-flex;
  display: flex;
}

.rating span {
  font-weight: 600;
  margin-right: 0.1rem;
  margin-top: 0.1rem;
}

/* custom dropdwn */

.dropdown-container {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.4em 1em;
  cursor: pointer;
}

.dropdown-container.expand {
  box-shadow: 0 0rem 0.5rem #0002;
  border-radius: 0.7em 0.7em 0 0;
  background: #ffffff;
  background: var(--clr-light);
}

body.dark-mode .dropdown-container.expand {
  background: #161616;
  background: var(--clr-dark);
  box-shadow: 0 0rem 0.5rem #0007;
}

.dropdown-container svg {
  width: 0.6rem;
  height: 0.6rem;
  margin-left: 0.3rem;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
body.dark-mode .dropdown-container svg path {
  stroke: #ffffff;
  stroke: var(--clr-light);
}

.dropdown-item-container {
  background: #ffffff;
  background: var(--clr-light);
  position: absolute;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
  /* color: var(--clr-gray); */
  max-height: 0;
  opacity: 0;
  z-index: 10;
  pointer-events: none;
  overflow-x: hidden;
  scrollbar-width: none;
  border-radius: 0 0 0.7em 0.7em;
  box-shadow: 0 0.3rem 0.5rem #0002;
}

.dropdown-item-container::-webkit-scrollbar {
  display: none;
}

body.dark-mode .dropdown-item-container {
  background: #161616;
  background: var(--clr-dark);
  box-shadow: 0 0.3rem 0.5rem #0007;
}

.dropdown-item-container.expand {
  max-height: 18rem;
  transition: max-height 0.3s ease-out, opacity 0.3s 0.1s;
  opacity: 1;
  pointer-events: all;
}

.dropdown-item-container label {
  padding: 0.4em 1em;
  width: 100%;
}

.dropdown-item-container label:hover {
  background: #f5f5f5;
  background: var(--clr-header-light);
}
body.dark-mode .dropdown-item-container label:hover {
  background: #242526;
  background: var(--clr-hover-dark);
}

.dropdown-item-container input[type="radio"] {
  display: none;
}

.dropdown-item-container input,
.dropdown-item-container label {
  cursor: pointer;
}

.bg-black {
  background: #000;
}
.bg-white {
  background: #f3f3f3;
}
.bg-red {
  background: #ea3943;
  background: var(--clr-invalid);
}
.bg-yellow {
  background: #ffb800;
  background: var(--clr-yellow);
}
.bg-blue {
  background: #077aff;
  background: var(--clr-primary);
}
.bg-green {
  background: #28c76f;
  background: var(--clr-success);
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: -webkit-flex;
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

/* error */
.text-input-error {
  font-size: 0.8rem;
  color: #ea3943;
  color: var(--clr-invalid);
  margin-top: 0.2rem;
}

.commentCard {
  margin-top: 1rem;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.commentCard img {
  width: 100%;
  margin-right: 0.5rem;
  max-width: 2.8rem;
  /* border-radius: 0.7em; */
}

.commentCard__header h3 {
  font-size: 0.9rem;
  font-weight: 500;
  margin-right: 1rem;
}

.commentCard__header small {
  font-size: 0.7rem;
}
.commentCard__reply {
  cursor: pointer;
}

.commentCard__content p {
  font-size: 0.8rem;
  line-height: 1.5;
  margin: 0.3rem 0;
}
.commentCard__action {
  font-size: 0.8rem;
  grid-gap: 1rem;
  gap: 1rem;
}
.commentCard__action svg {
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
  margin-right: 0.2rem;
}

.commentCard__actionLike {
  cursor: pointer;
  font-weight: 500;
  color: var(--clr-gray400);
}

.commentCard__actionLike.liked {
  color: var(--clr-primary);
}
/* .commentCard__actionLike.liked svg path {
  fill: var(--clr-primary);
  stroke: var(--clr-primary);
} */
/* .commentCard__actionUnlike.unliked svg path {
  fill: var(--clr-invalid);
}

*/
.commentCard__actionUnlike.unliked svg rect {
  fill: var(--clr-invalid);
}

.commentCard__actionLike.liked svg rect {
  fill: var(--clr-primary);
}

.upgradePremiumPopup {
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000b;
  z-index: 200;
}

.upgradePremiumPopup__container h1 {
  font-size: 1.3rem;
}

.upgradePremiumPopup__container {
  /* width: 80%; */
  width: 100%;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  background: var(--clr-light);
  box-shadow: 0 0px 27px 0 rgb(0 0 0 / 50%);
  padding: 1.5em;
  margin: auto;
}

.upgradePremiumPopup__container img {
  width: 100%;
  max-width: 5rem;
  margin-bottom: 1rem;
}

.upgradePremiumPopup__container button {
  border-radius: 0.5em;
  margin-top: 1rem;
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  box-shadow: 0 1rem 0.5rem var(--clr-primary-light) / 50%;
}

.upgradePremiumPopup__container > div:last-child {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.upgradePremiumPopup__container h3 {
  font-size: 1.3rem;
  text-align: center;
  color: var(--clr-text-dark-header);
}

.upgradePremiumPopup__container p {
  text-align: center;
  max-width: 28rem;
  line-height: 1.8;
  margin: 1rem 0;
  font-size: 0.9rem;
}

.upgradePremiumPopup__close {
  position: absolute;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  stroke: var(--clr-gray300);
  top: 1.5rem;
  right: 1.5rem;
}

@media (min-width: 900px) {
  .upgradePremiumPopup__container {
    position: fixed;
    width: 45%;
    height: 60%;
    overflow-y: scroll;
    max-width: 40rem;
    padding: 2em;
    left: 50%;
    top: 50%;
    scrollbar-width: none;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 0.75em;
    box-shadow: 0 10px 27px 0 rgb(0 0 0 / 50%);
    -webkit-animation: fadein 0.7s;
            animation: fadein 0.7s;
  }

  .upgradePremiumPopup__container::-webkit-scrollbar {
    display: none;
  }

  /* .topupGiftPopup__radio {
      flex-wrap: nowrap;
    } */
}

.contentCard {
  width: 100%;
  height: 100%;
  position: relative;
}

.contentCard small {
  color: var(--clr-text-dark);
  cursor: pointer;
}
body.dark-mode .contentCard small {
  color: var(--clr-gray400);
}
.contentCard__img {
  width: 100%;
  cursor: pointer;
}

.contentCard__imgChannel:hover .contentCard__channelDetails {
  opacity: 1;
  pointer-events: all;
}

.contentCard__channelDetails {
  position: absolute;
  top: 2.5rem;
  padding: 1em;
  border-radius: 0.4em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0;
  z-index: 100;
  width: 19rem;
  pointer-events: none;
  min-height: 7rem;
  transition: 0.3s ease-in-out;
  /* background: var(--clr-dark400); */
  background: var(--clr-light);
  color: #000;

  box-shadow: 0rem 0rem 1rem #0002;
}

body.dark-mode .contentCard__channelDetails {
  color: var(--clr-ligth);
  background: var(--clr-modal);
  box-shadow: 0rem 0rem 0.5rem #0007;
}

.contentCard__channelDetails::after {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: -1rem;
  border-right: 1rem solid transparent;
  border-left: 1rem solid transparent;
  /* border-bottom: 1.3rem solid var(--clr-dark400); */
  border-top: 0.5rem solid transparent;
  border-bottom: 1.3rem solid var(--clr-light);
}

body.dark-mode .contentCard__channelDetails:after {
  border-bottom: 1.3rem solid var(--clr-modal);
}

.contentCard__mainImg {
  position: relative;
  border-radius: 0.3rem;
  overflow: hidden;
  margin-bottom: 0.3rem;
}

.contentCard__mainImg.premium-video::after {
  content: url("https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/47002e261e3a4fc729b59877e495d7012c40e306/lock.svg");
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 1em;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 1;
  border-radius: 50%;
  margin: auto;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background: var(--clr-primary);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.contentCard__mainImg.premium-video::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  -webkit-font-smoothing: antialiased;
  background-clip: padding-box;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  left: 0;
  top: 0;
}

.contentCard__thumbnail {
  display: block;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}

.contentCard__about {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.contentCard__about small {
  font-size: 0.7rem;
}

.contentCard__views small {
  margin-left: 0.2rem;
}

.contentCard__desc {
  margin-top: 0.4rem;
}

.contentCard__descHeader {
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
}

.contentCard__descChannel {
  margin-top: 0.3rem;
  cursor: pointer;
}

.contentCard__descChannel small {
  margin-right: 0.3rem;
  font-size: 0.75rem;
}

.contentCard__imgChannel {
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  bottom: 1rem;
  left: 0.5rem;
}

.contentCard__imgChannel.png .contentCard__imgChannelImg,
.contentCard__imgChannel.jpg .contentCard__imgChannelImg {
  position: absolute;
  width: 2rem !important;
  height: 2rem !important;
}

.watchVideo {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  /* padding: 1em; */
}

.watchVideo small {
  color: var(--clr-gray);
}

.watchVideo__playedVideo img {
  width: 100%;
  /* height: 100%; */
  /* max-height: 28rem; */
  aspect-ratio: 16 / 9;
  border-radius: 0.5em;
}

.watchVideo__playedVideo {
  width: 100%;
  position: relative;

  /* width: fit-content; */
}

.watchVideo__playedVideo iframe {
  width: 100%;
  border-radius: 0.5em;
  aspect-ratio: 16 / 9;

  /* height: 100%; */
}

/* .watchVideo__playedVideo::after {
  content: "";
  left: 0;
  border-radius: 0 0 0.5em 0.5em;
  position: absolute;
  width: 100%;
  height: 3.5rem;
  bottom: 0.4rem;
  background: linear-gradient(to bottom, #0000, #000b);
} */

.watchVideo__playedAction {
  position: absolute;
  grid-gap: 1rem;
  gap: 1rem;
  width: calc(100% - 2rem);
  bottom: 1.5rem;
  z-index: 1;
  left: 1rem;
}

.watchVideo__playedAction svg {
  cursor: pointer;
}

.watchVideo__progressBar {
  position: relative;
  width: 100%;
  cursor: pointer;
  height: 0.3rem;
  border-radius: 2rem;
  background: var(--clr-dark-gray);
}

.watchVideo__progressBar::after {
  content: "";
  position: absolute;
  width: 60%;
  border-radius: inherit;
  height: 100%;
  background: var(--clr-primary);
}

.watchVideo__playedDetails {
  margin-top: 0.5rem;
}

.watchVideo__playedDetailsTitle {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  /* align-items: flex-start; */
  -webkit-flex-direction: column;
          flex-direction: column;
}

.watchVideo__playedDetailsAction {
  font-size: 0.8rem;
  border-radius: 3em;
  padding: 0.15em;
  margin-top: 1rem;
  background: var(--clr-modal-light);
  border: 0.1rem solid var(--clr-gray300);
}

body.dark-mode .watchVideo__playedDetailsAction {
  background: var(--clr-modal);
  border: 0.1rem solid var(--clr-secondary-dark);
}

.watchVideo__playedDetailsActionUnlike {
  padding: 0.25em;
}
.watchVideo__playedDetailsActionLike {
  padding: 0 0.5em;
  margin-right: 0.5em;
  cursor: pointer;
  font-weight: 500;
  color: var(--clr-gray400);
  /* background: red; */
  border-right: 0.1rem solid var(--clr-gray300);
}

body.dark-mode .watchVideo__playedDetailsActionLike {
  border-right: 0.1rem solid var(--clr-secondary-dark);
}
.watchVideo__playedDetailsAction svg {
  cursor: pointer;
}

.watchVideo .watchVideo__playedDetailsActionGift {
  color: var(--clr-primary);
  background: transparent;
}
/* body.dark-mode .watchVideo__playedDetailsActionGift {
  color: var(--clr-light);
} */

.watchVideo__playedDetailsActionUnlike,
.watchVideo__playedDetailsActionLike svg,
.watchVideo__playedDetailsActionGift svg {
  margin-right: 0.2rem;
}

.watchVideo__playedDetailsActionLike.liked {
  color: var(--clr-primary);
}
/* .watchVideo__playedDetailsActionUnlike.unliked svg path {
  fill: var(--clr-invalid);
}

.watchVideo__playedDetailsActionLike.liked svg path {
  fill: var(--clr-primary);
} */
.watchVideo__playedDetailsActionUnlike.unliked svg rect {
  fill: var(--clr-invalid);
}

.watchVideo__playedDetailsActionLike.liked svg rect {
  fill: var(--clr-primary);
}

.watchVideo__playedDetailsTitle h1 {
  font-size: 1.1rem;
}

.watchVideo__playedDetailsTitle small {
  font-size: 0.8rem;
}

.watchVideo__more {
  margin-top: 1rem;
  padding: 1rem 0;
  border-bottom: 0.1rem solid var(--clr-light-gray);
  border-top: 0.1rem solid var(--clr-light-gray);
}
body.dark-mode .watchVideo__more {
  border-bottom: 0.1rem solid var(--clr-secondary-dark);
  border-top: 0.1rem solid var(--clr-secondary-dark);
}

.watchVideo__channel {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.watchVideo__channelInfo {
  cursor: pointer;
}

.watchVideo__channelInfo h4 {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: -0.3rem;
  margin-right: 0.3rem;
}

.watchVideo__channelInfo small {
  font-size: 0.75rem;
}

.watchVideo__channelImg img {
  width: 2.8rem;
  height: 2.8rem;
  object-fit: cover;
  margin-right: 0.5rem;
  border-radius: 50%;
}

.watchVideo__channelImg.svg img {
  background: var(--clr-light);
  padding: 0.3em;
}

.watchVideo__commentAction {
  width: 100%;
}

.watchVideo__commentAction button,
.watchVideo__channel button {
  border-radius: 0.7em;
  padding: 0.75em 1.5em;
}

.watchVideo__channelInfoBtn button:first-child.primary {
  color: var(--clr-light);
}
.watchVideo__channelInfoBtn button:first-child {
  color: var(--clr-gray400);
  font-weight: 600;
}

.watchVideo__channelInfoBtn button:first-child svg {
  margin-right: 0.5rem;
}

.watchVideo__channelInfoBtn button:last-child {
  background: transparent;
  /* color: var(--clr-light); */
  margin-left: 0.5rem;
  color: var(--clr-gray400);
  font-weight: 600;
  padding: 0.7em 1.75em;
  border: 0.1rem solid var(--clr-primary);
}

.watchVideo__channelInfoBtn button:last-child svg {
  margin-right: 0.5rem;
}

.watchVideo__desc {
  margin-top: 1rem;
  max-height: 6rem;
  overflow: hidden;
}

.watchVideo__desc.more {
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  overflow: visible;
  overflow: initial;
}

.watchVideo__desc p {
  font-size: 0.8rem;
  line-height: 1.5;
}

.watchVideo__descSeeMore {
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-top: 0.5rem;
  letter-spacing: 0.05rem;
  cursor: pointer;
  color: var(--clr-gray);
}

.watchVideo__other {
  grid-gap: 1rem;
  gap: 1rem;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.watchVideo__comment {
  padding-bottom: 5em;
}

.watchVideo__comment img {
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 0.5rem;
}

.watchVideo__commentInputContainer {
  position: static;
  position: initial;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  /* top: 100%; */
  /* width: calc(100% - 34rem); */
  /* max-width: 50rem; */
  margin-top: 1rem;
  /* transform: translateY(-100%); */
  /* padding: 1em 1.8em; */
  /* background: var(--clr-dark); */
}

/* .watchVideo__commentInputContainer.fix {
  position: fixed;
  top: 100%;
  width: 100%;
  max-width: 50rem;
  margin-top: 0;
  margin-left: -1.8em;
  transform: translateY(-100%);
  padding: 1em 1.8em;
  background: var(--clr-dark);
} */

.watchVideo__commentInput {
  width: 100%;
  padding: 1em 1.25em;
  border-radius: 1em;
  resize: none;
  font-size: 0.8rem;
  height: 3rem;
  z-index: 1;
  scrollbar-width: none;
  color: var(--clr-darker);
  background: var(--clr-modal-light);
  border: 0.1rem solid var(--clr-gray300);
}

body.dark-mode .watchVideo__commentInput {
  border: 0.15rem solid var(--clr-hover-dark);
  background: var(--clr-modal);
  color: var(--clr-light);
}

.watchVideo__commentInput label {
  position: absolute;
  left: 1em;
  top: 50%;
  color: var(--clr-gray400);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  pointer-events: none;
}

/* .watchVideo__commentInput:focus-within.watchVideo__commentInput::after {
  display: none;
} */

/* .watchVideo__commentInput::after {
  content: "Comment what you thoughts";
  position: absolute;
  z-index: -1;
  font-size: 0.8rem;
  pointer-events: none;
  color: var(--clr-gray400);
} */

.watchVideo__commentInput::-webkit-scrollbar {
  display: none;
}

.watchVideo__commentAction {
  width: 100%;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.watchVideo__commentAction button:first-child {
  margin-right: 0.5rem;
  background: transparent;
  color: var(--clr-gray);
}
/* ad */

.watchVideo__autoPlay {
  width: 100%;
  /* cursor: pointer; */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.75em 1.25em;
  border-radius: 0.7em;
  margin-bottom: 0.5rem;
  background: var(--clr-modal-light);
  border: 0.1rem solid var(--clr-gray300);
}

.watchVideo__autoPlay::before {
  content: "";
  position: absolute;
  width: calc(100% + 2rem);
  left: -1rem;
  z-index: -2;
  height: calc(100% + 3rem);
  background: var(--clr-light);
  top: -2rem;
}

.watchVideo__autoPlay::after {
  content: "";
  position: absolute;
  width: 100%;
  z-index: -2;
  height: 100%;
  background: inherit;
  top: 0;
  border-radius: inherit;
  border: inherit;
  left: 0;
}
body.dark-mode .watchVideo__autoPlay {
  background: var(--clr-modal);
  border: 0.1rem solid var(--clr-secondary-dark);
}

.watchVideo__autoPlay p {
  font-size: 0.9rem;
}

.watchVideo__toggle {
  cursor: pointer;
  position: relative;
  width: 3.5rem;
  height: 1.75rem;
  border-radius: 3em;
  background: rgb(235, 235, 235);

  padding: 0.25em;
  transition: background 0.2s ease-in-out;
}
body.dark-mode .watchVideo__toggle {
  background: var(--clr-hover-dark);
}

/* .watchVideo.white-mode .watchVideo__toggle.active,
.watchVideo__toggle.active {
  background: var(--clr-primary);
} */

.watchVideo__toggle input {
  visibility: hidden;
  pointer-events: none;
}

.watchVideo__toggleIcon {
  position: absolute;
  top: 50%;
  width: 1.25rem;
  height: calc(100% - 0.5em);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0.25rem;
  cursor: pointer;
  background: var(--clr-gray);

  transition: left 0.3s ease-in;
}

body.dark-mode .watchVideo__toggleIcon {
  background: var(--clr-darker);
}

.watchVideo__toggle input:checked ~ .watchVideo__toggleIcon {
  left: calc(100% - 1.5rem);
  background: var(--clr-primary);
}

@media (min-width: 450px) {
  .watchVideo__channel {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}

@media (min-width: 900px) {
  .watchVideo {
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 0;
    gap: 0;
    margin-top: -1rem;
    width: calc(100% + 4rem);
    margin-left: -2rem;
    margin-bottom: -5rem;
    height: calc(100vh - 4rem);
  }

  .watchVideo__played {
    padding: 2em 2em 1em 2em;
  }

  .watchVideo__other {
    padding: 2em 2em 1em 0;
  }

  .watchVideo__channelInfo {
    width: 100%;
  }

  .watchVideo__comment {
    width: inherit;
  }

  .watchVideo__commentInputContainer {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    /* position: fixed; */
    /* top: 90%; */
    opacity: 1;
    margin-top: 0;
    margin-left: -1.8em;
    /* bottom: 50%; */
    /* transform: translateY(-90%); */
    bottom: 0rem;
    padding: 1em 1.8em;
    background: var(--clr-light);
  }

  .watchVideo__commentInputContainer.fix {
    position: fixed;
    /* top: 100%; */
    /* width: 100%; */
    /* max-width: 50rem; */
    margin-top: 0;
    opacity: 1;
    /* transform: translateY(-100%); */
    background: var(--clr-light);
  }

  body.dark-mode .watchVideo__commentInputContainer {
    background: var(--clr-dark-background);
  }

  .watchVideo__other,
  .watchVideo__played {
    overflow-y: scroll;
    /* height: 100vh; */
    height: calc(100vh - 4rem);

    scrollbar-width: none;
  }

  .watchVideo__played {
    -webkit-flex: 2.5 1;
            flex: 2.5 1;
    /* max-width: 48rem; */
  }

  /* .watchVideo__playedVideo iframe {
    max-width: 48rem;
  } */

  .watchVideo__other::-webkit-scrollbar,
  .watchVideo__played::-webkit-scrollbar {
    display: none;
  }

  .watchVideo__playedDetailsTitle {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .watchVideo__playedDetailsAction {
    margin-top: 0;
  }
}

.sidebar {
  width: 100%;
  position: fixed;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: initial;
          align-items: initial;
  background: var(--clr-header-light);
  bottom: 0;
  z-index: 10;
  color: var(--clr-darker);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* box-shadow: 0rem -0.2rem 0.7rem #0003; */
}

body.dark-mode .sidebar {
  /* background: var(--clr-light); */
  background: var(--clr-darker);
}

.sidebar__logo {
  display: none;
  cursor: pointer;
}

.sidebar__logoIcon {
  display: grid;
  place-items: center;
  border-radius: 0.3em;
  width: 2rem;
  height: 2rem;
  background: var(--clr-primary);
}

.sidebar__logoName {
  color: var(--clr-primary);
  margin-left: 0.75rem;
  font-weight: 500;
  font-size: 1.4rem;
}

.sidebar__logo svg path:first-child {
  fill: var(--clr-primary);
}
.sidebar__logo svg path + path {
  stroke: var(--clr-primary);
}

body.dark-mode .sidebar__logo svg path:first-child {
  fill: var(--clr-light);
}
body.dark-mode .sidebar__logo svg path + path {
  stroke: var(--clr-light);
}

body.dark-mode .sidebar__logoName {
  color: var(--clr-light);
}

.sidebar__navItems {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.7em 2em;
}

.sidebar__navItem {
  display: -webkit-flex;
  display: flex;
}

.sidebar__navItem svg {
  width: 25px;
  height: 25px;
}

.sidebar__navItem svg path {
  fill: #818e94;
  stroke: #818e94;
}
.sidebar__navItem:nth-of-type(2) svg path,
.sidebar__navItem:first-child svg path {
  stroke: initial;
}

body.dark-mode .sidebar__navItem svg path {
  fill: var(--clr-light);
  stroke: var(--clr-light);
}

.sidebar__navItem svg rect {
  fill: #818e94;
}
body.dark-mode .sidebar__navItem svg rect {
  fill: var(--clr-light);
}

/* .sidebar__navItem svg path.white-mode {
} */

.sidebar__navItem.center {
  position: relative;
  top: -2rem;
  padding: 0.7em;
  background: var(--clr-primary);
  border: 0.15rem solid var(--clr-header-light);
  box-shadow: 0px 0px 0px 0.1rem #2c91fd;
}

body.dark-mode .sidebar__navItem.center {
  border: 0.2rem solid var(--clr-darker);
}

body.dark-mode .sidebar__navItem.center svg path,
.sidebar__navItem.center svg path {
  fill: var(--clr-light);
}

.sidebar__navItem p {
  display: none;
  color: var(--clr-text-dark);
}
body.dark-mode .sidebar__navItem p {
  color: var(--clr-light);
}

.sidebar__modalLevel {
  display: none;
}

/* .sidebar__modalLevel > div:first-of-type img {
  width: 100%;
  max-width: 0.75rem;
  margin: 0;
} */

.sidebar__profile {
  display: none;
}

/* show at cetain width */
.sidebar__upgrade,
.sidebar__navItem.more,
.sidebar__navItem.connections,
.sidebar__navItem.images,
.sidebar__navItem.messages-nav,
.sidebar__navItem.quiz-nav,
.sidebar__navItem.geniuspass,
.sidebar__navItem.communities-nav,
.sidebar__navItem.shop-nav {
  display: none;
}

@media (min-width: 500px) {
  .sidebar__navItem svg {
    width: 28px;
    height: 28px;
  }
}

@media (min-width: 900px) {
  .sidebar {
    width: 100%;
    left: 0;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background: none;
    margin-top: auto;
    height: calc(100vh - 4rem);
    top: 4rem;
    padding: 1.5em 1.25em 1em 1.25em;
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
    max-width: 16.5rem;
    background: var(--clr-light);
    box-shadow: none;

    border-right: 0.1rem solid var(--clr-gray300);
  }

  .sidebar__navItem.account,
  .sidebar__navItem.messageMobile {
    display: none;
  }
  .sidebar__navItem.more,
  .sidebar__navItem.connections,
  .sidebar__navItem.images,
  .sidebar__navItem.messages-nav,
  .sidebar__navItem.quiz-nav,
  .sidebar__navItem.geniuspass,
  .sidebar__navItem.communities-nav,
  .sidebar__navItem.shop-nav {
    display: -webkit-flex;
    display: flex;
  }

  body.dark-mode .sidebar {
    background: var(--clr-dark);
    border-right: 0.1rem solid var(--clr-secondary-dark);
  }

  .sidebar::-webkit-scrollbar {
    display: none;
  }

  .sidebar__moreNav {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(calc(-50% - 1rem));
            transform: translateY(calc(-50% - 1rem));
    padding: 1em;
    opacity: 0;
    width: 100%;
    visibility: hidden;
    pointer-events: none;
    background: var(--clr-light);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 0 0.5em 0.5em 0;
    max-width: 14rem;
    box-shadow: 0.2rem 0px 0.5rem #0002;
    transition: left 0.3s ease-in-out, opacity 0.05s 0.25s;
  }

  .sidebar__navItem svg path {
    fill: #818e94;
    stroke: none;
  }
  body.dark-mode .sidebar__navItem svg path {
    fill: var(--clr-light);
    stroke: initial;
  }

  body.dark-mode .sidebar__moreNav {
    background: var(--clr-dark);
    box-shadow: 0px 0px 0.5rem #191a1f;
  }

  .sidebar__moreNav.show {
    left: 16.5rem;
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }

  /* .sidebar__nav {
    margin-top: 1.5rem;
  } */

  .sidebar__navItems {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: initial;
            justify-content: initial;
    -webkit-align-items: initial;
            align-items: initial;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    padding: 0;
  }

  .sidebar__navItem.more {
    position: relative;
    z-index: 1;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  /* body.dark-mode .sidebar__navItem.more svg path {
    fill: transparent;
  } */

  .sidebar__navItem.more::after {
    content: "";
    width: 3rem;
    height: 100%;
    padding: 2em;
    right: -2rem;
    position: absolute;
  }

  .sidebar__navItem.more::before {
    content: "";
    width: 100%;
    height: 100%;
    padding: 2.2rem;
    position: absolute;
    z-index: -1;
  }

  .sidebar__navItem svg {
    margin-right: 1.5rem;
    width: 20px;
    height: 20px;
  }

  .sidebar__navItem svg.sidebar__moreIcon {
    margin-right: 0 !important;
  }

  /* .sidebar__navItem.white-mode.center {
    border: none;
  } */

  .sidebar__navItem.center,
  .sidebar__navItem {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 0.6em 1em;
    border-radius: 0.5em;
    color: var(--clr-text-dark);
    font-size: 1rem;
    cursor: pointer;
  }

  body.dark-mode .sidebar__navItem:hover {
    /* background: var(--clr-hover-dark); */
    background: var(--clr-modal-darker);
  }
  .sidebar__navItem.center {
    background: var(--clr-light);
  }

  /* body.dark-mode .sidebar__navItem.white-mode.center {
    background: var(--clr-light);
  } */

  .sidebar__navItem.center svg path {
    fill: #818e94;
  }

  body.dark-mode .sidebar__navItem.center svg path {
    fill: var(--clr-light);
  }
  /* .sidebar__navItem.white-mode.active,
  .sidebar__navItem.white-mode.center:hover,
  .sidebar__navItem.white-mode:hover {
    background: var(--clr-light-gray);
  } */
  .sidebar__navItem.center:hover,
  .sidebar__navItem:hover {
    background: var(--clr-light-gray);

    /* background: var(--clr-modal-light); */
  }

  .sidebar__navItem.active {
    position: relative;
  }

  /* body.dark-mode .sidebar__navItem.active p {
    color: #569EE8;
  } */
  body.dark-mode .sidebar__navItem.active p,
  .sidebar__navItem.active p {
    color: var(--clr-primary);
  }

  /* body.dark-mode .sidebar__navItem.active svg path {
    fill: #569EE8;
  } */
  body.dark-mode .sidebar__navItem.active svg path,
  .sidebar__navItem.active svg path {
    fill: var(--clr-primary);
  }

  body.dark-mode .sidebar__navItem.active::after,
  .sidebar__navItem.active:after {
    content: "";
    position: absolute;
    left: -0.3rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: var(--clr-primary);
    height: 90%;
    width: 0.1rem;
  }

  .sidebar__navItem p {
    display: -webkit-flex;
    display: flex;
    font-size: 0.95rem;
  }

  .sidebar__navItem.center {
    /* background: var(--clr-dark); */
    box-shadow: none;
    border: none;
    top: 0;
  }
  body.dark-mode .sidebar__navItem.center {
    background: var(--clr-dark);
    border: none;
  }

  body.dark-mode .sidebar__navItem.center:hover {
    background: var(--clr-modal-darker);
    /* background: var(--clr-hover-dark); */
  }

  .sidebar__logo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .sidebar__modalLevel {
    display: block;
    cursor: pointer;
    background: var(--clr-modal-light);
    border: 0.1rem solid var(--clr-gray300);
    padding: 1em 1.5em 1.5em 1.5em;
    border-radius: 0.5em;
  }

  body.dark-mode .sidebar__modalLevel {
    background: var(--clr-modal-darker);
    /* background: var(--clr-modal); */
    border: none;
  }

  .sidebar__modalLevel:hover {
    background: var(--clr-light-gray);
  }

  body.dark-mode .sidebar__modalLevel:hover {
    background: var(--clr-hover-dark);
  }

  .sidebar__modalLevel p {
    font-size: 0.85rem;
    color: var(--clr-text-dark);
  }
  body.dark-mode .sidebar__modalLevel p {
    color: #c4c4c4;
  }

  .sidebar__modalLevelHeader {
    margin-bottom: 0.5rem;
  }

  .sidebar__modalLevelHeader h3 {
    font-weight: 500;
    color: var(--clr-primary);
    font-size: 0.85rem;
  }

  .sidebar__modalLevelHeader img {
    width: 100%;
    max-width: 1.4rem;
    margin-right: 0.5rem;
  }

  .sidebar__modalLevelProgress p {
    margin: 0.1rem 0 0.1rem auto;
    font-size: 0.85rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .sidebar__modalLevelProgressBar {
    position: relative;
    width: 100%;
    background: var(--clr-gray300);
    height: 3.5px;
    border-radius: 3rem;
  }

  body.dark-mode .sidebar__modalLevelProgressBar {
    background: #373737;
  }

  .sidebar__modalLevelProgressBar::after {
    content: "";
    position: absolute;
    width: 0%;
    border-radius: 0.5em;
    background: var(--clr-primary);
    height: 100%;
  }
  body.dark-mode .sidebar__modalLevelProgressBar::after {
    background: var(--clr-light-gray);
  }

  .sidebar__profile {
    width: calc(100% + 1rem);
    display: -webkit-flex;
    display: flex;
    margin: 1rem 0 0 -0.5rem;
  }

  .sidebar__upgrade {
    display: -webkit-flex;
    display: flex;
    padding: 1em 1.25em;
    cursor: pointer;
    position: relative;
    border-radius: 0.7em;
    color: var(--clr-text-dark);
    /* box-shadow: 0 0 1rem #077aff50; */
    background: var(--clr-primary-light);
  }

  .sidebar__upgradeImgContainer {
    display: grid;
    place-items: center;
    position: absolute;
    top: -2rem;
    left: 0;
    background: var(--clr-light);
    right: 0;
    margin: 0 auto;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.1rem solid var(--clr-gray300);
  }

  .sidebar__upgradeImgContainer img {
    display: block;
    max-width: 2.5rem;
    width: 100%;
  }

  .sidebar__upgradePrice {
    margin-top: 1rem;
    font-size: 1.8rem;
    color: var(--clr-primary);
    font-weight: 600;
  }

  .sidebar__upgradePrice span {
    font-size: 0.9rem;
    color: var(--clr-gray400);
    font-weight: 400;
  }

  .sidebar__upgradeDesc {
    margin: 0.25rem 0 1rem 0;
    text-align: center;
  }

  .sidebar__upgradeDesc h3 {
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  .sidebar__upgradeDesc p {
    font-size: 0.9rem;
  }

  .sidebar__upgrade button {
    padding: 0.75em 2em;
    border-radius: 0.75em;
  }
}

.topupGiftPopup {
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000b;
  z-index: 200;
}

.topupGiftPopup__container {
  /* width: 80%; */
  width: 100%;
  height: 100%;
  /* max-width: 60rem; */
  background: var(--clr-light);
  box-shadow: 0 0px 27px 0 rgb(0 0 0 / 50%);
  padding: 1em;
  overflow-y: scroll;
  scrollbar-width: none;
  margin: auto;
}

.topupGiftPopup__container::-webkit-scrollbar {
  display: none;
}

.topupGiftPopup__container h1 {
  text-align: center;
  font-size: 1.5rem;
}

/* radio btn */

.topupGiftPopup__radio {
  width: 100%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 2rem 0;
  font-size: 0.8rem;
  cursor: pointer;
}

.topupGiftPopup__radioTitle {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.topupGiftPopup__radio label {
  -webkit-flex: 1 1 50%;
          flex: 1 1 50%;
  background: var(--clr-primary-light);
  width: 100%;
  padding: 2.5em 2em;
  border-radius: 0.75em;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  border: 0.15rem solid transparent;
}

.topupGiftPopup__radio label > div {
  width: 100%;
}

.topupGiftPopup__radio label.checked {
  border: 0.15rem solid var(--clr-primary);
}

.topupGiftPopup__radioText {
  width: 100%;
  cursor: pointer;
  /* justify-content: space-between; */
}

.topupGiftPopup__radioText span {
  margin: 0.5rem 0 0 0.4rem;
}

.topupGiftPopup__radioText {
  font-size: 0.9rem;
  color: var(--clr-gray400);
}
.topupGiftPopup__radioText strong {
  font-size: 2rem;
}

body.dark-mode .topupGiftPopup__radioText {
  color: var(--clr-gray500);
}

.topupGiftPopup__radioContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.topupGiftPopup__radioInput {
  visibility: hidden;
  display: none;
}

.topupGiftPopup__radioInput:checked ~ .topupGiftPopup__radioControl {
  border: 0.5rem solid var(--clr-primary);
}
.topupGiftPopup__radioInput:checked ~ .topupGiftPopup__radioText {
  color: var(--clr-darker);
}
body.dark-mode
  .topupGiftPopup__radioInput:checked
  ~ .topupGiftPopup__radioText {
  color: var(--clr-light);
}

.topupGiftPopup__radioControl {
  width: 100%;
  max-width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  cursor: pointer;
  border: 0.1rem solid var(--clr-dark);
}

/* slider or range price */

/* price} */

.topupGiftPopup__sliderSection {
  width: 100%;
  max-width: 25rem;
  margin: 2rem auto;
}

.topupGiftPopup__sliderSection > div:last-child {
  width: 100%;
  margin-top: 1.25rem;
  font-weight: 600;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.topupGiftPopup__sliderSection h3 {
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
}

.topupGiftPopup__sliderPrice {
  width: 100%;
  margin-bottom: -0.5rem;
}

.topupGiftPopup__track {
  position: relative;
  width: 100%;
  height: 0.3rem;
  border-radius: 3em;
  background: #077aff55;
}

.topupGiftPopup__range {
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 3em;
  background: var(--clr-primary);
  height: 100%;
  left: 0;
  right: 0;
}

.topupGiftPopup__thumb {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  background: var(--clr-light);
  box-shadow: 0 0 0.25rem #0007;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.topupGiftPopup__value {
  position: relative;
  display: grid;
  place-items: center;
  /* padding: 0.25em 0.75em; */
  padding: 0.5em;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: 600;
  top: -2.5rem;
  font-size: 0.65rem;
  color: var(--clr-dark);
  transition: opacity 0.5s;
  opacity: 0;
  border-radius: 0.5em;
  box-shadow: 0 0px 27px 0 rgb(0 0 0 / 20%);
  background: var(--clr-light);

  /* box-shadow: 0 0 0.25rem #0005; */
}

.topupGiftPopup__value.show {
  opacity: 1;
}

.topupGiftPopup__sliderPrice {
  position: relative;
  margin-top: 1rem;
}

.topupGiftPopup__slider {
  position: absolute;
  width: 100%;
  z-index: 30;
  height: 0.4rem;
  pointer-events: none;
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
}

.topupGiftPopup__slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  pointer-events: all;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: red;
  cursor: pointer;
}

.topupGiftPopup__sliderPrice {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-size: 0.9rem;
}

.topupGiftPopup button {
  margin: 1rem 0;
  /* width: 100%; */
  font-weight: 600;
  letter-spacing: 0.05rem;
  font-size: 0.85rem;
  color: var(--clr-gray400);
  padding: 1.5em 1.75em;
  border-radius: 0.5em;
  cursor: default;
}

.topupGiftPopup button.primary {
  cursor: pointer;
}

.topupGiftPopup button svg {
  margin-left: 0.5rem;
}
.topupGiftPopup button svg path {
  stroke: var(--clr-gray400);
}
.topupGiftPopup button.primary svg path {
  stroke: var(--clr-light);
}

@media (min-width: 900px) {
  .topupGiftPopup__container {
    position: fixed;
    width: 80%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-width: 45rem;
    padding: 2em;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 0.75em;
    -webkit-animation: fadein 0.7s;
            animation: fadein 0.7s;
  }

  .topupGiftPopup__container h1 {
    font-size: 1.8rem;
  }

  .topupGiftPopup__radio {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
}

.notificationsPopup {
  position: absolute;
  top: calc(100% + 1rem);
  padding: 1em 0.5em;
  width: 20rem;
  border-radius: 0.7em;
  right: -2rem;
  background: var(--clr-light);
  box-shadow: 0 0 1rem #0002;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  visibility: hidden;
}

.notificationsPopup.show {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.notificationsPopup::after {
  content: "";
  position: absolute;
  top: -0.5rem;
  right: 2rem;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  /* border-top: 1rem solid red; */
  border-bottom: 1rem solid var(--clr-light);
}

.notificationsPopup li {
  /* width: fit-content; */
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.notificationsPopup li img {
  width: 100%;
  max-width: 2.5rem;
  border-radius: 50%;
  margin-right: 0.75rem;
}

/* .notificationsPopup__img {
  margin-right: 0.75rem;
} */

.notificationsPopup li {
  padding: 0.5em 0.75em;
  border-radius: 0.7em;
}

.notificationsPopup li:hover {
  background: var(--clr-light-gray);
}

.notificationsPopup li + li {
  margin-top: 0.25rem;
}

.notifications__messages h3 {
  font-size: 0.85rem;
}

.notifications__messages h3 span {
  font-weight: 400;
}

.notifications__messages svg {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0.1rem;
  margin-right: 0.5rem;
}

.notifications__time {
  margin-top: 0.2rem;
  color: var(--clr-gray500);
}

.notifications__time span {
  font-size: 0.75rem;
}

.notifications__messages {
  width: 100%;
}

.settingAccount {
  width: 100%;
}

.settingAccount__profileDescContainer {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  /* flex-direction: column; */
  grid-gap: 1rem;
  gap: 1rem;
}

.settingAccount__profileDescContainer img {
  /* width: 100%; */
  height: 5.5rem;
  /* max-width: 6rem; */
  border-radius: 0.5em;
}

.settingAccount__profileDesc {
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.settingAccount__profileDesc h2 {
  font-size: 1.1rem;
  font-weight: 500;
}

.settingAccount__profileDesc small {
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--clr-primary);
}

.settingAccount__profileDesc button {
  font-size: 0.8rem;
  padding: 0.5em 1em;
  margin-top: 0.7rem;
}

/* form  */

.settingAccount__formContainer {
  margin-top: -2rem;
}

.settingAccount__profileInputSections {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin: 1.75rem 0;
}

.settingAccount__profileInputContainer {
  width: 100%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  position: relative;
}

.settinAccount__changes {
  font-size: 0.7rem;
  margin-top: 0.2rem;
  margin-left: 0.5rem;
  position: absolute;
  bottom: -1rem;
  left: 0;
  color: var(--clr-success);
}

.settingAccount__profileInputContainer .dropdown-container {
  width: 100%;
  padding: 1em 1.25em;
  font-size: 0.85rem;
  /* background: var(--clr-modal-light); */
  border: 0.15rem solid var(--clr-gray300);
  /* box-shadow: 0rem 0rem 0.5rem #0002; */
  border-radius: 0.75em;
  font-weight: 500;
}

.settingAccount__profileInputContainer .dropdown-container.expand {
  box-shadow: none;
}

body.dark-mode
  .settingAccount__profileInputContainer
  .dropdown-container.expand {
  box-shadow: none;
}

.settingAccount__profileInputContainer .dropdown-item-container {
  width: calc(100% - 0.1rem);
  top: 3.5rem;
  font-weight: 500;
  left: 0.05rem;
  background: var(--clr-modal-light);
  border: 0.1rem solid var(--clr-gray300);
  border-radius: 0.75em;
}

body.dark-mode .settingAccount__profileInputContainer .dropdown-container,
body.dark-mode .settingAccount__profileInputContainer .dropdown-item-container {
  background: var(--clr-modal);
  color: var(--clr-gray);
  border: 0.1rem solid var(--clr-hover-dark);
}

body.dark-mode
  .settingAccount__profileInputContainer
  .dropdown-item-container
  label:first-child,
.settingAccount__profileInputContainer
  .dropdown-item-container
  label:first-child {
  background: none;
}

.settingAccount__profileInputContainer .dropdown-item-container.expand {
  max-height: 14rem;
}
.settingAccount__profileInputContainer .dropdown-item-container label {
  margin: 0;
  padding: 0.75em 1.5em;
}

body.dark-mode
  .settingAccount__profileInputContainer
  .dropdown-item-container
  input,
.settingAccount__profileInputContainer
  .settingAccount__profileInputSearch
  input {
  padding: 0.25em 0.5em;
  cursor: text;
  border: none;
}
.settingAccount__profileInputContainer
  .settingAccount__profileInputSearch
  input:focus-within {
  border: none;
}

.settingAccount__profileInputContainer input {
  width: 100%;
}

.settingAccount__profileInputContainer select {
  font-size: 0.85rem;
  padding: 0.75em 1.25em;
  width: 100%;
  border: 0.1rem solid var(--clr-gray);
  background: var(--clr-light-gray);
  border-radius: 1em;
  position: relative;
  color: var(--clr-darker);
  outline: none;
}

.settingAccount__dropdownIcon {
  position: absolute;
  right: 0;
  top: 55%;
  -webkit-transform: rotate(-90deg) translateY(-55%);
          transform: rotate(-90deg) translateY(-55%);
  background: var(--clr-light-gray);
}
body.dark-mode .settingAccount__dropdownIcon {
  background: var(--clr-modal);
}

.settingAccount__dropdownIcon path {
  stroke: var(--clr-gray);
}
body.dark-mode .settingAccount__dropdownIcon path {
  stroke: var(--clr-light);
}

body.dark-mode .settingAccount__profileInputContainer select {
  color: #fff;
  background: var(--clr-modal);
  border: 0.15rem solid var(--clr-hover-dark);
  box-shadow: none;
}

.settingAccount__btnUpdate {
  width: 100%;
  border-radius: 1em;
  margin-top: 1.5rem;
}

.settingAccount__bio p {
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 0.5rem;
}

.settingAccount__bio > div:first-child svg {
  display: -webkit-flex;
  display: flex;
  width: 1rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.settingAccount__bioEdit {
  border: 0.15rem solid var(--clr-dark-background);
  padding: 1em;
  border-radius: 0.25em;
}

.settingAccount__btnBioContainer {
  margin-top: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  justify-self: flex-end;
}

.settingAccount__btnBioContainer button {
  border-radius: 0.5em;
  padding: 0.75em 1em;
}

/* language */

.settingAccount__dropdown {
  min-height: 3.5rem;
  cursor: pointer;
}

.settingAccount__selectedLanguages {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.settingAccount__selectedLanguage {
  padding: 0.25em 0.5em;
  border-radius: 3em;
  background: var(--clr-gray300);
}
.settingAccount__dropdownLists {
  position: absolute;
  background: var(--clr-light);
  width: 100%;
  max-height: 10rem;
  pointer-events: none;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  visibility: hidden;
  border: 0.15rem solid var(--clr-primary);
  border-top: none;
  transition: -webkit-transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 0 0 1em 1em;
  z-index: 10;
  -webkit-transform-origin: top;
          transform-origin: top;
  scrollbar-track-color: transparent;
  scrollbar-width: thin;
}

.settingAccount__identity .settingAccount__dropdownLists {
  top: -5.75rem;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

.settingAccount__dropdownLists.show {
  pointer-events: all;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  visibility: visible;
}

.settingAccount__countryLists::-webkit-scrollbar,
.settingAccount__dropdownLists::-webkit-scrollbar {
  width: 0.5rem;
  cursor: pointer;
}

.settingAccount__countryLists::-webkit-scrollbar-track,
.settingAccount__dropdownLists::-webkit-scrollbar-track {
  background-color: transparent;
}

.settingAccount__countryLists::-webkit-scrollbar-thumb,
.settingAccount__dropdownLists::-webkit-scrollbar-thumb {
  background: var(--clr-primary);
  border-radius: 3rem;
}

.settingAccount__dropdownLists li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding: 1em;
  cursor: pointer;
  position: relative;
  z-index: -1;
}
.settingAccount__dropdownLists label {
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}
.settingAccount__dropdownLists li input[type="checkbox"] {
  display: none;
  cursor: pointer;
}
.settingAccount__dropdownLists li input[type="checkbox"]:checked ~ label span {
  border: 0.1rem solid var(--clr-primary);
  background: var(--clr-primary);
}
.settingAccount__dropdownLists li input[type="checkbox"]:checked ~ label {
  color: var(--clr-primary);
  font-weight: 700;
}

.settingAccount__dropdownLists span {
  display: block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  border-radius: 0.25em;
  position: relative;
  border: 0.1rem solid var(--clr-gray);
}

.settingAccount__dropdownLists
  li
  input[type="checkbox"]:checked
  ~ label
  span::after {
  content: url("data:image/svg+xml,%3Csvg width='11' height='10' viewBox='0 0 33 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 12L12 21.5L30.5 3' stroke='white' stroke-width='7' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  position: absolute;
  top: -0.45rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

/* country */
.settingAccount__countryLists {
  max-height: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  border-radius: 1em;
  top: 3.5rem;
  pointer-events: none;
  background: var(--clr-light);
  box-shadow: 0 0.5rem 1.25rem #0002;
  width: 100%;
  transition: height 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* transition: max-height 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  z-index: 5;
  scrollbar-width: none;
}

.settingAccount__countryLists.show {
  max-height: calc(100% + 8rem);
  pointer-events: all;
}

.settingAccount__countryLists li {
  padding: 1em;
  cursor: pointer;
}

.settingAccount__languages li:hover,
.settingAccount__countryLists li:hover {
  background: #0001;
}

/* identity & gender*/
.settingAccount__gender input[type="radio"] {
  display: none;
}

.settingAccount__gender .input__label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.settingAccount__gender .input__label img {
  margin-right: 0.5rem;
  width: 0.9rem;
  -webkit-filter: invert(83%) sepia(17%) saturate(221%) hue-rotate(169deg)
    brightness(89%) contrast(91%);
          filter: invert(83%) sepia(17%) saturate(221%) hue-rotate(169deg)
    brightness(89%) contrast(91%);
}

.settingAccount__gender.open-dropdown .input__label img {
  -webkit-filter: invert(42%) sepia(98%) saturate(4685%) hue-rotate(200deg)
    brightness(101%) contrast(102%);
          filter: invert(42%) sepia(98%) saturate(4685%) hue-rotate(200deg)
    brightness(101%) contrast(102%);
}

.settingAccount__gender .settingAccount__dropdown {
  text-transform: capitalize;
}

.settingAccount__gender.settingAccount__dropdown.error ~ .input__label img {
  -webkit-filter: invert(22%) sepia(39%) saturate(6019%) hue-rotate(344deg)
    brightness(122%) contrast(84%);
          filter: invert(22%) sepia(39%) saturate(6019%) hue-rotate(344deg)
    brightness(122%) contrast(84%);
}

@media (min-width: 900px) {
  .settingAccount__profileInputSections {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
}

.settingPassword {
  margin-top: -0.75em;
}

.settingPassword__profileInputSections {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 1rem 0;
}

.settingPassword__profileInputContainer {
  width: 100%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  position: relative;
}

.settingPassword__profileInputContainer label {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0.5rem 0;
}

.settingPassword__profileInputContainer input {
  width: 100%;
}

.settingPassword__requirements li {
  color: var(--clr-text-gray);
  font-size: 0.7rem;
}

.settingPassword__btnUpdate {
  width: 100%;
  border-radius: 1em;
  margin-top: 1.5rem;
  color: var(--clr-gray400);
  font-weight: 600;
  cursor: default;
}
.settingPassword__btnUpdate.primary {
  cursor: pointer;
}

.settingPassword__twoStep {
  padding-top: 2em;
  margin: 2rem 0;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
  border-top: 0.1rem solid var(--clr-gray300);
}

.settingPassword__twoStepHeader {
  margin-bottom: 0.3rem;
}

.settingPassword__twoStepHeader h3 {
  color: var(--clr-text-dark);
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: -0.5rem;
}

.settingPassword__twoStepHeader span {
  font-size: 0.6rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: var(--clr-success);
}

.settingPassword__twoStepDesc {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-right: 4rem;
}

.settingPassword__twoStepDesc p {
  color: var(--clr-text-gray);
  font-size: 0.8rem;
  line-height: 1.5;
  font-weight: 400;
}

.settingPassword__dropdownToggle {
  position: relative;
  width: 3.5rem;
  height: 1.75rem;
  background: var(--clr-light-gray);
  border-radius: 3em;
  cursor: pointer;
  padding: 0.25em;
  transition: background 0.2s ease-in-out;
}
body.dark-mode .settingPassword__dropdownToggle {
  background: var(--clr-dark-gray);
}

body.dark-mode .settingPassword__dropdownToggle.active,
.settingPassword__dropdownToggle.active {
  background: var(--clr-primary);
}

body.dark-mode .settingPassword__dropdownToggle {
  background: var(--clr-hover-dark);
}

.settingPassword__dropdownToggle input {
  visibility: hidden;
  pointer-events: none;
}

.settingPassword__dropdownToggleIcon {
  position: absolute;
  top: 50%;
  width: 1.25rem;
  height: calc(100% - 0.5em);
  background: var(--clr-gray);

  -webkit-transform: translateY(-50%);

          transform: translateY(-50%);
  left: 0.25rem;
  cursor: pointer;
  transition: left 0.3s ease-in;
}

body.dark-mode .settingPassword__dropdownToggleIcon {
  background: var(--clr-darker);
}

.settingPassword__dropdownToggle
  input:checked
  ~ .settingPassword__dropdownToggleIcon {
  left: calc(100% - 1.7em);
  background: var(--clr-light);
}

@media (min-width: 900px) {
  .settingPassword__profileInputSections {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
}

.interestedCard {
  width: 100%;
  cursor: pointer;
  position: relative;
  /* width: fit-content; */
}

/* .interestedCard:last-child::after {
  content: "asdasd";
  font-size: 4rem;
  pointer-events: none;
  visibility: hidden;
} */

.interestedCard__selected {
  position: absolute;
  display: grid;
  place-items: center;
  width: 1.5rem;
  border-radius: 0.3em;
  height: 1.5rem;
  top: 0.5rem;
  right: 0.5rem;
  background: var(--clr-primary);
}

.interestedCard__img {
  width: 100%;
  display: block;
  height: 10rem;
  /* height: 8rem; */
  object-fit: cover;
  border-radius: 0.75em;
}

.interestedCard__title {
  position: absolute;
  bottom: 0.5rem;
  right: 0.8rem;
  color: var(--clr-light);
  font-weight: 500;
  font-size: 0.9rem;
}

/* @media (min-width: 450px) {
  .interestedCard__img {
    width: 100%;
    max-width: 10rem;
    height: 10rem;
    object-fit: contain;
    border-radius: 0.9em;
  }
} */

.settingTopics {
  height: 100%;
}
.settingTopics__container {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}

.settingTopics__container::-webkit-scrollbar {
  display: none;
}

.settingTopics__btnContainer {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  bottom: -2.15rem;
  left: 0;
  right: 0;
  padding: 1em 0;
  margin-left: auto;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background: var(--clr-light);
}

.settingTopics__btnContainer {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.settingTopics__btnContainer > div {
  width: 100%;
}
.settingTopics__btnContainer > div {
  grid-gap: 0.5rem;
  gap: 0.5rem;
  max-width: 20rem;
}

.settingTopics__btnContainer button {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  /* margin: 0 1rem 1rem auto; */
}

.settingPopup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  background: #000b;
}

.settingPopup__container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--clr-light);
  box-shadow: 0rem 0rem 1rem #0002;
  border-radius: 0;
}

body.dark-mode .settingPopup__container {
  background: var(--clr-dark);
  box-shadow: 0rem 0rem 1rem #0007;
}

.settingPopup__header {
  padding: 1em 1.5em;
  border-bottom: 0.05rem solid var(--clr-light-gray);
}

.settingPopup__header svg {
  margin-right: 1rem;
}

body.dark-mode .settingPopup__header {
  border-bottom: 0.05rem solid var(--clr-secondary-dark);
  padding: 1em 2em;
}

.settingPopup__header h1 {
  font-size: 1rem;
  font-weight: 500;
}

.settingPopup__body {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.settingPopup__bodyNav {
  width: 100%;
}

.settingPopup__bodyNavItems {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  padding: 1em 1.5em;
  border-bottom: 0.05rem solid var(--clr-gray300);
}

body.dark-mode .settingPopup__bodyNavItems {
  border-bottom: 0.05rem solid var(--clr-secondary-dark);
}

.settingPopup__bodyNavItems::-webkit-scrollbar {
  display: none;
}

.settingPopup__bodyNavItem:hover,
body.dark-mode .settingPopup__bodyNavItem.active,
.settingPopup__bodyNavItem.active {
  color: var(--clr-primary);
}

.settingPopup__bodyNavItem {
  font-size: 0.85rem;
  width: 100%;
  margin-right: 1rem;
  font-weight: 400;
  cursor: pointer;
}

.settingPopup__mainContent {
  width: 100%;
  padding: 1.5em 1.5em calc(1.5em + 3.5em) 1.5em;
  /* height: 100%; */
  /* max-height: initial; */
  /* overflow-y: scroll; */
  scrollbar-width: none;
  /* border-left: 0.05rem solid var(--clr-gray); */
}

.settingPopup__mainContent::-webkit-scrollbar {
  display: none;
}
/* 
.settingPopup input {
  padding: 0.75em 1.25em;
  border-radius: 1em;
  color: var(--clr-darker);
  background: var(--clr-modal-light);
  border: 0.1rem solid var(--clr-gray300);
}

body.dark-mode .settingPopup input {
  color: var(--clr-light);
  background: var(--clr-modal);
  border: 0.1rem solid var(--clr-hover-dark);
}

.settingPopup input::placeholder {
  color: var(--clr-text-gray);
}

.settingPopup label {
  color: var(--clr-text-dark);
}

body.dark-mode .settingPopup label {
  color: var(--clr-light);
}

body.dark-mode .settingPopup input:focus-within,
.settingPopup input:focus-within {
  border: 0.1rem solid var(--clr-primary);
} */

@media (min-width: 550px) {
  .settingPopup__body {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .settingPopup__header {
    padding: 1em 2em;
  }

  .settingPopup__bodyNav {
    max-width: 11rem;
    height: 100%;
    border-right: 0.05rem solid var(--clr-gray300);
  }
  /* body.dark-mode .settingPopup__bodyNav {
    border-right: 0.05rem solid var(--clr-secondary-dark);
  } */

  .settingPopup__bodyNavItems {
    -webkit-flex-direction: column;
            flex-direction: column;
    /* padding: 2.5em 2em; */
    border-bottom: none;
  }

  .settingPopup__bodyNavItem {
    padding: 0.5em 1em;
    margin-bottom: 0.5rem;
    color: var(--clr-text-dark);
    border-radius: 0.5em;
  }

  body.dark-mode .settingPopup__bodyNavItem {
    color: var(--clr-light);
  }

  .settingPopup__bodyNavItem:hover {
    /* background: var(--clr-modal-light); */
    background: var(--clr-light-gray);
    color: var(--clr-text-dark);
  }

  .settingPopup__bodyNavItem.active {
    position: relative;
  }
  body.dark-mode .settingPopup__bodyNavItem.active::after,
  .settingPopup__bodyNavItem.active::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: var(--clr-primary);
    height: 90%;
    width: 0.1rem;
  }

  body.dark-mode .settingPopup__bodyNavItem:hover {
    color: var(--clr-light);
    /* background: var(--clr-hover-dark); */
    background: var(--clr-modal-darker);

  }

  .settingPopup__mainContent {
    padding: 2em;
  }
}

@media (min-width: 1200px) {
  .settingPopup__container {
    position: absolute;
    width: 60rem;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    height: 36rem;
    border-radius: 1em;
    -webkit-animation: fadein 0.7s;
            animation: fadein 0.7s;
    /* overflow-y: hidden; */
  }

  .settingPopup__header svg {
    display: none;
  }

  .settingPopup__mainContent {
    border-left: 0.05rem solid var(--clr-gray300);
    overflow-y: scroll;
    height: 32.25rem;
  }

  body.dark-mode .settingPopup__mainContent {
    border-left: 0.05rem solid var(--clr-secondary-dark);
  }

  .settingPopup__bodyNav {
    border-right: none;
  }
}

.profileCard {
  position: relative;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.75em 1em;
  border-radius: 0.5em;
  color: var(--clr-darker);
  background: var(--clr-modal-light);
  cursor: pointer;
}

body.dark-mode .profileCard {
  background: var(--clr-modal);
  color: var(--clr-light);
  border: none;
}

.profileCard__profileLeft.header,
.profileCard.header {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  border: none;
}

.profileCard.header {
  background: transparent;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0;
}

.profileCard__profileLeft {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.profileCard svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.profileCard__profileImg {
  position: relative;
  display: -webkit-flex;
  display: flex;
}

.profileCard__dropdownProfiePicture,
.profileCard__profileImg img {
  width: 100%;
  height: 2rem;
  object-fit: cover;
  width: 2rem;
}

.profileCard__dropdownProfiePicture {
  margin-bottom: 0.5rem;
}

.profileCard__dropdow {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
  position: absolute;
  padding: 1em 1.5em;
  background: var(--clr-light);
  color: var(--clr-darker);
  opacity: 0;
  bottom: 1rem;
  visibility: hidden;
  pointer-events: none;
  border-radius: 0.5em;
  left: 0;
  width: -webkit-max-content;
  width: max-content;
  max-width: 40rem;
  box-shadow: 0rem 0rem 1rem #0003;
  transition: left 0.4s ease-out, opacity 0.1s 0.2s;
}

body.dark-mode .profileCard__dropdow {
  background: var(--clr-dark);
  /* background: var(--clr-modal); */
  color: var(--clr-light);
  box-shadow: 0rem 0rem 1rem #000e;
}

.profileCard__dropdow.header {
  top: 0;
  left: auto;
  left: initial;
  right: 1.5rem;
  bottom: auto;
  bottom: initial;
  transition: top 0.4s ease-out, opacity 0.1s 0.2s;
}

.profileCard__dropdow.show {
  opacity: 1;
  visibility: visible;
  pointer-events: initial;
  z-index: 30;
  left: 105%;
}

.profileCard__dropdow.header.show {
  top: 100%;
  left: 1rem;
  bottom: auto;
  bottom: initial;
}

.profileCard__dropdownHeader {
  /* align-items: flex-start; */
  grid-gap: 2rem;
  gap: 2rem;
  margin: -1rem 0 0 -1.5rem;
  padding: 1rem 1.5em;
  border-radius: 0.5em;
  width: calc(100% + 3rem);
  cursor: default;
}

/* .profileCard__dropdownHeader:hover {
  background: var(--clr-light-gray);
} */

body.dark-mode .profileCard__dropdownHeader:hover {
  background: var(--clr-modal-darker);
  /* background: var(--clr-hover-dark); */
}

.profileCard__dropdowProfileDetail p,
.profileCard__dropdownHeader small {
  text-transform: uppercase;
}

.profileCard__dropdownProfileText {
  text-align: center;
}

.profileCard__dropdownProfileText > div:first-of-type {
  margin-top: 0.25rem;
}

/* .profileCard__dropdowProfileDetail {
  align-items: flex-start;
  gap: -1rem;
} */

.profileCard__dropdowProfileDetail:nth-of-type(2),
.profileCard__dropdowProfileDetail:nth-of-type(3) {
  margin-top: -0.2rem;
}

.profileCard__dropdowProfileDetail:nth-of-type(2) p {
  letter-spacing: 0.1rem;
}

.profileCard__dropdowProfileDetail:nth-of-type(3) p {
  background: #28c76f31;
  color: var(--clr-success);
  font-size: 0.65rem;
  font-weight: 600;
  padding: 0.1em 0.5em;
  border-radius: 0.25em;
  text-align: center;
  color: var(--clr-success);
}

.profileCard__dropdowProfileDetail small {
  font-size: 0.65rem;
  font-weight: 500;
  letter-spacing: 1px;
  color: #828282;
  margin-bottom: 2rem;
}

.profileCard__dropdowProfileDetail p {
  color: #0d0e11;
  font-size: 0.8rem;
}

body.dark-mode .profileCard__dropdowProfileDetail p {
  color: var(--clr-light);
}

.profileCard__dropdownProfileText h3,
.profileCard__profileText h3 {
  font-weight: 300;
  font-size: 0.95rem;
}

.profileCard__dropdownProfileText h3 svg {
  width: 1rem;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  margin-bottom: -0.1rem;
}

.profileCard__dropdownProfileText p,
.profileCard__profileText p {
  color: var(--clr-primary);
  font-size: 0.8rem;
}

.profileCard__profileText.header {
  display: none;
  text-align: right;
  margin-right: 1rem;
}

.profileCard__profileText.header > div {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.profileCard__badge {
  width: 100%;
  max-width: 0.9rem;
  margin: 0 0.25rem;
}

.profileCard__dropdownMenu {
  width: 100%;
  grid-gap: 0.2rem;
  gap: 0.2rem;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.profileCard__dropdownMenuItem {
  font-size: 0.85rem;
  cursor: pointer;
  margin-left: -1em;
  width: calc(100% + 2em);
  padding: 0.75em 1.25em;
  color: var(--clr-text-dark);
  border-radius: 0.5em;
}

body.dark-mode .profileCard__dropdownMenuItem {
  color: var(--clr-light);
}
body.dark-mode .profileCard__dropdownMenuItem.membership,
.profileCard__dropdownMenuItem.membership {
  /* background: #ffb70017; */
  color: var(--clr-primary);
}
body.dark-mode .profileCard__dropdownMenuItem.membership svg path,
.profileCard__dropdownMenuItem.membership svg path {
  fill: var(--clr-primary);
}

.profileCard__dropdownMenuItem:hover {
  background: var(--clr-light-gray);
}

body.dark-mode .profileCard__dropdownMenuItem:hover {
  background: var(--clr-modal-darker);
  /* background: var(--clr-hover-dark); */
}

.profileCard__dropdownMenuItem svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}
.profileCard__dropdownMenuItem svg path {
  fill: #818e94;
}

/* .profileCard__dropdownMenuItem.withdrawal-option svg path:first-of-type,
.profileCard__dropdownMenuItem:first-child svg path {
  fill: transparent;
  stroke: #818e94;
} */

body.dark-mode .profileCard__dropdownMenuItem svg path {
  fill: var(--clr-light);
}

/* toggle */
.profileCard__dropdownMenuItem.toggle {
  padding: 0;
  -webkit-justify-content: center;
          justify-content: center;
}
.profileCard__dropdownMenuItem.toggle:hover {
  background: none;
}
body.dark-mode .profileCard__dropdownMenuItem.toggle:hover {
  background: none;
}

.profileCard__dropdownToggle {
  position: relative;
  width: 7rem;
  height: 2.5rem;
  border-radius: 3em;
  padding: 0.25em;
  background: var(--clr-light-gray);

  transition: background 0.2s ease-in-out;
}

body.dark-mode .profileCard__dropdownToggle {
  background: var(--clr-hover-dark);
}

.profileCard__dropdownToggle input {
  visibility: hidden;
  pointer-events: none;
}

.profileCard__dropdownToggleIcon {
  position: absolute;
  top: 50%;
  width: 2rem;
  height: calc(100% - 0.5em);
  background: var(--clr-gray);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0.25rem;
  transition: left 0.5s ease-in;
}

.profileCard__dropdownToggle input:checked ~ .profileCard__dropdownToggleIcon {
  left: calc(100% - 2.6em);
  background: var(--clr-darker);

  /* transform: translate(calc(-100% - 0.25em), -50%); */
}

.profileCard__dropdownToggle
  input:checked
  ~ .profileCard__dropdownToggleIcon
  svg {
  -webkit-transform: rotate(2turn);
          transform: rotate(2turn);
}

.profileCard__dropdownToggleIcon svg circle {
  fill: var(--clr-gray);
  transition: -webkit-transform 1s 0.2s;
  transition: transform 1s 0.2s;
  transition: transform 1s 0.2s, -webkit-transform 1s 0.2s;
}

.profileCard__dropdownToggleIcon svg circle:last-child {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  fill: var(--clr-dark);
  transition: -webkit-transform 1s 0.2s;
  transition: transform 1s 0.2s;
  transition: transform 1s 0.2s, -webkit-transform 1s 0.2s;
}

.profileCard__dropdownToggleIcon svg rect {
  fill: var(--clr-dark);
}

.profileCard__dropdownToggle
  input:checked
  ~ .profileCard__dropdownToggleIcon
  svg
  circle:last-child {
  fill: var(--clr-light);
  -webkit-transform: translateX(-5px);
          transform: translateX(-5px);
}
.profileCard__dropdownToggle
  input:checked
  ~ .profileCard__dropdownToggleIcon
  svg
  circle {
  fill: var(--clr-darker);
}
.profileCard__dropdownToggle
  input:checked
  ~ .profileCard__dropdownToggleIcon
  svg
  rect {
  fill: var(--clr-light);
}

.profileCard__dropdownToggleIcon svg {
  width: 1.3rem;
  margin-left: 0.3rem;
  height: 1.3rem;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  transition: -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
}

/* .profileCard__settingPopup {
  visibility: hidden;
  pointer-events: none;
}
.profileCard__settingPopup.show {
  visibility: visible;
  pointer-events: initial;
} */

@media (min-width: 768px) {
  .profileCard:hover {
    background: var(--clr-light-gray);
  }
  body.dark-mode .profileCard:hover {
    background: var(--clr-hover-dark);
  }

  .profileCard__dropdownProfiePicture,
  .profileCard__profileImg img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .profileCard__profileText {
    margin-left: 1rem;
  }

  .profileCard__profileText p {
    color: var(--clr-primary);
    font-size: 0.75rem;
  }

  .profileCard__dropdownProfileText h3,
  .profileCard__profileText h3 {
    font-weight: 500;
    color: var(--clr-text-dark);
    font-size: 0.85rem;
  }

  body.dark-mode .profileCard__dropdownProfileText h3,
  body.dark-mode .profileCard__profileText h3 {
    color: var(--clr-light);
    font-weight: 300;
  }
}

@media (min-width: 1200px) {
  .profileCard {
    border: 0.1rem solid var(--clr-gray300);
  }
  /* 
  .profileCard__profileImg::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0.8rem;
    height: 0.8rem;
    background: var(--clr-success);
    border-radius: 50%;
    border: 0.2rem solid var(--clr-modal-light);
  }
  body.dark-mode .profileCard__profileImg::after {
    border: 0.2rem solid var(--clr-modal);
  }

  .profileCard.header .profileCard__profileImg::after {
    border: 0.2rem solid var(--clr-light);
  }
  body.dark-mode .profileCard.header .profileCard__profileImg::after {
    border: 0.2rem solid var(--clr-modal);
  } */

  .profileCard.header {
    padding: 0.5em 1em;
  }

  .profileCard__profileText.header {
    display: block;
  }

  .profileCard__dropdow.header.show {
    left: auto;
    left: initial;
  }
}

header {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  width: 100%;
  padding: 0.5em 1.5em;
  min-height: 5rem;
  background: var(--clr-light);
}

body.dark-mode header {
  /* background: var(--clr-light); */
  background: var(--clr-dark-background);
  color: var(--clr-light);

  /* transition: background 0.4s; */
}

.header__dropdownSearch {
  display: none;
}

.header__form svg {
  width: 1.1rem;
  height: 1.1rem;
}
.header__balance {
  margin-right: 0.5rem;
}

.header__balance p {
  /* color: var(--clr-gray500); */
  margin-left: 0.3rem;
  font-size: 0.85rem;
  margin-top: 0.2rem;
}

.header__form input {
  display: none;
}

.header__rightMessage,
.header__rightNotification {
  display: none;
}

.header__dropbox select {
  font-size: 0.85rem;
  padding: 0.5em 0;
  margin-left: 0.5rem;
  border: none;
  background: var(--clr-ligth);
  color: var(--clr-darker);
  position: relative;
  outline: none;
  box-shadow: none;
}

body.dark-mode .header__dropbox select {
  color: #fff;
  background: var(--clr-darker);
}

.header__detail {
  display: none;
}

.header__right a {
  display: none;
}

.header__btnDonate,
.header__balanceUser {
  display: none;
}

/* .header__dropdownSearch { */
/* display: none; */
/* } */

@media (min-width: 900px) {
  header {
    padding: 0.25em 1.5em;
    min-height: 0;
    min-height: initial;
    -webkit-flex-direction: row;
            flex-direction: row;
    /* margin-top: 1rem; */
    background: var(--clr-light);
    border-bottom: 0.1rem solid var(--clr-gray300);
  }

  .header__right a {
    display: inline;
    display: initial;
  }

  .header__right {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  /* body.dark-mode header { */
  /* background: var(--clr-dark); */
  /* border-bottom: 0.1rem solid var(--clr-secondary-dark); */
  /* } */

  /* .header__form svg {
    width: 35px;
    height: 35px;
  }

  .header__balance svg {
    width: 32px;
    height: 32px;
  }

  .header__balance p {
    font-size: 0.9rem;
  } */

  .header__form {
    width: 18rem;
    padding: 0.45em 0.25em;
    /* padding: 0.25em 0.75em 0.25em 0.75em; */
    background: var(--clr-modal-light);
    border: 0.1rem solid var(--clr-gray300);
    border-radius: 0.7em;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .header__form.shop-page {
    -webkit-flex-direction: row;
            flex-direction: row;
    padding: 0.1em 0.75em 0.25em 0;
  }

  .header__dropdownSearch {
    width: 100%;
    /* width: fit-content; */
    max-width: 8.1rem;

    /* margin-right: 0.5rem; */
  }

  .header__dropdownSearch .dropdown-container {
    font-size: 0.8rem;
    width: 100%;
    /* display: none; */
    padding: 1em 1em;
  }
  .header__dropdownSearch .dropdown-container.expand {
    background-color: transparent;
    box-shadow: none;
  }

  .header__form.shop-page .header__dropdownSearch .dropdown-container {
    display: -webkit-flex;
    display: flex;
  }

  .header__dropdownSearch .dropdown-container p {
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 0.1rem;
    color: var(--clr-text-dark);
  }

  .header__dropdownSearchIcon {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
  }
  body.dark-mode .header__dropdownSearch .dropdown-container p {
    color: var(--clr-light);
  }
  .header__dropdownSearch .dropdown-item-container {
    top: calc(100% + 0.5rem);
    border-radius: 0.5em;
    z-index: 10;
  }
  .header__dropdownSearch .dropdown-item-container label {
    font-weight: 600;
    padding: 1em 1em;
    width: 10rem;
    font-size: 0.8rem;
  }

  .header__dropdownSearch .dropdown-item-container label.menu-active {
    color: var(--clr-primary);
    position: relative;
  }
  .header__dropdownSearch .dropdown-item-container label.menu-active::after {
    content: "";
    position: absolute;
    height: 90%;
    left: 0;
    width: 0.15rem;
    background: var(--clr-primary);
  }

  .header__dropdownSearch .dropdown-item-container label span {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    border: 0.1rem solid var(--clr-gray500);
  }
  .header__dropdownSearch .dropdown-item-container label input:checked ~ span {
    border: 0.35rem solid var(--clr-primary);
  }

  .header__dropdownSearch .dropdown-item-container label.menu-active img {
    -webkit-filter: invert(41%) sepia(53%) saturate(7452%) hue-rotate(204deg)
      brightness(105%) contrast(100%);
            filter: invert(41%) sepia(53%) saturate(7452%) hue-rotate(204deg)
      brightness(105%) contrast(100%);
  }

  .header__dropdownSearch .dropdown-container .header__dropdownSearchArrow {
    width: 0.5rem;
    height: 0.5rem;
  }

  body.dark-mode .header__dropdownSearchIcon {
    -webkit-filter: invert(1) sepia(100%) saturate(1%) hue-rotate(183deg)
      brightness(200%) contrast(101%);
            filter: invert(1) sepia(100%) saturate(1%) hue-rotate(183deg)
      brightness(200%) contrast(101%);
  }

  body.dark-mode
    .header__dropdownSearch
    .dropdown-container
    .header__dropdownSearchArrow
    path,
  .header__dropdownSearch
    .dropdown-container
    .header__dropdownSearchArrow
    path {
    stroke: var(--clr-primary);
  }
  .header__dropdownSearch {
    display: inline;
    display: initial;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    position: relative;
    font-size: 1rem;
  }

  .header__searchIcon {
    width: 2.2rem !important;
    height: 1.8rem !important;
    /* width: 2.2rem !important;
    height: 2rem !important; */
    /* margin-top: 0.1rem; */
  }

  .header__form.shop-page input {
    border-left: 0.1rem solid var(--clr-gray300);
    padding-left: 1em;
  }

  body.dark-mode .header__form {
    /* background: var(--clr-modal); */
    background: transparent;
    border: 0.1rem solid transparent;
  }

  body.dark-mode .header__form:focus-within,
  .header__form:focus-within {
    box-shadow: 0 0 0.5rem #077aff30;
    border: 0.1rem solid var(--clr-primary);
  }
  body.dark-mode .header__form:focus-within input {
    border: none;
  }

  .header__form:focus-within svg rect {
    fill: var(--clr-primary);
  }

  .header__form:focus-within svg circle {
    stroke: var(--clr-primary);
  }

  .header__form input {
    display: inline;
    width: 100%;
    background: transparent;
    font-size: 0.8rem;
    margin-left: 0.5rem;
    color: var(--clr-darker);
  }
  body.dark-mode .header__form input {
    color: #a0a0a0;
  }

  .header__form input::-webkit-input-placeholder {
    /* color: #818e94; */
    color: var(--clr-gray500);
  }

  .header__form input:-ms-input-placeholder {
    /* color: #818e94; */
    color: var(--clr-gray500);
  }

  .header__form input::placeholder {
    /* color: #818e94; */
    color: var(--clr-gray500);
  }

  .header__right {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .header__notification {
    display: -webkit-flex;
    display: flex;
    position: relative;
  }

  .header__notification.notification-circle::after {
    content: "";
    position: absolute;
    width: 0.8rem;
    height: 0.8rem;
    right: 0;
    border-radius: 50%;
    border: 0.2rem solid var(--clr-light);
    background: var(--clr-primary);
  }

  body.dark-mode .header__notification::after {
    border: 0.2rem solid var(--clr-dark);
  }

  .header__cart,
  .header__rightMessage,
  .header__rightNotification {
    display: -webkit-flex;
    display: flex;
    cursor: pointer;
  }

  .header__cart {
    display: -webkit-flex;
    display: flex;
    position: relative;
  }

  .header__cartItem {
    position: absolute;
    font-size: 0.5rem;
    width: 1.1rem;
    height: 1rem;
    right: -0.4rem;
    line-height: 0.9rem;
    top: -0.2rem;
    font-weight: 500;
    color: var(--clr-light);
    border-radius: 2em;
    border: 0.1rem solid var(--clr-light);
    background: var(--clr-primary);
  }

  body.dark-mode .header__rightMessage svg {
    fill: #818e94;
  }

  .header__balance {
    display: none;
  }

  .header__dropbox {
    display: none;
  }
  .header__detail {
    display: -webkit-flex;
    display: flex;
    /* margin-right: -1rem; */
  }
  .header__detail span {
    font-size: 0.7rem;
    color: var(--clr-gray);
  }
  .header__detail strong {
    font-size: 0.9rem;
  }

  .header__balanceUser {
    display: -webkit-flex;
    display: flex;
    margin-right: 0.5rem;
  }

  .header__balanceUser,
  .header__totalAssets {
    cursor: pointer;
  }

  .header__totalAssets strong {
    color: var(--clr-primary);
  }

  .header__balanceUser svg {
    display: -webkit-flex;
    display: flex;
    margin-right: 0.4rem;
  }

  .header__balanceUser strong {
    color: var(--clr-primary);
    font-size: 0.9rem;
    margin-bottom: -0.1rem;
  }

  .sidebar__logoName {
    color: var(--clr-primary);
    margin-left: 0.5rem;
    font-weight: 600;
  }

  body.dark-mode .sidebar__logoName {
    color: var(--clr-light);
  }
}

@media (min-width: 1200px) {
  header {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .header__left {
    -webkit-flex: 1.75 1;
            flex: 1.75 1;
  }

  .header__rightMessage,
  .header__rightNotification {
    display: -webkit-flex;
    display: flex;
  }

  .header__form {
    width: 100%;
    max-width: 25rem;
    margin-left: 1.5rem;
  }

  .header__form.no-dropdown {
    max-width: 28rem;
  }

  /* .header__dropdownSearch {
    max-width: 15rem;
  } */

  body.dark-mode .header__cartItem {
    border: 0.15rem solid var(--clr-dark);
  }
  .header__dropdownSearch {
    display: block;
  }

  .header__right {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .header__btnDonate.btn {
    display: -webkit-flex;
    display: flex;
    padding: 0.65em 1.25em;
    border-radius: 0.5em;
    margin-right: -1.25rem;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--clr-primary);
    background: transparent;
    border: 0.1rem solid var(--clr-primary);
    transition: 0.25s;
  }

  .header__btnDonate.btn:hover {
    background: var(--clr-primary);
    color: var(--clr-light);
  }

  .header__btnDonate.btn:hover svg {
    fill: var(--clr-gray450);
  }

  .header__btnDonate svg {
    width: 0.9rem;
    transition: 0.25s;
    margin-left: 0.5rem;
    fill: var(--clr-primary);
  }
}

.productCard {
  /* border: 0.1rem solid var(--clr-gray300); */
  /* border-radius: 0.8em; */
  cursor: pointer;
  width: 100%;
  height: 18rem;
  border-radius: 0.5em;
  overflow: hidden;
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
  /* background-color: red; */
}

body.dark-mode .productCard {
  border: 0.1rem solid var(--clr-secondary-dark);
}

.productCard.list {
  height: 100%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  /* overflow: hidden; */
}

.productCard__img {
  position: relative;
}

.productCard img {
  width: 100%;
  height: 11rem;
  /* border-radius: 0.6em; */
  /* max-height: 20rem; */
  object-fit: cover;
  /* aspect-ratio: 3 / 2; */
}

.productCard__discount {
  position: absolute;
  font-size: 0.75rem;
  color: var(--clr-primary);
  padding: 0.1em 0.5em;
  border-radius: 1em;
  background: var(--clr-primary-light);
  top: 0.5rem;
  left: 0.5rem;
}

.productCard__body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%;
  /* max-height: calc(100% - 22rem + 10.5rem); */
  max-height: calc(100% - 11.5rem);
  padding: 0 0.9em 0.9em;
}

.productCard__bodyTitle {
  font-size: 1rem;
}

.productCard__bodyDetails > div {
  margin-top: 0.3rem;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.productCard__bodyDetails {
  font-size: 0.8rem;
}

.productCard__bodyDetails > span {
  display: block;
  font-weight: 500;
  font-size: 0.8rem;
  /* margin-top: -0.1rem; */

  color: var(--clr-gray400);
}

.productCard__bodyDesc {
  font-size: 0.8rem;
  margin: 0.3rem 0 0.7rem;
  color: var(--clr-dark-gray);
}
.productCard.list .productCard__bodyDesc {
  margin: 0.2rem 0;
}

.productCard__itemDetails {
  margin-top: 0.5rem;
}

.productCard__itemDetail {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-size: 0.8rem;
  max-width: 10rem;
  margin-top: 0.3rem;

  color: var(--clr-gray400);
}

.productCard__bodyBot {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 0.7rem;
}

.productCard__delivery {
  font-size: 0.75rem;
  margin: 0.4rem 0 0.2rem 0;
  color: var(--clr-gray400);
}

.productCard__delivery strong {
  font-weight: 500;
}

.productCard.list .productCard__bodyDetails {
  -webkit-flex: 2 1;
          flex: 2 1;
  width: 100%;
  height: 100%;
}

.productCard.list .productCard__bodyBot {
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  margin-left: 1rem;
  margin-top: 0;
  height: 100%;
}

.productCard__bodyBotPrice {
  justify-self: flex-end;
  -webkit-align-items: center;
          align-items: center;
  margin-left: auto;
}

.productCard__bodyBotPrice strong {
  font-size: 0.9rem;
  margin-left: 0.5rem;
}
.productCard__bodyBotPrice small {
  text-decoration: line-through;
  color: var(--clr-gray400);
  font-weight: 600;
  margin-top: 0.05rem;
}

.productCard.list .productCard__btnContainer {
  height: 100%;
}

.productCard__btnContainer button {
  padding: 0.75em 1em;
  border-radius: 0.7em;
  font-size: 0.8rem;
}
.productCard.list .productCard__btnContainer button {
  width: 100%;
  padding: 0.75em;
  margin-top: 0.5rem;
}
.productCard.list .productCard__btnContainer button:last-child svg {
  width: 0.7rem;
  height: 0.7rem;
  margin-left: 0.5rem;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.productCard__bodyBot > div:first-child ul {
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.productCard__ColorsColor {
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0rem;
  position: relative;
}

.categories {
  position: fixed;
  width: 100%;
  padding: 0 calc(1.5em + 1rem) 0.5em 1.5em;
  z-index: 5;
  color: var(--clr-darker);
  background: var(--clr-light);
  top: 5rem;
  left: 0;
}

.categories.relative {
  position: relative;
  top: 0;
  /* padding-inline: 0; */
}

body.dark-mode .categories {
  background: var(--clr-darker);
}

.categories__items {
  grid-gap: 0.7rem;
  gap: 0.7rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.categories__items::-webkit-scrollbar {
  -webkit-transform: translateX(-200px);
          transform: translateX(-200px);
  display: none;
}

.categories__item {
  padding: 0.5em 1em;
  font-size: 0.8rem;
  border-radius: 3rem;
  /* border-radius: 0.75em; */
  white-space: nowrap;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  color: #4d4f5c;
  position: relative;
  z-index: 1;
  cursor: pointer;
  border: 0.12rem solid var(--clr-gray450);
}

.categories__item::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  left: 0;
  z-index: -1;
  -webkit-transform: scale(0);
          transform: scale(0);
  border-radius: inherit;
  border: 0.12rem solid var(--clr-primary);
  transition: 0.1s;
}

.categories__item:hover.categories__item::after {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.categories__item:hover {
  color: var(--clr-light);
}

body.dark-mode .categories__item {
  border: 0.12rem solid #e4e5e7;
  color: var(--clr-light);
}
/* .categories__item:hover {
  cursor: pointer;
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  border: 0.12rem solid var(--clr-primary);
  color: var(--clr-light);
} */

.categories__itemImg.primary {
  padding: 0.25em;
  border-radius: 50%;
  margin-right: 0.5rem;
  background: var(--clr-primary-light);
}

.categories__itemImg img {
  display: block;
  /* width: 100%; */
  width: 1.01rem;
  -webkit-filter: invert(33%) sepia(76%) saturate(2951%) hue-rotate(201deg)
    brightness(103%) contrast(102%);
          filter: invert(33%) sepia(76%) saturate(2951%) hue-rotate(201deg)
    brightness(103%) contrast(102%);
}

body.dark-mode .categories__item:hover {
  cursor: pointer;
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  color: var(--clr-light);
}

.categories__item.active {
  border: 0.12rem solid var(--clr-primary);
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  color: var(--clr-light);
}

body.dark-mode .categories__item.active {
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  color: var(--clr-light);
}

.categories__nav {
  position: absolute;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-65%);
          transform: translateY(-65%);
  cursor: pointer;
}

.categories__nav.right {
  right: 1rem;
  z-index: 10;
}

.categories__nav.left {
  left: 1rem;
  right: auto;
  right: initial;
  z-index: 10;
}

.categories__nav::after {
  content: "";
  position: absolute;
  /* height: calc(100% + 3rem); */
  z-index: -1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: var(--clr-light);
}

body.dark-mode .categories__nav::after {
  background: #0d0e11;
}

.categories__nav.right::after {
  right: 1rem;
  width: 0.5rem;
  box-shadow: -1.2rem 0px 0.2rem -0.4rem #f5f5f5;
}

body.dark-mode .categories__nav.right::after {
  box-shadow: -1.2rem 0px 0.1rem -0.4rem #0d0e11;
}

.categories__nav.left::after {
  left: 0rem;
  box-shadow: 1rem 0px 1rem -0.4rem #f5f5f5;
  width: 1.2rem;
}

body.dark-mode .categories__nav.left::after {
  box-shadow: 0.5rem 0px 0.1rem -0.45rem #0d0e11;
}

.categories__nav.right svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.categories__nav svg path {
  stroke: var(--clr-darker);
  stroke-width: 3;
}
body.dark-mode .categories__nav svg path {
  stroke: var(--clr-light);
  stroke-width: 4;
}

@media (min-width: 900px) {
  .categories {
    position: fixed;
    width: calc(100% - 16.5rem);
    padding: 1em 2em 1em 2em;
    right: 0;
    overflow: hidden;
    margin-left: auto;
    top: 4rem;
    background: var(--clr-light);
  }

  .categories.relative {
    width: 100%;
  }

  body.dark-mode .categories {
    background: var(--clr-dark);
  }

  .categories__item {
    font-size: 0.85rem;
  }

  .categories__nav::after {
    background: var(--clr-light);
    height: calc(100% + 3rem);
  }

  body.dark-mode .categories__nav::after {
    background: var(--clr-dark);
    width: 2.5rem;
    box-shadow: -1.5rem 0px 0.5rem -0.3rem #191a1f;
  }

  .categories__nav {
    position: absolute;
    right: 2rem;
    top: 51%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
  }

  .categories__nav.right::after {
    width: 2rem;
    right: 0rem;
    box-shadow: -1.2rem 0px 1rem -0.4rem #ffffff;
  }

  /* .categories.relative .categories__nav.right::after {
    right: -2rem;
  } */

  .categories__nav.left::after {
    left: -2rem;
    box-shadow: 1.2rem 0px 1rem -0.4rem #ffffff;
    width: 3rem;
  }
  body.dark-mode .categories__nav.right::after {
    box-shadow: -1.2rem 0px 1rem -0.45rem #191a1f;
  }
  body.white-mode .categories__nav.left::after {
    box-shadow: 1.2rem 0px 1rem -0.45rem #0d0e11;
  }

  .categories__nav.right {
    right: 2rem;
  }
  .categories__nav.left {
    left: 2rem;
  }
}

@media (min-width: 1326px) {
  .categories__items.center {
    -webkit-justify-content: center;
            justify-content: center;
  }
}

.shop__header {
  margin-top: 0.5rem;
  /* flex-direction: column; */
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  /* align-items: flex-start; */
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.shop__headerOptionItem {
  cursor: pointer;
}

.shop__headerOptionItem.list,
.shop__headerOptionItem.grid {
  display: none;
}

.shop__headerOptionItem svg {
  margin-right: 0.3rem;
}

.shop__headerOptionItem.viewSelected {
  color: var(--clr-primary);
}

.shop__headerOptionItem.viewSelected svg path {
  stroke: var(--clr-primary);
}

.shop__circleRounded {
  padding: 0.2em 0.5em;
  background: var(--clr-header-light);
  font-size: 0.7rem;
  color: var(--clr-primary);
  margin-right: 0.3rem;
  border-radius: 2em;
}
body.dark-mode .shop__circleRounded {
  background: var(--clr-modal);
}

.shop__main {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-top: 0.5rem;
}

.shop__mainContainer {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: grid;
  place-items: center;
  /* grid-template-columns: auto; */
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  /* grid-template-columns: repeat(2, minmax(14rem, 100%)); */
  grid-gap: 1.5rem;
  gap: 1.5rem;
  /* padding-top: 1.5em; */
}

.shop__mainContainer.list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

@media (min-width: 900px) {
  .shop {
    margin-bottom: -5rem;
    /* height: calc(100vh - 5rem); */
    /* overflow: hidden; */
  }

  .shop__main {
    width: calc(100% + 4rem);
    margin-left: -2rem;
    margin-top: 0.5rem;
  }

  .shop__mainContainer {
    padding: 3em 2em 0 2em;
    height: 87vh;
    scrollbar-width: none;
    overflow-y: scroll;
  }

  .shop__mainContainer::after {
    content: "asdasd";
    visibility: hidden;
    margin-top: -1rem;
    pointer-events: none;
  }

  .shop__mainContainer::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 1200px) {
  .shop__headerOptionItem.list,
  .shop__headerOptionItem.grid {
    display: -webkit-flex;
    display: flex;
  }

  .shop__headerOptionItem + .shop__headerOptionItem {
    margin-left: 0.5rem;
  }
}

/* @Media */

.contentSection {
  margin-bottom: 1.5rem;
  /* overflow-x: hidden; */
}

.contentSection__header {
  /* overflow-x: hidden; */
  display: none;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 1rem;
  /* overflow-x: hidden; */
}

.contentSection__header h2 {
  font-size: 0.95rem;
  font-weight: 500;
  color: var(--clr-text-dark-header);
  cursor: pointer;
}
body.dark-mode .contentSection__header h2 {
  color: var(--clr-light);
}

.contentSection__header h2 svg {
  margin-right: 0.5rem;
}

.contentSection__headerChannelContainer {
  width: 11.5rem;
  padding: 0.5rem 0.2rem;
  overflow-x: hidden;
  padding: 0.5rem 15rem 15rem 10rem;
  margin: 0 -3.5rem -14.5rem -10rem;
  scrollbar-width: none;
  transition: width 1.2s ease-in-out;
}

.contentSection__headerChannelWrapper {
  margin-left: auto;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.contentSection__headerChannelContainer.show {
  width: 33rem;
  padding: 0.5rem 0.2rem;
  margin: 0;
  margin: initial;
  padding-bottom: 15rem;
  margin-bottom: -14.5rem;
}

.contentSection__headerChannelContainer::-webkit-scrollbar {
  display: none;
}

.contentSection__headerChannelNav {
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
}

.contentSection__headerChannelNav svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-left: 0.85rem;
}

.contentSection__headerChannelImg {
  display: grid;
  place-items: center;
  position: relative;
  opacity: 1;
  padding: 0;
  border: 0.12rem solid transparent;
  transition: 1s;
  /* display: none; */
}

.contentSection__headerChannelImg.order5,
.contentSection__headerChannelImg.order6,
.contentSection__headerChannelImg.order7,
.contentSection__headerChannelImg.order8,
.contentSection__headerChannelImg.order9 {
  pointer-events: none;
  width: 0;
  visibility: hidden;
  opacity: 0;
}

.contentSection__headerChannelImg.svg {
  height: 1.5rem;
  width: 1.5rem;
}

.contentSection__headerChannelImg img {
  width: 0.75rem;
  height: 0.75rem;
}

/* .contentSection__headerChannelImg:hover.contentSection__headerChannelImg::before {
  content: "";
  position: absolute;
  width: 0.7rem;
  height: 0.2rem;
  border-radius: 3rem;
  bottom: -0.35rem;
  left: 50%;
  transform: translateX(-50%);
  background: var(--clr-gray400);
} */

.contentSection__headerChannelImg:hover.contentSection__headerChannelImg.svg::before {
  bottom: -0.65rem;
}

.contentSection__headerChannelImg {
  cursor: pointer;
}

.contentSection__content {
  display: grid;
  place-items: center;
  /* width: 100%; */
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}
.contentSection__content.less-content {
  grid-template-columns: repeat(auto-fit, minmax(15rem, 15rem));
}

.contentSection__expand {
  display: none;
}

@media (min-width: 900px) {
  .contentSection__header {
    margin-bottom: 0.5rem;
    display: -webkit-flex;
    display: flex;
  }

  .contentSection__header .contentSection__headerChannelNav svg path {
    stroke: var(--clr-darker);
    stroke-width: 3;
  }

  body.dark-mode .contentSection__headerChannelNav svg path {
    stroke: var(--clr-light);
    stroke-width: 4;
  }

  .contentSection__headerChannelContainer li {
    position: relative;
  }

  .contentSection__headerChannelImg:hover ~ .contectSection__channelDetails {
    opacity: 1;
    pointer-events: initial;
  }

  /* .contentSection__headerChannelImg::after {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    padding: 1.2em;
    top: 0;
    z-index: 40;
  } */

  .contectSection__channelDetails {
    position: absolute;
    top: 2.5rem;
    padding: 1em;
    border-radius: 0.4em;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    opacity: 0;
    z-index: 30;
    width: 19rem;
    pointer-events: none;
    min-height: 7rem;
    transition: 0.3s ease-in-out;
    /* background: var(--clr-dark400); */
    background: var(--clr-light);
    color: #000;
    box-shadow: 0rem 0rem 1rem #0002;
  }

  body.dark-mode .contectSection__channelDetails {
    background: var(--clr-modal);
    color: var(--clr-ligth);
    box-shadow: 0rem 0rem 0.5rem #0007;
  }

  .contectSection__channelDetails.order6,
  .contectSection__channelDetails.order2 {
    left: calc(50% - 2rem);
  }

  .contectSection__channelDetails.order7,
  .contectSection__channelDetails.order3 {
    left: calc(50% - 5rem);
  }

  .contectSection__channelDetails.order4,
  .contectSection__channelDetails.order8 {
    left: calc(50% - 7rem);
  }

  .contectSection__channelDetails.order9 {
    left: calc(50% - 8.5rem);
  }

  .contectSection__channelDetails.expand.order4,
  .contectSection__channelDetails.expand.order3,
  .contectSection__channelDetails.expand.order3::after,
  .contectSection__channelDetails.expand.order4::after {
    left: 50%;
  }

  .contectSection__channelDetails:hover {
    opacity: 1;
    pointer-events: initial;
  }

  .contectSection__channelDetails::after {
    content: "";
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: -1rem;
    border-right: 1rem solid transparent;
    border-left: 1rem solid transparent;
    /* border-bottom: 1.3rem solid var(--clr-dark400); */
    border-top: 0.5rem solid transparent;
    border-bottom: 1.3rem solid var(--clr-light);
  }
  body.dark-mode .contectSection__channelDetails:after {
    border-bottom: 1.3rem solid var(--clr-modal);
  }

  .contectSection__channelDetails.order6::after,
  .contectSection__channelDetails.order2::after {
    left: calc(50% + 2rem);
  }

  .contectSection__channelDetails.order7::after,
  .contectSection__channelDetails.order3::after {
    left: calc(50% + 5rem);
  }

  .contectSection__channelDetails.order4::after,
  .contectSection__channelDetails.order8::after {
    left: calc(50% + 7rem);
  }

  .contectSection__channelDetails.order9::after {
    left: calc(50% + 8.5rem);
  }
}

@media (min-width: 900px) {
  .contentSection__content {
    /* height: initial; */
    /* height: 100%; */
    /* max-height: 0; */
    overflow: hidden;
    position: relative;
  }

  .contentSection__content::after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 100%;
    background: var(--clr-light);
    height: 100%;
    transition: 2s;
  }

  /* .contentSection__content.expand-video {
    animation: exapandVideo2 15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  } */

  /* .contentSection__content.expand-video::after { */
  /* bottom: -100%; */

  /* transition: max-height 10s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  /* } */

  .contentSection__expand {
    display: -webkit-flex;
    display: flex;
    cursor: pointer;
    width: 0.8rem;
    height: 0.8rem;
    margin-left: 0.5rem;
  }

  .contentSection__videoRowOne {
    max-height: 0;
    height: 100%;
    display: none;
    visibility: hidden;
    pointer-events: none;
    margin-bottom: -2rem;
    /* transition: max-height 5s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  }

  .contentSection__videoRowOne.expanded-video {
    display: -webkit-flex;
    display: flex;
    visibility: visible;
    pointer-events: all;
    margin-bottom: 0;
    max-height: 50rem;
    -webkit-animation: exapandVideo2 5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            animation: exapandVideo2 5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    /* max-height: 50rem;
    display: flex;
    visibility: visible;
    pointer-events: all;
    margin-bottom: 0; */
  }

  /* .contentSection.video-expanded {
    position: fixed;
    width: calc(100% - 16.5rem);
    right: 0;
    height: calc(100vh - 7rem);
    top:7.8rem;
    padding: 1em 2em;
    z-index: 20;

    background-color: red;
  } */
}

@media (min-width: 1200px) {
  /* .contentSection__content.expand-video { */
  /* transition: max-height 10s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  /* } */
}

@-webkit-keyframes exapandVideo2 {
  0% {
    max-height: 0rem;
  }
  100% {
    /* max-height: ; */

    max-height: 50rem;
  }
}

@keyframes exapandVideo2 {
  0% {
    max-height: 0rem;
  }
  100% {
    /* max-height: ; */

    max-height: 50rem;
  }
}

/* .videosTeacher {
  justify-content: center;
  height: calc(100vh - 4rem);
  overflow: hidden;
  text-align: center;
  width: 80%;
  margin: 0 auto;
} */

.videosTeacher img {
  width: 100%;
  max-width: 18rem;
}

.videosTeacher h1 {
  margin: 2rem 0;
  font-size: 1.8rem;
  line-height: 1.3;
}

.videosTeacher p {
  margin-bottom: 2rem;
  max-width: 37rem;
}
.videosTeacher form {
  height: 3.5rem;
  width: 100%;
  max-width: 28rem;
}

.videosTeacher form button {
  border-radius: 0.25em;
  height: 100%;
  text-transform: uppercase;
  margin-left: 0.5rem;
  padding: 0;
  -webkit-flex: 1 1 30%;
          flex: 1 1 30%;
}

.videosTeacher input {
  width: 100%;
  height: 100%;
  -webkit-flex: 1 1 70%;
          flex: 1 1 70%;
  border-radius: 0.25em;
  padding: 0.25em 1em;
  border: 0.1rem solid var(--clr-gray400);
}

/*  */

.videosTeacher__image img {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  height: 8rem;
  margin: 0 auto;
  object-fit: contain;
}

.videosTeacher__card span {
  font-size: 0.8rem;
}

.videosTeacher__card {
  background: var(--clr-light);
  /* background: var(--clr-gray300); */
  /* box-shadow: 0 5rem 3rem -4rem #077aff20; */
  /* box-shadow: 0 2.5rem 3rem -1rem #077aff30; */
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  padding: 2em;
  border: 0.1rem solid var(--clr-gray300);
  min-height: 25rem;
  text-align: center;
  border-radius: 1em;
  transition: 0.5s;
}

.videosTeacher__card:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  /* border: 0.1rem solid var(--clr-gray300); */
  box-shadow: 0 3.5rem 4rem -2rem #0003;
  /* box-shadow: 0 3.5rem 4rem -2rem #077aff40; */
}

.videosTeacher a {
  text-align: center;
  color: var(--clr-text-dark);
  margin-top: 0.5rem;
  line-height: 1.8;
  font-size: 0.9rem;
}

.videosTeacher__card h4 {
  text-align: center;
  color: var(--clr-text-dark-header);
}

.videosTeacher__ .videosTeacher__card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.videosTeacher__container {
  place-items: center;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
}

.videosTeacher__image svg {
  display: -webkit-flex;
  display: flex;
  width: 4rem;
  height: 4rem;
}
.videosTeacher__header {
  max-width: 30rem;
}

.videosTeacher__container span {
  font-size: 0.9rem;
  color: var(--clr-text-dark);
  text-align: center;
  line-height: 2;
}

.videosTeacher__container h4 {
  font-size: 1rem;
  margin: 1.25rem;
}

.videosTeacher__videContainer {
  display: grid;
  place-items: center;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}
.videosTeacher__videContainer.less-content {
  grid-template-columns: repeat(auto-fit, minmax(15rem, 15rem));
}

@media (min-width: 900px) {
  .videosTeacher {
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: -5rem;
    height: calc(100vh - 5rem);
    /* overflow: hidden; */
  }
  .videosTeacher.list-video {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
}

.videos {
  position: relative;
  display: grid;
  /* overflow-x: hidden; */
}

.videos::-webkit-scrollbar {
  display: none;
}

.videos__header {
  position: fixed;
  width: 100%;
  padding-bottom: 1em;
  z-index: 2;
  background: #fff;
  top: 5rem;
  left: 0;
}

body.dark-mode .videos__header {
  background: var(--clr-dark-background);

  /* background: var(--clr-light); */
  /* box-shadow: 0rem 0.2rem 0.2rem #0003; */
}

.videos__container {
  margin-top: 3.5rem;
}

.videos__container.videos-teacher {
  margin-top: 0;
}

.videos__content {
  display: grid;
  place-items: center;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  /* grid-template-columns: repeat(1, minmax(14rem, 100%)); */
  grid-gap: 1rem;
  gap: 1rem;
}

@media (min-width: 900px) {
  .videos__header {
    /* width: calc(100% - 17rem); */
    padding-bottom: 0;
    background: var(--clr-light);
    width: calc(100% - 16.5rem);
    right: 0;
    overflow: hidden;
    margin-left: auto;
    /* top: 5rem; */
    top: 4.6rem;
    /* z-index: 10; */
  }

  body.dark-mode .videos__header {
    background: var(--clr-dark);
    box-shadow: none;
  }
}

/* @media (min-width: 1200px) {
  .videos__header {
    
  }
} */

.productDetail {
  height: 100%;
  margin-top: -1rem;
  margin-left: -2rem;
  /* background: red; */
  width: calc(100% + 3rem);
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.productDetail__imgContainer {
  height: 100%;
  width: 100%;
  /* max-width: 50rem; */
}

.productDetail__imgContainer img {
  /* width: 120rem; */
  width: 100%;
  object-fit: cover;
  max-width: inherit;
  height: 100%;
  max-height: 20rem;
}

.productDetail__details {
  width: 100%;
  /* height: 100%; */
  padding: 3em 4em;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  scrollbar-width: none;
  height: 100%;

  /* background: red; */
  /* justify-content: space-between; */
}

.productDetail__details::-webkit-scrollbar {
  display: none;
}

.productDetail__kind {
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  background: var(--clr-primary);
  padding: 0.25em 0.75em;
  border-radius: 0.5em;
  color: var(--clr-light);
}

.productDetail__tabs {
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-bottom: 0.75em;
  margin: 2rem 0 1rem 0;
  border-bottom: 0.2rem solid var(--clr-gray300);
}

.productDetail__tabs li {
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  padding-inline: 0.2em;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.productDetail__tabs li.active::after {
  content: "";
  position: absolute;
  height: 0.15rem;
  width: 100%;
  bottom: -1.15em;
  left: 0;
  background-color: var(--clr-primary);
}

.productDetail__detailsTop {
  width: 100%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.productDetail__price {
  -webkit-flex: 1 1 10%;
          flex: 1 1 10%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-left: auto;
  font-size: 1rem;
  margin-top: 0.5rem;
  font-weight: 800;
}

.productDetail__detailsTop h1 {
  -webkit-flex: 1 1 90%;
          flex: 1 1 90%;
  margin-right: 1rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.productDetail__detailsRating {
  margin: 1rem 0 1.5rem 0;
}

.productDetail__detailsDesc {
  font-size: 0.9rem;
  color: var(--clr-gray500);
  margin-bottom: 1rem;
}
.productDetail__SizesContainer p,
.productDetail__ColorsContainer p,
.productDetail__detailsQtyTitle {
  letter-spacing: 2px;
}

.productDetail__Sizes + .productDetail__Sizes {
  cursor: pointer;
}

.productDetail__attribute {
  grid-gap: 2rem;
  gap: 2rem;
}

.productDetail__detailsQtyContainer {
  margin-top: 0.5rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.productDetail__ColorsContainer ul,
.productDetail__SizesContainer ul {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.productDetail__SizesContainer,
.productDetail__ColorsContainer,
.productDetail__detailsQty {
  font-size: 0.7rem;
  width: 100%;
  max-width: 15rem;
  margin: 1rem 0;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  /* background: red; */
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: var(--clr-gray500);
  text-transform: uppercase;
  font-weight: 600;
}

.productDetail__detailsQtyCountContainer {
  padding: 0.25em 0.75em;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 5rem;
  border-radius: 2em;
  border: 0.1rem solid var(--clr-gray500);
}

.productDetail__detailsQtyCount {
  color: var(--clr-darker);
  font-weight: 700;
}

body.dark-mode .productDetail__detailsQtyCount {
  color: var(--clr-light);
}

.productDetail__detailsQtyMinus,
.productDetail__detailsQtyPlus {
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  padding: 0.2em;
}

.productDetail__Sizes,
.productDetail__ColorsColor {
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0rem;
  margin-top: 0.5rem;
  position: relative;
  cursor: pointer;
  border: 0.1rem solid var(--clr-light);
}

.productDetail__ColorsColor svg {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 0.05rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.productDetail__ColorsColor.selected svg {
  display: -webkit-flex;
  display: flex;
}

.productDetail__Sizes.selected,
body.dark-mode .productDetail__ColorsColor {
  border: 0.3rem solid var(--clr-dark);
}

.productDetail__ColorsColor.selected {
  border: 0.1rem solid var(--clr-light);
  /* box-shadow: 0 0 0.2rem var(--clr-gray500); */
}

.productDetail__Sizes.selected {
  cursor: pointer;
  color: var(--clr-darker);
  font-weight: 700;
  border: 0.18rem solid var(--clr-gray300);
}

.productDetail__price span {
  font-size: 1rem;
  margin-top: 0.1rem;
  color: var(--clr-gray500);
}

.productDetail__bottom {
  margin-top: 1rem;
  width: 100%;
  padding-top: 1.5rem;
  border-top: 0.15rem solid var(--clr-gray300);
  justify-self: flex-end;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

body.dark-mode .productDetail__bottom {
  border-top: 0.1rem solid var(--clr-secondary-dark);
}

.productDetail__bottom {
  color: var(--clr-gray500);
  font-size: 0.8rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
}

.productDetail__bottom svg {
  margin-right: 0.5rem;
}

.productDetail__bottom button {
  width: 100%;
  max-width: 13rem;
  font-size: 0.7rem;
  font-weight: normal;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1.25em 1.25em;
}

.productDetail__bottom button:last-child {
  color: var(--clr-gray400);
  background: transparent;
}
.productDetail__bottom button:last-child svg {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

@media (min-width: 900px) {
  .productDetail {
    margin-top: -1.5rem;
    margin-bottom: -5rem;
    height: calc(100vh - 4rem);
    overflow: hidden;
    /* height: 90.5vh; */
    -webkit-flex-direction: row;
            flex-direction: row;
    width: calc(100% + 4rem);
  }

  .productDetail__details {
    overflow-y: scroll;
  }

  .productDetail__imgContainer img {
    max-height: none;
    max-height: initial;
  }
}
@media (min-width: 1200px) {
  .productDetail {
    margin-top: -1rem;
  }
}

.completedSteps {
  /* position: relative; */
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  /* background: red; */
  max-width: 50rem;
  margin: 0 auto;
  margin-top: 1rem;
}

.completedSteps__content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.completedSteps__content:last-child {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.completedSteps__rounded::after {
  content: "";
  position: absolute;
  bottom: -1.5em;
  font-size: 0.8rem;
  /* text-transform: uppercase; */
  /* letter-spacing: 2px; */
  font-weight: 500;
  color: var(--clr-gray500);
}

/* .completedSteps__rounded::after {
  content: "";
  position: absolute;
  bottom: -1.2rem; */
  /* left: 50%;
  transform: translateX(-50%); */
  /* text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--clr-gray500);
} */

.completedSteps__rounded.active,
.completedSteps__rounded {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.6rem;
  width: 1.5rem;
  height: 1.5rem;
  font-weight: 600;
  background: var(--clr-light-gray);
  margin-bottom: 5px;
  color: var(--clr-dark-gray);
  border-radius: 50%;
}

.completedSteps__rounded.active {
  color: var(--clr-light);
}

body.dark-mode .completedSteps__rounded {
  color: var(--clr-darker);
}
body.dark-mode .completedSteps__rounded.active {
  color: var(--clr-light);
}

.completedSteps__rounded.active::after {
  color: var(--clr-darker);
}
body.dark-mode .completedSteps__rounded.active::after {
  color: var(--clr-light);
}

.completedSteps__rounded.one::after {
  content: "Cart";
}
.completedSteps__rounded.two::after {
  content: "Delivery";
}
.completedSteps__rounded.three::after {
  content: "Payment";
}
.completedSteps__rounded.four::after {
  content: "Done";
}

.completedSteps__line {
  /* width: 100%; */
  max-width: 15rem;
  height: 0.18rem;
  margin-top: -5px;
  -webkit-flex: 1 1;
          flex: 1 1;
  background: var(--clr-light-gray);
}

.completedSteps__rounded.active,
.completedSteps__line.active {
  background: var(--clr-primary);
}

.cart table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 4rem;
}

.cart th {
  text-align: left;
  padding: 0.75em 1em;
  /* font-size: 0.65rem; */
  /* font-size: 0.9rem; */
  font-weight: 700;
  /* text-transform: uppercase; */
  /* color: var(--clr-gray500); */
  /* letter-spacing: 2px; */
  display: none;
}

body.dark-mode .cart th {
  border-bottom: 0.05rem solid var(--clr-gray300);
}

.cart td {
  padding: 2em 0.5em 2.75em 0.5em;
  font-size: 0.8rem;
  border-bottom: 0.05rem solid var(--clr-gray300);
}

body.dark-mode .cart td {
  border-bottom: 0.05rem solid var(--clr-secondary-dark);
}

/* .cart td:last-child {
  padding: 0;
} */

.cart__item img {
  width: 6rem;
  height: 6rem;
  border-radius: 0.3em;
  margin-right: 1rem;
  object-fit: cover;
}

.cart__itemTitle {
  font-size: 0.9rem;
}
.cart__promoCode,
.cart__price,
.cart__priceTotal strong {
  display: none;
}

.cart__priceTotal strong,
.cart__price strong {
  font-size: 0.9rem;
}

.cart__discountTotal p,
.cart__allPriceTotal p {
  /* font-size: 0.6rem; */
  /* text-transform: uppercase; */
  color: var(--clr-gray500);
  /* letter-spacing: 2px; */
  font-weight: 600;
}

.cart__ColorsContainer {
  font-size: 0.6rem;
  text-transform: uppercase;
  color: var(--clr-gray500);
  letter-spacing: 2px;
  font-weight: 600;
}

.cart tr {
  position: relative;
}

.cart__removeItem {
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  font-size: 0.85rem;
  color: var(--clr-invalid);
}

.cart__removeItem svg {
  margin-right: 0.3rem;
  width: 1rem;
  height: 1rem;
}
/* 
.cart__detailsQty {
  flex-direction: column;
} */

.cart__deleteIconItem {
  margin-top: 0.5rem;
}

.cart__detailsQtyCountContainer {
  padding: 0.25em 0.3em;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-radius: 2em;
  border: 0.1rem solid var(--clr-gray500);
}

.cart__detailsQtyCountContainer svg {
  margin: 0.2rem 0;
}

.cart__detailsQtyCount {
  color: var(--clr-darker);
  font-weight: 700;
}

body.dark-mode .cart__detailsQtyCount {
  color: var(--clr-light);
}

.cart__detailsQtyMinus,
.cart__detailsQtyPlus {
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  padding: 0.2em;
}

.cart__ColorsColor {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  position: relative;
  border: 0.1rem solid var(--clr-light);
}

/* body.dark-mode .cart__ColorsColor {
  border: 0.3rem solid var(--clr-dark);
} */

.cart__priceTotal .flex-row {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.cart__priceTotal svg {
  cursor: pointer;
}
.cart__subtotal {
  width: 100%;
  /* width: 90%; */
  margin: 2rem auto 0 auto;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.cart__promoCode {
  padding: 1.25em 2em;
  width: 100%;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 700;
  border: 0.1rem solid var(--clr-gray300);
  background: var(--clr-header-light);
  color: var(--clr-gray500);
  max-width: 10rem;
}

.cart__promoCode > div.flex-row {
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.cart__promoCode.promo-input {
  width: 100%;
  max-width: 20rem;
  padding: 0.75em;
}

.cart__promoCode button {
  padding: 0.75em !important;
  color: var(--clr-gray400);
  cursor: default;
  /* color: var(--clr-light); */
}
.cart__promoCode button.primary {
  cursor: pointer;
}

.cart__promoCode input {
  padding: 0 0.75em;
  max-width: 10rem;
}
.cart__promoCode input.promo-err {
  color: var(--clr-invalid);
  font-weight: 700;
}

body.dark-mode .cart__promoCode {
  background: var(--clr-modal);
}

.cart__promoCode svg {
  width: 0.5rem;
  display: -webkit-flex;
  display: flex;
}

.cart__promoCode svg path {
  stroke: var(--clr-primary);
}

.cart__discountTotal,
.cart__allPriceTotal {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin: 0 1rem;
}

.cart__discountTotal {
  display: none;
}
.cart__discountTotal strong,
.cart__allPriceTotal strong {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 0rem;
}

.cart__discountTotal strong span,
.cart__allPriceTotal strong span {
  font-size: 1rem;
  margin-top: 0.1rem;
  color: var(--clr-gray500);
}

.cart__subtotal button {
  width: 100%;
  max-width: 8rem;
  font-size: 0.6rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1.25em;
}

@media (min-width: 580px) {
  .cart__subtotal button {
    max-width: 10rem;
    font-size: 0.7rem;
  }

  .cart__promoCode {
    display: -webkit-flex;
    display: flex;
  }
  .cart__price {
    display: table-cell;
  }
  .cart__priceTotal strong {
    display: -webkit-flex;
    display: flex;
  }

  .cart__removeItem {
    position: static;
    position: initial;
  }

  .cart__removeItem svg {
    margin-right: 0.5rem;
    width: auto;
    width: initial;
    height: auto;
    height: initial;
  }

  .cart__deleteIconItem {
    display: none;
  }

  .cart__detailsQtyCountContainer {
    padding: 0.25em 0.75em;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 5rem;
  }

  .cart__removeItem small {
    display: none;
  }

  .cart td {
    padding: 2em 1em;
    font-size: 0.8rem;
    border-bottom: 0.05rem solid var(--clr-gray300);
  }

  .cart th {
    display: table-cell;
    border-bottom: 0.05rem solid var(--clr-gray400);
  }
}

@media (min-width: 900px) {
  .cart {
    padding: 0 2em;
  }

  .cart__itemTitle strong {
    display: none;
  }

  .cart__discountTotal {
    display: -webkit-flex;
    display: flex;
  }

  .cart__item img {
    margin-right: 2rem;
  }
}

.paymentMethod__container {
  margin-top: 4rem;
}

.paymentMethod__method {
  position: relative;
  width: auto;
  margin-top: 1rem;
  font-size: 0.85rem;
}
.paymentMethod__data,
.paymentMethod__method .dropdown-item-container label,
.paymentMethod__method .dropdown-container {
  padding: 1em 1.75em;
}

.paymentMethod__method .dropdown-container {
  /* box-shadow: 0rem 0rem 0.5rem #0002; */
  border: 0.1rem solid var(--clr-gray300);
  border-radius: 0.7em;
  margin-bottom: 1rem;
}

.paymentMethod__data,
.paymentMethod__method .dropdown-item-container {
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
  border-radius: 0.7em;
}

body.dark-mode .paymentMethod__method .dropdown-container,
body.dark-mode .paymentMethod__data,
body.dark-mode .paymentMethod__method .dropdown-item-container {
  box-shadow: 0rem 0rem 0.5rem #0005;
  border-radius: 0.7em;
}

.paymentMethod__container h1 {
  font-size: 1rem;
}

/* .paymentMethod__container p,
.paymentMethod__method label {
  font-weight: 700;
} */

.paymentMethod__items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  margin-top: 4rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.paymentMethod__item {
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
  padding: 2em;
  border: 0.1rem solid transparent;
  transition: 0.5s;
  border-radius: 0.5em;
}
.paymentMethod__item.active {
  background: var(--clr-primary-light);
  border: 0.1rem solid var(--clr-primary);
}

.paymentMethod__item:hover {
  cursor: pointer;
  box-shadow: inset 0rem 0rem 0.5rem #0002;
}

.paymentMethod__item img {
  display: -webkit-flex;
  display: flex;
  /* width: 4rem; */
  height: 5rem;
}
/* 
.paymentMethod__container img {
  margin-right: 1rem;
} */

.paymentMethod__data {
  padding: 1.5em;
}

.paymentMethod__data p {
  font-size: 0.8rem;
  margin-top: 1rem;
}

.paymentMethod__inputContainer {
  width: 100%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  position: relative;
}

.paymentMethod__inputContainer label {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0.5rem 0;
}

.paymentMethod__data {
  margin-top: 2rem;
}

.paymentMethod__data form {
  margin-top: 1rem;
}

.paymentMethod__profileInputSections {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.paymentMethod__inputContainerCard {
  border-radius: 0.25em;
  transition: 0.3s;
  max-width: 0;
  opacity: 0;
}
.paymentMethod__inputContainerCard.show {
  max-width: 2rem;
  opacity: 1;
  border-radius: 0.25em;
}
/* card number */

.paymentMethod__inputCardNumber {
  /* margin-top: 0.5rem; */
  width: 100%;
  grid-gap: 0.5em;
  gap: 0.5em;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.paymentMethod__inputCardNumber input {
  width: 100%;
}

.paymentMethod__inputCardNumber > div:first-child {
  /* flex: 1 1 60%; */
  width: 100%;
}

.paymentMethod__inputCardNumber > div:last-child {
  margin-top: 0.5rem;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
}

.paymentMethod__inputCardNumber > div:last-child > div {
  width: 100%;
}
/* .paymentMethod__inputCardNumber > div:nth-child(2),
.paymentMethod__inputCardNumber > div:nth-child(3) {
  flex: 1 1 20%;
} */

/* card number */
.paymentMethod__cardNumberSection {
  width: 100%;
  position: relative;
}

.paymentMethod__cardNumberSection > div {
  position: absolute;
  top: 50%;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 1rem;
}

/* .paymentMethod__inputCardNumber > div:first-child input {
  margin-top: 1rem;
} */

.paymentMethod__btnContainer {
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.paymentMethod__btnContainer button {
  width: 100%;
  margin-top: 2rem;
  max-width: 10rem;
  font-size: 0.55rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1.5em 1em;
}

.paymentMethod__btnContainer button:first-child {
  margin-right: 0.5rem;
}
.paymentMethod__btnContainer.no-active {
  color: var(--clr-gray400);
  cursor: not-allowed;
}
.paymentMethod__btnContainer.primary.active {
  color: var(--clr-light);
  cursor: pointer;
}
body.dark-mode .paymentMethod__btnContainer button:first-child {
  background: var(--clr-modal);
  color: var(--clr-light);
}

.paymentMethod__btnContainer svg {
  display: -webkit-flex;
  display: flex;
  margin-right: 1rem;
  margin-bottom: 0.1rem;
}

body.dark-mode .paymentMethod__btnContainer svg path {
  stroke: var(--clr-light);
}

@media (min-width: 550px) {
  .paymentMethod__btnContainer button {
    max-width: 15rem;
    font-size: 0.7rem;
    padding: 1.25em;
  }
}

@media (min-width: 900px) {
  .paymentMethod {
    min-height: calc(100vh - 8.5rem);
    margin-bottom: -3rem;
    overflow: hidden;
    padding: 0 2em 2em 2em;
  }

  .paymentMethod__inputContainer + .paymentMethod__inputContainer {
    margin-left: 1rem;
  }

  .paymentMethod__profileInputSections {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  /* .paymentMethod__inputCardNumber > div:first-child input {
    margin-top: 0.5rem;
  } */
}

.delivery__container {
  margin-top: 4rem;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.delivery__containerInformation {
  width: 100%;
}

.delivery__containerInformation,
.delivery__shippingMethod {
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
  border-radius: 0.7em;
  padding: 1.25em 1.75em;
}
body.dark-mode .delivery__containerInformation,
body.dark-mode .delivery__shippingMethod {
  box-shadow: 0rem 0rem 0.5rem #0005;
  border-radius: 0.7em;
}

.delivery__container h3 {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 2rem;
}

body.dark-mode input:focus-within,
.delivery input:focus-within {
  border: 0.15rem solid var(--clr-primary);
}

.delivery__inputContainer {
  width: 100%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  position: relative;
}

.delivery__inputContainer label {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0.5rem 0;
}

.delivery__data form {
  margin-top: 1rem;
}

.delivery__profileInputSections {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 1rem 0;
}


.delivery__shippingMethod {
  width: 100%;
  /* flex: 1; */
  margin-top: 1rem;
}

.delivery__radio {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
  font-size: 0.8rem;
  cursor: pointer;
}

.delivery__radio label {
  width: 100%;
}

.delivery__radioText {
  width: 100%;
  cursor: pointer;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.delivery__radioText {
  color: var(--clr-gray400);
}
body.dark-mode .delivery__radioText {
  color: var(--clr-gray500);
}

.delivery__radioText strong {
  font-size: 0.9rem;
}

.delivery__radioContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.delivery__radioInput {
  visibility: hidden;
  display: none;
}

.delivery__radioInput:checked ~ .delivery__radioControl {
  border: 0.35rem solid var(--clr-primary);
}
.delivery__radioInput:checked ~ .delivery__radioText {
  color: var(--clr-darker);
}
body.dark-mode .delivery__radioInput:checked ~ .delivery__radioText {
  color: var(--clr-light);
}

.delivery__radioControl {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  cursor: pointer;
  border: 0.1rem solid var(--clr-primary);
}

.delivery__btnContainer {
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.delivery__btnContainer button {
  width: 100%;
  margin-top: 2rem;
  max-width: 10rem;
  font-size: 0.55rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1.5em 1em;
}

.delivery__btnContainer button:first-child {
  margin-right: 0.5rem;
}
body.dark-mode .delivery__btnContainer button:first-child {
  background: var(--clr-modal);
  color: var(--clr-light);
}

.delivery__btnContainer svg {
  display: -webkit-flex;
  display: flex;
  margin-right: 1rem;
  margin-bottom: 0.1rem;
}

body.dark-mode .delivery__btnContainer path {
  stroke: var(--clr-light);
}

@media (min-width: 550px) {
  .delivery__btnContainer button {
    max-width: 15rem;
    font-size: 0.7rem;
    padding: 1.25em;
  }
}
@media (min-width: 550px) {
  .delivery {
    padding: 0 2em;
  }

  .delivery__inputContainer + .delivery__inputContainer {
    margin-left: 1rem;
  }

  .delivery__profileInputSections {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .delivery input {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 900px) {
  .delivery {
    padding: 0 2em;
  }

  .delivery__inputContainer + .delivery__inputContainer {
    margin-left: 1rem;
  }

  .delivery input {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 1100px) {
  .delivery__container {
    margin-top: 4rem;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .delivery__shippingMethod {
    margin-top: 0;
    max-width: 20rem;
  }

  .delivery__inputContainer + .delivery__inputContainer {
    margin-left: 0;
  }

  .delivery__profileInputSections {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .delivery__containerInformation {
    margin-right: 2rem;
  }

  .delivery__radio {
    max-width: 25rem;
  }
}

@media (min-width: 1200px) {
  .delivery__radio {
    max-width: none;
    max-width: initial;
  }

  .delivery__inputContainer + .delivery__inputContainer {
    margin-left: 1rem;
  }

  .delivery__profileInputSections {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
}

.successfullOrder__container {
  width: 80%;
  margin: 5rem auto 0 auto;
}

.successfullOrder__container {
  text-align: center;
}

.successfullOrder__container h1 {
  font-size: 1.3rem;
  margin: 1.5rem 0;
}

.successfullOrder__container p {
  width: 100%;
  max-width: 35rem;
  font-size: 0.8rem;
  color: var(--clr-gray500);
}

.successfullOrder__container button {
  width: 100%;
  margin-top: 2rem;
  max-width: 10rem;
  font-size: 0.55rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1.5em 1em;
}

@media (min-width: 550px) {
  .successfullOrder__container button {
    font-size: 0.7rem;
    padding: 1.25em;
  }
}

@media (min-width: 900px) {
  .successfullOrder {
    padding: 0 2em;
    min-height: calc(100vh - 8.5rem);
    margin-bottom: -3rem;
    overflow: hidden
  }

  .successfullOrder__container h1 {
    font-size: 1.5rem;
    margin: 1.5rem 0;
  }

  .successfullOrder__container p {
    font-size: 0.9rem;
  }
}

.quizCard {
  padding: 1em;
  background: var(--clr-gray450);
  border-radius: 1em;
}

.quizCard a {
  color: #000;
}
body.dark-mode .quizCard a {
  color: var(--clr-light);
}

body.dark-mode .quizCard {
  background: var(--clr-modal);
}

.quizCard + .quizCard {
  margin-top: 1rem;
}

.quizCard__mainImg {
  width: 100%;
  height: 8rem;
  object-fit: cover;
  border-radius: 0.7em;
}

.quizCard__title {
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
}

.quizCard__desc {
  font-size: 0.75rem;
  margin: 0.5rem 0;
  color: var(--clr-gray400);
}

.quizCard__bottom {
  margin-top: 1rem;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.quizCard__usersImg {
  width: 1.5rem;
  object-fit: cover;
  height: 1.5rem;
}

.quizCard__action {
  font-size: 0.75rem;
  color: var(--clr-gray400);
  margin: 0 0.25rem;
  cursor: pointer;
}

.quizCard__action svg {
  display: -webkit-flex;
  display: flex;
}

.quizCard__badges {
  margin: 0.5rem 0;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.quizCard__badge {
  font-size: 0.7rem;
  padding: 0.25em 1em;
  border-radius: 3em;
  font-weight: 600;
}

.quizCard__usersImg + .quizCard__usersImg {
  margin-left: -0.5rem;
}

.quizCard__userMore,
.quizCard__userAdd {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin-left: 0.3rem;
  background: var(--clr-darker);
}

.quizCard__userMore {
  color: var(--clr-gray);
  margin-left: -0.5rem;
  font-weight: 700;
  background: var(--clr-light-gray);
  font-size: 0.6rem;
  cursor: default;
}

.quizCard__badge.yellow {
  background: var(--clr-yellow);
  color: var(--clr-light);
}
.quizCard__badge.red {
  background: var(--clr-invalid);
  color: var(--clr-light);
}
.quizCard__badge.blue {
  background: var(--clr-primary);
  color: var(--clr-light);
}
.quizCard__badge.dark-blue {
  background: var(--clr-dark-purple);
  color: var(--clr-light);
}
.quizCard__badge.green {
  background: var(--clr-success);
  color: var(--clr-light);
}
.quizCard__badge.purple {
  background: var(--clr-purple);
  color: var(--clr-light);
}

.quizHeader {
  background: var(--clr-gray450);
  padding: 0.75em 1em;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-radius: 0.75em;
  margin-bottom: 0.5rem;
}

body.dark-mode .quizHeader {
  background: var(--clr-modal);
}

.quizHeader h3 {
  font-size: 0.9rem;
  font-weight: 600;
}

.quizHeader {
  cursor: pointer;
}

.communitiesCard {
  padding: 1.5em;
  width: 100%;
  background: var(--clr-light);
  -webkit-align-items: flex-start;
          align-items: flex-start;
  border-radius: 0.5em;
  position: relative;
  z-index: 1;
  box-shadow: 0 5px 27px 0 rgb(0 0 0 / 10%);
}

.communitiesCard::after,
.communitiesCard::before {
  position: absolute;
  left: 0;
  top: 0;
}

.communitiesCard.communities-lock::after {
  content: url("https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/47002e261e3a4fc729b59877e495d7012c40e306/lock.svg");
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 1em;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 1;
  border-radius: 50%;
  margin: auto;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background: var(--clr-primary);
  right: 0;
  bottom: 0;
}

.communitiesCard.communities-lock::before {
  content: "";
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  -webkit-font-smoothing: antialiased;
  background-clip: padding-box;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
}

.communitiesCard h3 {
  color: var(--clr-text-dark-header);
}

.communitiesCard img {
  width: 100%;
  max-width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 1.5rem;
}

.communitiesCard span {
  display: block;
  font-size: 0.8rem;
  color: var(--clr-gray500);
}

.communitiesCard p {
  font-size: 0.9rem;
  margin-top: 0.75rem;
  color: var(--clr-text-dark);
}

.teacherCreateCard {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}
.teacherCreateCard__create {
  -webkit-flex: 1 1 50%;
          flex: 1 1 50%;
  /* padding-right: 2em; */
  /* background-color: var(--clr-gray300); */
}

.teacherCreateCard__create > div:first-child p {
  font-size: 0.9rem;
  margin: 0.5rem 0 1rem 0;
}

.teacherCreateCard__create form {
  width: 100%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.teacherCreateCard__create button {
  width: 100%;
  border-radius: 0.5em;
  margin-top: 0.5rem;
  font-weight: 600;
  cursor: default;
  color: var(--clr-gray400);
}
.teacherCreateCard__create button.primary {
  cursor: pointer;
}

.teacherCreateCard__create form > label {
  width: 100%;
  margin-top: 0.75rem;
  font-size: 0.9rem;
  font-weight: 500;
}

/* .teacherCreateCard__create form > label select {
      width: 100%;
  } */

.teacherCreateCard__create form > label select,
.teacherCreateCard__create form > label textarea,
.teacherCreateCard__create form > label input {
  width: 100%;
  border-radius: 0.5em;
  font-size: 0.9rem;
  padding: 0.75em 1em;
  border: 0.1rem solid var(--clr-gray300);
  background: var(--clr-modal-light);
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.teacherCreateCard__create form > label textarea {
  resize: none;
  min-height: 10rem;
}

/* dropdown */

.teacherCreateCard__dropdownSearch {
  width: 100%;
  position: relative;
}

.teacherCreateCard__dropdownSearch .dropdown-container {
  font-size: 0.9rem;
  width: 100%;
  padding: 0.75em 1em;
  border: 0.1rem solid var(--clr-gray300);
  background: var(--clr-modal-light);
  border-radius: 0.5em;
}
.teacherCreateCard__dropdownSearch .dropdown-container.expand {
  border-radius: 0.5em;
  border: none;
  border: 0.1rem solid transparent;
}

.teacherCreateCard__form.shop-page
  .teacherCreateCard__dropdownSearch
  .dropdown-container {
  display: -webkit-flex;
  display: flex;
}

.teacherCreateCard__dropdownSearch .dropdown-container p {
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 0.1rem;
  color: var(--clr-gray500);
}
.teacherCreateCard__dropdownSearch .dropdown-container p.active {
  color: var(--clr-dark);
}

.teacherCreateCard__dropdownSearchIcon {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
}
body.dark-mode .teacherCreateCard__dropdownSearch .dropdown-container p {
  color: var(--clr-light);
}
.teacherCreateCard__dropdownSearch .dropdown-item-container {
  top: calc(100% + 0.75rem);
  border-radius: 0.5em;
  z-index: 10;
  top: calc(100% + 0.5rem);
  box-shadow: 0 0 0.5rem #0002;
}
.teacherCreateCard__dropdownSearch .dropdown-item-container label {
  font-weight: 600;
  padding: 1em 1em;
  font-size: 0.8rem;
}

.teacherCreateCard__dropdownSearch .dropdown-item-container label span {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  border: 0.1rem solid var(--clr-gray500);
}
.teacherCreateCard__dropdownSearch
  .dropdown-item-container
  label
  input:checked
  ~ span {
  border: 0.35rem solid var(--clr-primary);
}

.teacherCreateCard__dropdownSearch
  .dropdown-item-container
  label.menu-active
  img {
  -webkit-filter: invert(41%) sepia(53%) saturate(7452%) hue-rotate(204deg)
    brightness(105%) contrast(100%);
          filter: invert(41%) sepia(53%) saturate(7452%) hue-rotate(204deg)
    brightness(105%) contrast(100%);
}

.teacherCreateCard__dropdownSearch
  .dropdown-container
  .teacherCreateCard__dropdownSearchArrow {
  width: 0.5rem;
  height: 0.5rem;
}

body.dark-mode .teacherCreateCard__dropdownSearchIcon {
  -webkit-filter: invert(1) sepia(100%) saturate(1%) hue-rotate(183deg) brightness(200%)
    contrast(101%);
          filter: invert(1) sepia(100%) saturate(1%) hue-rotate(183deg) brightness(200%)
    contrast(101%);
}

body.dark-mode
  .teacherCreateCard__dropdownSearch
  .dropdown-container
  .teacherCreateCard__dropdownSearchArrow
  path,
.teacherCreateCard__dropdownSearch
  .dropdown-container
  .teacherCreateCard__dropdownSearchArrow
  path {
  stroke: var(--clr-primary);
}

/* preview */
.teacherCreateCard__preview {
  -webkit-flex: 1 1 50%;
          flex: 1 1 50%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* background: red; */
  height: 100%;
  /* padding: 2em; */
  /* background: #077aff10; */
}

.teacherCreateCard__preview h2 {
  font-weight: 600;
}
.teacherCreateCard__previewCard.community {
  max-width: none;
  max-width: initial;
  width: 20rem;
}
.teacherCreateCard__previewCard {
  max-width: 15rem;
  margin: 2rem auto;
  -webkit-filter: drop-shadow(0 10px 20px rgb(0 0 0 / 10%));
          filter: drop-shadow(0 10px 20px rgb(0 0 0 / 10%));
}

.teacherCreateCard__wordLimit {
  font-size: 0.8rem;
  margin: 0.2rem 0 0.2rem auto;
}

.teacherCreateCard__twoInputs {
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 0.5rem;
}

.teacherCreateCard__twoInputs > label {
  font-size: 0.9rem;
  font-weight: 500;
}
.teacherCreateCard__twoInputs > label:first-child {
  -webkit-flex: 1 1 80%;
          flex: 1 1 80%;
}

.teacherCreateCard__twoInputs input[type="file"] {
  color: transparent;
  position: relative;
  max-width: 10rem;
  padding: 0.75em 1em;
}
.teacherCreateCard__twoInputs input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}
.teacherCreateCard__twoInputs input[type="file"]::after {
  content: "Upload Icon";
  color: var(--clr-light);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 0.5em;
  cursor: pointer;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 100%;
  left: 0;
  background: var(--clr-primary);
}

.teacherCreateCard__twoInputs > label:last-child {
  -webkit-flex: 1 1 20%;
          flex: 1 1 20%;
}

@media (min-width: 900px) {
  .teacherCreateCard {
    grid-gap: 0;
    gap: 0;
  }
}

/* table */
.teacherTableCreate__table > div:first-child {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 1em 0 0;
}
.teacherTableCreate__table > div:first-child button {
  margin: 1rem 0;
}

.teacherTableCreate__table {
  margin-top: 1rem;
  width: 100%;
  background: var(--clr-light);
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
  /* box-shadow: 0 0 1rem #00000022; */
  border-radius: 1em;
}
.teacherTableCreate__tableContainer {
  overflow-x: scroll;
}

.teacherTableCreate__table h3 {
  padding: 1em;
  font-weight: 600;
  font-size: 1.05rem;
  color: var(--clr-text-dark-header);
}

.teacherTableCreate__table table {
  width: 100%;
  border-collapse: collapse;
}
.teacherTableCreate__table th {
  text-align: left;
  padding: 1em 1.5em;
  font-size: 0.7rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--clr-text-dark);
  border-bottom: 0.1rem solid var(--clr-gray450);
  /* background: var(--clr-light-gray); */
}

.teacherTableCreate__table tr {
  cursor: pointer;
}

.teacherTableCreate__table tr:first-child th:last-child {
  border-radius: 0 0.5em 0 0;
}
.teacherTableCreate__table tr:first-child th:first-child {
  border-radius: 0.5em 0 0 0;
}
.teacherTableCreate__table tr:last-child td:last-child {
  border-radius: 0 0 0 0.5em;
}
.teacherTableCreate__table tr:last-child td:first-child {
  border-radius: 0 0 0.5em 0;
}

body.dark-mode .teacherTableCreate__table th {
  /* border-bottom: 0.1rem solid var(--clr-secondary-dark); */
  background: var(--clr-modal);
}

/* .teacherTableCreate__table tr:nth-of-type(2n + 1) {
      background: #077aff10;
    }
    body.dark-mode .teacherTableCreate__table tr:nth-of-type(2n + 1) {
      background: var(--clr-modal);
    } */

.teacherTableCreate__table td {
  padding: 1em 1.5em;
  font-size: 0.8rem;
  font-weight: 500;
  border-bottom: 0.1rem solid var(--clr-gray450);
  color: var(--clr-text-dark);
}

.teacherTableCreate__tableName h4 {
  font-size: 0.9rem;
  font-weight: 700;
}

.teacherTableCreate__tableName span {
  font-size: 0.7rem;
  font-weight: 500;
}

td.teacherTableCreate__tableType {
  font-size: 0.9rem;
}

.teacherTableCreate__tableType span {
  display: block;
  font-weight: 700;
}

.teacherTableCreate__status span {
  padding: 0.25em 0.75em;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 0.5em;
  color: var(--clr-light);
}

.teacherTableCreate__status.active span {
  background: var(--clr-success);
}
.teacherTableCreate__status.delete span {
  background: var(--clr-dark-gray);
}

.teacherTableCreate__tableContainer .teacherTableCreate__delete {
  color: var(--clr-invalid);
  font-weight: 600;
  cursor: pointer;
}

@media (min-width: 900px) {
  .teacherTableCreate__tableContainer {
    scrollbar-width: none;
  }
  .teacherTableCreate__tableContainer::-webkit-scrollbar {
    display: none;
  }
}

.quizTeacher {
  /* margin-top: -1rem; */
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  /* gap: 0.25rem; */
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.quizTeacher__btn {
  border-radius: 0.5em;
  background: transparent;
  padding: 0.75em 1em;
  margin-left: auto;
  border: 0.1rem solid var(--clr-gray300);
}

.quiz {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  /* overflow: hidden; */
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}

.quiz__content::-webkit-scrollbar {
  display: none;
}

.quiz__invite {
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #000;
  z-index: 200;
  position: fixed;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.quiz__inviteContainer {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  inset: 0;
  height: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: #f8f8f8;
  padding: 2em;
}

body.dark-mode .quiz__inviteContainer {
  background: var(--clr-dark);
  color: var(--clr-light);
}
/* body.dark-mode .quiz__inviteContainer form:focus-within {
  border: none;
} */

/* .quiz__container + .quiz__container {
  margin-left: 1rem;
} */

.quiz__toggle input[type="checkbox"] {
  display: none;
}

.quiz__toggle {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* justify-content: center; */
  position: relative;
  /* background-color: var(--clr-light); */
  background-color: var(--clr-focus-shadow);
  border: 0.2rem solid #077aff17;
  /* border: 0.2rem solid var(--clr-primary); */
  --height: 2.8rem;
  height: var(--height);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: calc(var(--height) / 2);
  margin-top: 0.5rem;
  cursor: pointer;
  /* margin: 0 auto; */
}

body.dark-mode .quiz__toggle {
  background: var(--clr-darker);
  border: 0.2rem solid var(--clr-secondary-dark);
}

.quiz__toggle :is(.quiz__toggleName, .quiz__toggleLink) {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
  /* width: 128px; */
  padding: 1em 1.25em;
  font-size: 0.7rem;
  font-weight: 600;
  /* background: red; */
  z-index: 2;
  transition: color 400ms ease;
}
.quiz__toggle .quiz__toggleName {
  color: var(--clr-primary);
  /* margin-left: -0.4rem; */
}
.quiz__toggle .quiz__toggleLink {
  padding-right: 1.5em;
  padding-left: 1em;
  color: var(--clr-text-gray);
}
.quiz__toggle input ~ div {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  height: 100%;
  width: 7.2rem;
  border-radius: 22px;
  padding: 1em;
  background-color: var(--clr-primary-light);
  z-index: 1;
  transition: all 400ms ease;
}

body.dark-mode .quiz__toggle input ~ div {
  background: var(--clr-modal);
}
.quiz__toggle input:checked ~ div {
  left: 100%;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}
/* .quiz__toggle input:checked ~ div {
  width: 7.2rem;
} */
.quiz__toggle input:checked ~ .quiz__toggleName {
  color: var(--clr-text-gray);
}
.quiz__toggle input:checked ~ .quiz__toggleLink {
  color: var(--clr-primary);
}

.quiz__inviteContainer h3 {
  font-size: 1.2rem;
}
.quiz__inviteContainerText {
  display: block;
  font-size: 0.8rem;
  line-height: 1.8;
  margin: 0.75rem 0 1.5rem 0;
}

.quiz__inviteContainerAction {
  margin-top: 1.5rem;
}

/* .quiz__inviteContainerAction form:focus-within {
  box-shadow: 0 0 0.5rem #077aff30;
  border: 0.1rem solid var(--clr-primary);
} */

.quiz__inviteContainerAction {
  font-size: 0.8rem;
}

.quiz__inviteContainerAction form {
  background: var(--clr-light);
  padding: 0.25em 0.75em;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 0.75rem;
  box-shadow: 0 0 0.5rem #077aff30;
  border: 0.1rem solid var(--clr-primary);
  border-radius: 3em;
  /* border: 0.1rem solid var(--clr-light); */
  position: relative;
}

body.dark-mode .quiz__inviteContainerAction form {
  background: var(--clr-modal);
}

.quiz__inviteContainerAction button {
  -webkit-flex: 0.5 1;
          flex: 0.5 1;
  font-weight: 600;
  font-size: 0.65rem;
  color: var(--clr-gray500);
}

.quiz__inviteContainerAction input {
  -webkit-flex: 2 1;
          flex: 2 1;
  /* background: red; */
  /* height: 100%; */
  padding: 1.25em 1em;
}

body.dark-mode .quiz__inviteContainerAction input {
  color: var(--clr-light);
}

.quiz__inviteSuccess {
  position: absolute;
  padding: 0.75em 2em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-justify-content: center;
          justify-content: center;
  background: var(--clr-light);
  font-size: 0.8rem;
  border-radius: 3em;
  font-weight: 700;
  left: 50%;
  bottom: -5rem;
  opacity: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

body.dark-mode .quiz__inviteSuccess {
  background: var(--clr-modal);
}

.quiz__inviteSuccess.sent {
  bottom: 1rem;
  transition: bottom 0.3s, opacity 0.2s 0.2s;
  opacity: 1;
}

.quiz__inviteSuccessIcon {
  padding: 0.5em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 0.5rem;
  /* background: var(--clr-success); */
  background: #4bec00;
}

.quiz__inviteSuccessIcon svg {
  width: 0.9rem;
  display: -webkit-flex;
  display: flex;
  height: 0.9rem;
}

.quiz__inviteContainerPerson {
  position: absolute;
  top: calc(100% + 1rem);
  width: calc(100% - 11rem);
  left: 0;
  background: var(--clr-light);
  max-height: 12rem;
  overflow-y: scroll;
  border-radius: 1em;
  scrollbar-width: none;
}

body.dark-mode .quiz__inviteContainerPerson {
  background: var(--clr-modal);
}
.quiz__inviteContainerPerson::-webkit-scrollbar {
  display: none;
}

/* .quiz__inviteContainerPerson li:first-child {
  border-radius: 1em 1em 0 0;
}
.quiz__inviteContainerPerson li:last-child {
  border-radius: 0 0 1em 1em;
} */
.quiz__inviteContainerPerson li {
  color: var(--clr-text-dark);
  padding: 1em 1.5em;
}

body.dark-mode .quiz__inviteContainerPerson li {
  color: var(--clr-light);
}
body.dark-mode .quiz__inviteContainerPerson li:hover {
  background: var(--clr-modal-darker);
}
.quiz__inviteContainerPerson li:hover {
  background: var(--clr-light-gray);
  cursor: pointer;
}

.quiz__inviteContainerPerson li img {
  max-width: 2.5rem;
  margin-right: 1rem;
}
.quiz__inviteContainerPerson li p {
  font-size: 0.9rem;
  font-weight: 600;
}

.quiz__inviteContainerPerson li p span {
  color: var(--clr-gray500);
}

.quiz__invited {
  margin-top: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

/* .quiz__invited {
  display: grid;
  flex: 2;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  gap: 0.5rem;
}

.quiz__invited li:last-child {
  background: transparent;
  padding: 0;
} */

.quiz__invited li {
  background: #efefef;
  padding: 0.5rem 1em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 3em;
  font-weight: 700;
}

.quiz__invited li svg {
  margin-left: 1rem;
  cursor: pointer;
}
.quiz__invited li svg path {
  stroke: var(--clr-gray500);
}

@media (min-width: 425px) {
  .quiz__inviteContainerAction button {
    font-size: 0.8rem;
  }
  .quiz__toggle :is(.quiz__toggleName, .quiz__toggleLink) {
    font-size: 0.8rem;
  }

  .quiz__toggle input ~ div {
    width: 8.2rem;
  }

  /* .quiz__toggle input:checked ~ div {
    width: 8.2rem;
  } */
}

@media (min-width: 528px) {
  .quiz__content {
    height: calc(100vh - 6rem);
    /* height: 100%; */
    scrollbar-width: none;
    overflow-y: scroll;
  }
}

@media (min-width: 768px) {
  .quiz__inviteContainer {
    padding: 1.8em 2em;
  }
}

@media (min-width: 900px) {
  .quiz {
    padding: 1em;
    height: calc(100vh - 5.5rem);
    overflow: hidden;
    margin-bottom: -4rem;
  }

  .quiz__content::after {
    content: "asdasdasda";
    font-size: 3.5rem;
    visibility: hidden;
    pointer-events: none;
    /* height: 10rem; */
  }
}

@media (min-width: 1200px) {
  .quiz__invite {
    background: #000000b3;
  }

  body.dark-mode .quiz__inviteContainer {
    box-shadow: 0 0 1rem #0007;
  }

  .quiz__inviteContainer {
    position: relative;
    border-radius: 1em;
    max-width: 50rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    left: 50%;
    top: 50%;
    padding: 5em 4em;
    -webkit-animation: fadein 0.7s;
            animation: fadein 0.7s;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

.connections__initial {
  margin-top: 2rem;
}

.connections__initial img {
  max-width: 20rem;
  margin-bottom: -4rem;
}

.connections__initial h1 {
  margin-top: 1.5rem;
  text-align: center;
  line-height: 1.5;
  color: var(--clr-text-dark-header);
}

.connections__initial h1 span {
  display: block;
  font-size: 2.25rem;
  margin-bottom: .5rem;
}

.connections__initial p {
  max-width: 30rem;
  text-align: center;
  margin-top: 1rem;
}

.connections__cardContainer {
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
}

.connections__table {
  margin-top: 1rem;
  width: 100%;
  background: var(--clr-light);
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
  /* box-shadow: 0 0 1rem #00000022; */
  padding-right: 1;
  border-radius: 1em;
}
.connections__tableContainer {
  overflow-x: scroll;
}

.connections__table h3 {
  padding: 1em;
  font-weight: 600;
  font-size: 1.05rem;
  color: var(--clr-text-dark-header);
}

.connections table {
  width: 100%;
  border-collapse: collapse;
}
.connections th {
  text-align: left;
  padding: 1em 1.5em;
  font-size: 0.7rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--clr-text-dark);
  border-bottom: 0.1rem solid var(--clr-gray450);
  /* background: var(--clr-light-gray); */
}

.connections tr:first-child th:last-child {
  border-radius: 0 0.5em 0 0;
}
.connections tr:first-child th:first-child {
  border-radius: 0.5em 0 0 0;
}
.connections tr:last-child td:last-child {
  border-radius: 0 0 0 0.5em;
}
.connections tr:last-child td:first-child {
  border-radius: 0 0 0.5em 0;
}

body.dark-mode .connections th {
  /* border-bottom: 0.1rem solid var(--clr-secondary-dark); */
  background: var(--clr-modal);
}

/* .connections tr:nth-of-type(2n + 1) {
  background: #077aff10;
}
body.dark-mode .connections tr:nth-of-type(2n + 1) {
  background: var(--clr-modal);
} */

.connections td {
  padding: 1em 1.5em;
  font-size: 0.8rem;
  font-weight: 500;
  border-bottom: 0.1rem solid var(--clr-gray450);
  color: var(--clr-text-dark);
}

.connections__connection {
  cursor: pointer;
}

.connections__connection img {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  margin-right: 1rem;
}

.connections__connection h4 {
  font-size: 0.9rem;
  font-weight: 700;
}

.connections__connection span {
  font-size: 0.7rem;
  font-weight: 500;
}

td.connections__identity {
  font-size: 0.7rem;
}

.connections__identity span {
  display: block;
  font-weight: 700;
}

.connections__status span {
  padding: 0.25em 0.75em;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 0.5em;
  color: var(--clr-light);
}

.connections__status.online span {
  background: var(--clr-success);
}
.connections__status.offline span {
  background: var(--clr-dark-gray);
}

td.connections__function {
  font-size: 0.7rem;
  /* padding: 0; */
  cursor: pointer;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.connections__function > div,
.connections__function a {
  position: relative;
  top: -0.5rem;
}

.connections__function a:hover::after {
  content: "Message";
}

.connections__function > div:nth-child(2):hover::after {
  content: "Gift";
}
.connections__function > div:last-child:hover::after {
  content: "Remove";
}
.connections__function > div::after,
.connections__function a::after {
  position: absolute;
  color: var(--clr-light);
  padding: 0.5em 0.75em;
  font-size: 0.75em;
  background-color: var(--clr-dark-background);
  box-shadow: 0 0 0.5rem #0002;
  top: -1.6rem;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  border-radius: 0.5em;
}

.connections__function > div:nth-child(2):hover::before,
.connections__function > div:last-child:hover::before,
.connections__function a:hover::before {
  content: "";
  position: absolute;
  border-left: 0.3rem solid transparent;
  left: 50%;
  top: -0.4rem;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  border-right: 0.3rem solid transparent;
  border-top: 0.35rem solid var(--clr-dark-background);
}

.connections__statusCircle {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.5rem;
  margin-bottom: 0.2rem;
}

.connections__statusCircle.Ongoing {
  background: var(--clr-yellow);
}
.connections__statusCircle.Completed {
  background: var(--clr-success);
}
.connections__statusCircle.On {
  background: var(--clr-primary);
}
.connections__statusCircle.Delayed {
  background: #fb6240;
}

.connections__completionProgress {
  position: relative;
  width: 9rem;
  margin-left: 0.5rem;
  height: 0.3rem;
  border-radius: 3em;
  background: #b9c1cc;
}
body.dark-mode .connections__completionProgress {
  background: #373737;
}

.connections__projects img {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.connections__completionProgress::after {
  content: "";
  position: absolute;
  height: 100%;
}

@media (min-width: 900px) {
  .connections__tableContainer {
    scrollbar-width: none;
  }
  .connections__tableContainer::-webkit-scrollbar {
    display: none;
  }
}

.badges {
  width: 100%;
  margin: 2rem 0;
  border: 0.1rem solid var(--clr-primary);
  /* background: var(--clr-primary); */
  /* background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  ); */
  color: var(--clr-light);
  padding: 1.5em;
  border-radius: 1em;
}

.badges__header {
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.badges__header h3 {
  color: var(--clr-dark);
}

.badges__header img {
  opacity: 0.5;
  -webkit-filter: invert(1);
          filter: invert(1);
  width: 100%;
  max-width: 1.5rem;
}

.badges__container {
  display: grid;
  place-items: flex-start;
  margin-top: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
}

.badges__badge {
  cursor: pointer;
}

.badges__badge img:first-child {
  width: 100%;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  /* margin-bottom: 1rem; */
}

.badge__details {
  position: fixed;
  -webkit-justify-content: center;
          justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  pointer-events: none;
  opacity: 0;
  background: #000b;
}

.badge__details.show {
  pointer-events: all;
  opacity: 1;
}

.badge__detailsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: 0.25s;
  /* overflow-y: scroll; */
  padding: 2em;
  background: var(--clr-light);
  box-shadow: 0rem 0rem 1rem #0002;
  border-radius: 0;
}

.badge__details.show .badge__detailsContainer {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.badge__detailsBadgeText {
  display: block;
  margin-top: 2rem;
  text-transform: uppercase;
  color: var(--clr-dark);
  font-weight: 800;
  font-size: 0.6rem;
  letter-spacing: 0.2rem;
}

.badge__detailsContainer h4 {
  color: var(--clr-primary);
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  margin-top: 0.25rem;
  font-size: 1.1rem;
}

.badge__detailsContainer p {
  color: var(--clr-text-dark);
  text-align: center;
  margin: 1.75rem 0;
  max-width: 22rem;
  font-size: 0.85rem;
}

.badge__detailsContainer > div:first-of-type {
  background: var(--clr-primary);
  font-size: 0.7rem;
  border-radius: 3em;
  padding: 0.75em 0.5em;
}

.badge__detailsContainer > div:first-of-type span {
  padding: 0 0.5em;
  text-transform: uppercase;
  font-weight: 600;
}

.badge__detailsContainer > div:first-of-type span:first-child {
  border-right: 0.1rem solid var(--clr-light);
}

.badges__more {
  display: grid;
  place-items: center;
  font-size: 2rem;
  font-weight: 600;
  background: var(--clr-light-gray);
  color: var(--clr-gray);
  width: 100%;
  max-width: 6rem;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  height: 6rem;
}

.badge__socialLinksContainer h4 {
  font-size: 0.7rem;
  margin-bottom: 0.25rem;
  margin-top: 1rem;
  color: var(--clr-dark);
  text-transform: none;
}

.badge__socialLinks li + li {
  margin-left: 0.45rem;
}

.badge__socialLinksContainer .badge__socialLinks svg {
  width: 2rem;
  cursor: pointer;
  fill: var(--clr-dark);
}

.badge__detailsContainer .cross-mark {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

/* .badge__socialLinks  */

@media (min-width: 1200px) {
  .badge__detailsContainer {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    max-width: 20rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 1em;

    box-shadow: 0 0.5rem 1rem #0007;
    /* overflow-y: hidden; */
  }

  .badge__detailsBadgeText {
    margin-top: 3rem;
  }

  .badge__detailsContainer img {
    position: absolute;
    top: -40%;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    /* box-shadow: 0 0.25rem 0.5rem #0004; */
    -webkit-filter: drop-shadow(0 0.25rem 0.5rem #0003);
            filter: drop-shadow(0 0.25rem 0.5rem #0003);
  }

  .badge__socialLinksContainer {
    position: absolute;
    top: calc(100% + 1rem);
  }

  .badge__socialLinksContainer h4 {
    color: var(--clr-light);
    margin-top: 0;
  }

  .badge__socialLinksContainer .badge__socialLinks svg {
    width: 2rem;
    cursor: pointer;
    fill: var(--clr-light);
  }

  .badge__detailsContainer .cross-mark {
    position: absolute;
    bottom: calc(100% + 2.5rem);
    right: 0;
    top: unset;
  }

  .badge__detailsContainer .cross-mark::after,
  .badge__detailsContainer .cross-mark::before {
    background: var(--clr-light);
  }
}

.missionCard {
  position: relative;
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 10%);
  border-radius: 0.75em;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: default;
  padding: 1.75em;
}

.missionCard.clicked {
  cursor: pointer;
}

.missionCard__emblem {
  width: 100%;
  max-width: 5.5rem;
}
.missionCard__emblem.main-emblem {
  max-width: 10rem;
}

.missionCard__miniEmblem {
  position: absolute;
  width: 100%;
  top: 1rem;
  max-width: 2rem;
  left: 1.5rem;
}

.missionCard__progress {
  width: 100%;
  position: relative;
  border-radius: 3rem;
  height: 0.25rem;
  margin: 1rem 0;
  background: var(--clr-gray);
}

.missionCard__exp {
  position: absolute;
  right: -1rem;
  top: 1rem;
  border-radius: 3em;
  background: var(--clr-light);
  padding: 0.5em 1em;
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 10%);
  font-size: 0.7em;
  font-weight: 700;
}

.missionCard__exp svg {
  margin: 0 0.2rem 0.1rem 0;
}

/* .missionCard__emblem {
  margin-bottom: 1rem;
} */

.missionCard__progress.unlocked::after {
  content: "";
  position: absolute;
  width: var(--mission-progress-persent);
  height: inherit;
  border-radius: inherit;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    to left,
    #86ddff,
    #57caff,
    #3b9aff,
    #288cfd,
    #077aff
  );
}

.missionCard__title {
  margin: 1rem 0;
}

.missionCard__desc {
  font-size: 0.8rem;
  max-width: 15rem;
  text-align: center;
}

.missionCard__lock {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
}

.missionCard__badges {
  margin-top: 1rem;
  display: -webkit-flex;
  display: flex;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.missionCard__badges img {
  width: 1.25rem;
}

.mission__allCategory,
.mission__mainEmblem {
  display: grid;
  margin-top: 2rem;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

.mission__title {
  margin: 4rem 0 2rem 0;
  font-size: 1.5rem;
}

.mission__allCategory {
  margin-top: 1rem;
}

.overview {
  margin-top: 2rem;
  overflow-x: hidden;
}

.overview__hiring {
  padding: 2em;
  background: var(--clr-primary);
  border-radius: 0.75em;
  margin-top: 1rem;
  color: var(--clr-light);
  position: relative;
  overflow: hidden;
}

.overview__hiring > div {
  max-width: 35rem;
}

.overview__hiring img {
  position: absolute;
  width: 100%;
  max-width: 25rem;
  right: 0;
  top: 0;
}

.overview__hiring > div > span {
  display: inline-block;
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
  color: #fffa;
}

.overview__hiringBtnContainer {
  margin-top: 1.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.overview__hiringBtnContainer button {
  border-radius: 0.75em;
}

.overview__hiringBtnContainer button:first-child {
  background: var(--clr-light);
  font-weight: 600;
}

.overview__hiringBtnContainer button:last-child {
  background: #0002;
  color: var(--clr-light);
}

.overviewSlider {
  width: 95%;
  position: relative;
  max-width: 800px;
  margin: 4rem auto;
  background: var(--clr-light);
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 25px;
  border-radius: 25px;
  height: 400px;
  transition: all 0.3s;
}

.overviewSlider__code {
  color: var(--clr-text-dark) !important;
}

.swiper-container {
  overflow: unset !important;
}

.overviewSlider__item.swiper-slide-next {
  visibility: hidden;
}

@media screen and (max-width: 992px) {
  .overviewSlider {
    max-width: 680px;
    height: 400px;
  }
}

@media screen and (max-width: 768px) {
  .overviewSlider {
    min-height: 500px;
    height: auto;
    margin: 180px auto;
  }
}

@media screen and (max-height: 500px) and (min-width: 992px) {
  .overviewSlider {
    height: 350px;
  }
}
.overviewSlider__item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

@media screen and (max-width: 768px) {
  .overviewSlider__item {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
.overviewSlider__item.swiper-slide-active .overviewSlider__img img {
  opacity: 1;
  transition-delay: 0.3s;
}
.overviewSlider__item.swiper-slide-active .overviewSlider__content > * {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(1) {
  transition-delay: 0.3s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(2) {
  transition-delay: 0.4s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(3) {
  transition-delay: 0.5s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(4) {
  transition-delay: 0.6s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(5) {
  transition-delay: 0.7s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(6) {
  transition-delay: 0.8s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(7) {
  transition-delay: 0.9s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(8) {
  transition-delay: 1s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(9) {
  transition-delay: 1.1s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(10) {
  transition-delay: 1.2s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(11) {
  transition-delay: 1.3s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(12) {
  transition-delay: 1.4s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(13) {
  transition-delay: 1.5s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(14) {
  transition-delay: 1.6s;
}
.overviewSlider__item.swiper-slide-active
  .overviewSlider__content
  > *:nth-child(15) {
  transition-delay: 1.7s;
}
.overviewSlider__img {
  width: 300px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  height: 300px;
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  box-shadow: 4px 13px 30px 1px #077aff30;
  border-radius: 20px;
  -webkit-transform: translateX(-80px);
          transform: translateX(-80px);
  overflow: hidden;
}
.overviewSlider__img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  border-radius: 20px;
  opacity: 0.8;
}
.overviewSlider__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  opacity: 0;
  border-radius: 20px;
  transition: all 0.3s;
}
@media screen and (max-width: 768px) {
  .overviewSlider__img {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 90%;
  }
}
@media screen and (max-width: 576px) {
  .overviewSlider__img {
    width: 95%;
  }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
  .overviewSlider__img {
    height: 270px;
  }
}
.overviewSlider__content {
  padding-right: 25px;
}
@media screen and (max-width: 768px) {
  .overviewSlider__content {
    margin-top: -80px;
    text-align: center;
    padding: 0 30px;
  }
}
@media screen and (max-width: 576px) {
  .overviewSlider__content {
    padding: 0;
  }
}
.overviewSlider__content > * {
  opacity: 0;
  -webkit-transform: translateY(25px);
          transform: translateY(25px);
  transition: all 0.4s;
}
.overviewSlider__code {
  color: #7b7992;
  margin-bottom: 15px;
  display: block;
  font-weight: 500;
}
.overviewSlider__title {
  font-size: 24px;
  font-weight: 700;
  color: #0d0925;
  margin-bottom: 20px;
}
.overviewSlider__text {
  color: #4e4a67;
  margin-bottom: 30px;
  line-height: 1.5em;
}
.overviewSlider__button {
  display: -webkit-inline-flex;
  display: inline-flex;
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  padding: 15px 35px;
  border-radius: 50px;
  color: #fff;
  box-shadow: 0px 14px 80px #077aff30;
  text-decoration: none;
  font-weight: 500;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  letter-spacing: 1px;
}
@media screen and (max-width: 576px) {
  .overviewSlider__button {
    width: 100%;
  }
}
.overviewSlider .swiper-container-horizontal > .swiper-pagination-bullets,
.overviewSlider .swiper-pagination-custom,
.overviewSlider .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.overviewSlider__pagination {
  position: absolute;
  z-index: 21;
  right: 20px;
  width: 11px !important;
  text-align: center;
  left: auto !important;
  top: 50%;
  bottom: auto !important;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
@media screen and (max-width: 768px) {
  .overviewSlider__pagination {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 50% !important;
    top: 205px;
    width: 100% !important;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
}
.overviewSlider__pagination.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 8px 0;
}
@media screen and (max-width: 768px) {
  .overviewSlider__pagination.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 5px;
  }
}
.overviewSlider__pagination .swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  display: block;
  border-radius: 10px;
  background: #062744;
  opacity: 0.2;
  transition: all 0.3s;
}
.overviewSlider__pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--clr-primary);
  height: 30px;
  box-shadow: 0px 0px 20px #077aff30;
}
@media screen and (max-width: 768px) {
  .overviewSlider__pagination .swiper-pagination-bullet-active {
    height: 11px;
    width: 30px;
  }
}

.profileProjects {
  margin-top: 2rem;
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 10%);
  background: var(--clr-light);
  border-radius: 1em;
}

.profileProjects .categories {
  border-radius: 1em 1em 0 0;
}

.profileProjects__container {
  padding: 1.5em;
  width: 100%;
}
.profileProjects__container.genius-projects {
  padding: 0 1.5em 1.5em;
}

.profileProjects h3 {
  font-size: 1.05rem;
  font-weight: 600;
}

.profileProjects h4 {
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.profileProjects__items {
  width: 100%;
  display: grid;
  margin-top: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

.profileProjects__imgContainer.video-show {
  width: 100%;
  margin: 0 2rem;
}

.profileProjects__items iframe,
.profileProjects__imgThumbnail {
  display: block;
  width: 100%;
  height: 10rem;
  border-radius: 0.75em;
  object-fit: cover;
}

.profileProjects__items button {
  background: transparent;
  padding: 0.5em 1.25em;
  text-transform: uppercase;
  color: var(--clr-primary);
  font-weight: 600;
  border-radius: 0.75em;
  width: 100%;
  margin-top: 1rem;
  border: 0.1rem solid var(--clr-primary);
}

.profileProjects__category {
  display: block;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  color: var(--clr-gray500);
}

.profileProjects__items {
  display: grid;
  height: 100%;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}

.profileProjects__item {
  min-height: 20rem;
  position: relative;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.profileProjects__ranking {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 11;
}

.profileProjects__ranking img {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
}

.profileProjects__item h4 {
  font-size: 1.2rem;
  font-weight: 600;
}

.profileProjects__item p {
  font-size: 0.85rem;
  margin: 0.5rem 0;
}
.profileProjects__item span {
  display: block;
  color: var(--clr-gray500);
  font-size: 0.9rem;
  margin: 0.5rem 0 0.25rem 0;
}

.profileProjects__item ~ .profileProjects__create {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  color: var(--clr-gray500);
  border: 0.1rem solid #0002;
  border-radius: 1em;
  cursor: pointer;
  font-weight: 600;
}

.profileProjects__item ~ .profileProjects__create svg {
  margin-bottom: 0.5rem;
}

/* videos premium */
.profileProjects__imgContainer {
  position: relative;
  border-radius: 0.75em;
  overflow: hidden;
  margin-bottom: 0.5rem;
  z-index: 1;
}

.profileProjects__imgContainer.premium-video::after {
  content: url("https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/47002e261e3a4fc729b59877e495d7012c40e306/lock.svg");
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 1em;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 1;
  border-radius: 50%;
  margin: auto;
  z-index: 11;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background: var(--clr-primary);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.profileProjects__imgContainer.premium-video::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  border: 2px solid transparent;
  -webkit-font-smoothing: antialiased;
  background-clip: padding-box;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  left: 0;
  top: 0;
}

/* contributors */
.profileProjects__contributors {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  bottom: 0rem;
  right: 0.5rem;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.profileProjects__contributors img {
  width: 100%;
  box-shadow: 0 0.5rem 1rem #0005;
  max-width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  z-index: 200;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.profileProjects__darkBg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0005;
  left: 0;
  top: -0.5rem;
}

.viewProjects {
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000b;
  z-index: 200;
}

.viewProjects__close {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  height: 25vh;
  opacity: 0;
  z-index: 90;
}
.viewProjects__close.bot {
  top: auto;
  top: initial;
  bottom: 0;
}

.viewProjects iframe::before {
  content: "asdasd";
  position: absolute;
}

.viewProjects iframe,
.viewProjects img {
  display: block;
  width: 100%;
  position: relative;
  object-fit: cover;
  background: linear-gradient(to left, #56a8ff, #077aff);
  background-size: 300% 300%;
  -webkit-animation: gradient 3s ease infinite;
          animation: gradient 3s ease infinite;
  box-shadow: 0 0 2rem #000e;
  /* margin: 0 2rem; */
  border-radius: 0.5em;
  height: 50vh;
}

.profileProjects__imgContainer.video-show {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.BrainhubCarouselItem.BrainhubCarouselItem--active iframe,
.BrainhubCarouselItem.BrainhubCarouselItem--active
  .profileProjects__imgContainer.video-show {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
  padding: 10em 0;
}

.BrainhubCarousel__arrows {
  position: absolute;
  background: transparent;
  cursor: pointer;
  top: 50%;
  left: 24%;
  z-index: 100;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.BrainhubCarousel__arrows:hover:enabled,
.BrainhubCarousel__arrows:hover {
  background: transparent;
}
.BrainhubCarousel__arrows:last-of-type {
  left: 76% !important;
}

.BrainhubCarousel__dots {
  position: absolute;
  top: 83%;
  z-index: 100;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.BrainhubCarousel__dots .BrainhubCarousel__dot {
  padding: 0.25rem;
  opacity: 0.5;
}

.BrainhubCarousel__dots
  .BrainhubCarousel__dot.BrainhubCarousel__dot--selected::before {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
  background: var(--clr-primary);
}

.BrainhubCarousel__dots .BrainhubCarousel__dot::before {
  background: var(--clr-light);
  width: 0.5rem;
  height: 0.5rem;
}

.profileContent {
  width: 100%;
  margin-top: -1rem;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
/* 
.profileContent.missions {
  margin-top: 4rem;
} */

.profileContent__about {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 1.5em;
  gap: 1.5em;
}

.profileContent__profile ul li > svg {
  display: -webkit-flex;
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
}

.profileContent__profile ul li.message-tab-profile > svg {
  stroke: var(--clr-dark);
  fill: transparent;
  width: 1.3rem;
  height: 1.3rem;
}

.profileContent__img {
  position: relative;
  width: 100%;
  border-radius: 1em;
  overflow: hidden;
}

.profileContent__img:hover .profileContent__imgOverlay {
  visibility: visible;
  pointer-events: all;
  cursor: pointer;
  opacity: 1;
}

.profileContent__img:hover .profileContent__imgOverlay.hidden {
  visibility: hidden;
  pointer-events: none;
  cursor: default;
}

.profileContent__img img {
  display: block;
  width: 100%;
  max-height: 20rem;
  border-radius: 1em;
  object-fit: cover;
}

.profileContent__profile {
  width: 95%;
  margin: 0 auto;
  padding: 1.15em;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* align-items: flex-start; */
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: -3rem;
  position: relative;
  z-index: 5;
  border-radius: 1em;
  background: var(--clr-light);
  box-shadow: 0 0.5rem 1.25rem #0002;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.profileContent__profileMainImg {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 5rem;
  height: 5rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 1em;
}

.profileContent__profileImg {
  width: 100%;
  object-fit: cover;
}

.profileContent__profileMainImg:hover .profileContent__imgOverlay {
  visibility: visible;
  pointer-events: all;
  cursor: pointer;
  opacity: 1;
}
.profileContent__profileMainImg:hover .profileContent__imgOverlay.hidden {
  visibility: hidden;
  pointer-events: none;
  cursor: default;
}

.profileContent__imgOverlay {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  background: #000a;
  height: 100%;
  transition: 0.25s;
}

.profileContent__imgOverlayImgInput {
  width: 100%;
  height: 20rem;
  top: 0;
  left: 0;
  position: absolute;
  cursor: pointer;
  z-index: 100;
  color: transparent;
  background: transparent;
}

.profileContent__imgOverlay svg {
  fill: var(--clr-primary);
}

.profileContent__imgOverlayImgInput {
  visibility: hidden;
}

.profileContent__imgOverlayImgInput::-webkit-file-upload-button {
  visibility: hidden;
}

.profileContent__imgOverlayImgInput::-moz-file-upload-button {
  visibility: hidden;
}

.profileContent__profileDetails {
  /* margin-left: 1rem; */
  margin: 1rem 0;
}

.profileContent__profileDetails h3 {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: -0.2rem;
  text-align: center;
}

.profileContent__profile > div:first-of-type {
  -webkit-flex-direction: column;
          flex-direction: column;
}

.profileContent__profileDetails h3 svg {
  width: 1.1rem;
  margin-bottom: -0.1rem;
  margin-left: 0.2rem;
}

.profileContent__profileDetails span {
  font-size: 0.8rem;
  font-weight: 400;
  cursor: pointer;
  text-align: center;
  margin-top: 0.3rem;

  /* color: var(--clr-primary); */
}

.profileContent__profileDetails span:hover svg {
  display: -webkit-flex;
  display: flex;
}

.profileContent__profileDetails span svg {
  display: none;
  width: 1.2rem;
  margin-right: 0.2rem;
}

.profileContent__profile ul {
  font-size: 0.9rem;
}

.profileContent__profile ul li {
  padding: 0.5em 1em;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.3s ease-out;
}
.profileContent__profile ul li p {
  display: none;
  margin-left: 0.3rem;
  font-size: 0.8rem;
}
.profileContent__profile ul a {
  color: #000;
}

.profileContent__profile ul li svg {
  fill: var(--clr-dark);
}
.profileContent__profile ul li.active {
  border-radius: 0.5em;
  background: var(--clr-primary);
  color: var(--clr-light);
  box-shadow: 0 0.1rem 0.4rem #077bff71;
}
.profileContent__profile ul li.followed {
  border-radius: 0.5em;
  background: var(--clr-gray300);
  color: var(--clr-gray400);
  box-shadow: 0 0.1rem 0.4rem #0003;
}

.profileContent__profile ul li.active svg {
  fill: var(--clr-light);
}

.profileContent__profile ul li.followed svg {
  fill: var(--clr-gray400);
}

.profileContent__profile ul li.active.message-tab-profile svg {
  fill: transparent;
  stroke: var(--clr-light);
}
.profileContent__profile ul li.active.message-tab-profile svg path {
  stroke: var(--clr-light);
}

.profileContent__badges {
  position: absolute;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  right: 1rem;
  top: 1rem;
}

.profileContent__badges img {
  max-width: 2rem;
}

.profileContent__teacherHashtag {
  position: absolute;
  color: var(--clr-light);
  font-weight: 800;
  font-size: 1rem;
  /* font-size: 2rem; */
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: auto;
}

@media (min-width: 475px) {
  .profileContent__profile ul li p {
    display: inline;
    margin-left: 0.3rem;
  }

  .profileContent__teacherHashtag {
    font-size: 2rem;
  }
}

@media (min-width: 600px) {
  .profileContent__profile {
    margin-top: -5rem;
  }
}

@media (min-width: 900px) {
  .profileContent {
    margin-top: 1rem;
    /* height: calc(100vh - 8.5rem); */
    /* overflow: hidden; */
    /* margin-bottom: -3rem; */
  }
}

@media (min-width: 1200px) {
  .profileContent__profile {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .profileContent__profile > div:first-of-type {
    width: 100%;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .profileContent__profileDetails {
    margin: 0;
    margin-left: 1rem;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
}


.communities {
  padding-top: 2em;
}

.communities__container {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}

@media (min-width: 900px) {
  .communities__container {
    margin-top: 3rem;
  }
}

.search__card {
  padding: 1.5em;
  border-radius: 0.7em;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background: var(--clr-light);
  /* overflow-x: hidden; */
  cursor: pointer;
  grid-gap: 2rem;
  gap: 2rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  /* flex: 1 1 50%; */
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
}

.search__card h3 svg {
  display: -webkit-flex;
  display: flex;
  width: 1.2rem;
  margin-left: 0.5rem;
  margin-bottom: 0.2rem;
}

.search__card > div:first-child {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  height: 100%;
  width: 100%;
}

.search__card + .search__card {
  margin-top: 2rem;
}

.search__cardImgProfile {
  margin-right: 1rem;
}

.search__cardImgProfile img {
  display: -webkit-flex;
  display: flex;
  width: 4.5rem;
  object-fit: cover;
  height: 4.5rem;
  border: 0.4rem solid var(--clr-light);
}

.search__cardDetail {
  width: 100%;
  /* align-items: flex-start; */
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.search__cardDetailProfile {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
  padding-top: 0.5em;
  /* width: 100%; */
  /* overflow-x: scroll; */
}

.search__cardDetailProfile span {
  display: block;
  margin-top: 0.2rem;
  font-size: 0.9rem;
}

.search__cardDetailProfile button svg {
  width: 1.2rem;
  height: 1.1rem;
  fill: var(--clr-light);
  margin-right: 0.5rem;
}

.search__cardDetailProfile button {
  display: -webkit-flex;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  padding: 0.75em 1.5em;
  border-radius: 0.5em;
  margin: 1rem 0;
}

.search__cardProfileProject img {
  width: 100%;
  border-radius: 0.5em;
}

.search__cardProfileProject {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

.search__cardBtnFollowed {
  background: transparent;
  border: 0.1rem solid var(--clr-secondary-dark);
}

.search__cardDetail .search__cardBtnFollowed svg {
  fill: var(--clr-secondary-dark);
}
.search__cardUserBadges {
  width: 100%;
  max-width: 1.5rem;
  object-fit: contain;
  margin-left: 0.5rem;
}

@media (min-width: 475px) {
  .search__cardDetailProfile {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .search__cardDetailProfile button {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .search__card {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .search__card > div:first-child {
    max-width: 20rem;
  }

  .search__cardDetailProfile {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .search__cardDetailProfile button {
    margin: 1rem 0;
  }
}

/* .search__container {
  margin-top: 4rem;
} */

.search__shopCategory,
.search__communitiesCategory,
.search__videosCategory {
  display: grid;
  place-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.search__shopCategory,
.search__videosCategory {
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

.search__communitiesCategory {
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}

.referRewards h1 {
  text-align: center;
  margin: 8rem 0 5rem 0;
}

.referRewards__map {
  /* background: red; */
  margin-top: 2rem;
  /* padding: 0 2em; */
}

.referRewards__map li {
  font-weight: 600;
  background: var(--clr-light);
}

.referRewards__map > div.one-component,
.referRewards__map > div {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  position: relative;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.referRewards__map > div::before,
.referRewards__map > div::after {
  content: "";
  position: absolute;
  -webkit-filter: invert(33%) sepia(25%) saturate(6038%) hue-rotate(201deg)
    brightness(102%) contrast(101%);
          filter: invert(33%) sepia(25%) saturate(6038%) hue-rotate(201deg)
    brightness(102%) contrast(101%);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  z-index: -1;
}

.referRewards__map > div:first-of-type::after {
  width: 100%;
  left: 0;
  right: 0;
  /* height: 0.1rem; */
  height: 0.15rem;
  top: 2.5rem;
  /* border: 0.2rem dotted #077aff40; */

  /* background: #077aff40; */
}

.referRewards__map > div:first-of-type li:first-child {
  margin-left: -2.5rem;
}
.referRewards__map > div:first-of-type li:last-child {
  margin-right: 7.5rem;
}

.referRewards__imgContainer svg {
  width: 3rem;
  display: block;
  fill: var(--clr-primary);
}

.referRewards__imgContainer {
  padding: 1em;
  margin-bottom: 0.5rem;
  background: var(--clr-primary-light);
  cursor: pointer;
  position: relative;
  /* border: 0.15rem solid var(--clr-primary); */
}

.referRewards__map li.locked .referRewards__imgContainer::after {
  content: url("https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/47002e261e3a4fc729b59877e495d7012c40e306/lock.svg");
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 1em;
  line-height: 1;
  border-radius: 50%;
  margin: auto;
  z-index: 10;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background: var(--clr-primary);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.referRewards__map > div:nth-of-type(4),
.referRewards__map > div:nth-of-type(2) {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  /* margin-bottom: 10rem; */
}
.referRewards__map > div:nth-of-type(4) li,
.referRewards__map > div:nth-of-type(3) li,
.referRewards__map > div:nth-of-type(2) li {
  margin: 10rem -2.5rem 0;
}

/* .referRewards__map > div:nth-of-type(2) li:first-of-type {
  margin-left: 15rem;
} */

.referRewards__map > div:nth-of-type(4)::before,
.referRewards__map > div:nth-of-type(2)::before {
  height: 100%;
  width: 0.1rem;
  top: -4rem;
  right: 0;
}

.referRewards__map > div:nth-of-type(3)::after,
.referRewards__map > div:nth-of-type(4)::after,
.referRewards__map > div:nth-of-type(2)::after {
  width: 100%;
  left: 0;
  right: 0;
  height: 0.15rem;
  bottom: 4rem;
}

.referRewards__map > div:nth-of-type(3)::before {
  left: 0;
  height: 100%;
  /* max-height: 12rem; */
  right: unset;
  width: 0.1rem;
  bottom: 4.5rem;
}

.referRewards__map > div:nth-of-type(3) li:first-child {
  margin-left: 7.5rem;
}
.referRewards__map > div:nth-of-type(4) li:first-of-type,
.referRewards__map > div:nth-of-type(3) li:last-child {
  margin-right: 7.5rem;
}

/* arrow */

.referRewards__arrow svg {
  width: 2rem;
  fill: var(--clr-primary);
}

.referRewards__map > div:first-of-type .referRewards__arrow:first-of-type {
  position: absolute;
  left: 40%;
  top: 50%;
  -webkit-transform: translate(-40%, -80%);
          transform: translate(-40%, -80%);
}
.referRewards__map > div:first-of-type .referRewards__arrow:last-of-type {
  position: absolute;
  right: -0.75rem;
  top: calc(100% + 2rem);
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.referRewards__map > div:nth-of-type(4) .referRewards__arrow:nth-of-type(2),
.referRewards__map > div:nth-of-type(2) .referRewards__arrow:last-of-type,
.referRewards__map > div:nth-of-type(2) .referRewards__arrow:first-of-type {
  position: absolute;
  left: 75%;
  bottom: 3rem;
  -webkit-transform: translateX(-75%) rotate(-180deg);
          transform: translateX(-75%) rotate(-180deg);
}

.referRewards__map > div:nth-of-type(4) .referRewards__arrow:nth-of-type(3),
.referRewards__map > div:nth-of-type(2) .referRewards__arrow:last-of-type {
  position: absolute;
  left: 25%;
  bottom: 3rem;
  -webkit-transform: translateX(-25%) rotate(-180deg);
          transform: translateX(-25%) rotate(-180deg);
}

.referRewards__map > div:nth-of-type(3) .referRewards__arrow:first-of-type {
  position: absolute;
  left: -2.5rem;
  /* top: calc(100% + 2rem); */
  bottom: 55%;
  -webkit-transform: rotate(90deg) translateY(-50%);
          transform: rotate(90deg) translateY(-50%);
}
.referRewards__map > div:nth-of-type(3) .referRewards__arrow:last-of-type {
  position: absolute;
  left: 60%;
  bottom: 2.5rem;
  -webkit-transform: translateX(-60%);
          transform: translateX(-60%);
}
.referRewards__map > div:nth-of-type(4) .referRewards__arrow:first-of-type {
  position: absolute;
  right: -0.75rem;
  top: 25%;
  /* top: calc(100% + 2rem); */
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.referRewards__map > div:nth-of-type(4) .referRewards__arrow:nth-of-type(2) {
  left: 40%;
}

.refer__cta {
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  grid-gap: 5rem;
  gap: 5rem;
  width: 100%;
  padding: 2em;
  min-height: 18rem;
  border-radius: 1em;
  z-index: 1;
  background: var(--clr-primary);
  overflow: hidden;
}

.refer__ctaBackgroundImg {
  width: 100%;
  right: -13rem;
  position: absolute;
  z-index: -1;
}

.refer__ctaImg {
  width: 100%;
  max-width: 11rem;
}

.refer h1 {
  font-size: 2.2rem;
  line-height: 1.3;
}

.refer__ctaAction {
  width: 100%;
  max-width: 22rem;
}

.refer__ctaAction h1 {
  color: var(--clr-light);
  margin-bottom: 1.5rem;
}

.refer__ctaAction form {
  background: var(--clr-light);
  height: 3rem;
  overflow: hidden;
  border-radius: 0.25em;
}

.refer__ctaAction form input {
  -webkit-flex: 1 1 70%;
          flex: 1 1 70%;
  padding: 0 0.75em;
}
.refer__ctaAction form button {
  -webkit-flex: 1 1 30%;
          flex: 1 1 30%;
  height: 100%;
  font-size: 0.8rem;
  padding: 0;
  background: var(--clr-dark-background);
  color: var(--clr-light);
  border-radius: 0;
  width: -webkit-max-content;
  width: max-content;
}

/* how it works */
.refer__works {
  margin: 5rem 0 3rem 0;
}

.refer__works ul {
  /* display: flex; */
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
  /* flex-wrap: wrap; */
  margin-top: 3.5rem;
  z-index: 1;
}

.refer__works ul li {
  /* flex: 1 1 25%; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  max-width: 15rem;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.refer__works ul li > div {
  padding: 1.5em;
  border-radius: 50%;
  /* background: #c1deff; */
  background: var(--clr-primary-light);
}
.refer__works ul li > div svg {
  display: -webkit-flex;
  display: flex;
  width: 1.8rem;
  fill: var(--clr-primary);
}

.refer__works ul li h3 {
  text-align: center;
  margin: 1rem 0 0.25rem 0;
}
.refer__works ul li span {
  text-align: center;
  font-size: 0.9rem;
  max-width: 14rem;
}

@media (min-width: 600px) {
  .refer__works ul::after {
    content: "";
    position: absolute;
    width: 80%;
    left: 0;
    right: 0;
    margin: 0 auto;
    /* height: 0.1rem; */
    height: 0.1rem;
    top: 2.5rem;
    z-index: -1;
    /* border: 0.2rem dotted #077aff40; */
    -webkit-filter: invert(96%) sepia(31%) saturate(2187%) hue-rotate(174deg)
      brightness(99%) contrast(104%);
            filter: invert(96%) sepia(31%) saturate(2187%) hue-rotate(174deg)
      brightness(99%) contrast(104%);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    /* background: #077aff40; */
  }
}

.track {
  /* justify-content: center;
  height: calc(100vh - 8.5rem); */
  margin-top: 1rem;
}
.track ul {
  /* display: flex; */
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 2rem;
  /* flex-wrap: wrap; */
  z-index: 1;
}

.track ul li {
  /* flex: 1 1 25%; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  max-width: 15rem;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.track ul li.completed > div {
  background: var(--clr-primary);
}

.track ul li.completed > div svg {
  fill: var(--clr-light);
}

.track ul li > div {
  padding: 1.5em;
  border-radius: 50%;
  /* background: #c1deff; */
  background: var(--clr-primary-light);
}
.track ul li > div svg {
  display: -webkit-flex;
  display: flex;
  width: 1.8rem;
  fill: var(--clr-primary);
}
.track ul li:last-child > div svg {
  fill: none;
  stroke: var(--clr-primary);
}

.track ul li h3 {
  text-align: center;
  margin: 1rem 0 0.25rem 0;
}
.track ul li span {
  text-align: center;
  font-size: 0.9rem;
}
.track__desc {
  display: block;
  text-align: center;
  margin: 1rem 0;
  font-size: 0.9rem;
  color: var(--clr-text-dark);
  max-width: 45rem;
}

/* dropdown */

.track__dropdownSearch {
  width: 100%;
  max-width: 15rem;
  margin: 1.5rem 0;
}

.track__dropdownSearch .dropdown-container {
  font-size: 0.8rem;
  width: 100%;
  padding: 1em 1em;
  border: 0.1rem solid #0005;
  border-radius: .5em;
}
.track__dropdownSearch .dropdown-container.expand {
  border-radius: 1em;
  border: none;
  border: 0.1rem solid transparent;
}

.track__form.shop-page .track__dropdownSearch .dropdown-container {
  display: -webkit-flex;
  display: flex;
}

.track__dropdownSearch .dropdown-container p {
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 0.1rem;
  color: var(--clr-text-dark);
}

.track__dropdownSearchIcon {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
}
body.dark-mode .track__dropdownSearch .dropdown-container p {
  color: var(--clr-light);
}
.track__dropdownSearch .dropdown-item-container {
  top: calc(100% + 0.75rem);
  border-radius: 0.5em;
  z-index: 10;
  box-shadow: 0 0 0.5rem #0002;
}
.track__dropdownSearch .dropdown-item-container label {
  font-weight: 600;
  padding: 1em 1em;
  font-size: 0.8rem;
}

.track__dropdownSearch .dropdown-item-container label.menu-active {
  color: var(--clr-primary);
  position: relative;
}
.track__dropdownSearch .dropdown-item-container label.menu-active::after {
  content: "";
  position: absolute;
  height: 90%;
  left: 0;
  width: 0.15rem;
  background: var(--clr-primary);
}

.track__dropdownSearch .dropdown-item-container label span {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  border: 0.1rem solid var(--clr-gray500);
}
.track__dropdownSearch .dropdown-item-container label input:checked ~ span {
  border: 0.35rem solid var(--clr-primary);
}

.track__dropdownSearch .dropdown-item-container label.menu-active img {
  -webkit-filter: invert(41%) sepia(53%) saturate(7452%) hue-rotate(204deg)
    brightness(105%) contrast(100%);
          filter: invert(41%) sepia(53%) saturate(7452%) hue-rotate(204deg)
    brightness(105%) contrast(100%);
}

.track__dropdownSearch .dropdown-container .track__dropdownSearchArrow {
  width: 0.5rem;
  height: 0.5rem;
}

body.dark-mode .track__dropdownSearchIcon {
  -webkit-filter: invert(1) sepia(100%) saturate(1%) hue-rotate(183deg) brightness(200%)
    contrast(101%);
          filter: invert(1) sepia(100%) saturate(1%) hue-rotate(183deg) brightness(200%)
    contrast(101%);
}

body.dark-mode
  .track__dropdownSearch
  .dropdown-container
  .track__dropdownSearchArrow
  path,
.track__dropdownSearch .dropdown-container .track__dropdownSearchArrow path {
  stroke: var(--clr-primary);
}
.track__dropdownSearch {
  display: inline;
  display: initial;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: relative;
  font-size: 1rem;
}

@media (min-width: 600px) {
  .track ul::after {
    content: "";
    position: absolute;
    width: 80%;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 0.1rem;
    top: 2.5rem;
    z-index: -2;
    background: var(--clr-primary-light);
  }

  .track ul::before {
    content: "";
    width: 25%;
    position: absolute;
    height: 0.1rem;
    left: 5.5rem;
    z-index: -1;
    top: 2.5rem;
    background: var(--clr-primary);
  }
}

/* .contactSupport {
  margin-top: 5rem;
} */

.contactSupport h1 {
  width: 100%;
  text-align: center;
  max-width: 35rem;
  margin-top: 1rem;
  color: var(--clr-text-dark-header);
}

.contactSupport__header h3 {
  font-size: 1.6rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
  color: var(--clr-text-dark);
}

.contactSupport__card span {
  font-size: 0.8rem;
}

.contactSupport__card {
  background: var(--clr-light);
  /* background: var(--clr-gray300); */
  /* box-shadow: 0 5rem 3rem -4rem #077aff20; */
  /* box-shadow: 0 2.5rem 3rem -1rem #077aff30; */
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  border: 0.1rem solid var(--clr-gray300);
  padding: 2em;
  min-height: 25rem;
  text-align: center;
  border-radius: 1em;
  transition: 0.5s;
}

.contactSupport__card:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  /* box-shadow: 0 3.5rem 4rem -2rem #077aff40; */
  box-shadow: 0 3.5rem 4rem -2rem #0003;
}

.contactSupport a,
.contactSupport__header span {
  text-align: center;
  color: var(--clr-text-dark);
  margin-top: 0.5rem;
  line-height: 1.8;
  font-size: 0.9rem;
}

.contactSupport__card h4 {
  text-align: center;
  color: var(--clr-text-dark-header);
}

.contactSupport__ .contactSupport__card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.contactSupport__container {
  place-items: center;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
}

.contactSupport__image img {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  height: 8rem;
  margin: 0 auto;
  object-fit: cover;
}
.contactSupport__header {
  max-width: 30rem;
}

.contactSupport__container span {
  font-size: 0.9rem;
  color: var(--clr-text-dark);
  text-align: center;
  line-height: 2;
}

.contactSupport__container h4 {
  font-size: 1rem;
  margin: 1.25rem;
}
/* 
  @media (min-width: 960px) {
    
      .contactSupport__card:last-child {
        grid-column: 2 / 2;
      }
    } */

@media (min-width: 768px) {
  .contactSupport__header h3 {
    font-size: 2rem;
  }
}

@media (min-width: 900px) {
  .contactSupport {
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: -5rem;
    height: calc(100vh - 5rem);
    /* overflow: hidden; */
  }
}
/* 
  @media (min-width: 1280px) {
      .contactSupport__card:last-child {
        grid-column: initial;
      }
    }
    
    @media (min-width: 1290px) {
      .contactSupport__card:last-child {
        grid-column: initial;
      }
    } */

.imagesPage {
  width: calc(100% + 4rem);
  height: calc(100vh - 4rem);
  overflow: hidden;
  margin: -1rem 0 -5rem -2rem;
}

/* https://api.unsplash.com/search/photos?query=office&client_id=Fwa70W-29DEtqss22FEqDEVVJUxsVdFvWMlVFTYtTic */

.imagesPage__hero {
  width: 100%;
  height: 100%;
  /* max-height: 30rem; */
  position: relative;
  z-index: 1;
}

.imagesPage__hero img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  /* filter: blur(.2rem); */
  max-height: inherit;
}

.imagesPage__main,
.imagesPage__hero::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.imagesPage__hero::after {
  content: "";
  background: #0007;
}

.imagesPage__main {
  width: 100%;
  max-width: 50rem;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  color: var(--clr-light);
  z-index: 1;
}

.imagesPage__main h1 {
  font-size: 2.5rem;
}

.imagesPage__main p {
  margin: 0.5rem 0 0.5rem 0;
}

.imagesPage__main form {
  width: 100%;
  background: var(--clr-light);
  padding: 0.75em 0.5em;
  margin-top: 0.5rem;
  border-radius: 0.5em;
}

.imagesPage__main form input {
  width: 100%;
}

.imagesPage__attribute {
  width: 100%;
  position: absolute;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--clr-gray500);
  padding: 0.5em 1em;
  font-size: 0.75rem;
  z-index: 1;
}

.imagesPage__attribute a {
  color: var(--clr-gray400);
}

.gift__card {
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  padding: 2.5em;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
  margin-bottom: 2rem;
  position: relative;
  border-radius: 0.5em;
}

.gift h1 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.gift__card > div:first-child p {
  font-size: 0.9rem;
  margin-top: 0.3rem;
}
.gift__card > div:first-child {
  max-width: 50%;
}

.gift__card:last-child::after {
  content: "Most Popular";
  position: absolute;
  color: var(--clr-light);
  top: 0;
  left: 0;
  width: 15rem;
  text-align: center;
  padding: 0.25em 1em;
  font-size: 0.8rem;
  border-radius: 0 0 0.5em 0;
  background: var(--clr-primary);
}

.gift__radio {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  font-size: 0.8rem;
  margin-left: auto;
  cursor: pointer;
}

.gift__radio label {
  width: 100%;
}

.gift__radioText {
  width: 100%;
  cursor: pointer;
  /* justify-content: space-between; */
}

.gift__radioText {
  font-size: 0.9rem;
  color: var(--clr-gray400);
}
.gift__radioText strong {
  font-size: 1rem;
}

body.dark-mode .gift__radioText {
  color: var(--clr-gray500);
}

.gift__radioContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.gift__radioInput {
  visibility: hidden;
  display: none;
}

.gift__radioInput:checked ~ .gift__radioControl {
  border: 0.35rem solid var(--clr-primary);
}
.gift__radioInput:checked ~ .gift__radioText {
  color: var(--clr-darker);
}
body.dark-mode .gift__radioInput:checked ~ .gift__radioText {
  color: var(--clr-light);
}

.gift__radioControl {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  cursor: pointer;
  border: 0.1rem solid var(--clr-primary);
}

.gift__term {
  font-size: 0.8rem;
  max-width: 35rem;
  text-align: center;
  margin-bottom: 1rem;
}

.gift button {
  width: 15rem;
  border-radius: 0.75em;
}

.report h1 {
  margin-bottom: 1rem;
}

.avatarIntialUser {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  max-width: 10rem;
  max-height: 10rem;
  font-size: 5rem;
  /* border-radius: 50%; */
  text-transform: uppercase;
  background: #001219;
}

.avatarIntialUser.rounded {
  border-radius: 50%;
}

.avatarIntialUser.rounded-3 {
  border-radius: 1em;
}

.popupUserInfo {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  background: #000b;
}

.popupUserInfo__container {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  overflow-y: scroll;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background: var(--clr-light);
  box-shadow: 0rem 0rem 1rem #0002;
  border-radius: 0;
}

/* popup user info content */

.popupUserInfo__leftSection {
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  padding: 2.5em;
  background: var(--clr-primary);
  color: var(--clr-light);
  -webkit-flex: 1 1 40%;
          flex: 1 1 40%;
  width: 100%;
  min-width: 18rem;
  height: 100%;
}

.popupUserInfo__leftSection img {
  width: 100%;
  max-width: 12rem;
}

.popupUserInfo__leftSectionText {
  margin: 3rem 0;
}

.popupUserInfo__leftSectionText span {
  display: block;
  max-width: 15rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  font-weight: 300;
}

.popupUserInfo__btnArrow {
  background: var(--clr-light);
  display: block;
  width: 3rem;
  display: grid;
  height: 3rem;
  place-items: center;
  cursor: pointer;
}

.popupUserInfo__btnArrow svg {
  width: 0.75rem;
  fill: var(--clr-primary);
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.popupUserInfo__rightSection {
  padding: 3em 1.5em;
  /* padding: 3em 3.5em; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex: 1 1 60%;
          flex: 1 1 60%;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  font-size: 0.9rem;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.popupUserInfo__container.parental-consent .popupUserInfo__rightSection {
  padding: 3em 1.5em;
  /* padding: 3em 6em; */
}
.popupUserInfo__container.choose-topic .popupUserInfo__rightSection {
  padding: 2em;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.popupUserInfo__rightSection > div {
  -webkit-flex-direction: column;
          flex-direction: column;
  /* flex-wrap: wrap; */
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.popupUserInfo__rightSection > div > label {
  -webkit-flex: 1 1 30%;
          flex: 1 1 30%;
}
.popupUserInfo__rightSection > div > input {
  -webkit-flex: 1 1 70%;
          flex: 1 1 70%;
}

/* topics */

.popupUserInfo__chooseTopics {
  position: relative;
}

.popupUserInfo__chooseTopics .settingTopics__btnContainer {
  bottom: -1.75rem;
}

/* language */

.popupUserInfo__dropdown {
  min-height: 3.5rem;
  cursor: pointer;
}

.popupUserInfo__selectedLanguages {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.popupUserInfo__selectedLanguage {
  padding: 0.25em 0.5em;
  border-radius: 3em;
  background: var(--clr-gray300);
}

.popupUserInfo__dropdownLists {
  position: absolute;
  background: var(--clr-light);
  width: 100%;
  max-height: 10rem;
  pointer-events: none;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  visibility: hidden;
  border: 0.15rem solid var(--clr-primary);
  border-top: none;
  transition: -webkit-transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 0 0 1em 1em;
  z-index: 10;
  -webkit-transform-origin: top;
          transform-origin: top;
  scrollbar-track-color: transparent;
  scrollbar-width: thin;
}
/* .popupUserInfo__identity .popupUserInfo__dropdownLists {
  top: -5.75rem;
  transform-origin: top;
} */

/* .popupUserInfo__identity .popupUserInfo__dropdownLists {
  top: -9.5rem;
  border-radius: 1em 1em 0 0;
  border: 0.15rem solid var(--clr-primary);
  border-bottom: none;
  transform-origin: bottom;
} */

.popupUserInfo__dropdownLists.show {
  pointer-events: all;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  visibility: visible;
}

.popupUserInfo__countryLists::-webkit-scrollbar,
.popupUserInfo__dropdownLists::-webkit-scrollbar {
  width: 0.5rem;
  cursor: pointer;
}

.popupUserInfo__countryLists::-webkit-scrollbar-track,
.popupUserInfo__dropdownLists::-webkit-scrollbar-track {
  background-color: transparent;
}

.popupUserInfo__countryLists::-webkit-scrollbar-thumb,
.popupUserInfo__dropdownLists::-webkit-scrollbar-thumb {
  background: var(--clr-primary);
  border-radius: 3rem;
}

.popupUserInfo__dropdownLists li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding: 1em;
  cursor: pointer;
  position: relative;
  z-index: -1;
}
.popupUserInfo__dropdownLists label {
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}
.popupUserInfo__dropdownLists li input[type="checkbox"] {
  display: none;
  cursor: pointer;
}
.popupUserInfo__dropdownLists li input[type="checkbox"]:checked ~ label span {
  border: 0.1rem solid var(--clr-primary);
  background: var(--clr-primary);
}
.popupUserInfo__dropdownLists li input[type="checkbox"]:checked ~ label {
  color: var(--clr-primary);
  font-weight: 700;
}

.popupUserInfo__dropdownLists span {
  display: block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  border-radius: 0.25em;
  position: relative;
  border: 0.1rem solid var(--clr-gray);
}

.popupUserInfo__dropdownLists
  li
  input[type="checkbox"]:checked
  ~ label
  span::after {
  content: url("data:image/svg+xml,%3Csvg width='11' height='10' viewBox='0 0 33 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 12L12 21.5L30.5 3' stroke='white' stroke-width='7' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  position: absolute;
  top: -0.25rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

/* country */
.popupUserInfo__countryLists {
  max-height: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  border-radius: 1em;
  top: 3.5rem;
  pointer-events: none;
  background: var(--clr-light);
  box-shadow: 0 0.5rem 1.25rem #0002;
  width: 100%;
  transition: height 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* transition: max-height 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  z-index: 5;
  scrollbar-width: none;
}

.popupUserInfo__countryLists.show {
  max-height: calc(100% + 2.5rem);
  pointer-events: all;
}

.popupUserInfo__countryLists li {
  padding: 1em;
  cursor: pointer;
}

.popupUserInfo__languages li:hover,
.popupUserInfo__countryLists li:hover {
  background: #0001;
}

/* .popupUserInfo__languages li:focus-within::after {
  content: "";
  width: 100%; 
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  background: var(--clr-focus-shadow);
} */

/* .popupUserInfo__rightSection input[type="text"] {
  width: 100%;
  padding: 1em 1.25em;
  border: 0.1rem solid var(--clr-gray300);
  border-radius: 0.5em;
  color: var(--clr-darker);
  background: var(--clr-modal-light);
} */

/* identity & gender*/
.popupUserInfo__gender .input__label,
.popupUserInfo__identity .input__label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.popupUserInfo__gender .input__label img,
.popupUserInfo__identity .input__label img {
  margin-right: 0.5rem;
  width: 0.9rem;
  -webkit-filter: invert(83%) sepia(17%) saturate(221%) hue-rotate(169deg)
    brightness(89%) contrast(91%);
          filter: invert(83%) sepia(17%) saturate(221%) hue-rotate(169deg)
    brightness(89%) contrast(91%);
}

.popupUserInfo__gender.open-dropdown .input__label img,
.popupUserInfo__identity.open-dropdown .input__label img {
  -webkit-filter: invert(42%) sepia(98%) saturate(4685%) hue-rotate(200deg)
    brightness(101%) contrast(102%);
          filter: invert(42%) sepia(98%) saturate(4685%) hue-rotate(200deg)
    brightness(101%) contrast(102%);
}

.popupUserInfo__gender .popupUserInfo__dropdown,
.popupUserInfo__identity .popupUserInfo__dropdown {
  text-transform: capitalize;
}

.popupUserInfo__gender.popupUserInfo__dropdown.error ~ .input__label img,
.popupUserInfo__identity .popupUserInfo__dropdown.error ~ .input__label img {
  -webkit-filter: invert(22%) sepia(39%) saturate(6019%) hue-rotate(344deg)
    brightness(122%) contrast(84%);
          filter: invert(22%) sepia(39%) saturate(6019%) hue-rotate(344deg)
    brightness(122%) contrast(84%);
}

/* /////// */

.popupUserInfo__rightSection input[type="radio"] {
  display: none;
}
.popupUserInfo__rightSection
  input[type="radio"]:checked
  ~ .popupUserInfo__rightSectionIconContainer {
  background: var(--clr-primary);
}
.popupUserInfo__rightSection
  input[type="radio"]:checked
  ~ .popupUserInfo__rightSectionIconContainer
  img {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.popupUserInfo__rightSectionIdentity > div:first-of-type > div:first-of-type,
.popupUserInfo__rightSectionGender > div:first-of-type > div:first-of-type {
  grid-gap: 1rem;
  gap: 1rem;
  -webkit-flex: 1 1 70%;
          flex: 1 1 70%;
}

.popupUserInfo__rightSectionIdentity.error > div:first-of-type > label,
.popupUserInfo__rightSectionGender.error > div:first-of-type > label {
  color: var(--clr-invalid);
  font-weight: 500;
}

.popupUserInfo__rightSectionIdentity > div:first-of-type > label,
.popupUserInfo__rightSectionGender > div:first-of-type > label {
  -webkit-flex: 1 1 30%;
          flex: 1 1 30%;
}

.popupUserInfo__rightSectionIdentity
  > div:first-of-type
  > div:first-of-type
  > label,
.popupUserInfo__rightSectionGender
  > div:first-of-type
  > div:first-of-type
  > label {
  cursor: pointer;
}

.popupUserInfo__rightSectionIconContainer {
  background: var(--clr-modal-light);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 50%;
  margin-right: 0.5rem;
  width: 100%;
  aspect-ratio: 1 / 1;
  max-width: 2.5rem;
  height: 2.5rem;
}

.popupUserInfo__rightSectionIconContainer img {
  display: block;
  width: 1.25rem;
  -webkit-filter: invert(83%) sepia(17%) saturate(221%) hue-rotate(169deg)
    brightness(89%) contrast(91%);
          filter: invert(83%) sepia(17%) saturate(221%) hue-rotate(169deg)
    brightness(89%) contrast(91%);
  aspect-ratio: 1 / 1;
  margin-right: 0;
}

/* .popupUserInfo__rightSectionIconContainer.gender-female img {
  margin-left: -.5rem;
} */

.popupUserInfo__rightSectionParentalConsent {
  font-size: 1.1rem;
}

.popupUserInfo__rightSectionIdentity > div {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.popupUserInfo__rightSectionIdentity > div > label {
  -webkit-flex: 1 1 33%;
          flex: 1 1 33%;
}

.popupUserInfo__nextBtnMobile {
  width: 100%;
  font-size: 1rem;
  padding: 0.75em 1em;
  margin-top: 1.5rem;
  border-radius: 0.75em;
}

@media (min-width: 720px) {
  .popupUserInfo__btnArrow svg {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  .popupUserInfo__rightSectionIdentity > div {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .popupUserInfo__container,
  .popupUserInfo__rightSection {
    scrollbar-width: none;
  }
  .popupUserInfo__container::-webkit-scrollbar,
  .popupUserInfo__rightSection::-webkit-scrollbar {
    display: none;
  }

  .popupUserInfo__nextBtnMobile {
    display: none;
  }
}

@media (min-width: 768px) {
  .popupUserInfo__rightSection {
    padding: 3em 3.5em;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .popupUserInfo__container.parental-consent .popupUserInfo__rightSection {
    padding: 3em 6em;
  }

  .popupUserInfo__rightSection > div {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
  }
}

@media (min-width: 1200px) {
  .popupUserInfo__container {
    position: absolute;
    width: 60rem;
    /* width: fit-content; */
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    height: 90%;
    border-radius: 1em;
    -webkit-animation: fadein 0.7s;
            animation: fadein 0.7s;
    /* overflow-y: hidden; */
  }

  .popupUserInfo__container.parental-consent {
    -webkit-animation: fadeIn2 0.7s;
            animation: fadeIn2 0.7s;
  }
}

@-webkit-keyframes fadeIn2 {
  0% {
    left: 50%;
    top: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
            transform: translate(-50%, -50%) scale(0.5);
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes fadeIn2 {
  0% {
    left: 50%;
    top: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
            transform: translate(-50%, -50%) scale(0.5);
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
}

.earnMoney__offer {
  display: -webkit-flex;
  display: flex;
  padding: 1em;
  border-radius: 1.25em;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-bottom: 3rem;
  box-shadow: 0 5px 27px 0 rgb(0 0 0 / 10%);
}

.earnMoney__offer img {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 0.75em;
  max-width: 5rem;
}

.earnMoney__offerText {
  max-width: 35rem;
  font-size: 0.8rem;
}

.earnMoney__offerText h3 {
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

.earnMoney__offer button {
  margin-left: auto;
  margin-right: 1rem;
  font-size: 0.8rem;
  padding: 0.75em 1em;
  border-radius: 0.5em;
}

.earnMoney__card {
  background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  );
  -webkit-flex: 1 1 40%;
          flex: 1 1 40%;
  width: 100%;
  border-radius: 0.75em;
  padding: 1em;
  max-width: 22rem;
  height: 13rem;
  color: var(--clr-light);
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.earnMoney__cardHeader {
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.earnMoney__cardHeader strong {
  font-size: 1.15rem;
}

.earnMoney__cardInput {
  width: 100%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.earnMoney__card label {
  font-size: 0.75rem;
  margin-bottom: 0.1rem;
}

.earnMoney input::-webkit-input-placeholder {
  color: #fffa;
}

.earnMoney input:-ms-input-placeholder {
  color: #fffa;
}

.earnMoney input::placeholder {
  color: #fffa;
}

.earnMoney__cardInput input {
  color: var(--clr-light);
  font-size: 1.25rem;
  letter-spacing: 0.15rem;
  font-weight: 600;
  width: 100%;
}

.earnMoney__cardDetails {
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.earnMoney__cardDetails > div {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.earnMoney__cardDetails input {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--clr-light);
}

/* exp */
.earnMoney__cardDetails > div:last-of-type {
  max-width: 4rem;
}

.earnMoney__options {
  width: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  /* justify-content: center; */
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.earnMoney__cardPage {
  padding: 2em;
  width: 100%;
  max-width: 13rem;
  height: 13rem;
  -webkit-flex: 1 1 20%;
          flex: 1 1 20%;
  border: 0.1rem solid var(--clr-gray300);
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 0.75em;
  cursor: pointer;
  transition: 0.25s;
}

.earnMoney__cardPage:hover {
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
}

.earnMoney__cardPage img {
  width: 100%;
  max-width: 4rem;
}

.earnMoney__cardPage {
  text-align: center;
}

.earnMoney__tasksContainer {
  margin-top: 2rem;
}

.earnMoney__tasks {
  display: grid;
  margin-top: 1rem;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

.earnMoney__task {
  width: 100%;
  padding: 0.75em;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border: 0.1rem solid var(--clr-gray300);
  border-radius: 0.75em;
}

.earnMoney__taskScore {
  width: 5rem;
  height: 5rem;
  border-radius: 0.5em;
  -webkit-justify-content: center;
          justify-content: center;
  line-height: 1.4;
}

.earnMoney__taskScore.A {
  color: var(--clr-primary);
  background: var(--clr-blue200);
}
.earnMoney__taskScore.B {
  color: var(--clr-success);
  background: var(--clr-green200);
}
.earnMoney__taskScore.C {
  color: var(--clr-yellow);
  background: var(--clr-yellow200);
}
.earnMoney__taskScore.D {
  color: var(--clr-invalid);
  background: var(--clr-red200);
}
.earnMoney__taskScore.E {
  color: var(--clr-dark);
  background: #0002;
}

.earnMoney__taskScore span:first-child {
  font-size: 1.5rem;
  font-weight: 800;
}
.earnMoney__taskScore span:last-child {
  font-size: 0.9rem;
  font-weight: 600;
}

.earnMoney__taskDesc {
  font-size: 0.9rem;
}

@media (min-width: 1200px) {
  .earnMoney__options {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
}

.searchImages h1 {
  margin-bottom: 1rem;
  text-transform: capitalize;
}

.searchImages__container {
  display: -webkit-flex;
  display: flex;
  position: relative;
  z-index: 1;
}

.searchImages__container img {
  width: 100%;
  position: -1;
}

.searchImages__overlay {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  background: #000a;
  z-index: 10;
  cursor: pointer;
  transition: 0.25s;
}

.searchImages__container:hover .searchImages__overlay {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.searchImages__overlay a:first-child {
  background: var(--clr-light);
  width: 4rem;
  height: 4rem;
  opacity: 1;
  transition: 0.5s;
}
.searchImages__overlay a:first-child:hover {
  opacity: 0.75;
}

.searchImages__overlay a svg {
  width: 1.5rem;
}

.searchImages__overlay a:last-child {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.5em 1em;
}
.searchImages__overlay a:last-child img {
  width: 2.2rem;
  margin-right: 0.5rem;
}

.searchImages__overlay a:last-child {
  color: var(--clr-light);
}

.searchImages__overlay a:last-child > div {
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.searchImages__overlay a:last-child span {
  display: block;
  font-size: 0.8rem;
}
.searchImages__overlay a:last-child span:last-child {
  font-size: 0.65rem;
  font-weight: 400;
}

.credits {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.credits h1 {
  text-align: center;
}

.credits__description {
  max-width: 35rem;
  text-align: center;
  font-weight: 300;
  margin-top: 0.5rem;
}

.credits__toggle {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 1.5rem;
  cursor: pointer;
  font-size: 0.85rem;
  width: 100%;
  border-radius: 3em;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 0.75em 0;
  --max-width: 12rem;
  max-width: var(--max-width);
  font-weight: 600;
  border: 0.125rem solid var(--clr-primary);
}

.credits__toggle input[type="checkbox"]:checked ~ span:nth-of-type(2),
.credits__toggle span:first-of-type {
  color: var(--clr-primary);
}

.credits__toggle input[type="checkbox"]:checked ~ span:first-of-type {
  color: var(--clr-dark);
}

.credits__toggle input[type="checkbox"]:checked ~ .credits__box {
  left: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.credits__box {
  content: "";
  background: var(--clr-primary-light);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* margin-left: auto; */
  height: 100%;
  border-radius: 3em;
  transition: 0.25s;
  z-index: -1;
  width: calc(var(--max-width) / 2);
}

.credits__toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.credits__options {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  max-width: 48rem;
  margin-block: 3rem;
  /* margin-inline: auto; */
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}

.credits__option svg {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.5rem;
}

.credits__option {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1.5em;
  width: 100%;
  background: var(--clr-light);
  position: relative;
  z-index: 1;
  height: 14rem;
  border-radius: 1em;
  -webkit-filter: drop-shadow(0 5px 27px rgb(0 0 0 / 7%));
          filter: drop-shadow(0 5px 27px rgb(0 0 0 / 7%));
}

.credits__option h4 {
  font-weight: 600;
}

.credits__option.best-price {
  color: var(--clr-light);
  position: relative;
  background: var(--clr-primary);
}

.credits__option.best-price::after {
  content: "Most Popular";
  background: var(--clr-light);
  padding: 0.5em 1em;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 0.8rem;
  border-radius: 3rem;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--clr-dark);
}

.credits__option.best-price button {
  color: var(--clr-light);
}
.credits__option.best-price button svg path {
  stroke: var(--clr-light);
}

.credits__optionPrice {
  font-size: 1.5rem;
}

/* .credits__optionPrice span {
  font-size: 1.5rem;
} */

.credits__option button {
  background: transparent;
  padding: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.credits__option button svg {
  width: 1rem;
  margin-left: 0.5rem;
}
.credits__option button svg path {
  stroke: var(--clr-dark);
}

.credits__optionPriceYear {
  font-size: 0.9rem;
  display: block;
  margin-top: -0.05rem;
}

.credits__btn {
  border-radius: 1em;
  background: transparent;
  color: var(--clr-dark);
  font-weight: 600;
  width: 100%;
  max-width: 9rem;
  border: 0.1rem solid var(--clr-dark);
}

@media (min-width: 768px) {
  .credits {
    height: calc(100vh - 10rem);
  }
}

