/* .search__container {
  margin-top: 4rem;
} */

.search__shopCategory,
.search__communitiesCategory,
.search__videosCategory {
  display: grid;
  place-items: center;
  gap: 1rem;
}
.search__shopCategory,
.search__videosCategory {
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

.search__communitiesCategory {
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}
