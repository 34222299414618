.referRewards h1 {
  text-align: center;
  margin: 8rem 0 5rem 0;
}

.referRewards__map {
  /* background: red; */
  margin-top: 2rem;
  /* padding: 0 2em; */
}

.referRewards__map li {
  font-weight: 600;
  background: var(--clr-light);
}

.referRewards__map > div.one-component,
.referRewards__map > div {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  position: relative;
  justify-content: space-between;
}

.referRewards__map > div::before,
.referRewards__map > div::after {
  content: "";
  position: absolute;
  filter: invert(33%) sepia(25%) saturate(6038%) hue-rotate(201deg)
    brightness(102%) contrast(101%);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  z-index: -1;
}

.referRewards__map > div:first-of-type::after {
  width: 100%;
  left: 0;
  right: 0;
  /* height: 0.1rem; */
  height: 0.15rem;
  top: 2.5rem;
  /* border: 0.2rem dotted #077aff40; */

  /* background: #077aff40; */
}

.referRewards__map > div:first-of-type li:first-child {
  margin-left: -2.5rem;
}
.referRewards__map > div:first-of-type li:last-child {
  margin-right: 7.5rem;
}

.referRewards__imgContainer svg {
  width: 3rem;
  display: block;
  fill: var(--clr-primary);
}

.referRewards__imgContainer {
  padding: 1em;
  margin-bottom: 0.5rem;
  background: var(--clr-primary-light);
  cursor: pointer;
  position: relative;
  /* border: 0.15rem solid var(--clr-primary); */
}

.referRewards__map li.locked .referRewards__imgContainer::after {
  content: url("https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/47002e261e3a4fc729b59877e495d7012c40e306/lock.svg");
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  line-height: 1;
  border-radius: 50%;
  margin: auto;
  z-index: 10;
  transform: scale(1.1);
  background: var(--clr-primary);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.referRewards__map > div:nth-of-type(4),
.referRewards__map > div:nth-of-type(2) {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  /* margin-bottom: 10rem; */
}
.referRewards__map > div:nth-of-type(4) li,
.referRewards__map > div:nth-of-type(3) li,
.referRewards__map > div:nth-of-type(2) li {
  margin: 10rem -2.5rem 0;
}

/* .referRewards__map > div:nth-of-type(2) li:first-of-type {
  margin-left: 15rem;
} */

.referRewards__map > div:nth-of-type(4)::before,
.referRewards__map > div:nth-of-type(2)::before {
  height: 100%;
  width: 0.1rem;
  top: -4rem;
  right: 0;
}

.referRewards__map > div:nth-of-type(3)::after,
.referRewards__map > div:nth-of-type(4)::after,
.referRewards__map > div:nth-of-type(2)::after {
  width: 100%;
  left: 0;
  right: 0;
  height: 0.15rem;
  bottom: 4rem;
}

.referRewards__map > div:nth-of-type(3)::before {
  left: 0;
  height: 100%;
  /* max-height: 12rem; */
  right: unset;
  width: 0.1rem;
  bottom: 4.5rem;
}

.referRewards__map > div:nth-of-type(3) li:first-child {
  margin-left: 7.5rem;
}
.referRewards__map > div:nth-of-type(4) li:first-of-type,
.referRewards__map > div:nth-of-type(3) li:last-child {
  margin-right: 7.5rem;
}

/* arrow */

.referRewards__arrow svg {
  width: 2rem;
  fill: var(--clr-primary);
}

.referRewards__map > div:first-of-type .referRewards__arrow:first-of-type {
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translate(-40%, -80%);
}
.referRewards__map > div:first-of-type .referRewards__arrow:last-of-type {
  position: absolute;
  right: -0.75rem;
  top: calc(100% + 2rem);
  transform: rotate(90deg);
}

.referRewards__map > div:nth-of-type(4) .referRewards__arrow:nth-of-type(2),
.referRewards__map > div:nth-of-type(2) .referRewards__arrow:last-of-type,
.referRewards__map > div:nth-of-type(2) .referRewards__arrow:first-of-type {
  position: absolute;
  left: 75%;
  bottom: 3rem;
  transform: translateX(-75%) rotate(-180deg);
}

.referRewards__map > div:nth-of-type(4) .referRewards__arrow:nth-of-type(3),
.referRewards__map > div:nth-of-type(2) .referRewards__arrow:last-of-type {
  position: absolute;
  left: 25%;
  bottom: 3rem;
  transform: translateX(-25%) rotate(-180deg);
}

.referRewards__map > div:nth-of-type(3) .referRewards__arrow:first-of-type {
  position: absolute;
  left: -2.5rem;
  /* top: calc(100% + 2rem); */
  bottom: 55%;
  transform: rotate(90deg) translateY(-50%);
}
.referRewards__map > div:nth-of-type(3) .referRewards__arrow:last-of-type {
  position: absolute;
  left: 60%;
  bottom: 2.5rem;
  transform: translateX(-60%);
}
.referRewards__map > div:nth-of-type(4) .referRewards__arrow:first-of-type {
  position: absolute;
  right: -0.75rem;
  top: 25%;
  /* top: calc(100% + 2rem); */
  transform: rotate(90deg);
}

.referRewards__map > div:nth-of-type(4) .referRewards__arrow:nth-of-type(2) {
  left: 40%;
}
