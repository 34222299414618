.paymentMethod__container {
  margin-top: 4rem;
}

.paymentMethod__method {
  position: relative;
  width: auto;
  margin-top: 1rem;
  font-size: 0.85rem;
}
.paymentMethod__data,
.paymentMethod__method .dropdown-item-container label,
.paymentMethod__method .dropdown-container {
  padding: 1em 1.75em;
}

.paymentMethod__method .dropdown-container {
  /* box-shadow: 0rem 0rem 0.5rem #0002; */
  border: 0.1rem solid var(--clr-gray300);
  border-radius: 0.7em;
  margin-bottom: 1rem;
}

.paymentMethod__data,
.paymentMethod__method .dropdown-item-container {
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
  border-radius: 0.7em;
}

body.dark-mode .paymentMethod__method .dropdown-container,
body.dark-mode .paymentMethod__data,
body.dark-mode .paymentMethod__method .dropdown-item-container {
  box-shadow: 0rem 0rem 0.5rem #0005;
  border-radius: 0.7em;
}

.paymentMethod__container h1 {
  font-size: 1rem;
}

/* .paymentMethod__container p,
.paymentMethod__method label {
  font-weight: 700;
} */

.paymentMethod__items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  margin-top: 4rem;
  gap: 1rem;
}

.paymentMethod__item {
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
  padding: 2em;
  border: 0.1rem solid transparent;
  transition: 0.5s;
  border-radius: 0.5em;
}
.paymentMethod__item.active {
  background: var(--clr-primary-light);
  border: 0.1rem solid var(--clr-primary);
}

.paymentMethod__item:hover {
  cursor: pointer;
  box-shadow: inset 0rem 0rem 0.5rem #0002;
}

.paymentMethod__item img {
  display: flex;
  /* width: 4rem; */
  height: 5rem;
}
/* 
.paymentMethod__container img {
  margin-right: 1rem;
} */

.paymentMethod__data {
  padding: 1.5em;
}

.paymentMethod__data p {
  font-size: 0.8rem;
  margin-top: 1rem;
}

.paymentMethod__inputContainer {
  width: 100%;
  align-items: flex-start;
  position: relative;
}

.paymentMethod__inputContainer label {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0.5rem 0;
}

.paymentMethod__data {
  margin-top: 2rem;
}

.paymentMethod__data form {
  margin-top: 1rem;
}

.paymentMethod__profileInputSections {
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
}

.paymentMethod__inputContainerCard {
  border-radius: 0.25em;
  transition: 0.3s;
  max-width: 0;
  opacity: 0;
}
.paymentMethod__inputContainerCard.show {
  max-width: 2rem;
  opacity: 1;
  border-radius: 0.25em;
}
/* card number */

.paymentMethod__inputCardNumber {
  /* margin-top: 0.5rem; */
  width: 100%;
  gap: 0.5em;
  align-items: flex-start;
}

.paymentMethod__inputCardNumber input {
  width: 100%;
}

.paymentMethod__inputCardNumber > div:first-child {
  /* flex: 1 1 60%; */
  width: 100%;
}

.paymentMethod__inputCardNumber > div:last-child {
  margin-top: 0.5rem;
  width: 100%;
  gap: 1rem;
}

.paymentMethod__inputCardNumber > div:last-child > div {
  width: 100%;
}
/* .paymentMethod__inputCardNumber > div:nth-child(2),
.paymentMethod__inputCardNumber > div:nth-child(3) {
  flex: 1 1 20%;
} */

/* card number */
.paymentMethod__cardNumberSection {
  width: 100%;
  position: relative;
}

.paymentMethod__cardNumberSection > div {
  position: absolute;
  top: 50%;
  gap: 0.25rem;
  transform: translateY(-50%);
  right: 1rem;
}

/* .paymentMethod__inputCardNumber > div:first-child input {
  margin-top: 1rem;
} */

.paymentMethod__btnContainer {
  width: 100%;
  justify-content: space-between;
}

.paymentMethod__btnContainer button {
  width: 100%;
  margin-top: 2rem;
  max-width: 10rem;
  font-size: 0.55rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1.5em 1em;
}

.paymentMethod__btnContainer button:first-child {
  margin-right: 0.5rem;
}
.paymentMethod__btnContainer.no-active {
  color: var(--clr-gray400);
  cursor: not-allowed;
}
.paymentMethod__btnContainer.primary.active {
  color: var(--clr-light);
  cursor: pointer;
}
body.dark-mode .paymentMethod__btnContainer button:first-child {
  background: var(--clr-modal);
  color: var(--clr-light);
}

.paymentMethod__btnContainer svg {
  display: flex;
  margin-right: 1rem;
  margin-bottom: 0.1rem;
}

body.dark-mode .paymentMethod__btnContainer svg path {
  stroke: var(--clr-light);
}

@media (min-width: 550px) {
  .paymentMethod__btnContainer button {
    max-width: 15rem;
    font-size: 0.7rem;
    padding: 1.25em;
  }
}

@media (min-width: 900px) {
  .paymentMethod {
    min-height: calc(100vh - 8.5rem);
    margin-bottom: -3rem;
    overflow: hidden;
    padding: 0 2em 2em 2em;
  }

  .paymentMethod__inputContainer + .paymentMethod__inputContainer {
    margin-left: 1rem;
  }

  .paymentMethod__profileInputSections {
    flex-direction: row;
  }
  /* .paymentMethod__inputCardNumber > div:first-child input {
    margin-top: 0.5rem;
  } */
}
