.badges {
  width: 100%;
  margin: 2rem 0;
  border: 0.1rem solid var(--clr-primary);
  /* background: var(--clr-primary); */
  /* background: linear-gradient(
    to left,
    #2b91ff,
    #218bff,
    #1786ff,
    #0e80ff,
    #077aff
  ); */
  color: var(--clr-light);
  padding: 1.5em;
  border-radius: 1em;
}

.badges__header {
  width: 100%;
  justify-content: space-between;
}

.badges__header h3 {
  color: var(--clr-dark);
}

.badges__header img {
  opacity: 0.5;
  filter: invert(1);
  width: 100%;
  max-width: 1.5rem;
}

.badges__container {
  display: grid;
  place-items: flex-start;
  margin-top: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
}

.badges__badge {
  cursor: pointer;
}

.badges__badge img:first-child {
  width: 100%;
  filter: grayscale(1);
  /* margin-bottom: 1rem; */
}

.badge__details {
  position: fixed;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  pointer-events: none;
  opacity: 0;
  background: #000b;
}

.badge__details.show {
  pointer-events: all;
  opacity: 1;
}

.badge__detailsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-wrap: wrap;
  transform: scale(0);
  transition: 0.25s;
  /* overflow-y: scroll; */
  padding: 2em;
  background: var(--clr-light);
  box-shadow: 0rem 0rem 1rem #0002;
  border-radius: 0;
}

.badge__details.show .badge__detailsContainer {
  transform: scale(1);
}

.badge__detailsBadgeText {
  display: block;
  margin-top: 2rem;
  text-transform: uppercase;
  color: var(--clr-dark);
  font-weight: 800;
  font-size: 0.6rem;
  letter-spacing: 0.2rem;
}

.badge__detailsContainer h4 {
  color: var(--clr-primary);
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  margin-top: 0.25rem;
  font-size: 1.1rem;
}

.badge__detailsContainer p {
  color: var(--clr-text-dark);
  text-align: center;
  margin: 1.75rem 0;
  max-width: 22rem;
  font-size: 0.85rem;
}

.badge__detailsContainer > div:first-of-type {
  background: var(--clr-primary);
  font-size: 0.7rem;
  border-radius: 3em;
  padding: 0.75em 0.5em;
}

.badge__detailsContainer > div:first-of-type span {
  padding: 0 0.5em;
  text-transform: uppercase;
  font-weight: 600;
}

.badge__detailsContainer > div:first-of-type span:first-child {
  border-right: 0.1rem solid var(--clr-light);
}

.badges__more {
  display: grid;
  place-items: center;
  font-size: 2rem;
  font-weight: 600;
  background: var(--clr-light-gray);
  color: var(--clr-gray);
  width: 100%;
  max-width: 6rem;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  height: 6rem;
}

.badge__socialLinksContainer h4 {
  font-size: 0.7rem;
  margin-bottom: 0.25rem;
  margin-top: 1rem;
  color: var(--clr-dark);
  text-transform: none;
}

.badge__socialLinks li + li {
  margin-left: 0.45rem;
}

.badge__socialLinksContainer .badge__socialLinks svg {
  width: 2rem;
  cursor: pointer;
  fill: var(--clr-dark);
}

.badge__detailsContainer .cross-mark {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

/* .badge__socialLinks  */

@media (min-width: 1200px) {
  .badge__detailsContainer {
    position: relative;
    width: fit-content;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    max-width: 20rem;
    height: fit-content;
    border-radius: 1em;

    box-shadow: 0 0.5rem 1rem #0007;
    /* overflow-y: hidden; */
  }

  .badge__detailsBadgeText {
    margin-top: 3rem;
  }

  .badge__detailsContainer img {
    position: absolute;
    top: -40%;
    transform: translateY(100%);
    /* box-shadow: 0 0.25rem 0.5rem #0004; */
    filter: drop-shadow(0 0.25rem 0.5rem #0003);
  }

  .badge__socialLinksContainer {
    position: absolute;
    top: calc(100% + 1rem);
  }

  .badge__socialLinksContainer h4 {
    color: var(--clr-light);
    margin-top: 0;
  }

  .badge__socialLinksContainer .badge__socialLinks svg {
    width: 2rem;
    cursor: pointer;
    fill: var(--clr-light);
  }

  .badge__detailsContainer .cross-mark {
    position: absolute;
    bottom: calc(100% + 2.5rem);
    right: 0;
    top: unset;
  }

  .badge__detailsContainer .cross-mark::after,
  .badge__detailsContainer .cross-mark::before {
    background: var(--clr-light);
  }
}
