.sidebar {
  width: 100%;
  position: fixed;
  justify-content: center;
  align-items: initial;
  background: var(--clr-header-light);
  bottom: 0;
  z-index: 10;
  color: var(--clr-darker);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* box-shadow: 0rem -0.2rem 0.7rem #0003; */
}

body.dark-mode .sidebar {
  /* background: var(--clr-light); */
  background: var(--clr-darker);
}

.sidebar__logo {
  display: none;
  cursor: pointer;
}

.sidebar__logoIcon {
  display: grid;
  place-items: center;
  border-radius: 0.3em;
  width: 2rem;
  height: 2rem;
  background: var(--clr-primary);
}

.sidebar__logoName {
  color: var(--clr-primary);
  margin-left: 0.75rem;
  font-weight: 500;
  font-size: 1.4rem;
}

.sidebar__logo svg path:first-child {
  fill: var(--clr-primary);
}
.sidebar__logo svg path + path {
  stroke: var(--clr-primary);
}

body.dark-mode .sidebar__logo svg path:first-child {
  fill: var(--clr-light);
}
body.dark-mode .sidebar__logo svg path + path {
  stroke: var(--clr-light);
}

body.dark-mode .sidebar__logoName {
  color: var(--clr-light);
}

.sidebar__navItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7em 2em;
}

.sidebar__navItem {
  display: flex;
}

.sidebar__navItem svg {
  width: 25px;
  height: 25px;
}

.sidebar__navItem svg path {
  fill: #818e94;
  stroke: #818e94;
}
.sidebar__navItem:nth-of-type(2) svg path,
.sidebar__navItem:first-child svg path {
  stroke: initial;
}

body.dark-mode .sidebar__navItem svg path {
  fill: var(--clr-light);
  stroke: var(--clr-light);
}

.sidebar__navItem svg rect {
  fill: #818e94;
}
body.dark-mode .sidebar__navItem svg rect {
  fill: var(--clr-light);
}

/* .sidebar__navItem svg path.white-mode {
} */

.sidebar__navItem.center {
  position: relative;
  top: -2rem;
  padding: 0.7em;
  background: var(--clr-primary);
  border: 0.15rem solid var(--clr-header-light);
  box-shadow: 0px 0px 0px 0.1rem #2c91fd;
}

body.dark-mode .sidebar__navItem.center {
  border: 0.2rem solid var(--clr-darker);
}

body.dark-mode .sidebar__navItem.center svg path,
.sidebar__navItem.center svg path {
  fill: var(--clr-light);
}

.sidebar__navItem p {
  display: none;
  color: var(--clr-text-dark);
}
body.dark-mode .sidebar__navItem p {
  color: var(--clr-light);
}

.sidebar__modalLevel {
  display: none;
}

/* .sidebar__modalLevel > div:first-of-type img {
  width: 100%;
  max-width: 0.75rem;
  margin: 0;
} */

.sidebar__profile {
  display: none;
}

/* show at cetain width */
.sidebar__upgrade,
.sidebar__navItem.more,
.sidebar__navItem.connections,
.sidebar__navItem.images,
.sidebar__navItem.messages-nav,
.sidebar__navItem.quiz-nav,
.sidebar__navItem.geniuspass,
.sidebar__navItem.communities-nav,
.sidebar__navItem.shop-nav {
  display: none;
}

@media (min-width: 500px) {
  .sidebar__navItem svg {
    width: 28px;
    height: 28px;
  }
}

@media (min-width: 900px) {
  .sidebar {
    width: 100%;
    left: 0;
    justify-content: space-between;
    background: none;
    margin-top: auto;
    height: calc(100vh - 4rem);
    top: 4rem;
    padding: 1.5em 1.25em 1em 1.25em;
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
    max-width: 16.5rem;
    background: var(--clr-light);
    box-shadow: none;

    border-right: 0.1rem solid var(--clr-gray300);
  }

  .sidebar__navItem.account,
  .sidebar__navItem.messageMobile {
    display: none;
  }
  .sidebar__navItem.more,
  .sidebar__navItem.connections,
  .sidebar__navItem.images,
  .sidebar__navItem.messages-nav,
  .sidebar__navItem.quiz-nav,
  .sidebar__navItem.geniuspass,
  .sidebar__navItem.communities-nav,
  .sidebar__navItem.shop-nav {
    display: flex;
  }

  body.dark-mode .sidebar {
    background: var(--clr-dark);
    border-right: 0.1rem solid var(--clr-secondary-dark);
  }

  .sidebar::-webkit-scrollbar {
    display: none;
  }

  .sidebar__moreNav {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(calc(-50% - 1rem));
    padding: 1em;
    opacity: 0;
    width: 100%;
    visibility: hidden;
    pointer-events: none;
    background: var(--clr-light);
    height: fit-content;
    border-radius: 0 0.5em 0.5em 0;
    max-width: 14rem;
    box-shadow: 0.2rem 0px 0.5rem #0002;
    transition: left 0.3s ease-in-out, opacity 0.05s 0.25s;
  }

  .sidebar__navItem svg path {
    fill: #818e94;
    stroke: none;
  }
  body.dark-mode .sidebar__navItem svg path {
    fill: var(--clr-light);
    stroke: initial;
  }

  body.dark-mode .sidebar__moreNav {
    background: var(--clr-dark);
    box-shadow: 0px 0px 0.5rem #191a1f;
  }

  .sidebar__moreNav.show {
    left: 16.5rem;
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }

  /* .sidebar__nav {
    margin-top: 1.5rem;
  } */

  .sidebar__navItems {
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
    gap: 0.3rem;
    padding: 0;
  }

  .sidebar__navItem.more {
    position: relative;
    z-index: 1;
    justify-content: space-between;
  }

  /* body.dark-mode .sidebar__navItem.more svg path {
    fill: transparent;
  } */

  .sidebar__navItem.more::after {
    content: "";
    width: 3rem;
    height: 100%;
    padding: 2em;
    right: -2rem;
    position: absolute;
  }

  .sidebar__navItem.more::before {
    content: "";
    width: 100%;
    height: 100%;
    padding: 2.2rem;
    position: absolute;
    z-index: -1;
  }

  .sidebar__navItem svg {
    margin-right: 1.5rem;
    width: 20px;
    height: 20px;
  }

  .sidebar__navItem svg.sidebar__moreIcon {
    margin-right: 0 !important;
  }

  /* .sidebar__navItem.white-mode.center {
    border: none;
  } */

  .sidebar__navItem.center,
  .sidebar__navItem {
    display: flex;
    align-items: center;
    padding: 0.6em 1em;
    border-radius: 0.5em;
    color: var(--clr-text-dark);
    font-size: 1rem;
    cursor: pointer;
  }

  body.dark-mode .sidebar__navItem:hover {
    /* background: var(--clr-hover-dark); */
    background: var(--clr-modal-darker);
  }
  .sidebar__navItem.center {
    background: var(--clr-light);
  }

  /* body.dark-mode .sidebar__navItem.white-mode.center {
    background: var(--clr-light);
  } */

  .sidebar__navItem.center svg path {
    fill: #818e94;
  }

  body.dark-mode .sidebar__navItem.center svg path {
    fill: var(--clr-light);
  }
  /* .sidebar__navItem.white-mode.active,
  .sidebar__navItem.white-mode.center:hover,
  .sidebar__navItem.white-mode:hover {
    background: var(--clr-light-gray);
  } */
  .sidebar__navItem.center:hover,
  .sidebar__navItem:hover {
    background: var(--clr-light-gray);

    /* background: var(--clr-modal-light); */
  }

  .sidebar__navItem.active {
    position: relative;
  }

  /* body.dark-mode .sidebar__navItem.active p {
    color: #569EE8;
  } */
  body.dark-mode .sidebar__navItem.active p,
  .sidebar__navItem.active p {
    color: var(--clr-primary);
  }

  /* body.dark-mode .sidebar__navItem.active svg path {
    fill: #569EE8;
  } */
  body.dark-mode .sidebar__navItem.active svg path,
  .sidebar__navItem.active svg path {
    fill: var(--clr-primary);
  }

  body.dark-mode .sidebar__navItem.active::after,
  .sidebar__navItem.active:after {
    content: "";
    position: absolute;
    left: -0.3rem;
    top: 50%;
    transform: translateY(-50%);
    background: var(--clr-primary);
    height: 90%;
    width: 0.1rem;
  }

  .sidebar__navItem p {
    display: flex;
    font-size: 0.95rem;
  }

  .sidebar__navItem.center {
    /* background: var(--clr-dark); */
    box-shadow: none;
    border: none;
    top: 0;
  }
  body.dark-mode .sidebar__navItem.center {
    background: var(--clr-dark);
    border: none;
  }

  body.dark-mode .sidebar__navItem.center:hover {
    background: var(--clr-modal-darker);
    /* background: var(--clr-hover-dark); */
  }

  .sidebar__logo {
    display: flex;
    justify-content: center;
  }

  .sidebar__modalLevel {
    display: block;
    cursor: pointer;
    background: var(--clr-modal-light);
    border: 0.1rem solid var(--clr-gray300);
    padding: 1em 1.5em 1.5em 1.5em;
    border-radius: 0.5em;
  }

  body.dark-mode .sidebar__modalLevel {
    background: var(--clr-modal-darker);
    /* background: var(--clr-modal); */
    border: none;
  }

  .sidebar__modalLevel:hover {
    background: var(--clr-light-gray);
  }

  body.dark-mode .sidebar__modalLevel:hover {
    background: var(--clr-hover-dark);
  }

  .sidebar__modalLevel p {
    font-size: 0.85rem;
    color: var(--clr-text-dark);
  }
  body.dark-mode .sidebar__modalLevel p {
    color: #c4c4c4;
  }

  .sidebar__modalLevelHeader {
    margin-bottom: 0.5rem;
  }

  .sidebar__modalLevelHeader h3 {
    font-weight: 500;
    color: var(--clr-primary);
    font-size: 0.85rem;
  }

  .sidebar__modalLevelHeader img {
    width: 100%;
    max-width: 1.4rem;
    margin-right: 0.5rem;
  }

  .sidebar__modalLevelProgress p {
    margin: 0.1rem 0 0.1rem auto;
    font-size: 0.85rem;
    width: fit-content;
  }

  .sidebar__modalLevelProgressBar {
    position: relative;
    width: 100%;
    background: var(--clr-gray300);
    height: 3.5px;
    border-radius: 3rem;
  }

  body.dark-mode .sidebar__modalLevelProgressBar {
    background: #373737;
  }

  .sidebar__modalLevelProgressBar::after {
    content: "";
    position: absolute;
    width: 0%;
    border-radius: 0.5em;
    background: var(--clr-primary);
    height: 100%;
  }
  body.dark-mode .sidebar__modalLevelProgressBar::after {
    background: var(--clr-light-gray);
  }

  .sidebar__profile {
    width: calc(100% + 1rem);
    display: flex;
    margin: 1rem 0 0 -0.5rem;
  }

  .sidebar__upgrade {
    display: flex;
    padding: 1em 1.25em;
    cursor: pointer;
    position: relative;
    border-radius: 0.7em;
    color: var(--clr-text-dark);
    /* box-shadow: 0 0 1rem #077aff50; */
    background: var(--clr-primary-light);
  }

  .sidebar__upgradeImgContainer {
    display: grid;
    place-items: center;
    position: absolute;
    top: -2rem;
    left: 0;
    background: var(--clr-light);
    right: 0;
    margin: 0 auto;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.1rem solid var(--clr-gray300);
  }

  .sidebar__upgradeImgContainer img {
    display: block;
    max-width: 2.5rem;
    width: 100%;
  }

  .sidebar__upgradePrice {
    margin-top: 1rem;
    font-size: 1.8rem;
    color: var(--clr-primary);
    font-weight: 600;
  }

  .sidebar__upgradePrice span {
    font-size: 0.9rem;
    color: var(--clr-gray400);
    font-weight: 400;
  }

  .sidebar__upgradeDesc {
    margin: 0.25rem 0 1rem 0;
    text-align: center;
  }

  .sidebar__upgradeDesc h3 {
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  .sidebar__upgradeDesc p {
    font-size: 0.9rem;
  }

  .sidebar__upgrade button {
    padding: 0.75em 2em;
    border-radius: 0.75em;
  }
}
