.videos {
  position: relative;
  display: grid;
  /* overflow-x: hidden; */
}

.videos::-webkit-scrollbar {
  display: none;
}

.videos__header {
  position: fixed;
  width: 100%;
  padding-bottom: 1em;
  z-index: 2;
  background: #fff;
  top: 5rem;
  left: 0;
}

body.dark-mode .videos__header {
  background: var(--clr-dark-background);

  /* background: var(--clr-light); */
  /* box-shadow: 0rem 0.2rem 0.2rem #0003; */
}

.videos__container {
  margin-top: 3.5rem;
}

.videos__container.videos-teacher {
  margin-top: 0;
}

.videos__content {
  display: grid;
  place-items: center;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  /* grid-template-columns: repeat(1, minmax(14rem, 100%)); */
  gap: 1rem;
}

@media (min-width: 900px) {
  .videos__header {
    /* width: calc(100% - 17rem); */
    padding-bottom: 0;
    background: var(--clr-light);
    width: calc(100% - 16.5rem);
    right: 0;
    overflow: hidden;
    margin-left: auto;
    /* top: 5rem; */
    top: 4.6rem;
    /* z-index: 10; */
  }

  body.dark-mode .videos__header {
    background: var(--clr-dark);
    box-shadow: none;
  }
}

/* @media (min-width: 1200px) {
  .videos__header {
    
  }
} */
