.interestedCard {
  width: 100%;
  cursor: pointer;
  position: relative;
  /* width: fit-content; */
}

/* .interestedCard:last-child::after {
  content: "asdasd";
  font-size: 4rem;
  pointer-events: none;
  visibility: hidden;
} */

.interestedCard__selected {
  position: absolute;
  display: grid;
  place-items: center;
  width: 1.5rem;
  border-radius: 0.3em;
  height: 1.5rem;
  top: 0.5rem;
  right: 0.5rem;
  background: var(--clr-primary);
}

.interestedCard__img {
  width: 100%;
  display: block;
  height: 10rem;
  /* height: 8rem; */
  object-fit: cover;
  border-radius: 0.75em;
}

.interestedCard__title {
  position: absolute;
  bottom: 0.5rem;
  right: 0.8rem;
  color: var(--clr-light);
  font-weight: 500;
  font-size: 0.9rem;
}

/* @media (min-width: 450px) {
  .interestedCard__img {
    width: 100%;
    max-width: 10rem;
    height: 10rem;
    object-fit: contain;
    border-radius: 0.9em;
  }
} */
