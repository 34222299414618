.shop__header {
  margin-top: 0.5rem;
  /* flex-direction: column; */
  flex-wrap: wrap;
  /* align-items: flex-start; */
  justify-content: space-between;
}

.shop__headerOptionItem {
  cursor: pointer;
}

.shop__headerOptionItem.list,
.shop__headerOptionItem.grid {
  display: none;
}

.shop__headerOptionItem svg {
  margin-right: 0.3rem;
}

.shop__headerOptionItem.viewSelected {
  color: var(--clr-primary);
}

.shop__headerOptionItem.viewSelected svg path {
  stroke: var(--clr-primary);
}

.shop__circleRounded {
  padding: 0.2em 0.5em;
  background: var(--clr-header-light);
  font-size: 0.7rem;
  color: var(--clr-primary);
  margin-right: 0.3rem;
  border-radius: 2em;
}
body.dark-mode .shop__circleRounded {
  background: var(--clr-modal);
}

.shop__main {
  align-items: flex-start;
  margin-top: 0.5rem;
}

.shop__mainContainer {
  flex: 1;
  display: grid;
  place-items: center;
  /* grid-template-columns: auto; */
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  /* grid-template-columns: repeat(2, minmax(14rem, 100%)); */
  gap: 1.5rem;
  /* padding-top: 1.5em; */
}

.shop__mainContainer.list {
  display: flex;
  flex-direction: column;
}

@media (min-width: 900px) {
  .shop {
    margin-bottom: -5rem;
    /* height: calc(100vh - 5rem); */
    /* overflow: hidden; */
  }

  .shop__main {
    width: calc(100% + 4rem);
    margin-left: -2rem;
    margin-top: 0.5rem;
  }

  .shop__mainContainer {
    padding: 3em 2em 0 2em;
    height: 87vh;
    scrollbar-width: none;
    overflow-y: scroll;
  }

  .shop__mainContainer::after {
    content: "asdasd";
    visibility: hidden;
    margin-top: -1rem;
    pointer-events: none;
  }

  .shop__mainContainer::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 1200px) {
  .shop__headerOptionItem.list,
  .shop__headerOptionItem.grid {
    display: flex;
  }

  .shop__headerOptionItem + .shop__headerOptionItem {
    margin-left: 0.5rem;
  }
}

/* @Media */
