.viewProjects {
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000b;
  z-index: 200;
}

.viewProjects__close {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  height: 25vh;
  opacity: 0;
  z-index: 90;
}
.viewProjects__close.bot {
  top: initial;
  bottom: 0;
}

.viewProjects iframe::before {
  content: "asdasd";
  position: absolute;
}

.viewProjects iframe,
.viewProjects img {
  display: block;
  width: 100%;
  position: relative;
  object-fit: cover;
  background: linear-gradient(to left, #56a8ff, #077aff);
  background-size: 300% 300%;
  animation: gradient 3s ease infinite;
  box-shadow: 0 0 2rem #000e;
  /* margin: 0 2rem; */
  border-radius: 0.5em;
  height: 50vh;
}

.profileProjects__imgContainer.video-show {
  filter: grayscale(1);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.BrainhubCarouselItem.BrainhubCarouselItem--active iframe,
.BrainhubCarouselItem.BrainhubCarouselItem--active
  .profileProjects__imgContainer.video-show {
  filter: grayscale(0);
  transform: scale(1.1);
}

.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
  padding: 10em 0;
}

.BrainhubCarousel__arrows {
  position: absolute;
  background: transparent;
  cursor: pointer;
  top: 50%;
  left: 24%;
  z-index: 100;
  transform: translate(-50%, -50%);
}

.BrainhubCarousel__arrows:hover:enabled,
.BrainhubCarousel__arrows:hover {
  background: transparent;
}
.BrainhubCarousel__arrows:last-of-type {
  left: 76% !important;
}

.BrainhubCarousel__dots {
  position: absolute;
  top: 83%;
  z-index: 100;
  transform: translateY(-100%);
}

.BrainhubCarousel__dots .BrainhubCarousel__dot {
  padding: 0.25rem;
  opacity: 0.5;
}

.BrainhubCarousel__dots
  .BrainhubCarousel__dot.BrainhubCarousel__dot--selected::before {
  transform: scale(1.25);
  background: var(--clr-primary);
}

.BrainhubCarousel__dots .BrainhubCarousel__dot::before {
  background: var(--clr-light);
  width: 0.5rem;
  height: 0.5rem;
}
