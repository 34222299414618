.profileCard {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75em 1em;
  border-radius: 0.5em;
  color: var(--clr-darker);
  background: var(--clr-modal-light);
  cursor: pointer;
}

body.dark-mode .profileCard {
  background: var(--clr-modal);
  color: var(--clr-light);
  border: none;
}

.profileCard__profileLeft.header,
.profileCard.header {
  flex-direction: row-reverse;
  border: none;
}

.profileCard.header {
  background: transparent;
  width: fit-content;
  padding: 0;
}

.profileCard__profileLeft {
  display: flex;
  align-items: center;
}

.profileCard svg {
  transform: rotate(180deg);
}

.profileCard__profileImg {
  position: relative;
  display: flex;
}

.profileCard__dropdownProfiePicture,
.profileCard__profileImg img {
  width: 100%;
  height: 2rem;
  object-fit: cover;
  width: 2rem;
}

.profileCard__dropdownProfiePicture {
  margin-bottom: 0.5rem;
}

.profileCard__dropdow {
  align-items: flex-start;
  gap: 1rem;
  position: absolute;
  padding: 1em 1.5em;
  background: var(--clr-light);
  color: var(--clr-darker);
  opacity: 0;
  bottom: 1rem;
  visibility: hidden;
  pointer-events: none;
  border-radius: 0.5em;
  left: 0;
  width: max-content;
  max-width: 40rem;
  box-shadow: 0rem 0rem 1rem #0003;
  transition: left 0.4s ease-out, opacity 0.1s 0.2s;
}

body.dark-mode .profileCard__dropdow {
  background: var(--clr-dark);
  /* background: var(--clr-modal); */
  color: var(--clr-light);
  box-shadow: 0rem 0rem 1rem #000e;
}

.profileCard__dropdow.header {
  top: 0;
  left: initial;
  right: 1.5rem;
  bottom: initial;
  transition: top 0.4s ease-out, opacity 0.1s 0.2s;
}

.profileCard__dropdow.show {
  opacity: 1;
  visibility: visible;
  pointer-events: initial;
  z-index: 30;
  left: 105%;
}

.profileCard__dropdow.header.show {
  top: 100%;
  left: 1rem;
  bottom: initial;
}

.profileCard__dropdownHeader {
  /* align-items: flex-start; */
  gap: 2rem;
  margin: -1rem 0 0 -1.5rem;
  padding: 1rem 1.5em;
  border-radius: 0.5em;
  width: calc(100% + 3rem);
  cursor: default;
}

/* .profileCard__dropdownHeader:hover {
  background: var(--clr-light-gray);
} */

body.dark-mode .profileCard__dropdownHeader:hover {
  background: var(--clr-modal-darker);
  /* background: var(--clr-hover-dark); */
}

.profileCard__dropdowProfileDetail p,
.profileCard__dropdownHeader small {
  text-transform: uppercase;
}

.profileCard__dropdownProfileText {
  text-align: center;
}

.profileCard__dropdownProfileText > div:first-of-type {
  margin-top: 0.25rem;
}

/* .profileCard__dropdowProfileDetail {
  align-items: flex-start;
  gap: -1rem;
} */

.profileCard__dropdowProfileDetail:nth-of-type(2),
.profileCard__dropdowProfileDetail:nth-of-type(3) {
  margin-top: -0.2rem;
}

.profileCard__dropdowProfileDetail:nth-of-type(2) p {
  letter-spacing: 0.1rem;
}

.profileCard__dropdowProfileDetail:nth-of-type(3) p {
  background: #28c76f31;
  color: var(--clr-success);
  font-size: 0.65rem;
  font-weight: 600;
  padding: 0.1em 0.5em;
  border-radius: 0.25em;
  text-align: center;
  color: var(--clr-success);
}

.profileCard__dropdowProfileDetail small {
  font-size: 0.65rem;
  font-weight: 500;
  letter-spacing: 1px;
  color: #828282;
  margin-bottom: 2rem;
}

.profileCard__dropdowProfileDetail p {
  color: #0d0e11;
  font-size: 0.8rem;
}

body.dark-mode .profileCard__dropdowProfileDetail p {
  color: var(--clr-light);
}

.profileCard__dropdownProfileText h3,
.profileCard__profileText h3 {
  font-weight: 300;
  font-size: 0.95rem;
}

.profileCard__dropdownProfileText h3 svg {
  width: 1rem;
  transform: rotate(0deg);
  margin-bottom: -0.1rem;
}

.profileCard__dropdownProfileText p,
.profileCard__profileText p {
  color: var(--clr-primary);
  font-size: 0.8rem;
}

.profileCard__profileText.header {
  display: none;
  text-align: right;
  margin-right: 1rem;
}

.profileCard__profileText.header > div {
  flex-direction: row-reverse;
}

.profileCard__badge {
  width: 100%;
  max-width: 0.9rem;
  margin: 0 0.25rem;
}

.profileCard__dropdownMenu {
  width: 100%;
  gap: 0.2rem;
  align-items: flex-start;
}

.profileCard__dropdownMenuItem {
  font-size: 0.85rem;
  cursor: pointer;
  margin-left: -1em;
  width: calc(100% + 2em);
  padding: 0.75em 1.25em;
  color: var(--clr-text-dark);
  border-radius: 0.5em;
}

body.dark-mode .profileCard__dropdownMenuItem {
  color: var(--clr-light);
}
body.dark-mode .profileCard__dropdownMenuItem.membership,
.profileCard__dropdownMenuItem.membership {
  /* background: #ffb70017; */
  color: var(--clr-primary);
}
body.dark-mode .profileCard__dropdownMenuItem.membership svg path,
.profileCard__dropdownMenuItem.membership svg path {
  fill: var(--clr-primary);
}

.profileCard__dropdownMenuItem:hover {
  background: var(--clr-light-gray);
}

body.dark-mode .profileCard__dropdownMenuItem:hover {
  background: var(--clr-modal-darker);
  /* background: var(--clr-hover-dark); */
}

.profileCard__dropdownMenuItem svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}
.profileCard__dropdownMenuItem svg path {
  fill: #818e94;
}

/* .profileCard__dropdownMenuItem.withdrawal-option svg path:first-of-type,
.profileCard__dropdownMenuItem:first-child svg path {
  fill: transparent;
  stroke: #818e94;
} */

body.dark-mode .profileCard__dropdownMenuItem svg path {
  fill: var(--clr-light);
}

/* toggle */
.profileCard__dropdownMenuItem.toggle {
  padding: 0;
  justify-content: center;
}
.profileCard__dropdownMenuItem.toggle:hover {
  background: none;
}
body.dark-mode .profileCard__dropdownMenuItem.toggle:hover {
  background: none;
}

.profileCard__dropdownToggle {
  position: relative;
  width: 7rem;
  height: 2.5rem;
  border-radius: 3em;
  padding: 0.25em;
  background: var(--clr-light-gray);

  transition: background 0.2s ease-in-out;
}

body.dark-mode .profileCard__dropdownToggle {
  background: var(--clr-hover-dark);
}

.profileCard__dropdownToggle input {
  visibility: hidden;
  pointer-events: none;
}

.profileCard__dropdownToggleIcon {
  position: absolute;
  top: 50%;
  width: 2rem;
  height: calc(100% - 0.5em);
  background: var(--clr-gray);
  transform: translateY(-50%);
  left: 0.25rem;
  transition: left 0.5s ease-in;
}

.profileCard__dropdownToggle input:checked ~ .profileCard__dropdownToggleIcon {
  left: calc(100% - 2.6em);
  background: var(--clr-darker);

  /* transform: translate(calc(-100% - 0.25em), -50%); */
}

.profileCard__dropdownToggle
  input:checked
  ~ .profileCard__dropdownToggleIcon
  svg {
  transform: rotate(2turn);
}

.profileCard__dropdownToggleIcon svg circle {
  fill: var(--clr-gray);
  transition: transform 1s 0.2s;
}

.profileCard__dropdownToggleIcon svg circle:last-child {
  transform: translateX(0);
  fill: var(--clr-dark);
  transition: transform 1s 0.2s;
}

.profileCard__dropdownToggleIcon svg rect {
  fill: var(--clr-dark);
}

.profileCard__dropdownToggle
  input:checked
  ~ .profileCard__dropdownToggleIcon
  svg
  circle:last-child {
  fill: var(--clr-light);
  transform: translateX(-5px);
}
.profileCard__dropdownToggle
  input:checked
  ~ .profileCard__dropdownToggleIcon
  svg
  circle {
  fill: var(--clr-darker);
}
.profileCard__dropdownToggle
  input:checked
  ~ .profileCard__dropdownToggleIcon
  svg
  rect {
  fill: var(--clr-light);
}

.profileCard__dropdownToggleIcon svg {
  width: 1.3rem;
  margin-left: 0.3rem;
  height: 1.3rem;
  transform: rotate(0);
  transition: transform 1s ease-in-out;
}

/* .profileCard__settingPopup {
  visibility: hidden;
  pointer-events: none;
}
.profileCard__settingPopup.show {
  visibility: visible;
  pointer-events: initial;
} */

@media (min-width: 768px) {
  .profileCard:hover {
    background: var(--clr-light-gray);
  }
  body.dark-mode .profileCard:hover {
    background: var(--clr-hover-dark);
  }

  .profileCard__dropdownProfiePicture,
  .profileCard__profileImg img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .profileCard__profileText {
    margin-left: 1rem;
  }

  .profileCard__profileText p {
    color: var(--clr-primary);
    font-size: 0.75rem;
  }

  .profileCard__dropdownProfileText h3,
  .profileCard__profileText h3 {
    font-weight: 500;
    color: var(--clr-text-dark);
    font-size: 0.85rem;
  }

  body.dark-mode .profileCard__dropdownProfileText h3,
  body.dark-mode .profileCard__profileText h3 {
    color: var(--clr-light);
    font-weight: 300;
  }
}

@media (min-width: 1200px) {
  .profileCard {
    border: 0.1rem solid var(--clr-gray300);
  }
  /* 
  .profileCard__profileImg::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0.8rem;
    height: 0.8rem;
    background: var(--clr-success);
    border-radius: 50%;
    border: 0.2rem solid var(--clr-modal-light);
  }
  body.dark-mode .profileCard__profileImg::after {
    border: 0.2rem solid var(--clr-modal);
  }

  .profileCard.header .profileCard__profileImg::after {
    border: 0.2rem solid var(--clr-light);
  }
  body.dark-mode .profileCard.header .profileCard__profileImg::after {
    border: 0.2rem solid var(--clr-modal);
  } */

  .profileCard.header {
    padding: 0.5em 1em;
  }

  .profileCard__profileText.header {
    display: block;
  }

  .profileCard__dropdow.header.show {
    left: initial;
  }
}
