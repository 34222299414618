/* table */
.teacherTableCreate__table > div:first-child {
  justify-content: space-between;
  padding: 0 1em 0 0;
}
.teacherTableCreate__table > div:first-child button {
  margin: 1rem 0;
}

.teacherTableCreate__table {
  margin-top: 1rem;
  width: 100%;
  background: var(--clr-light);
  box-shadow: 0 10px 27px 0 rgb(0 0 0 / 10%);
  /* box-shadow: 0 0 1rem #00000022; */
  border-radius: 1em;
}
.teacherTableCreate__tableContainer {
  overflow-x: scroll;
}

.teacherTableCreate__table h3 {
  padding: 1em;
  font-weight: 600;
  font-size: 1.05rem;
  color: var(--clr-text-dark-header);
}

.teacherTableCreate__table table {
  width: 100%;
  border-collapse: collapse;
}
.teacherTableCreate__table th {
  text-align: left;
  padding: 1em 1.5em;
  font-size: 0.7rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--clr-text-dark);
  border-bottom: 0.1rem solid var(--clr-gray450);
  /* background: var(--clr-light-gray); */
}

.teacherTableCreate__table tr {
  cursor: pointer;
}

.teacherTableCreate__table tr:first-child th:last-child {
  border-radius: 0 0.5em 0 0;
}
.teacherTableCreate__table tr:first-child th:first-child {
  border-radius: 0.5em 0 0 0;
}
.teacherTableCreate__table tr:last-child td:last-child {
  border-radius: 0 0 0 0.5em;
}
.teacherTableCreate__table tr:last-child td:first-child {
  border-radius: 0 0 0.5em 0;
}

body.dark-mode .teacherTableCreate__table th {
  /* border-bottom: 0.1rem solid var(--clr-secondary-dark); */
  background: var(--clr-modal);
}

/* .teacherTableCreate__table tr:nth-of-type(2n + 1) {
      background: #077aff10;
    }
    body.dark-mode .teacherTableCreate__table tr:nth-of-type(2n + 1) {
      background: var(--clr-modal);
    } */

.teacherTableCreate__table td {
  padding: 1em 1.5em;
  font-size: 0.8rem;
  font-weight: 500;
  border-bottom: 0.1rem solid var(--clr-gray450);
  color: var(--clr-text-dark);
}

.teacherTableCreate__tableName h4 {
  font-size: 0.9rem;
  font-weight: 700;
}

.teacherTableCreate__tableName span {
  font-size: 0.7rem;
  font-weight: 500;
}

td.teacherTableCreate__tableType {
  font-size: 0.9rem;
}

.teacherTableCreate__tableType span {
  display: block;
  font-weight: 700;
}

.teacherTableCreate__status span {
  padding: 0.25em 0.75em;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 0.5em;
  color: var(--clr-light);
}

.teacherTableCreate__status.active span {
  background: var(--clr-success);
}
.teacherTableCreate__status.delete span {
  background: var(--clr-dark-gray);
}

.teacherTableCreate__tableContainer .teacherTableCreate__delete {
  color: var(--clr-invalid);
  font-weight: 600;
  cursor: pointer;
}

@media (min-width: 900px) {
  .teacherTableCreate__tableContainer {
    scrollbar-width: none;
  }
  .teacherTableCreate__tableContainer::-webkit-scrollbar {
    display: none;
  }
}
